import React from "react";
import DashBoardNavItem from "./navItems/DashBoardNavItem";
import FindShowHostNavItem from "./navItems/FindShowHostNavItem";
import MessageNavItem from "./navItems/MessageNavItem";
import MatchingManagementNavItem from "./navItems/MatchingManagementNavItem";
import WalletNavItem from "./navItems/WalletNavItem";
import AccountNavItem from "./navItems/AccountNavItem";
import AdProductNavItem from "./navItems/AdProductNavItem";
import { Link, useLocation } from "react-router-dom";
import EndPoints from "../../EndPoints";
import HostProfileSettingsNavItem from "./navItems/ProfileSettingsNavItem";
import BankAccountNavItem from "./navItems/BankAccountNavItem";

function ShowhostNav({
  className,
  ...rest
}: Omit<React.HTMLAttributes<HTMLElement>, 'children'>) {
  const location = useLocation();

  const isActived = React.useCallback((path: string) => {
    return location.pathname.startsWith(path) ? "active" : undefined;
  }, [location.pathname]);

  return (
    <nav {...rest}>
      <ul className="nav flex-column">
        <Link to={EndPoints.dashboard}>
          <DashBoardNavItem className={isActived(EndPoints.dashboard)} />
        </Link>
        <Link to={EndPoints.adProduct}>
          <AdProductNavItem className={isActived(EndPoints.adProduct)} />
        </Link>
        <Link to={EndPoints.hostList}>
          <FindShowHostNavItem className={isActived(EndPoints.hostList)} />
        </Link>
        <Link to={EndPoints.message}>
          <MessageNavItem className={isActived(EndPoints.message)} />
        </Link>
        <Link to={EndPoints.matchingManagement}>
          <MatchingManagementNavItem className={isActived(EndPoints.matchingManagement)} />
        </Link>
        <Link to={EndPoints.wallet}>
          <WalletNavItem className={isActived(EndPoints.wallet)} />
        </Link>
        <Link to={EndPoints.profileSettings}>
          <HostProfileSettingsNavItem className={isActived(EndPoints.profileSettings)} />
        </Link>
        <Link to={EndPoints.bankAccount}>
          <BankAccountNavItem className={isActived(EndPoints.bankAccount)} />
        </Link>
        <Link to={EndPoints.account}>
          <AccountNavItem className={isActived(EndPoints.account)} />
        </Link>
      </ul>
    </nav>
  )
}

export default React.memo(ShowhostNav);