import React from "react";
import INavItemProps from "./INavItemProps";
import NavItem from "./NavItem";
import LazyGearIcon from "../../../icons/LazyGearIcon";

function RequestQuoteNavItem(props: Omit<INavItemProps, 'children'>) {
  return (
    <NavItem {...props}>
      <LazyGearIcon /> 견적 의뢰
    </NavItem>
  )
}

export default React.memo(RequestQuoteNavItem);