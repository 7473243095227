import React from "react"
import IServiceError from "../farmerLounges/IServiceError";

interface IErrorViewerProps extends React.HTMLAttributes<HTMLUListElement> {
  errors?: IServiceError[];
}

function ErrorViewer({
  errors,
  ...rest
}: IErrorViewerProps) {
  return (
    <ul className="text-danger" {...rest}>
      {errors?.map(item => <li key={item.codeDescription}>{item.message}</li>)}
    </ul>
  )
}

export default React.memo(ErrorViewer)  