import React from "react";
import INavItemProps from "./INavItemProps";
import NavItem from "./NavItem";
import LazyEnvelopeIcon from "../../../icons/LazyEnvelopeIcon";

function MessageNavItem(props: Omit<INavItemProps, 'children'>) {
  return (
    <NavItem {...props}>
      <LazyEnvelopeIcon /> 메세지
    </NavItem>
  );
}

export default React.memo(MessageNavItem);