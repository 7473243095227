import React from "react";
import INavItemProps from "./INavItemProps";
import NavItem from "./NavItem";
import LazyBagIcon from "../../../icons/LazyBagIcon";

function HostProfileSettingsNavItem(props: Omit<INavItemProps, 'children'>) {
  return (
    <NavItem {...props}>
      <LazyBagIcon /> 프로필 관리
    </NavItem>
  )
}

export default React.memo(HostProfileSettingsNavItem);