import React from "react";
import IServiceError from "../../farmerLounges/IServiceError";
import { CancellationToken, CancellationTokenSource } from "@code-rabbits/core";
import farmerLoungeService from "../../farmerLounges/FarmerLoungeService";
import Paginations from "../../components/Paginations";
import DatasetHelper from "../../lib/DatasetHelper";
import DangerButton from "../../components/buttons/DangerButton";
import { useSearchParams } from "react-router-dom";
import SerachParamsHelper from "../../lib/SerachParamsHelper";
import PostTable from "../../components/managemensts/events/posts/PostTable";
import PostTableItems from "../../components/managemensts/events/posts/PostTableItems";
import IPostResource from "../../farmerLounges/posts/IPostResource";
import { IPostListGetOptions } from "../../farmerLounges/posts/PostsService";
import PrimaryOutlineButton from "../../components/buttons/PrimaryOutlineButton";

interface IProductsManagerContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  options?: Omit<IPostListGetOptions, 'page' | 'size'>;

  onCreateClick?: React.MouseEventHandler<HTMLButtonElement>;
  onEditClick?: (evt: React.MouseEvent<HTMLButtonElement>, item: IPostResource) => void;
}

const PostsManagerContainer = React.memo(({
  options,
  className,
  onCreateClick,
  onEditClick,
  ...rest
}: Readonly<IProductsManagerContainerProps>) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const getLocationOffset = React.useCallback(() => {
    try {
      return SerachParamsHelper.getInt(searchParams, 'offset');
    } catch {
      return undefined;
    }
  }, [searchParams]);

  const [selectedProductIds, setSelectedProductIds] = React.useState<IPostResource['_id'][]>([]);
  const [posts, setPosts] = React.useState<IPostResource[] | null>(null);
  const [errors, setErrors] = React.useState<IServiceError[]>();
  const [offset, setOffset] = React.useState<number>(getLocationOffset() ?? 1);
  const [pagePerCount] = React.useState<number>(20);
  const [totalCount, setTotalCount] = React.useState<number>();

  const updatePostsAsync = React.useCallback(async (cancellationToken?: CancellationToken) => {
    const result = await farmerLoungeService.posts.getListAsync({
      ...options
    });

    if (cancellationToken?.isCancellationRequested) {
      return;
    }

    if (!result.succeeded) {
      setErrors(result.errors);
      return;
    }

    const data = result.data;
    if (!data) {
      alert("데이터가 비어있습니다.")
      return;
    }

    setTotalCount(data.count);
    setPosts(data.posts);
  }, [options]);


  React.useEffect(() => {
    const cts = new CancellationTokenSource();
    updatePostsAsync(cts.token);

    return () => {
      cts.cancel();
    }
  }, [updatePostsAsync]);

  React.useEffect(() => {
    setOffset(getLocationOffset() ?? 1);
  }, [getLocationOffset]);

  const handleCheckedChangeAsync = React.useCallback((
    evt: React.ChangeEvent<HTMLInputElement>,
    item: IPostResource
  ) => {
    const set = new Set(selectedProductIds);
    if (evt.currentTarget.checked) {
      set.add(item._id)
    } else {
      set.delete(item._id);
    }

    setSelectedProductIds(Array.from(set));
  }, [selectedProductIds]);

  const handlePaginationIndexClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(evt => {
    const index = DatasetHelper.getInt(evt.currentTarget.dataset, "index");
    searchParams.set('offset', index.toString());
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const handleRemoveClick = React.useCallback(async () => {
    if (!window.confirm("아 작업은 되될리수 없습니다. 정말 수행하시겠습니까?")) {
      return;
    }

    const result = await Promise.all(selectedProductIds.map(id => {
      return farmerLoungeService.posts.deletePostAsync(id);
    }));

    const faileds = result.filter(item => !item.succeeded);
    if (faileds.any()) {
      alert("지정된 상품들중 일부 삭제에 실패했습니다.");
    }
    else {
      alert("지정된 모든 상품를 제거하였습니다.");
    }

    updatePostsAsync();
  }, [selectedProductIds, updatePostsAsync]);


  return (
    <div className={className} {...rest}>
      <div className="row justify-content-end mt-3 mb-3">
        <div className="col-12 d-flex justify-content-end">
          <PrimaryOutlineButton className="mx-3" onClick={onCreateClick}>추가하기</PrimaryOutlineButton>
          <DangerButton className="mx-3" onClick={handleRemoveClick}>제거하기</DangerButton>
        </div>
      </div>
      <div className="row">
        <PostTable className="col-12">
          <PostTableItems
            items={posts?.take(pagePerCount)}
            errors={errors}
            onCheckedChange={handleCheckedChangeAsync}
            onEditClick={onEditClick}
          />
        </PostTable>
        <div className="row">
          <div className="col-12 justify-content-center">
            <Paginations
              className="text-center"
              totalCount={totalCount}
              index={offset}
              pagePerCount={pagePerCount}
              onIndexClick={handlePaginationIndexClick}
            />
          </div>
        </div>
      </div>
    </div>
  )
});

export default React.memo(PostsManagerContainer);