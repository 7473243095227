import React from "react";
import WalletSection from "./WalletSection";
import Wallet from "../components/Wallet";
import { useIdentityContext } from "../contexts/IdentityContext";
import PrimaryOutlineButton from "../components/buttons/PrimaryOutlineButton";
import PrimaryButton from "../components/buttons/PrimaryButton";
import farmerLoungeService from "../farmerLounges/FarmerLoungeService";
import { usePopupContext } from "../contexts/PopupContext";
import PointRefundHistoryContaier from "../containers/points/PointRefundHistoryContaier";
import PointRechargeHistoryContaier from "../containers/points/PointRechargeHistoryContaier";

interface IAdvertiserWalletSectionProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> {
}

function DepositWithdrawalWalletSectionSection(props: IAdvertiserWalletSectionProps) {
  const { userInfo } = useIdentityContext();
  const { setPopup } = usePopupContext();
  const userId = userInfo?._id;

  const handlePointRechargeSubmit = React.useCallback<React.FormEventHandler<HTMLFormElement>>(async evt => {
    evt.stopPropagation();
    evt.preventDefault();
    if (userId === null || userId === undefined) {
      return;
    }

    const formData = new FormData(evt.currentTarget);
    const amount = Number.parseInt(formData.get('amount') as string);
    if (Number.isNaN(amount)) {
      alert("올버른 금액을 입력해주세요");
      return;
    }

    const request = await farmerLoungeService.points.rechargeAsync(userId, amount);
    if (!request.succeeded) {
      alert(request.errors.first()?.message ?? "알 수 없는 에러 발생");
      return;
    }

    setPopup('recharg', {
      name: userInfo!.name,
      role: userInfo!.role
    })
    return;
  }, [userInfo, userId, setPopup]);

  const handlePointRefundSubmit = React.useCallback<React.FormEventHandler<HTMLFormElement>>(async evt => {
    evt.stopPropagation();
    evt.preventDefault();
    if (userId === null || userId === undefined) {
      return;
    }

    const formData = new FormData(evt.currentTarget);
    const amount = Number.parseInt(formData.get('amount') as string);
    if (Number.isNaN(amount)) {
      alert("올버른 금액을 입력해주세요");
      return;
    }

    const request = await farmerLoungeService.points.refundAsync(userId, amount);
    if (!request.succeeded) {
      alert(request.errors.first()?.message ?? "알 수 없는 에러 발생");
      return;
    }

    setPopup('refund', {
      name: userInfo!.name,
      role: userInfo!.role
    })
    return;
  }, [userInfo, userId, setPopup]);

  return (
    <WalletSection className={props.className}>
      <div className="row justify-content-center">
        <Wallet className="col-8 mt-4 primary-speech-bubble" value={userInfo?.wallet?.point} />
      </div>
      <div className="row justify-content-center mt-4 fs-2 gap-4 fw-semibold">
        <form className="col-4" onSubmit={handlePointRechargeSubmit}>
          <div>포인트 <span className="text-primary ">충전</span></div>
          <div className="row gap-3">
            <div className="col-7">
              <input className="w-100" type="number" name="amount" />
            </div>
            <PrimaryOutlineButton type="submit" className="col-3 fs-4">충전하기</PrimaryOutlineButton>
          </div>
        </form>
        <form className="col-4" onSubmit={handlePointRefundSubmit}>
          <div>출금 요청</div>
          <div className="row gap-3">
            <div className="col-7">
              <input className="w-100" type="number" name="amount" />
            </div>
            <PrimaryButton type="submit" className="col-3 fs-4">요청하기</PrimaryButton>
          </div>
        </form>
        <div className="row justify-content-center mt-4">
          <div className="col-11 fs-4 fw-medium">
            포인트 충전 내역
          </div>
          <div className="col-11 mt-2">
            <PointRechargeHistoryContaier />
          </div>
        </div>
      </div>
      <div className="row justify-content-center mt-4 ">
        <div className="col-11 fs-4 fw-medium">
          포인트 출금 내역
        </div>
        <div className="col-11 mt-2">
          <PointRefundHistoryContaier />
        </div>
      </div>
    </WalletSection>
  )
}

export default React.memo(DepositWithdrawalWalletSectionSection);