import { ClassNameHelper } from "@loonfactory/common";
import React from "react";

const ColGroup = React.memo(() => {
  return (
    <colgroup>
      <col />
      <col />
      <col />
      <col />
      <col />
      <col />
      <col style={{ width: "230px" }} />
      <col />
    </colgroup>
  )
});

function PopupTable({ children, className, ...rest }: React.TableHTMLAttributes<HTMLTableElement>) {
  return (
    <table className={ClassNameHelper.concat(className, "table")} {...rest}>
      <ColGroup />
      <thead>
        <tr>
          <th></th>
          <th>번호</th>
          <th>타이틀</th>
          <th>작성자 Id</th>
          <th>게시 시각</th>
          <th>제거 시각</th>
          <th>미리보기</th>
          <th></th>
        </tr>
      </thead>
      {children}
    </table >

  )
}

export default React.memo(PopupTable);