import React, { Suspense } from 'react';

const LChartIcon = React.lazy(() => import('./ChartIcon'));

function LazyChartIcon(props: React.HTMLAttributes<SVGSVGElement>) {
  return (
    <Suspense fallback={<svg
      width="33"
      height="31"
      viewBox="0 0 33 31"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />}>
      <LChartIcon {...props} />
    </Suspense>
  )
}

export default React.memo(LazyChartIcon)