import React from "react";
import MainLayout from "../layouts/MainLayout";
import RequestQuoteSection from "../sections/RequestQuoteSection";

function RequestQuotePage() {
  return (
    <MainLayout>
      <RequestQuoteSection className="main-section" />
    </MainLayout>
  )
}

export default React.memo(RequestQuotePage);