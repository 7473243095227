import React from "react";
import IServiceError from "../../farmerLounges/IServiceError";
import { CancellationToken, CancellationTokenSource } from "@code-rabbits/core";
import farmerLoungeService from "../../farmerLounges/FarmerLoungeService";
import DatasetHelper from "../../lib/DatasetHelper";
import { useSearchParams } from "react-router-dom";
import SerachParamsHelper from "../../lib/SerachParamsHelper";
import ScheduleTable from "../../components/managemensts/schedules/ScheduleTable";
import IScheduleDetailResource from "../../farmerLounges/farmerLoungeResources/IScheduleDetailResource";
import Schedules from "../../components/managemensts/schedules/Schedules";
import Paginations from "../../components/Paginations";
import IScheduleResource from "../../farmerLounges/farmerLoungeResources/IScheduleResource";
import { IGetScheduleListOptions } from "../../farmerLounges/farmerLoungeResources/schedules/IGetScheduleListOptions";

interface ISchedulesManagerContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  options?: IGetScheduleListOptions;
}

const SchedulesManagerContainer = React.memo(({
  options,
  className,
  ...rest
}: ISchedulesManagerContainerProps) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const getLocationOffset = React.useCallback(() => {
    try {
      return SerachParamsHelper.getInt(searchParams, 'offset');
    } catch {
      return undefined;
    }
  }, [searchParams]);

  const [selectedItemsIds, setSelectedItemsIds] = React.useState<IScheduleDetailResource['_id'][]>([]);
  const [items, setItems] = React.useState<IScheduleResource[] | null>(null);
  const [errors, setErrors] = React.useState<IServiceError[]>();
  const [offset, setOffset] = React.useState<number>(getLocationOffset() ?? 1);
  const [pagePerCount] = React.useState<number>(20);
  const [totalCount, setTotalCount] = React.useState<number>();

  const updateSchedulesAsync = React.useCallback(async (cancellationToken?: CancellationToken) => {
    const result = await farmerLoungeService.schedules.getListAsync({
      ...options,
      page: offset,
      size: pagePerCount,
    });

    if (cancellationToken?.isCancellationRequested) {
      return;
    }

    if (!result.succeeded) {
      setErrors(result.errors);
      return;
    }

    const data = result.data;
    if (!data) {
      alert("데이터가 비어있습니다.")
      return;
    }
    setTotalCount(data.count);
    setItems(data.schedules);
  }, [offset, options, pagePerCount]);


  React.useEffect(() => {
    const cts = new CancellationTokenSource();
    updateSchedulesAsync(cts.token);

    return () => {
      cts.cancel();
    }
  }, [updateSchedulesAsync]);

  React.useEffect(() => {
    setOffset(getLocationOffset() ?? 1);
  }, [getLocationOffset]);

  const handleAcceptClickAsync = React.useCallback(async (
    evt: React.MouseEvent<HTMLButtonElement>,
    item: IScheduleResource
  ) => {
    evt.stopPropagation();
    evt.preventDefault();

    const result = await farmerLoungeService.items.updateStateAsync(item._id, 'approved');
    if (!result.succeeded) {
      alert(result.errors.first().message);
      return;
    }

    alert("변경 성공");

    updateSchedulesAsync();
  }, [updateSchedulesAsync])

  const handleCheckedChangeAsync = React.useCallback((evt: React.ChangeEvent<HTMLInputElement>, item: IScheduleResource) => {
    const set = new Set(selectedItemsIds);
    if (evt.currentTarget.checked) {
      set.add(item._id)
    } else {
      set.delete(item._id);
    }

    setSelectedItemsIds(Array.from(set));
  }, [selectedItemsIds]);

  const handlePaginationIndexClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(evt => {
    const index = DatasetHelper.getInt(evt.currentTarget.dataset, "index");
    searchParams.set('offset', index.toString());
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  return (
    <div className={className} {...rest}>
      <div className="row justify-content-end mt-3 mb-3">
        <ScheduleTable className="col-12">
          <Schedules
            items={items}
            errors={errors}
            onAcceptClick={handleAcceptClickAsync}
            onCheckedChange={handleCheckedChangeAsync}
          />
        </ScheduleTable>
      </div>
      <div className="row">
        <div className="col-12 justify-content-center">
          <Paginations
            className="text-center"
            index={offset}
            totalCount={totalCount}
            pagePerCount={pagePerCount}
            onIndexClick={handlePaginationIndexClick}
          />
        </div>
      </div>
    </div>
  )
});

export default React.memo(SchedulesManagerContainer);