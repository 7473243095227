import React from "react";
import './ProfileSettingsSection.scss';
import { ClassNameHelper } from "@loonfactory/common";
import HorizontalLinearLayout from "../../../layouts/HorizontalLinearLayout";
import SNSAccountWrapper from "../../../components/SNSAccountWrapper";
import PortfolioURLWrapper from "../../../components/forms/inputs/PortfolioURLWrapper";
import SpecializationWrapper from "../../../components/SpecializationWrapper";
import MainActivityChannelWrapper from "../../../components/forms/inputs/MainActivityChannelWrapper";
import CareerWrapper from "../../../components/forms/inputs/CareerWrapper";
import TotalPeriodWrapper from "../../../components/TotalPeriodWrapper";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import PrimaryOutlineButton from "../../../components/buttons/PrimaryOutlineButton";
import AvailableLiveTimeWrapper from "../../../components/forms/inputs/AvailableLiveTimeWrapper";
import ImageInput from "../../../components/forms/inputs/ImageInput";
import farmerLoungeService from "../../../farmerLounges/FarmerLoungeService";
import { useIdentityContext } from "../../../contexts/IdentityContext";
import IntroduceInputGroup from "../../../components/forms/inputs/IntroduceInputGroup";
import VerticalLinearLayout from "../../../layouts/VerticalLinearLayout";

interface IProfileSettingsSectionProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> {
}

function ProfileSettingsSection({
  className,
  ...rest
}: IProfileSettingsSectionProps) {
  const { userInfo } = useIdentityContext();

  const handleSubmit = React.useCallback<React.FormEventHandler<HTMLFormElement>>(async evt => {
    evt.stopPropagation();
    evt.preventDefault();

    if (userInfo?._id === undefined) {
      alert("아이디 정보를 찾을수 없습니다.");
      return;
    }

    const images = (evt.currentTarget.querySelector('input[type=file]') as HTMLInputElement | null)?.files ?? null;
    if (images === null || images.length === 0) {
      alert("프로필 사진을 반드시 넣어주세요.")
      return;
    }

    const formData = new FormData(evt.currentTarget);
    const days = formData.get('time-select') as string;
    const introduce = formData.get('introduce') as string;
    const prevTime = formData.get('prev') as string;
    const nextTime = formData.get('next') as string;
    const time = `${prevTime}~${nextTime}`;
    const snsInstagramLink = formData.get('instagram') as string;
    const snsYoutubeLink = formData.get('youtube') as string;
    const videoLinks = formData.getAll('youtube-url') as string[];
    const period = formData.get('period') as string;
    const descriptions = formData.getAll('career') as string[];
    const channels = formData.getAll('channels') as string[];
    const contents = formData.getAll('specialization') as string[];

    const result = await farmerLoungeService.users.updatePortfolioAsync({
      userId: userInfo?._id,
      introduce: introduce,
      work_info: [{
        days: days,
        time: time
      }
      ],
      sns: [{
        service: 'youtube',
        link: snsYoutubeLink
      },
      {
        service: 'instagram',
        link: snsInstagramLink
      }],
      videos: [{
        channel: 'youtube',
        link: videoLinks.filter(item => !!(item.trim()))
      }],
      career: {
        period: period,
        descriptions: descriptions.filter(item => !!(item.trim())),
        channel: channels.filter(item => !!(item.trim())),
        content: contents.filter(item => !!(item.trim())),
      },
      images: images
    });

    if (!result.succeeded) {
      alert(result.errors.first().message);
      return;
    }

    alert("프로필 변경 요청이 생성되었습니다. 관리자의 승인후 진행됩니다.")
    return;
  }, [userInfo?._id]);

  return (
    <section className={ClassNameHelper.concat("profile-settings-section", className)}>
      <div className="container content-region mt-5">
        <HorizontalLinearLayout className="justify-content-between fs-3 fw-semibold">
          <div className="title">프로필 <span className="text-primary">관리</span></div>
        </HorizontalLinearLayout>
        <form onSubmit={handleSubmit}>
          <div className="row mt-3">
            <div className="col-4">
              <VerticalLinearLayout>
                <div className="fw-semibold fs-4 required">
                  이름
                </div>
                <div className="form-control fs-5" style={{ padding: 18 }}>
                  {userInfo?.name}
                </div>
              </VerticalLinearLayout>
            </div>
            <div className="col-8">
              <AvailableLiveTimeWrapper liveTimeDefaultValue={userInfo?.portfolio?.first()?.work_info} required />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <IntroduceInputGroup defaultValue={userInfo?.portfolio?.first().introduce} required />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-5">
              <SNSAccountWrapper snsDefaultValue={userInfo?.portfolio?.first()?.sns} />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-5">
              <div className="input-group">
                <label className="form-label fs-4">
                  <span className="required">프로필 사진 등록</span>
                  <ImageInput location={userInfo?.portfolio?.first().images?.first()?.location} required />
                </label>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-5">
              <PortfolioURLWrapper defaultValue={userInfo?.portfolio?.first()?.videos?.link} />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <SpecializationWrapper defaultValue={userInfo?.portfolio?.first()?.career?.content} />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <MainActivityChannelWrapper defaultValue={userInfo?.portfolio?.first()?.career?.channel} />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <TotalPeriodWrapper defaultValue={userInfo?.portfolio?.first()?.career?.period} required />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <CareerWrapper defaultValue={userInfo?.portfolio?.first()?.career?.descriptions} />
            </div>
          </div>
          <HorizontalLinearLayout className="justify-content-end mt-5">
            <PrimaryButton type="submit">
              저장하기
            </PrimaryButton>
            <PrimaryOutlineButton className="ms-4">
              취소
            </PrimaryOutlineButton>
          </HorizontalLinearLayout>
        </form>
      </div>
    </section>
  )
}

export default React.memo(ProfileSettingsSection);