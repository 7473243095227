import React from "react";
import DangerButton from "../buttons/DangerButton";
import PrimaryButton from "../buttons/PrimaryButton";

export interface IAcceptRejectButtonGroupProps {
  onAcceptClick?: React.MouseEventHandler<HTMLButtonElement>;
  onRejectClick?: React.MouseEventHandler<HTMLButtonElement>;
}

function AcceptRejectButtonGroup(props: IAcceptRejectButtonGroupProps) {
  return (
    <div className="row justify-content-end mt-5">
      <div className="col-2">
        <PrimaryButton className="w-100" onClick={props.onAcceptClick}>승인</PrimaryButton>
      </div>
      <div className="col-2">
        <DangerButton className="w-100" onClick={props.onRejectClick}>거절</DangerButton>
      </div>
    </div>

  )
}
export default React.memo(AcceptRejectButtonGroup);