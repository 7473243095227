import ServiceErrorGenerator from "../../ServiceErrorGenerator";
import ApiEndPoints from "../ApiEndPoints";
import FarmerLoungesBaseApiService from "../FarmerLoungesBaseApiService";
import IServiceResponse from "../IServiceResponse";
import ServiceResult from "../ServiceResult";
import IUserResource from "../farmerLoungeResources/IUserResource";
import IPointResource from "./IPointResource";

class PointsService extends FarmerLoungesBaseApiService {
  rechargeAsync = async (userId: IUserResource['_id'], amount: number): Promise<ServiceResult<IPointResource>> => {
    const url = this.buildUrl(ApiEndPoints.points.recharge.replaceAll("{id}", userId.toString()));
    const response = await this.postJsonAsync(url, { amount: amount });

    const result = (await response.json()) as IServiceResponse<IPointResource>;

    if (result.code !== 2000) {
      switch (result.code) {
        default:
          return this.globalErrorHandler(result);
      }
    }

    return ServiceResult.succeeded(result.result)
  }

  refundAsync = async (userId: IUserResource['_id'], amount: number): Promise<ServiceResult<IPointResource>> => {
    const url = this.buildUrl(ApiEndPoints.points.refund.replaceAll("{id}", userId.toString()));
    const response = await this.postJsonAsync(url, { amount: amount });

    const result = (await response.json()) as IServiceResponse<IPointResource>;

    if (result.code !== 2000) {
      switch (result.code) {
        case 4000:
          switch (result.detail?.message) {
            case "출금 계좌를 등록해주세요.":
              return ServiceResult.failed([ServiceErrorGenerator.withdrawalAccountIsRequired])
            case "포인트가 부족합니다.":
              return ServiceResult.failed([ServiceErrorGenerator.notEnoughpoints])
            default:
              return this.globalErrorHandler(result);;
          }

        default:
          return this.globalErrorHandler(result);
      }
    }

    return ServiceResult.succeeded(result.result)
  }
}

export default PointsService;