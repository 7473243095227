import React from "react";
import FormInput, { IFormInputProps } from "./FormInputGroup";

function AddressInput({
  onChange,
  ...rest
}: IFormInputProps) {
  return (
    <FormInput
      label="계좌번호"
      name="accountNumber"
      type="text"
      placeholder="계좌번호를 입력해 주세요."
      onChange={onChange}
      {...rest}
    />
  )
}

export default React.memo(AddressInput);