import React, { Suspense } from 'react';

const UserIcon = React.lazy(() => import('./UserIcon'));

function LazyUserIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <Suspense fallback={<svg width="32" height="32" viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />}>
      <UserIcon {...props} />
    </Suspense>
  )
}

export default React.memo(LazyUserIcon)