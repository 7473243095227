import React from "react";
import IdentityLayout from "../../layouts/IdentityLayout";
import { Link } from "react-router-dom";
import EndPoints from "../../EndPoints";
import PrimaryOutlineButton from "../../components/buttons/PrimaryOutlineButton";

function ForgotPasswordResultPage() {
  return (
    <IdentityLayout>
      <div className="text-primary text-center mt-5 fs-4 h-50">
        임시비밀번호가 <br />
        메일로 발송 되었습니다.
      </div>
      <div className="w-100 mt-5">
        <Link className="w-100" to={EndPoints.login}>
          <PrimaryOutlineButton className="w-100 mt-5">로그인하기</PrimaryOutlineButton>
        </Link>
      </div>
    </IdentityLayout>
  )
}

export default React.memo(ForgotPasswordResultPage);