import React from "react";
import ContentRegion from "../../../components/contents/ContentRegion";
import SpeechBalloonIcon from "../../../icons/SpeechBalloonIcon";
import RoomListContaier from "../../../containers/rooms/RoomListContaier";
import MessageRoomContainer from "../../../containers/rooms/MessageRoomContainer";
import ManagersLayout from "../../../layouts/ManagersLayout";
import MessageSection from "../../../sections/MessageSection";
import { useSearchParams } from "react-router-dom";
import ITalkingUserResource from "../../../lib/ITalkingUserResource";

interface IAgencyAdManagementDetailPageProps extends React.HTMLAttributes<HTMLDivElement> {

}

function ManagerChatPage(props: Readonly<IAgencyAdManagementDetailPageProps>) {
  const [searchParmas, setSearchParmas] = useSearchParams()
  const searchId = searchParmas.get('id');
  const targetId = searchId != null ? Number.parseInt(searchId) : undefined;

  const handleUserClick = React.useCallback((
    evt: React.MouseEvent<HTMLDivElement>,
    user: ITalkingUserResource
  ) => {
    setSearchParmas({
      ...searchParmas,
      'id': user.roomId!.toString()
    });
  }, [searchParmas, setSearchParmas])

  return (
    <ManagersLayout className={props.className}>
      <MessageSection>
        <ContentRegion>
          <div className="users-wrapper">
            <div className="speech-balloon-wrapper">
              <SpeechBalloonIcon />
              <div>메세지 리스트</div>
            </div>
            <RoomListContaier
              targetId={targetId}
              onUserClick={handleUserClick}
            />
          </div>
          <div className="message-list-wrapper">
            <MessageRoomContainer />
          </div>
        </ContentRegion>
      </MessageSection>
    </ManagersLayout>
  )
}

export default React.memo(ManagerChatPage);