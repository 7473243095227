import React from "react";
import IAbstractPopup from "../IAbstractPopup";
import DefaultPopup from "../DefaultPopup";
import IItemResource from "../../../farmerLounges/farmerLoungeResources/IItemResource";
import { IItemListGetOptions } from "../../../farmerLounges/ItemsService";
import { useIdentityContext } from "../../../contexts/IdentityContext";
import ProductRenderItem from "../../ProductRenderItem";
import ProductGirdContainer from "../../../containers/products/ProductGirdContainer";
import { ClassNameHelper } from "@loonfactory/common";
import './MessageRoomProudctPopup.scss'

export interface IMessageRoomProudctPopupProps {
  onAcceptClick: (evt: React.MouseEvent<HTMLButtonElement>, product: IItemResource) => void;
}

export interface IMessageRoomProudctPopup extends IAbstractPopup<IMessageRoomProudctPopupProps> {
  name: 'messageRoomProudct'
}

function MessageRoomProudctPopup({
  onAcceptClick,
}: IMessageRoomProudctPopupProps) {
  const { userInfo } = useIdentityContext();
  const [selectItem, setSelectItem] = React.useState<IItemResource>();

  const productOption: IItemListGetOptions = React.useMemo(() => {
    return {
      owner_id: userInfo?._id
    }
  }, [userInfo?._id])


  const handleAcceptClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(evt => {
    if (!selectItem) {
      alert("아이템을 선택해주세요");
      evt.stopPropagation();
      evt.preventDefault();
      return;
    }

    onAcceptClick && onAcceptClick(evt, selectItem)
  }, [selectItem, onAcceptClick]);

  const renderer = React.useCallback((product: IItemResource) => {
    return (
      <ProductRenderItem
        product={product}
        className={ClassNameHelper.concat(product._id === selectItem?._id && "selected")}
        onClick={evt => {
          evt.preventDefault();
          setSelectItem({ ...product });
        }}
      />
    )
  }, [selectItem?._id])

  return (
    <DefaultPopup className="message-proudct-popup" onAcceptClick={handleAcceptClick}>
      <ProductGirdContainer
        className="primary-gallery"
        row={2}
        itemWidth={100}
        gap={0.6}
        options={productOption}
        renderer={renderer}
        paginationDisabled
      />
    </DefaultPopup >
  )
}

export default React.memo(MessageRoomProudctPopup);