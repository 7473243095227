import React, { Suspense } from 'react';

const YoutubeIcon = React.lazy(() => import('./YoutubeIcon'));

function LazyYoutubeIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <Suspense fallback={<svg
      width="800"
      height="800"
      viewBox="0 0 461.001 461.001"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />}>
      <YoutubeIcon {...props} />
    </Suspense>
  )
}

export default React.memo(LazyYoutubeIcon)