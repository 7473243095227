import ApiErrorCodeDescriptions from "./ApiErrorCodeDescriptions";
import ApiServiceBase from "./ApiServiceBase";
import IServiceResponse from "./IServiceResponse";
import ServiceResult from "./ServiceResult";

abstract class FarmerLoungesBaseApiService extends ApiServiceBase {
  protected globalErrorHandler = <T, K = T>(response: IServiceResponse<T>): ServiceResult<K> => {
    switch (response.code) {
      case 'EAUTH':
        return ServiceResult.failed({
          code: 'EAUTH',
          codeDescription: ApiErrorCodeDescriptions.sendingEmailAuthenticationFailed,
          message: '이메일 발송 서버 문제 발생',
        })
      default:
        console.error(response);
        return ServiceResult.failed({
          code: response.code,
          codeDescription: ApiErrorCodeDescriptions.unknownError,
          message: '정의되지 않은 에러 발생',
        });
    }
  }
}

export default FarmerLoungesBaseApiService;