import React, { Suspense } from 'react';

const EnvelopeIcon = React.lazy(() => import('./EnvelopeIcon'));

function LazyEnvelopeIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <Suspense fallback={<svg width="32" height="33" viewBox="0 0 32 33"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />}>
      <EnvelopeIcon {...props} />
    </Suspense>
  )
}

export default React.memo(LazyEnvelopeIcon)