import React from "react";
import { CancellationToken, CancellationTokenSource } from "@code-rabbits/core";
import AutoResizeGallery, { IAutoResizeGalleryProps } from "../components/galleries/AutoResizeGallery/AutoResizeGallery";
import IServiceError from "../farmerLounges/IServiceError";
import ContentLoadFail from "../components/contents/ContentLoadFail";
import EmptyContent from "../components/contents/EmptyContent";
import LoadingContent from "../components/contents/LoadingContent";
import IUserDetailResource from "../farmerLounges/farmerLoungeResources/IUserDetailResource";
import { IChallengeUsersOptions, challengeUsersAsync } from "../challenges";

export interface IUsersGirdContainerProps
  extends Omit<IAutoResizeGalleryProps<IUserDetailResource>, 'items' | 'keySelector'> {
  renderer: (props: IUserDetailResource, index: number, array: IUserDetailResource[]) => React.ReactElement | null | undefined;
  options?: IChallengeUsersOptions,
}

const keySelector = (item: IUserDetailResource) => {
  return item._id;
}

function UsersGirdContainer({
  options,
  row,
  itemWidth,
  renderer,
  ...rest
}: IUsersGirdContainerProps) {
  const [items, setItems] = React.useState<IUserDetailResource[] | null>(null);
  const [errors, setErrors] = React.useState<IServiceError[]>();
  const [totalCount, setTotalCount] = React.useState<number>();
  const [pageIndex, setPageIndex] = React.useState<number>(1);

  const handleIndexClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(evt => {
    const target = evt.currentTarget;
    const index = Number(target.dataset.index);
    setPageIndex(index);
  }, []);

  React.useEffect(() => {
    const cts = new CancellationTokenSource();
    (async (token: CancellationToken) => {
      try {
        setErrors(undefined);
        const result = await challengeUsersAsync(
          { ...options },
          token
        );

        if (token.isCancellationRequested) {
          return;
        }

        if (!result.succeeded) {
          setErrors(result.errors);
          return;
        }

        setTotalCount(result.data!.count!);
        setItems(result.data!.users!);
      } catch (e) {
        console.warn(e);
        return;
      }
    })(cts.token);

    return () => {
      cts.cancel();
    }
  }, [options])

  if (errors?.any()) {
    return (
      <ContentLoadFail errors={errors} />
    )
  }

  if (items === null) {
    return (
      <LoadingContent />
    )
  }

  if (!items.any()) {
    return <EmptyContent />
  }

  return (
    <div {...rest}>
      <AutoResizeGallery
        className="primary-gallery"
        items={items}
        row={row}
        itemWidth={itemWidth}
        totalCount={totalCount}
        pageIndex={pageIndex}
        keySelector={keySelector}
        renderer={renderer}
        onIndexClick={handleIndexClick}
      />
    </div>

  )
}

export default React.memo(UsersGirdContainer);