import { useNavigate } from "react-router-dom";
import IItemResource from "../farmerLounges/farmerLoungeResources/IItemResource";
import React from "react";
import farmerLoungeService from "../farmerLounges/FarmerLoungeService";
import EndPoints from "../EndPoints";
import AgencyItem from "./AgencyItem";

function AgencyProductItem(item: IItemResource) {
  const navigate = useNavigate();

  const handleClick = React.useCallback<React.MouseEventHandler<HTMLDivElement>>(async evt => {
    const userId = item.owner_id;
    const result = await farmerLoungeService.rooms.createAsync(userId);
    if (!result.succeeded) {
      alert(result.errors.first().message);
      return;
    }

    navigate(`${EndPoints.message}?id=${result.data?._id}`);

  }, [item.owner_id, navigate]);

  return (
    <AgencyItem
      className="pe-pointer"
      thumbnailUrl={item.images?.first()?.location}
      title={item.name}
      onClick={handleClick}
    />
  )
}

export default React.memo(AgencyProductItem);