import React from "react";
import farmerLoungeService from "../../farmerLounges/FarmerLoungeService";
import IItemResource from "../../farmerLounges/farmerLoungeResources/IItemResource";
import IServiceError from "../../farmerLounges/IServiceError";
import { CancellationTokenSource } from "@code-rabbits/core";
import { IItemListGetOptions } from "../../farmerLounges/ItemsService";
import EmptyContent from "../../components/contents/EmptyContent";
import LoadingContent from "../../components/contents/LoadingContent";
import ContentSlider from "../../components/ContentSlider";
import { IContentSliderProps } from "../../components/ContentSlider/ContentSlider";
import ContentLoadFail from "../../components/contents/ContentLoadFail";

const keySelector = (item: IItemResource) => {
  return item._id;
}

interface IProductContentSliderContainerProps extends Omit<IContentSliderProps<IItemResource>, 'items' | 'keySelector'> {
  renderer: (props: IItemResource, index: number, array: IItemResource[]) => React.ReactElement | null | undefined;
  keySelector?: (item: IItemResource, index: number, array: IItemResource[]) => React.Key;
  options?: IItemListGetOptions | undefined
}

function ProductContentSliderContainer({
  renderer,
  options,
  ...rest
}: IProductContentSliderContainerProps) {
  const [items, setItems] = React.useState<IItemResource[] | null>(null);
  const [errors, setErrors] = React.useState<IServiceError[]>();

  React.useEffect(() => {
    let cts = new CancellationTokenSource();
    (async () => {
      const response = await farmerLoungeService.items.getListAsync(options);

      if (cts.isCancellationRequested) {
        return;
      }

      if (!response.succeeded) {
        setErrors(response.errors!);
        return;
      }

      setItems(response.data?.items ?? null);
    })();

    return () => {
      cts.cancel();
    }
  }, [options]);

  if (errors?.any()) {
    return <ContentLoadFail errors={errors} />
  }

  if (items === null) {
    return <LoadingContent />;
  }

  if (!items.any()) {
    return <EmptyContent />;
  }

  return (
    <ContentSlider invisibleButton
      className="product-slider mt-2"
      items={items}
      renderer={renderer}
      keySelector={keySelector}
      {...rest}
    />
  )
}



export default React.memo(ProductContentSliderContainer);