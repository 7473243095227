import React from "react";
import { ClassNameHelper } from "@loonfactory/common";
import './IdentityLayout.scss';
import VerticalLinearLayout from "../VerticalLinearLayout";
import Logo from "../../components/Logo";
import { Link } from "react-router-dom";
import EndPoints from "../../EndPoints";

function IdentityLayout({ className, children, ...rest }: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <VerticalLinearLayout className={ClassNameHelper.concat("identity-layout", className)} {...rest}>
            <VerticalLinearLayout className="page-content">
                <Link to={EndPoints.home}>
                    <Logo className="identity-logo" />
                </Link>
                {children}
            </VerticalLinearLayout>
        </VerticalLinearLayout>
    )
}

export default React.memo(IdentityLayout);