import React, { Suspense } from 'react';

const BarcodeIcon = React.lazy(() => import('./BarcodeIcon'));

function LazyBarcodeIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <Suspense fallback={<svg width="32" height="28" viewBox="0 0 32 28"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />}>
      <BarcodeIcon {...props} />
    </Suspense>
  )
}

export default React.memo(LazyBarcodeIcon)