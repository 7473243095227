import React from "react";
import { ClassNameHelper } from "@loonfactory/common";
import IItemResource from "../../farmerLounges/farmerLoungeResources/IItemResource";
import Message from "./Message";
import ProductMessage from "./ProductMessage";
import { ILocalMessageResource } from "../../containers/rooms/MessageRoomContainer";
import ScheduleCreateMessage from "./ScheduleCreateMessage";
import ICreateScheduleSubmitProperties from "./ICreateScheduleSubmitProperties";
import IScheduleDetailResource from "../../farmerLounges/farmerLoungeResources/IScheduleDetailResource";
import ScheduleConfirmMessage from "./ScheduleConfirmMessage";
import ScheduleAcceptMessage from "./ScheduleAcceptMessage";
import ScheduleCompletedMessage from "./ScheduleCompletedMessage";
import IScheduleCompletedSubmitProperties from "./IScheduleCompletedSubmitProperties";
import BroadcastReviewCreateMessage from "./BroadcastReviewCreateMessage";
import IBroadcastReviewSubmitProperties from "./IBroadcastReviewSubmitProperties";

interface IMessageListProps extends React.HTMLAttributes<HTMLDivElement> {
  items?: Partial<ILocalMessageResource>[]
  myId?: number;

  onCreateScheduleSubmit?: (evt: React.FormEvent<HTMLFormElement>, properties: ICreateScheduleSubmitProperties) => void;
  onScheduleConfirmClick?: (evt: React.MouseEvent<HTMLButtonElement>, schdule: IScheduleDetailResource) => void;
  onScheduleCompletedSubmit?: (evt: React.FormEvent<HTMLFormElement>, properties: IScheduleCompletedSubmitProperties) => void;
  onBroadcastReviewSubmit?: (evt: React.FormEvent<HTMLFormElement>, properties: IBroadcastReviewSubmitProperties) => void;
}

interface IMessageWrapperProps {
  myId?: number;
  senderId?: number;
  messageId?: string | number;
  publishAt?: string
  content?: string;
  product?: IItemResource;
  type?: string;
  schedule?: IScheduleDetailResource | null;

  onCreateScheduleSubmit?: (evt: React.FormEvent<HTMLFormElement>, properties: ICreateScheduleSubmitProperties) => void;
  onScheduleConfirmClick?: (evt: React.MouseEvent<HTMLButtonElement>, schdule: IScheduleDetailResource) => void;
  onScheduleCompletedSubmit?: (evt: React.FormEvent<HTMLFormElement>, properties: IScheduleCompletedSubmitProperties) => void;
  onBroadcastReviewSubmit?: (evt: React.FormEvent<HTMLFormElement>, properties: IBroadcastReviewSubmitProperties) => void;
}

const MessageWrapper = React.memo((props: IMessageWrapperProps) => {
  let message: React.ReactNode;
  if (props.type === "scheduleCreate") {
    message = (
      <ScheduleCreateMessage
        id={props.messageId?.toString()}
        publistAt={props.publishAt}
        onCreateScheduleSubmit={props.onCreateScheduleSubmit}
      />
    )
  }
  else if (props.type === 'scheduleCompleted') {
    message = (
      <ScheduleCompletedMessage
        id={props.messageId?.toString()}
        publistAt={props.publishAt}
        schedule={props.schedule ?? undefined}
        onScheduleCompletedSubmit={props.onScheduleCompletedSubmit}
      />
    )
  }
  else if (props.schedule !== undefined && props.schedule !== null) {
    switch (props.schedule.state) {
      case 'pending':
        message = (
          <ScheduleConfirmMessage
            id={props.messageId?.toString()}
            publistAt={props.publishAt}
            senderId={props.senderId}
            schdule={props.schedule}
            onScheduleConfirmClick={props.onScheduleConfirmClick}
          />
        )
        break;
      case 'accepted':
        message = (
          <ScheduleAcceptMessage
            id={props.messageId?.toString()}
            publistAt={props.publishAt}
            schedule={props.schedule}
          />
        )
        break;
      case 'completed':
        if (props.senderId !== props.myId) {
          return (
            <div className={ClassNameHelper.concat("message-wrapper", "my-item")}>
              <BroadcastReviewCreateMessage
                id={props.messageId?.toString()}
                publistAt={props.publishAt}
                schedule={props.schedule}
                onBroadcastReviewSubmit={props.onBroadcastReviewSubmit}
              />
            </div>
          )
        }
        else return null;
      default:
        message = null;
        console.warn(`Unvailed state: ${props.schedule?.state}`);
        break;
    }
  }
  else if (props.product !== undefined) {
    message = (
      <ProductMessage
        id={props.messageId?.toString()}
        publistAt={props.publishAt}
        product={props.product}
      />
    )
  }
  else {
    message = (
      <Message
        id={props.messageId?.toString()}
        publistAt={props.publishAt}
      >
        {props.content}
      </Message>
    )
  }

  return (
    <div className={ClassNameHelper.concat("message-wrapper", (props.myId === props.senderId) && "my-item")}>
      {message}
    </div>
  )
});

function MessageList({
  items,
  myId,
  className,
  onCreateScheduleSubmit,
  onScheduleConfirmClick,
  onScheduleCompletedSubmit,
  onBroadcastReviewSubmit,
  ...rest
}: IMessageListProps) {
  const divRef = React.useRef<HTMLDivElement>(null);
  const [itemCount, setItemCount] = React.useState(items?.length ?? 0);

  React.useEffect(() => {
    const count = items?.length ?? 0;
    if (itemCount !== count) {
      divRef.current?.scrollTo({
        top: divRef.current.scrollHeight,
        behavior: 'smooth',
      });

      setItemCount(count);
    }
  }, [itemCount, items?.length]);

  return (
    <div ref={divRef} className={ClassNameHelper.concat('message-list', className)} {...rest}>
      {items?.map(item => {
        let Info = null;
                
        if (item.type === undefined && item.schedule?.state === "accepted") {
          Info = (
            <div className="text-center" key={`${item._id}-accepted`}>
              <p>
                매칭이 완료되었습니다.<br />
                방송 완료 후 시청자 몇 명 / 판매 금액 및 수량 / 진행 채널 / 방송 링크를 입력하여야 정산이 가능합니다.<br />
                예약취소는 관리자에게 문의하세요.<br />
              </p>
              <hr />
            </div>
          )
        }

        return (
          <>
            <MessageWrapper
              key={item._id}
              myId={myId}
              content={item.content}
              messageId={item._id}
              publishAt={item.created_at}
              product={item.items?.first() ?? undefined}
              type={item.type}
              schedule={item.schedule}
              senderId={item.sender_id}
              onCreateScheduleSubmit={onCreateScheduleSubmit}
              onScheduleConfirmClick={onScheduleConfirmClick}
              onScheduleCompletedSubmit={onScheduleCompletedSubmit}
              onBroadcastReviewSubmit={onBroadcastReviewSubmit}
            />
            {Info}
          </>
        )
      })}
    </div >
  )
}

export default React.memo(MessageList);