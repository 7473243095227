import ServiceErrorGenerator from "../../ServiceErrorGenerator";
import ApiEndPoints from "../ApiEndPoints";
import FarmerLoungesBaseApiService from "../FarmerLoungesBaseApiService";
import IServiceResponse from "../IServiceResponse";
import ServiceResult from "../ServiceResult";
import TransactionStateType from "../TransactionStateType";
import ITransactionListOptions from "./ITransactionListOptions";
import ITransactionListResource from "./ITransactionsListResource";

class TransactionsService extends FarmerLoungesBaseApiService {
  getListAsync = async (
    options?: ITransactionListOptions
  ): Promise<ServiceResult<ITransactionListResource>> => {
    const url = this.buildUrl(ApiEndPoints.transactions.list, { ...options });
    const response = await this.getAsync(url);

    const result = (await response.json()) as IServiceResponse<ITransactionListResource>;
    if (result.code !== 2000) {
      switch (result.code) {
        default:
          return this.globalErrorHandler(result);
      }
    }

    return ServiceResult.succeeded(result.result);
  }

  updateStateAsync = async (
    id: number,
    state: TransactionStateType
  ): Promise<ServiceResult<ITransactionListResource>> => {
    const url = this.buildUrl(ApiEndPoints.transactions.updateState.replaceAll('{id}', id.toString()));
    const response = await this.putJsonAsync(url, {
      state: state
    });

    const result = (await response.json()) as IServiceResponse<ITransactionListResource>;
    if (result.code !== 2000) {
      switch (result.code) {
        case 4000:
          if (result.message === "이미 처리된 거래 입니다.")
            return ServiceResult.failed([ServiceErrorGenerator.duplicateTransactionRequests])
          else
            return this.globalErrorHandler(result);
        default:
          return this.globalErrorHandler(result);
      }
    }

    return ServiceResult.succeeded(result.result);
  }
}

export default TransactionsService;