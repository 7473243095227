import React from "react";
import Message, { IMessageProps } from "./Message";
import PrimaryButton from "../buttons/PrimaryButton";
import IScheduleDetailResource from "../../farmerLounges/farmerLoungeResources/IScheduleDetailResource";
import IBroadcastReviewSubmitProperties from "./IBroadcastReviewSubmitProperties";
import FormDataHelper from "../../lib/FormDataHelper";

interface IBroadcastReviewCreateMessageProps extends Omit<IMessageProps, 'children'> {
  schedule: IScheduleDetailResource;
  onBroadcastReviewSubmit?: (evt: React.FormEvent<HTMLFormElement>, properties: IBroadcastReviewSubmitProperties) => void;
}

function BroadcastReviewCreateMessage({ schedule, onBroadcastReviewSubmit, ...rest }: IBroadcastReviewCreateMessageProps) {
  const handleSubmit = React.useCallback<React.FormEventHandler<HTMLFormElement>>(evt => {
    evt.preventDefault();
    evt.stopPropagation();

    const formData = new FormData(evt.currentTarget);
    try {
      const review = FormDataHelper.getString(formData, 'review');

      onBroadcastReviewSubmit && onBroadcastReviewSubmit(evt, {
        review,
        schedule,
      });
    } catch (e) {
      if (e instanceof Error) {
        alert(e.message);
        return;
      }
      throw e;
    }
  }, [schedule, onBroadcastReviewSubmit]);

  return (
    <Message {...rest}>
      <form className="schedule-form contaier" onSubmit={handleSubmit}>
        <div className="row justify-content-center text-center title">
          <label className="col-12">방송 후기 작성</label>
        </div>
        <div className="row">
          <textarea className="col-12" name="review"></textarea>
        </div>
        <div className="row justify-content-around">
          <PrimaryButton className="col-8" type="submit">후기 등록</PrimaryButton>
        </div>
      </form>
    </Message>
  )
}

export default React.memo(BroadcastReviewCreateMessage);