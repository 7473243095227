import React from "react"
import Thumbnail from "./Thumbnail"
import { ClassNameHelper } from "@loonfactory/common";
import HorizontalLinearLayout from "../layouts/HorizontalLinearLayout";

interface IUserInfo {
  id?: number;
  profileUrl?: string;
  name?: string;
}

export interface IProductItemProps extends React.HTMLAttributes<HTMLDivElement> {
  proudctName?: string;
  description?: string;
  thumbnailUrl?: string | null;
  onwer?: IUserInfo;
}

function ProductItem({
  description,
  thumbnailUrl,
  className,
  onwer,
  proudctName,
  ...rest
}: IProductItemProps) {

  return (
    <div className={ClassNameHelper.concat('product-item', className)} {...rest}>
      <Thumbnail src={thumbnailUrl} />
      <div className="info ad-product-info">
        <div className="title">{proudctName}</div>
        <div className="description">{description}</div>
        {onwer && (
          <HorizontalLinearLayout className="user-info fs-9">
            <div className="user-name text-gray" >{onwer.name}</div>
          </HorizontalLinearLayout>
        )}
      </div>
    </div>
  )
}

export default React.memo(ProductItem)