import React from "react";
import HomeContentTitle from "../../components/homeContents/HomeContentTitle";
import HomeContentWrapper from "../../components/homeContents/HomeContentWrapper";
import LasyRightArrowIcon from "../../icons/LasyRightArrowIcon";
import "@loonfactory/common/JsExtensions";
import HorizontalLinearLayout from "../../layouts/HorizontalLinearLayout";
import ProductItemWithProfile from "../../components/ProductItemWithProfile";
import IItemResource from "../../farmerLounges/farmerLoungeResources/IItemResource";
import AutoResizeGallery from "../../components/galleries/AutoResizeGallery";
import { Link } from "react-router-dom";
import EndPoints from "../../EndPoints";

const recommendAdvertiserRenderer = (props: IItemResource) => {
  const images = props.images;
  return (
    <ProductItemWithProfile
      className="primary-gallery-item ad-product-item"
      thumbnailUrl={images && images[0]?.location}
      description={props.discussion_content}
      proudctName={props.name}
    />
  )
}

function AdProductGalleryContaier() {
  const [items, setItems] = React.useState<IItemResource[]>([]);

  React.useEffect(() => {
    let f = false;
    (async () => {
      const result = await fetch(`/json/Ads.json?t=${new Date().toJSON()}`);
      const data = await result.json();
      if (f) return;
      setItems(data);
    })();

    return () => {
      f = true;
    }
  }, [])

  return (
    <HomeContentWrapper className="d-grid mt-4">
      <HomeContentTitle className="justify-content-between">
        <div className="ad-title ms-5">
          <div className="fs-2 fw-bold title" >BEST <span className="text-primary">광고주</span> 상품</div>
          <div className="fs-3">인기있는 상품들을 확인해보세요!</div>
        </div>
        <div className="home-content-option d-none d-sm-flex">
          <HorizontalLinearLayout className="fs-4 text-info align-items-center">
            <Link to={EndPoints.login} className="fs-4 text-info align-items-center">
              광고주 상품 더보기 <LasyRightArrowIcon className="ms-3" />
            </Link>
          </HorizontalLinearLayout>
        </div>
      </HomeContentTitle>
      <AutoResizeGallery
        row={2}
        itemWidth={200}
        gap={0.6}
        items={items}
        renderer={recommendAdvertiserRenderer}
        keySelector={(item, index) => index.toString()}
        className="ad-product-gallery mt-4"
        paginationDisabled

      />
    </HomeContentWrapper >
  )
}

export default React.memo(AdProductGalleryContaier);