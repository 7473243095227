import { CancellationToken } from "@code-rabbits/core";
import OperationCanceledError from "./OperationCanceledError";

class Helper {
  static async getOgImageAsync(videoUrl: string, cancellationToken?: CancellationToken): Promise<string | null> {
    const response = await fetch(`https://loonfactory.xyz/api/ogimage?url=${encodeURI(videoUrl)}`)
    if (cancellationToken?.isCancellationRequested) {
      throw new OperationCanceledError();
    }

    return await response.text();
  }

  static get loadingImage() { return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAAB+CAMAAAAtHa5NAAAAk1BMVEX///8REiQAAADPz8/g4ODv7+/8/Pz09PTGxsZycnLq6uqvr6+1tbXJycmAgIA3NzdJSUlra2uXl5cuLi5ZWVkeHh4AABuPj48JCyDV1dVlZWW8vLxOTk4AABeoqKgVFRUnJydSU11iY2twcXk1NUA/Pz8ZGioAABItLjlHSFA/P0iSk5hbW2EiIyt8fYWEhIkjIzHZ94pqAAADPklEQVRoge2Y63aqOhRGIQ2JAlJBwh2VS62gR33/pzsrRNwo7v2jJXbsczIdZaTYMl0fuaGmKRQKhUKhUCgUCoVCoVD8bcSz+PVSjBDCL7e+gfXtVbJ4TcZWSuRKHYS8kZUYBpVqRYxt8IMVpIYhtVoLsU32YKWypZrmbxha31m7UuVKNcwYQ8bQKj1fjgcZJwMrz1deX8LmtZFBxqmwLu7zJZPbMWIe7VuMmf3cNMjXwHhqbbJdsXera0LGCFo4xqJUoaKYM7F1vlmtVizjd5J8IOdWFBX5UrOTmr/9/69B7A+2Wm03c57qcLEhkC8RTixhAFFvuwUvC0fDxBBOU874MRwE3s3jskpkOjlmglgyPivVyZk54x5DJC85r2Vh96TPoiTmjQm7sbl972GLJ+8bs19MFzZeLXveZ88+1WzRM6HV+Fj1sGfb0WGtE3ZmQnueX5TcmM55L5B03T9B18k4YsNeyBywxE6yzB1Vm1qWFUvLIHaXvu8vHxdRClJrbct5/MAOd2b+eOiY9pp70+kXOhp2zszm/qE45vMytrp6JxysgiQD59Ljd48mvtOXRa0w7FSx8E5s9TJ/GYrlxktciJmkFszJOPQ8sZuiC4j52cT1Hag7vw6Z2Hdd2Ddh13GxRtaeF17fMFN78gHUz0rUcV0f+iueO3wPZUKxXi+TN4OsEzfht+9q1WahF6bSbFdwAqVqA6sGtYayvyqYgzS+s3YZy52dLchXPKzfrFoKWrkZQ/+dGw9WwjOWWuzM7WfEX1Y+aJ/taybE7Be6gVWT8ajxnKH1dVBn7vyXNt8KhUKhUCgUCoVCoVAo/i+8/QQa+gk0/Z5IfwXcuos6dPj53Ot6kOs5nN1L/ABgDYq6zOrmWLZF6xyqdR1+Oqf5sQy+fNEoCHgZ3THQI9H+zIM8gkMgrNH+WJVV0ZbHovonPJwPbbp04/zrpUZFUbRN25ZlDYdd2zZNU5SnqqmKQ3WscpFwcGqqtgRrWbTVITyfT+fq8J1S66qCOk7tqYRXA62iLIsCzpXnqqwjYY0u+X5/qS8XuKltsAtqVF++dVsjETB0zUAPou7X3S6I8mv/Edbrn96OkfSe/DhyXsO/J39CtUwVlq4AAAAASUVORK5CYII="; }

}

export default Helper;