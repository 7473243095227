import { ClassNameHelper } from "@loonfactory/common";
import Thumbnail from "./Thumbnail";
import React from "react";
import { CancellationToken, CancellationTokenSource } from "@code-rabbits/core";
import Helper from "../lib/Helper";
import OperationCanceledError from "../lib/OperationCanceledError";

interface IPortfolioThumbnailItemProp extends React.HTMLAttributes<HTMLDivElement> {
  videoUrl?: string;
  width?: number | string;
  height?: number | string;
}

function PortfolioThumbnailItem({
  height,
  videoUrl,
  width,
  className,
  ...rest
}: IPortfolioThumbnailItemProp) {

  const [thumbnailUrl, setThumbnailUrl] = React.useState<string | null>(Helper.loadingImage);

  const updateThumbnailUrl = React.useCallback(async (url: string, token: CancellationToken) => {
    try {
      const result = await Helper.getOgImageAsync(url, token);
      if (token.isCancellationRequested) {
        return;
      }
      setThumbnailUrl(result);

    } catch (e) {
      if (e instanceof OperationCanceledError) {
        return;
      }

      console.warn(e);
      if (token.isCancellationRequested) {
        return;
      }

      setThumbnailUrl(null);
    }
  }, []);

  React.useEffect(() => {
    if (videoUrl === undefined) {
      return;
    }

    const cts = new CancellationTokenSource();
    updateThumbnailUrl(videoUrl, cts.token);

    return () => {
      cts.cancel();
    }

  }, [updateThumbnailUrl, videoUrl]);

  return (
    <div className={ClassNameHelper.concat("portfolio-video-item", className)} {...rest}>
      <Thumbnail
        height={height}
        width={width}
        src={thumbnailUrl && encodeURI(thumbnailUrl)}
      />
    </div>
  )
}

export default React.memo(PortfolioThumbnailItem);