import React from "react";
import INavItemProps from "./INavItemProps";
import { ClassNameHelper } from "@loonfactory/common";

function NavItem({
  className,
  ...rest
}: INavItemProps) {
  return (
    <li className={ClassNameHelper.concat('fs-5 nav-item', className)} {...rest} />
  )
}

export default React.memo(NavItem);