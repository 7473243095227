import React from "react";
import ManagersLayout from "../../../layouts/ManagersLayout";
import { IGetUserListOptions } from "../../../farmerLounges/UsersService";
import UserManagerContaier from "../../../containers/managers/UserManagerContaier";

const options: IGetUserListOptions = {
  role: 'farmer'
}

function AdvertiserUserPage(props: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <ManagersLayout {...props}>
      <UserManagerContaier className="container" options={options} />
    </ManagersLayout>
  )
}

export default React.memo(AdvertiserUserPage);