import { ClassNameHelper } from "@loonfactory/common";
import React from "react";
import LazyKakaoIcon from "../../icons/LazyKakaoIcon";
import ApiEndPoints from "../../farmerLounges/ApiEndPoints";

function KakaoLoginButton({ className, ...rest }: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <form method="post" action={`${process.env.REACT_APP_API_ORIGIN}${ApiEndPoints.auth.kakao}`}>
      <button type="submit" className={ClassNameHelper.concat("btn", "btn-kakao", className)} {...rest}>
        <LazyKakaoIcon className="login-icon" />
        카카오로 로그인하기
      </button>
    </form>
  )
}

export default React.memo(KakaoLoginButton);