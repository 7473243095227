import React from "react";
import { useIdentityContext } from "../contexts/IdentityContext";
import WalletSection from "./WalletSection";
import PrimaryButton from "../components/buttons/PrimaryButton";
import AccumulatedWallet from "../components/AccumulatedWallet";
import WalletContaier from "../containers/points/WalletContaier";
import PointRefundHistoryContaier from "../containers/points/PointRefundHistoryContaier";

interface IAgencyWalletSectionProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> {
}

function WithdrawalWalletSection(props: IAgencyWalletSectionProps) {
  const { userInfo } = useIdentityContext();

  return (
    <WalletSection className={props.className}>
      <div className="row justify-content-center">
        <WalletContaier className="col-8 mt-4" />
      </div>
      <div className="row justify-content-center">
        <AccumulatedWallet className="col-8 mt-4 primary-speech-bubble" value={userInfo?.wallet?.point} />
      </div>
      <div className="row justify-content-center mt-4 fs-2 gap-4">
        <div className="col-6">
          <div>포인트 <span className="text-primary">출금요청</span></div>
          <div className="row mt-2">
            <div className="col-8">
              <input className="w-100" />
            </div>
            <PrimaryButton className="col-4 col-xl-3 fs-4">출금하기</PrimaryButton>
          </div>
        </div>
      </div>
      <div className="row justify-content-center mt-4 ">
        <div className="col-11 fs-4 fw-medium">
          포인트 출금 내역
        </div>
        <div className="col-11 mt-2">
          <PointRefundHistoryContaier />
        </div>
      </div>
    </WalletSection>
  )
}

export default React.memo(WithdrawalWalletSection);