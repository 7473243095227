import { ClassNameHelper } from "@loonfactory/common";
import React from "react";
import HorizontalLinearLayout from "../HorizontalLinearLayout";
import MainNav from "../../components/navs/MainNav";
import VerticalLinearLayout from "../VerticalLinearLayout";
import MainHeader from "../../components/headers/MainHeader";
import "./MainLayout.scss"
import ShortCutsContaier from "../../containers/ShortCutsContaier";

interface IMainLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
}

function MainLayout({
  className,
  children,
  ...rest
}: IMainLayoutProps) {
  return (
    <HorizontalLinearLayout className={ClassNameHelper.concat("main-layout", className)} {...rest}>
      <MainNav />
      <VerticalLinearLayout className="content-body">
        <MainHeader />
        {children}
      </VerticalLinearLayout>
      <ShortCutsContaier />
    </HorizontalLinearLayout>
  )
}

export default React.memo(MainLayout);