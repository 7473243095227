import IServiceError from "./IServiceError";

class ServiceResult<T = void> {
  private readonly _errors: IServiceError[] = [];
  private readonly _data: T | null = null;
  private _succeeded: boolean;

  private constructor(succeeded: true, result: T)
  private constructor(succeeded: false, errors: IServiceError[])
  private constructor(succeeded: boolean, arg1: T | IServiceError[]) {
    this._succeeded = succeeded;
    if (succeeded) {
      this._data = arg1 as T;
    }
    else {
      this._errors = arg1 as IServiceError[];
    }
  }


  private set succeeded(value: boolean) {
    this._succeeded = value;
  }

  public get succeeded(): boolean {
    return this._succeeded;
  }

  public get data(): T | null {
    return this._data;
  }

  public get errors(): IServiceError[] {
    return this._errors;
  }

  public static succeeded<T>(result: T) {
    return new ServiceResult(true, result)
  }

  public static failed<T>(errors: IServiceError[]): ServiceResult<T>;
  public static failed<T>(...errors: IServiceError[]): ServiceResult<T>;
  public static failed<T>(errors: IServiceError | IServiceError[], ...rest: IServiceError[]) {
    if(!(errors instanceof Array)) {
      errors = [errors];
    }
    
    let result = new ServiceResult(false, [...errors, ...rest])
    return result as ServiceResult<T>;
  }
}

export default ServiceResult;