import React from "react";
import DangerButton from "../../buttons/DangerButton";
import PrimaryOutlineButton from "../../buttons/PrimaryOutlineButton";
import IUserDetailResource from "../../../farmerLounges/farmerLoungeResources/IUserDetailResource";
import IUserResource from "../../../farmerLounges/farmerLoungeResources/IUserResource";
import farmerLoungeService from "../../../farmerLounges/FarmerLoungeService";
import { CancellationTokenSource } from "@code-rabbits/core";
import AgeHelper from "../../../AgeHelper";

interface IProductProps {
  item: IUserResource;
  onCheckedChange?: (evt: React.ChangeEvent<HTMLInputElement>, item: IUserDetailResource) => void;
  onDetailClick?: (evt: React.MouseEvent<HTMLButtonElement>, item: IUserDetailResource) => void;
  onRejectClick?: (evt: React.MouseEvent<HTMLButtonElement>, item: IUserDetailResource) => void;
  onAcceptClick?: (evt: React.MouseEvent<HTMLButtonElement>, item: IUserDetailResource) => void;
}

const Showhost = React.memo(({
  item,
  onAcceptClick,
  onRejectClick,
  onDetailClick,
  onCheckedChange,
}: IProductProps) => {
  const [user, setUser] = React.useState<IUserDetailResource>();
  const portfolio = user?.portfolio?.first();

  const handleAcceptClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(async evt => {
    user && onAcceptClick && onAcceptClick(evt, user);
  }, [user, onAcceptClick]);

  const handleCheckedChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(evt => {
    user && onCheckedChange && onCheckedChange(evt, user);
  }, [user, onCheckedChange]);

  const handleRejectClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(async evt => {
    user && onRejectClick && onRejectClick(evt, user);
  }, [user, onRejectClick]);

  const handleDetailClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(async evt => {
    user && onDetailClick && onDetailClick(evt, user);
  }, [user, onDetailClick]);

  React.useEffect(() => {
    const cts = new CancellationTokenSource();
    (async () => {
      const response = await farmerLoungeService.users.getDetailAsync(item._id);
      if (response.succeeded && !cts.isCancellationRequested) {
        setUser(response.data!);
      }
    })();

    return () => {
      cts.cancel();
    }
  }, [item._id])

  const age = React.useMemo(() => {
    const birth = user?.birth ?? item?.birth;
    if (birth === undefined) {
      return undefined;
    }

    return AgeHelper.getAge(birth);
  }, [user?.birth, item.birth])
  return (
    <tr>
      <td><input type="checkbox" onChange={handleCheckedChange} /></td>
      <td>{(user ?? item)?.name}</td>
      <td>{age}</td>
      <td>{portfolio?.career?.period}</td>
      <td>{portfolio?.work_info?.time}</td>
      <td>
        <PrimaryOutlineButton onClick={handleDetailClick}>자세히 보기</PrimaryOutlineButton>
      </td>
      <td>
        {portfolio?.state === "pending" ? (
          <div className="d-flex w-100 justify-content-around">
            <PrimaryOutlineButton onClick={handleAcceptClick}>승인</PrimaryOutlineButton>
            <DangerButton onClick={handleRejectClick}>거절</DangerButton>
          </div>
        ) : user?.state}
      </td>
    </tr>
  )
});

export default React.memo(Showhost);