import FormatError from "./FormatError";

class FormDataHelper {
  private static validString(v: FormDataEntryValue | null, allowWhiteSpace = false): v is string {
    if (typeof v !== "string") {
      return false;
    }

    if (!allowWhiteSpace && String.isUnvalidOrWhiteSpace(v)) {
      return false;
    }

    return true;
  }

  static getString(formData: FormData, name: string, errorMessage?: string, allowWhiteSpace = false) {
    let value = formData.get(name) as FormDataEntryValue | null;
    errorMessage ??= `[${name}]은 유효하지 않은 문자 '${value}'입니다.`;

    if (!FormDataHelper.validString(value, allowWhiteSpace)) {
      throw new FormatError(errorMessage);
    }

    return value;
  }

  static getInt(formData: FormData, name: string, errorMessage?: string) {
    let value: FormDataEntryValue | number | null = formData.get(name) as FormDataEntryValue | null;
    errorMessage ??= `[${name}]은 유효하지 않은 숫자 '${value}'입니다.`;

    if (!FormDataHelper.validString(value)) {
      throw new FormatError(errorMessage);
    }

    value = Number.parseInt(value);
    if (Number.isNaN(value)) {
      throw new FormatError(errorMessage);
    }

    return value;
  }
}

export default FormDataHelper;