import React from "react";
import ManagersLayout from "../../../../layouts/ManagersLayout";
import { Editor, EditorState } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './SendEmailPage.scss'
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import FormDataHelper from "../../../../lib/FormDataHelper";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import farmerLoungeService from "../../../../farmerLounges/FarmerLoungeService";

const defaultToolbar = {
  options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'remove', 'history'],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
    bold: { icon: undefined, className: undefined },
    italic: { icon: undefined, className: undefined },
    underline: { icon: undefined, className: undefined },
    strikethrough: { icon: undefined, className: undefined },
    monospace: { icon: undefined, className: undefined },
    superscript: { icon: undefined, className: undefined },
    subscript: { icon: undefined, className: undefined },
  },
  blockType: {
    inDropdown: true,
    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  fontSize: {
    icon: undefined,
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  fontFamily: {
    options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['unordered', 'ordered', 'indent', 'outdent'],
    unordered: { icon: undefined, className: undefined },
    ordered: { icon: undefined, className: undefined },
    indent: { icon: undefined, className: undefined },
    outdent: { icon: undefined, className: undefined },
  },
  textAlign: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['left', 'center', 'right', 'justify'],
    left: { icon: undefined, className: undefined },
    center: { icon: undefined, className: undefined },
    right: { icon: undefined, className: undefined },
    justify: { icon: undefined, className: undefined },
  },
  colorPicker: {
    icon: undefined,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
      'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
      'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
      'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
      'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
      'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
  },
  link: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    dropdownClassName: undefined,
    showOpenOptionOnHover: true,
    defaultTargetOption: '_self',
    options: ['link', 'unlink'],
    link: { icon: undefined, className: undefined },
    unlink: { icon: undefined, className: undefined },
    linkCallback: undefined
  },
  emoji: {
    icon: undefined,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    emojis: [
      '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
      '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
      '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
      '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
      '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
      '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
      '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
      '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
      '✅', '❎', '💯',
    ],
  },
  embedded: {
    icon: undefined,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    embedCallback: undefined,
    defaultSize: {
      height: 'auto',
      width: 'auto',
    },
  },
  image: {
    icon: undefined,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    urlEnabled: true,
    uploadEnabled: true,
    alignmentEnabled: true,
    uploadCallback: undefined,
    previewImage: false,
    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
    alt: { present: false, mandatory: false },
    defaultSize: {
      height: 'auto',
      width: 'auto',
    },
  },
  remove: { icon: undefined, className: undefined, component: undefined },
  history: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['undo', 'redo'],
    undo: { icon: undefined, className: undefined },
    redo: { icon: undefined, className: undefined },
  },
}

const localization = {
  locale: 'ko'
}
function SendEmailPage() {
  const [editorState, setEditorState] = React.useState<EditorState>();
  const handleEditorStateChange = React.useCallback((editorState: EditorState) => {
    setEditorState(editorState);
  }, []);

  const handleFormSubmit = React.useCallback<React.FormEventHandler<HTMLFormElement>>(async evt => {
    evt.stopPropagation();
    evt.preventDefault();
    if (!editorState) {
      return;
    }

    const formEl = evt.currentTarget;


    try {
      const formData = new FormData(formEl);
      const title = FormDataHelper.getString(formData, 'title');
      const role = FormDataHelper.getString(formData, 'role') as 'all' | 'farmer' | 'showhost' | 'agency';
      const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));

      const result = await farmerLoungeService.email.sendAsync({
        role: role,
        content: html,
        subject: title,
      })

      if (!result.succeeded) {
        const error = result.errors?.first();
        if (error.code === "EAUTH") {
          alert(error.message);
          return;
        }

        alert(`이메일 발송에 실패했습니다(${error.message}).`)
        return;
      }

      alert("이메일 발송에 성공했습니다.");

      (formEl.querySelector('input[name="title"]') as HTMLInputElement).value = '';
      setEditorState(undefined);
    } catch (e) {
      if (e instanceof Error) {
        alert(e.message);
        return;
      }

      throw e;
    }
  }, [editorState]);

  return (
    <ManagersLayout className="send-email">
      <form className="d-flex border justify-content-center align-items-center h-100" onSubmit={handleFormSubmit}>
        <div className="w-75 h-75 rounded border-3 d-flex border justify-content-center align-items-center">
          <div className="h-100 w-100 p-5 d-flex flex-column justify-content-center align-items-center">
            <div className="d-flex w-100 justify-content-between mb-3">
              <div className="roles">
                <label><input type="radio" name="role" value="all" defaultChecked /> 전체</label>
                <label><input type="radio" name="role" value="showhost" /> 쇼호스트</label>
                <label><input type="radio" name="role" value="farmer" /> 광고주</label>
                <label><input type="radio" name="role" value="agency" /> 에이전시</label>
              </div>
              <div>
                <PrimaryButton type="submit">메일 발송</PrimaryButton>
              </div>
            </div>
            <input className="w-100 mb-2 border p-2" name="title" placeholder="제목을 입력해주세요" />
            <Editor
              wrapperClassName="ms-5 me-5 w-100 editor-wrapper"
              toolbarClassName="border border-2"
              editorClassName="content-editor border border-2"
              editorState={editorState}
              onEditorStateChange={handleEditorStateChange}
              localization={localization}
              toolbar={defaultToolbar}
            />
          </div>
        </div>
      </form>
    </ManagersLayout>
  )
}

export default React.memo(SendEmailPage);