import React from "react";
import MainLayout from "../../layouts/MainLayout";
import { ClassNameHelper } from "@loonfactory/common";
import ProductManagementContainer from "../../containers/products/ProductManagementContainer";
import './ProductPage.scss';

interface IProductListPageProps extends React.HTMLAttributes<HTMLDivElement> {

}

function ProductListPage(props: IProductListPageProps) {
  return (
    <MainLayout>
      <section className={ClassNameHelper.concat('product-management-section', 'main-section')}>
        <div className="content-region mt-4">
          <ProductManagementContainer className="mt-4" />
        </div>
      </section>
    </MainLayout>
  )
}

export default React.memo(ProductListPage);