class Heler {
  static numberWithCommas(value: number) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  static toDateTimeString(date: Date) {
    return date.toLocaleString();
  }

  static getYearMonths() {
    let now = new Date();
    now = new Date(now.getFullYear(), now.getMonth() + 1, 1);

    let months: Date[] = [];
    for (let i = 0; i <= 12; i++) {
      months.push(new Date(now.getFullYear(), now.getMonth() - 12 + i, 1))
    }

    return months;
  }

  static toDateChartLabelFormat(date: Date) {
    return `${date.getFullYear()}, ${(date.getMonth() + 1).toString().padStart(2, '0')}`
  }
}

export default Heler;