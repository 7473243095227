import { ClassNameHelper } from "@loonfactory/common";
import React from "react";

const ColGroup = React.memo(() => {
  return (
    <colgroup>
      <col style={{ width: "2rem" }} />
      <col />
      <col />
      <col />
      <col />
      <col />
      <col style={{ width: "10rem" }} />
      <col style={{ width: "9.5rem" }} />
    </colgroup>
  )
});

function ScheduleTable({ children, className, ...rest }: React.TableHTMLAttributes<HTMLTableElement>) {
  return (
    <table className={ClassNameHelper.concat(className, "table pd-table")} {...rest}>
      <ColGroup />
      <thead>
        <tr>
          <th></th>
          <th>번호</th>
          <th>상품 제공자</th>
          <th>쇼호스트</th>
          <th>상품명</th>
          <th>금액</th>
          <th>방송 시각</th>
          <th>상태</th>
        </tr>
      </thead>
      {children}
    </table>

  )
}

export default React.memo(ScheduleTable);