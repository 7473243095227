import React from "react";
import MatcheRoutes from "./MatcheRoutes";
import farmerLoungeService from "../farmerLounges/FarmerLoungeService";
import EndPoints from "../EndPoints";
import { RoutesProps } from "react-router-dom";

function AuthRoutes({
  children,
  location,
}: RoutesProps) {
  const handleMath = React.useCallback(() => {
    if (farmerLoungeService.accessToken === null) {
      window.location.pathname = EndPoints.home;
      return false;
    }
  }, []);

  return (
    <MatcheRoutes onMatche={handleMath} location={location} children={children} />
  )
}

export default AuthRoutes;