import React, { Suspense } from 'react';

const YoutubeOutlineIcon = React.lazy(() => import('./YoutubeOutlineIcon'));

function LazyYoutubeOutlineIcon(props: React.HTMLAttributes<SVGSVGElement>) {
  return (
    <Suspense fallback={<svg
      width="36"
      height="31"
      viewBox="0 0 36 31"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />}>
      <YoutubeOutlineIcon {...props} />
    </Suspense>
  )
}

export default React.memo(LazyYoutubeOutlineIcon)