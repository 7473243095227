import React from "react";
import FormInput, { IFormInputProps } from "./FormInputGroup";

function ManagerNameInputGroup({
  onChange,
  ...rest
}: IFormInputProps) {
  return (
    <FormInput
      label="이름"
      name="name"
      type="text"
      placeholder="담당자 이름을 입력해 주세요."
      onChange={onChange}
      {...rest}
    />
  )
}

export default React.memo(ManagerNameInputGroup);