import FormatError from "./FormatError";

class SerachParamsHelper {
  private static validString(v: string | null | undefined, allowWhiteSpace = false): v is string {
    if (typeof v !== "string") {
      return false;
    }

    if (!allowWhiteSpace && String.isUnvalidOrWhiteSpace(v)) {
      return false;
    }

    return true;
  }

  static getString(searchParams: string | URLSearchParams, name: string, errorMessage?: string, allowWhiteSpace = false) {
    if (typeof searchParams === "string") {
      searchParams = new URLSearchParams(searchParams);
    }

    let value = searchParams.get(name);
    errorMessage ??= `[${name}]은 유효하지 않은 문자 '${value}'입니다.`;

    if (!SerachParamsHelper.validString(value, allowWhiteSpace)) {
      throw new FormatError(errorMessage);
    }

    return value;
  }

  static getInt(searchParams: string | URLSearchParams, name: string, errorMessage?: string) {
    if (typeof searchParams === "string") {
      searchParams = new URLSearchParams(searchParams);
    }

    let value: string | number | null | undefined = searchParams.get(name);
    errorMessage ??= `[${name}]은 유효하지 않은 숫자 '${value}'입니다.`;

    if (!SerachParamsHelper.validString(value)) {
      throw new FormatError(errorMessage);
    }

    value = Number.parseInt(value);
    if (Number.isNaN(value)) {
      throw new FormatError(errorMessage);
    }

    return value;
  }
}

export default SerachParamsHelper;