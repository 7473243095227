import React from "react";
import EndPoints from "../EndPoints";
import farmerLoungeService from "../farmerLounges/FarmerLoungeService";
import { useNavigate } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";

function LoginCallbackPage() {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('token');

  React.useEffect(() => {
    if (token === null) {
      return;
    }

    (async () => {
      let d = new Date();
      d.setMinutes(d.getMinutes() + 1);
      farmerLoungeService.cookieProvider.setBanner(token, d)
      const request = await farmerLoungeService.auth.getTokenInfoAsync();
      if (!request.succeeded) {
        farmerLoungeService.cookieProvider.removeBanner();
        navigate(EndPoints.home);
      }

      const { exp } = request.data!;
      farmerLoungeService.cookieProvider.setBanner(token, new Date(exp * 1000));
      navigate(EndPoints.dashboard);
    })();
  }, [token, navigate]);

  if (!(typeof token === "string")) {
    window.location.pathname = EndPoints.home;
    return null;
  }

  return (
    <MainLayout>
      외부로그인 처리중입니다.
    </MainLayout>
  );
}

export default React.memo(LoginCallbackPage);