import React from "react";
import HomeContentTitle from "../components/homeContents/HomeContentTitle";
import HomeContentWrapper from "../components/homeContents/HomeContentWrapper";
import "@loonfactory/common/JsExtensions";
import HostItem from "../components/HostItem";
import AutoResizeGallery from "../components/galleries/AutoResizeGallery";
import IServiceError from "../farmerLounges/IServiceError";
import { CancellationTokenSource } from "@code-rabbits/core";
import farmerLoungeService from "../farmerLounges/FarmerLoungeService";
import IUserDetailResource from "../farmerLounges/farmerLoungeResources/IUserDetailResource";
import AgeHelper from "../AgeHelper";
import { MathExtension } from "@loonfactory/common";
import EmptyContent from "../components/contents/EmptyContent";

const hostBroadcastItemRenderer = (item: IUserDetailResource) => {
  let ageRange = undefined;
  if (item.birth !== undefined) {
    ageRange = MathExtension.round(AgeHelper.getAge(item.birth), -1);
  }

  const portfolio = item.portfolio?.first();
  return (
    <HostItem
      id={item._id}
      ageRange={ageRange}
      name={item.name}
      thumbnailUrl={portfolio?.images?.first().location}
      tags={portfolio?.career?.content}
    />
  )
}

const useRequsetDataEffcet = ({ onFaild, onSuccessed }: {
  onFaild: (errors: IServiceError[]) => void;
  onSuccessed: (data: IUserDetailResource[]) => void;
}) => React.useEffect(() => {
  const cts = new CancellationTokenSource();
  (async () => {
    const response = await farmerLoungeService.users.getListAsync({
      picked: true,
    });

    if (cts.isCancellationRequested) {
      return;
    }

    if (!response.succeeded) {
      onFaild(response.errors);
      return;
    }

    onSuccessed(response.data!.users);
  })();

  return () => {
    cts.cancel();
  }
}, [onFaild, onSuccessed]);


const selecthostItemKey = (item: IUserDetailResource) => {
  return item._id;
}

const BestHosts = React.memo(() => {
  const [items, setItems] = React.useState<IUserDetailResource[] | null>(null);
  const [errors, setErrors] = React.useState<IServiceError[]>();
  const handleSuccessed = React.useCallback((data: IUserDetailResource[]) => {
    setItems(data)
  }, []);

  const handleFaild = React.useCallback((errors: IServiceError[]) => {
    setErrors(errors)
  }, []);

  useRequsetDataEffcet({
    onSuccessed: handleSuccessed,
    onFaild: handleFaild,
  })

  if (errors?.any()) {
    return (
      <div className="text-danger">
        항목을 불러오는데 실패햐엿습니다.
        <ul>
          {errors.map(item => <li>{item.message}</li>)}
        </ul>
      </div>
    )
  }

  if (items === null) {
    return (
      <div>
        항목을 불러오고 있습니다.
      </div>
    )
  }

  if (!items.any()) {
    return (
      <EmptyContent />
    )
  }

  return (
    <AutoResizeGallery
      className="host-gallery mt-4"
      items={items}
      row={2}
      itemWidth={200}
      gap={0.75}
      renderer={hostBroadcastItemRenderer}
      keySelector={selecthostItemKey}
      paginationDisabled
    />
  )
})

function BestHostGalleryContaier() {
  return (
    <HomeContentWrapper className="d-grid mt-4 host-gallery-wrapper">
      <HomeContentTitle className="justify-content-between">
        <div className="host-title ms-5">
          <div className="fs-2 fw-bold title" >BEST <span className="text-primary">호스트</span></div>
          <div className="fs-3">인기있는 호스트를 만나보세요!</div>
        </div>
      </HomeContentTitle>
      <BestHosts />
    </HomeContentWrapper >
  )
}

export default React.memo(BestHostGalleryContaier);