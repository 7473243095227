import { ClassNameHelper } from "@loonfactory/common";
import React from "react";

const ColGroup = React.memo(() => {
  return (
    <colgroup>
      <col style={{ width: "2rem" }} />
      <col />
      <col />
      <col />
      <col />
      <col style={{ width: "10rem" }} />
      <col style={{ width: "9.5rem" }} />
    </colgroup>
  )
});

function ShowhostTable({ children, className, ...rest }: React.TableHTMLAttributes<HTMLTableElement>) {
  return (
    <table className={ClassNameHelper.concat(className, "table")} {...rest}>
      <ColGroup />
      <thead>
        <tr>
          <th></th>
          <th>이름</th>
          <th>나이</th>
          <th>경력</th>
          <th>라이브 가능 시간</th>
          <th>자세히 보기</th>
          <th>승인 여부</th>
        </tr>
      </thead>
      {children}
    </table >

  )
}

export default React.memo(ShowhostTable);