import React from "react";
import ManagersLayout from "../../../../layouts/ManagersLayout";
import ProductsManagerContainer from "../../../../containers/managers/ProductsManagerContainer";
import { IItemListGetOptions } from "../../../../farmerLounges/ItemsService";
import { useNavigate } from "react-router-dom";
import IItemResource from "../../../../farmerLounges/farmerLoungeResources/IItemResource";
import EndPoints from "../../../../EndPoints";

const options: IItemListGetOptions = {
  role: 'farmer'
}

interface IManagementPageProps extends React.HTMLAttributes<HTMLDivElement> {

}

function AdvertiserAdManagementPage(props: IManagementPageProps) {
  const navigate = useNavigate();

  const handleDetailClick = React.useCallback((
    evt: React.MouseEvent<HTMLButtonElement>,
    item: IItemResource
  ) => {
    navigate(`${EndPoints.manager.managements.advertiserAdDetail}?id=${item._id}`);
  }, [navigate]);


  return (
    <ManagersLayout className={props.className}>
      <ProductsManagerContainer
        className="container"
        options={options}
        onDetailClick={handleDetailClick}
      />
    </ManagersLayout>
  )
}

export default React.memo(AdvertiserAdManagementPage);