import Calendar, { CalendarProps } from 'react-calendar';

const formatDay = (locale: string | undefined, date: Date) => date.getDate().toString()

function PrimaryCalendar(props: CalendarProps) {
  return (
    <Calendar
      className="calendar"
      calendarType="gregory"
      minDetail="month"
      formatDay={formatDay}
      {...props}
    />
  )
}

export default PrimaryCalendar;