import React from "react";
import MessageRoomTitle from "./MessageRoomTitle";
import MessageList from "./MessageList";
import { ClassNameHelper } from "@loonfactory/common";
import IMessageResource from "../../farmerLounges/farmerLoungeResources/IMessageResource";
import PrimaryButton from "../buttons/PrimaryButton";
import ICreateScheduleSubmitProperties from "./ICreateScheduleSubmitProperties";
import IScheduleDetailResource from "../../farmerLounges/farmerLoungeResources/IScheduleDetailResource";
import IScheduleCompletedSubmitProperties from "./IScheduleCompletedSubmitProperties";
import IBroadcastReviewSubmitProperties from "./IBroadcastReviewSubmitProperties";
import RoleType from "../../farmerLounges/RoleType";

interface IMessageRoomProp extends React.HTMLAttributes<HTMLDivElement> {
  profileUrl?: string;
  name?: string;
  role?: RoleType;
  items?: Partial<IMessageResource>[];
  myId?: number;

  onProductClick?: React.MouseEventHandler<HTMLButtonElement>;
  onScheduleCreateClick?: React.MouseEventHandler<HTMLButtonElement>;
  onScheduleCompletedClick?: React.MouseEventHandler<HTMLButtonElement>;
  onReportClick?: React.MouseEventHandler<HTMLButtonElement>;
  onMessageSubmit?: React.FormEventHandler<HTMLFormElement>;
  onScheduleCreateSubmit?: (evt: React.FormEvent<HTMLFormElement>, properties: ICreateScheduleSubmitProperties) => void;
  onScheduleConfirmClick?: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>, schdule: IScheduleDetailResource) => void;
  onScheduleCompletedSubmit?: (evt: React.FormEvent<HTMLFormElement>, properties: IScheduleCompletedSubmitProperties) => void;
  onBroadcastReviewSubmit?: (evt: React.FormEvent<HTMLFormElement>, properties: IBroadcastReviewSubmitProperties) => void;
}

function MessageRoom({
  className,
  profileUrl,
  name,
  role,
  myId,
  items,
  onProductClick,
  onScheduleCreateClick,
  onMessageSubmit,
  onScheduleCreateSubmit,
  onScheduleConfirmClick,
  onScheduleCompletedClick,
  onScheduleCompletedSubmit,
  onBroadcastReviewSubmit,
  onReportClick,
  ...rest
}: IMessageRoomProp) {
  return (
    <div className={ClassNameHelper.concat(className, "message-room")}{...rest}>
      <MessageRoomTitle
        profileUrl={profileUrl}
        name={name}
        role={role}
        onProductClick={onProductClick}
        onScheduleCreateClick={onScheduleCreateClick}
        onScheduleCompletedClick={onScheduleCompletedClick}
        onReportClick={onReportClick}
      />
      <MessageList
        myId={myId}
        items={items}
        onCreateScheduleSubmit={onScheduleCreateSubmit}
        onScheduleConfirmClick={onScheduleConfirmClick}
        onScheduleCompletedSubmit={onScheduleCompletedSubmit}
        onBroadcastReviewSubmit={onBroadcastReviewSubmit}
      />
      <form className="message-input-wrapper" onSubmit={onMessageSubmit}>
        <input type="string" name="message" placeholder="메세지를 입력해주세요" />
        <PrimaryButton type="submit">보내기</PrimaryButton>
      </form>
    </div>
  )
}

export default React.memo(MessageRoom);