import { ClassNameHelper } from "@loonfactory/common"
import React from "react"

function ContentWrapper({
  className,
  
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={ClassNameHelper.concat(className, 'content-title-wrapper')} {...rest} />
  )
}

export default React.memo(ContentWrapper)