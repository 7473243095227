import React from "react";
import IdentityLayout from "../../layouts/IdentityLayout";
import IdentityContentWrapper from "../../components/identities/IdentityContentWrapper";
import LazyEyeIcon from "../../icons/LazyEyeIcon";
import SignInButton from "../../components/buttons/SignInButton";
import { Link, useNavigate } from "react-router-dom";
import RegisterButton from "../../components/buttons/RegisterButton";
import EndPoints from "../../EndPoints";
import KakaoLoginButton from "../../components/buttons/KakaoLoginButton";
import GoogleLoginButton from "../../components/buttons/GoogleLoginButton";
import "./LoginPage.scss";
import IServiceError from "../../farmerLounges/IServiceError";
import { challengeSignInAsync } from "../../challenges";
import farmerLoungeService from "../../farmerLounges/FarmerLoungeService";
import ErrorViewer from "../../components/ErrorViewer";

function LoginPage(props: React.HTMLAttributes<HTMLDivElement>) {
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = React.useState(false);
  const [submitLock, setSubmitLock] = React.useState(false);
  const [errors, setErrors] = React.useState<IServiceError[] | null>(null);

  const togglePasswordVisiable = React.useCallback(() => {
    setShowPassword(prevState => !prevState);
  }, []);

  const handleClick = React.useCallback<React.MouseEventHandler>(evt => {

  }, []);

  const handleFormSubmit = React.useCallback<React.FormEventHandler<HTMLFormElement>>(async evt => {
    evt.preventDefault();
    evt.stopPropagation();

    if (submitLock) {
      return;
    }

    setSubmitLock(true);
    const formData = new FormData(evt.currentTarget);
    try {
      const result = await challengeSignInAsync(formData);
      if (result.succeeded) {
        const token = await farmerLoungeService.auth.getTokenInfoAsync();
        const role = token.data?.role;
        if (role === "manager" || role === "admin") {
          navigate(EndPoints.manager.index);
        } else {
          navigate(EndPoints.dashboard);
        }
      } else {
        setErrors(result.errors!);
      }
    } finally {
      setSubmitLock(false);
    }
  }, [submitLock, navigate]);

  return (
    <IdentityLayout className="login-page">
      <IdentityContentWrapper className="gap-5">
        <form className="d-grid gap-5" method="post" onSubmit={handleFormSubmit}>
          <div className="d-grid gap-4">
            <div className="input-group-wrapper">
              <label htmlFor="id" className="form-label fs-4">이메일</label>
              <div className="input-group">
                <input type="text" id="username" name="username"
                  className="form-control" />
              </div>
            </div>
            <div className="input-group-wrapper">
              <label htmlFor="password" className="form-label fs-4">패스워드</label>
              <div className="input-group">
                <input type={showPassword ? "text" : "password"}
                  className="form-control pw-input"
                  id="password"
                  name="password"
                />
                <button className="btn btn-outline-secondary eye-icon" type="button"
                  onClick={togglePasswordVisiable}>
                  <LazyEyeIcon />
                </button>
              </div>
            </div>
            {errors?.any() && <ErrorViewer errors={errors} />}
          </div>
          <div className="d-grid gap-4">
            <SignInButton className="fs-3" type="submit" />
            <Link className="d-grid" to={EndPoints.registerIntro}>
              <RegisterButton className="fs-3" />
            </Link>
          </div>
        </form>
        <div className="d-flex gap-4 justify-content-between text-info">
          <Link to={EndPoints.identities.forgotId}>아이디 찾기</Link>
          <Link to={EndPoints.identities.forgotPassword}>비밀번호 찾기</Link>
        </div>
        <div className="d-grid gap-4 social-login-btn">
          <KakaoLoginButton className="fs-6" onClick={handleClick} />          
          <GoogleLoginButton className="fs-6" onClick={handleClick} />
        </div>
      </IdentityContentWrapper>
    </IdentityLayout>
  )
}

export default React.memo(LoginPage);