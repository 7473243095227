import React from "react";
import { ClassNameHelper } from "@loonfactory/common";
import './MessageSection.scss';

interface IMessageSectionProps extends React.HTMLAttributes<HTMLDivElement> {
}

function MessageSection({
  className,
  ...rest
}: IMessageSectionProps) {
  return (
    <section className={ClassNameHelper.concat('message-section', className)} {...rest} />
  )
}

export default React.memo(MessageSection);