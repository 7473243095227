import React from "react";
import ITransactionResource from "../../../farmerLounges/transactions/ITransactionResource";
import farmerLoungeService from "../../../farmerLounges/FarmerLoungeService";
import IWalletInfoResource from "../../../farmerLounges/wallets/IWalletInfoResource";

interface ITransactionProps extends React.HTMLAttributes<HTMLTableRowElement> {
  item: ITransactionResource;
  onAcceptClick?: React.MouseEventHandler<HTMLButtonElement>;
  onRejectClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const Transaction = React.memo(({
  item,
  onAcceptClick,
  onRejectClick,
  ...rest
}: ITransactionProps) => {
  const [walletInfo, setWalletInfo] = React.useState<IWalletInfoResource | null>();

  const walletId = item.target?.wallet_id ?? item.source?.wallet_id;
  React.useEffect(() => {
    if (walletId === undefined) {
      return;
    }

    (async () => {
      const result = await farmerLoungeService.wallets.getDetailAsync(walletId);
      if (!result.succeeded) {
        console.warn(result.errors);
        setWalletInfo(null);
        return;
      }
      setWalletInfo(result.data!);
    })();
  }, [walletId]);

  const textHelper = React.useCallback((v: string | number | undefined | null) => {
    if (v === undefined) {
      return "불러오는 중";
    }

    if (v === null) {
      return "로드 실패";
    }

    return v;
  }, []);

  return (
    <tr {...rest}>
      <td></td>
      <td>{textHelper(walletInfo?.user.email)}</td>
      <td>{textHelper(walletInfo?.user.name)}</td>
      <td>준비중</td>
      <td>{textHelper(walletInfo?.point)}</td>
      <td>{item.amount}</td>
      <td>{item.state}</td>
      <td>
        {
          item.state === "wait" && (
            <>
              <button onClick={onAcceptClick}>승인</button>
              <button onClick={onRejectClick}>취소</button>
            </>
          )
        }
      </td>
    </tr>
  )
});

export default React.memo(Transaction);