import React from "react";
import ManagersLayout from "../../../layouts/ManagersLayout";
import farmerLoungeService from "../../../farmerLounges/FarmerLoungeService";
import { useSearchParams } from "react-router-dom";
import SerachParamsHelper from "../../../lib/SerachParamsHelper";
import EmptyContent from "../../../components/contents/EmptyContent";
import { CancellationToken, CancellationTokenSource } from "@code-rabbits/core";
import IItemResource from "../../../farmerLounges/farmerLoungeResources/IItemResource";
import ProductFormContainer from "../../../containers/managers/ProductFormContainer";
import AcceptRejectButtonGroup from "../../../components/managemensts/AcceptRejectButtonGroup";

interface IProductDetailManagementPageProps extends React.HTMLAttributes<HTMLDivElement> {
  onAcceptClick?: (evt: React.MouseEvent<HTMLButtonElement>, id: IItemResource['_id']) => void;
  onRejectClick?: (evt: React.MouseEvent<HTMLButtonElement>, id: IItemResource['_id']) => void;
}

function AbstractProductDetailManagementPage({
  className,
  onAcceptClick,
  onRejectClick,
  ...rest
}: Readonly<IProductDetailManagementPageProps>) {
  const [searchParams] = useSearchParams();
  const [product, setProduct] = React.useState<IItemResource>();

  let productId: number | undefined = undefined;

  React.useEffect(() => {
    if (productId === undefined) {
      return;
    }

    let cts = new CancellationTokenSource();

    (async (token: CancellationToken) => {
      const result = await farmerLoungeService.items.detailAsync(productId);
      if (token.isCancellationRequested) {
        return;
      }

      if (!result.succeeded) {
        alert(result.errors?.first()?.message)
        return;
      }

      setProduct(result.data!);
    })(cts.token);

    return () => {
      cts.cancel();
    }
  }, [productId]);

  const handleAcceptClick = React.useCallback(async (evt: React.MouseEvent<HTMLButtonElement>) => {
    const result = await farmerLoungeService.items.updateStateAsync(productId!, 'approved');
    if (!result.succeeded) {
      alert(result.errors.first().message);
      return;
    }

    onAcceptClick && onAcceptClick(evt, productId!);
  }, [productId, onAcceptClick])

  const handleRejectClick = React.useCallback(async (evt: React.MouseEvent<HTMLButtonElement>) => {
    const result = await farmerLoungeService.items.updateStateAsync(productId!, 'approved');
    if (!result.succeeded) {
      alert(result.errors.first().message);
      return;
    }

    onRejectClick && onRejectClick(evt, productId!);
  }, [productId, onRejectClick]);

  try {
    productId = SerachParamsHelper.getInt(searchParams, 'id')
  } catch (e) {
    if (e instanceof Error) {
      alert(e.message);
    }

    return (
      <ManagersLayout className={className} {...rest}>
        <EmptyContent />
      </ManagersLayout>
    )
  }

  return (
    <ManagersLayout className={className} {...rest}>
      <main>
        <div className="container">
          <ProductFormContainer className="product-form"
            productId={productId}
            product={product}
            readOnly={true}
          />
          {product?.state === 'pending' && (
            <AcceptRejectButtonGroup
              onAcceptClick={handleAcceptClick}
              onRejectClick={handleRejectClick}
            />
          )}
        </div>
      </main>
    </ManagersLayout>
  )
}

export default React.memo(AbstractProductDetailManagementPage);