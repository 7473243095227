import React from "react";
import GalleryItem from "./galleries/GalleryItem";
import HorizontalLinearLayout from "../layouts/HorizontalLinearLayout";
import Thmbnail from "./Thumbnail";

export interface IHostItemProps {
  id: string | number;
  name?: string | null;
  thumbnailUrl?: string | null;
  ageRange?: number | null;
  gender?: string | null,
  tags?: string[]  | null;
}

const Tag = React.memo((props: { tag: string }) => {
  return (
    <span className="tag">#{props.tag}</span>
  )
})

const Tages = React.memo((props: { className?: string, tags: string[] }) => {
  return (
    <HorizontalLinearLayout className={props.className}>
      {props.tags.map(item => <Tag key={item} tag={item} />)}
    </HorizontalLinearLayout>
  )
})

function HostItem(props: IHostItemProps) {
  return (
    <GalleryItem className="host-item">
      <Thmbnail src={props.thumbnailUrl} />
      <div className="host-info">
        <div className="fs-4">{props.name}</div>
        <div className="fs-7 text-info description">
          {props.ageRange !== undefined ? `${props.ageRange}대` : "비공개"} {props.gender}
        </div>
        {props.tags && <Tages className="tags fs-7 text-info" tags={props.tags} />}
      </div>
    </GalleryItem>
  )
}

export default React.memo(HostItem);