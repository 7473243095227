import React from "react";
import MainLayout from "../../layouts/MainLayout";
import { ClassNameHelper } from "@loonfactory/common";
import ContentTitleWrapper from "../../components/contents/ContentTitleWrapper";
import ProductForm from "../../components/forms/ProductForm";
import farmerLoungeService from "../../farmerLounges/FarmerLoungeService";
import { ICreateItemProperties } from "../../farmerLounges/ItemsService";
import { useNavigate } from "react-router-dom";
import { useIdentityContext } from "../../contexts/IdentityContext";
import './ProductPage.scss';

interface IProductCreatePageProps extends React.HTMLAttributes<HTMLDivElement> {

}

function ProductCreatePage(props: IProductCreatePageProps) {
  const { userInfo } = useIdentityContext();

  const navigate = useNavigate();

  const handleFormSubmit = React.useCallback<React.FormEventHandler<HTMLFormElement>>(async evt => {
    evt.preventDefault();
    evt.stopPropagation();

    const current = evt.currentTarget;
    const formdata = new FormData(current);


    const discussionContents = formdata.get('discussionContents') as string;
    const request = formdata.get('request') as string;
    const saleType = formdata.get('saleType') as string;

    let payload: ICreateItemProperties = {
      company: userInfo?.name ?? "",
      discussion_content: discussionContents,
      request: request,
      sale_type: saleType,
      name: formdata.get('productName') as string,
      price: Number.parseInt(formdata.get('productPrice') as string ?? ''),
    };

    const thumbnail = current.querySelector('input[name=thumbnail]') as HTMLInputElement;
    const detail = current.querySelector('input[name=detail]') as HTMLInputElement;

    if (thumbnail.files !== null && thumbnail.files.length > 0) {
      payload.images = thumbnail.files;
    }


    if (detail.files !== null && detail.files.length > 0) {
      payload.details = detail.files;
    }

    const result = await farmerLoungeService.items.creatAsync(payload);
    if (!result.succeeded) {
      alert(result.errors.first().message)
      return;
    }

    alert("상품 등록 요청이 성공적으로 진행 되었습니다. 관리자의 승인후 진행됩니다.")
    navigate(-1);
  }, [userInfo?.name, navigate]);

  return (
    <MainLayout className={props.className}>
      <section className={ClassNameHelper.concat('product-create-section', "main-section")}>
        <div className="content-region mt-4">
          <ContentTitleWrapper>상품 <span className="text-primary">등록하기</span></ContentTitleWrapper>
          <ProductForm className="product-form" onSubmit={handleFormSubmit} />
        </div>
      </section>
    </MainLayout>
  )
}

export default React.memo(ProductCreatePage);