import React from "react";
import ManagersLayout from "../../../../layouts/ManagersLayout";
import PostsManagerContainer from "../../../../containers/managers/PostsManagerContainer";
import { IPostListGetOptions } from "../../../../farmerLounges/posts/PostsService";
import { useNavigate } from "react-router-dom";
import IPostResource from "../../../../farmerLounges/posts/IPostResource";
import EndPoints from "../../../../EndPoints";

const options: Readonly<IPostListGetOptions> = {
  type: 'notice'
}
function MainVisualManagementPage() {
  const navigate = useNavigate();

  const handleCreateClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(evt => {
    navigate(EndPoints.manager.events.mainVisual.create);
  }, [navigate]);

  const handleEditClick = React.useCallback(async (
    evt: React.MouseEvent<HTMLButtonElement>,
    item: IPostResource
  ) => {
    navigate(`${EndPoints.manager.events.mainVisual.edit}?id=${item._id}`, {
      state: item
    })
  }, [navigate]);


  return (
    <ManagersLayout className="main-visual-management-page">
      <PostsManagerContainer
        className="container"
        options={options}
        onCreateClick={handleCreateClick}
        onEditClick={handleEditClick}
      />
    </ManagersLayout>
  )
}

export default React.memo(MainVisualManagementPage);