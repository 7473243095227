import { ClassNameHelper } from "@loonfactory/common";
import React from "react";

const ColGroup = React.memo(() => {
  return (
    <colgroup>
      <col style={{ width: "2rem" }} />
      <col />
      <col />
      <col />
      <col />
      <col />
      <col style={{ width: "10rem" }} />
      <col style={{ width: "9.5rem" }} />
    </colgroup>
  )
});

function TransactionTable({ children, className, ...rest }: React.TableHTMLAttributes<HTMLTableElement>) {
  return (
    <table className={ClassNameHelper.concat(className, "table")} {...rest}>
      <ColGroup />
      <thead>
        <tr>
          <th></th>
          <th>아이디</th>
          <th>이름</th>
          <th>보유 계좌</th>
          <th>보유 포인트</th>
          <th>요청액</th>
          <th>상태</th>
          <th></th>
        </tr>
      </thead>
      {children}
    </table>

  )
}

export default React.memo(TransactionTable);