import React, { Suspense } from 'react';

const PouchWalletIcon = React.lazy(() => import('./PouchWalletIcon'));

function LazyNotepadIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <Suspense fallback={<svg
      width="37"
      height="31"
      viewBox="0 0 37 31"
      fill="#4E9A5E"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />}>
      <PouchWalletIcon {...props} />
    </Suspense>
  )
}

export default React.memo(LazyNotepadIcon)