import { ClassNameHelper } from "@loonfactory/common";
import Thumbnail from "./Thumbnail";
import React from "react";

interface ICircleThumbnailItemProp extends React.HTMLAttributes<HTMLDivElement> {
  src?: string | null;
  width?: number | string;
  height?: number | string;
  title?: string;
}

function CircleThumbnailItem({
  height,
  src,
  title,
  width,
  className,
  ...rest
}: ICircleThumbnailItemProp) {
  return (
    <div className={ClassNameHelper.concat("circle-thumbnail-item", className)} {...rest}>
      <Thumbnail
        height={height}
        width={width}
        src={src}
      />
      {title && <div>{title}</div>}
    </div>
  )
}

export default React.memo(CircleThumbnailItem);