import React from "react";
import IAbstractPopup from "../IAbstractPopup";
import DefaultPopup from "../DefaultPopup";
import ProductRenderItem from "../../ProductRenderItem";
import { ClassNameHelper } from "@loonfactory/common";
import './MessageRoomScheduleCompletedPopup.scss'
import RoomScheduleGirdContainer from "../../../containers/rooms/RoomScheduleGirdContainer";
import IScheduleDetailResource from "../../../farmerLounges/farmerLoungeResources/IScheduleDetailResource";

export interface IMessageRoomScheduleCompletedPopupProps {
  roomId: number;
  onAcceptClick: (evt: React.MouseEvent<HTMLButtonElement>, schedule: IScheduleDetailResource) => void;
}

export interface IMessageRoomScheduleCompletedPopup extends IAbstractPopup<IMessageRoomScheduleCompletedPopupProps> {
  name: 'messageRoomScheduleCompleted'
}

function MessageRoomScheduleCompletedPopup({
  roomId,
  onAcceptClick,
}: IMessageRoomScheduleCompletedPopupProps) {
  const [selectItem, setSelectItem] = React.useState<IScheduleDetailResource>();

  const handleAcceptClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(evt => {
    if (!selectItem) {
      alert("아이템을 선택해주세요");
      evt.stopPropagation();
      evt.preventDefault();
      return;
    }

    onAcceptClick && onAcceptClick(evt, selectItem)
  }, [selectItem, onAcceptClick]);

  const renderer = React.useCallback((item: IScheduleDetailResource) => {
    return (
      <ProductRenderItem
        product={item.items?.first()}
        className={ClassNameHelper.concat(item._id === selectItem?._id && "selected")}
        onClick={evt => {
          evt.preventDefault();
          setSelectItem({ ...item });
        }}
      />
    )
  }, [selectItem?._id])

  return (
    <DefaultPopup
      className="message-proudct-popup"
      onAcceptClick={handleAcceptClick}
    >
      <RoomScheduleGirdContainer
        className="primary-gallery"
        row={2}
        itemWidth={100}
        gap={0.6}
        roomId={roomId}
        renderer={renderer}
      />
    </DefaultPopup >
  )
}

export default React.memo(MessageRoomScheduleCompletedPopup);