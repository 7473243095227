import React from "react";
import FileInput, { IFileInputProps } from "./FileInput";
import { ClassNameHelper } from "@loonfactory/common";

interface IBusinessRegistrationFileInputGroupProps extends IFileInputProps {
  title?: string;
}

function BusinessRegistrationFileInputGroup({
  title,
  required,
  fileName,
  onChange,
  ...rest
}: IBusinessRegistrationFileInputGroupProps) {
  return (
    <div className="input-group" {...rest}>
      <label className="form-label fs-4">
        <span className={ClassNameHelper.concat(required && "required")}>{title ?? '사업자등록사본'}</span>
        <FileInput
          fileName={fileName}
          name="business"
          onChange={onChange}
        />
      </label>
    </div>
  )
}

export default React.memo(BusinessRegistrationFileInputGroup);