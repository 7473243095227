import { ClassNameHelper } from "@loonfactory/common";
import React from "react";

function Logo({ className, ...rest }: React.ImgHTMLAttributes<HTMLImageElement>) {
    return (
        <img className={ClassNameHelper.concat("logo", className)}
            width={211}
            height={41}
            src="/logo.png"
            alt="logo"
            {...rest}
        />
    )
}

export default React.memo(Logo);