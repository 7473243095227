import { ClassNameHelper } from "@loonfactory/common";
import HorizontalLinearLayout from "../../layouts/HorizontalLinearLayout";
import React from "react";
import { IHostItemProps } from "../../components/HostItem";
import CircleThumbnailItem from "../../components/CircleThumbnailItem";
import AutoResizeGallery from "../../components/galleries/AutoResizeGallery";
import IServiceError from "../../farmerLounges/IServiceError";
import { CancellationToken, CancellationTokenSource } from "@code-rabbits/core";
import { useIdentityContext } from "../../contexts/IdentityContext";
import ServiceErrorGenerator from "../../ServiceErrorGenerator";
import { Link, useNavigate } from "react-router-dom";
import EndPoints from "../../EndPoints";
import { challengeTalkingUsersAsync } from "../../challenges";
import AgeHelper from "../../AgeHelper";
import farmerLoungeService from "../../farmerLounges/FarmerLoungeService";

const HostItem = React.memo((props: IHostItemProps) => {
  const navigate = useNavigate();

  const handleClick = React.useCallback(async () => {
    const roomId = await farmerLoungeService.rooms.getListAsync({
      query: props.id?.toString()
    });

    navigate(`${EndPoints.message}?id=${roomId.data?.rooms?.first()?._id}`)
  }, [navigate, props.id])

  return (
    <CircleThumbnailItem
      className="talking-host-item pointer"
      src={props.thumbnailUrl ?? '/imgs/defaultProfileIcon.png'}
      title={`${props.name} 쇼호스트`}
      onClick={handleClick}
    />
  )
});

const newHostRenderer = (item: IHostItemProps) => {
  return (
    <HostItem {...item} />
  )
}

const keySelector = (item: IHostItemProps) => item.id;

interface IHostsProps {
  className: string;
}

const Hosts = React.memo((props: IHostsProps) => {
  const { userInfo } = useIdentityContext();
  const [errors, setErrors] = React.useState<IServiceError[] | null>(null);
  const [items, setItems] = React.useState<IHostItemProps[] | null>(null);

  React.useEffect(() => {
    const id = userInfo?._id;
    if (id === undefined || id === null) {
      return;
    }

    let cancellationTokenSource = new CancellationTokenSource();
    const updateActionAsync = async (token: CancellationToken) => {
      try {
        const result = await challengeTalkingUsersAsync({ myId: id, take: 6 }, token)
        if (!result.succeeded) {
          setErrors(result.errors);
          return;
        }

        setItems(result.data!.map(item => {
          const portfolio = item.portfolio?.first();
          const career = portfolio?.career;

          return {
            id: item._id!,
            ageRange: Math.floor(AgeHelper.getAge(item.birth!)),
            name: item?.name,
            tags: (career !== undefined && career !== null) ? career.descriptions.concat(career.content) : undefined,
            thumbnailUrl: item.profile?.location,
          }
        }));

      } catch (e) {
        console.error(e);
        setErrors([ServiceErrorGenerator.unknownError])
      }
    }

    updateActionAsync(cancellationTokenSource.token);

  }, [userInfo?._id]);

  if (errors?.any()) {
    return (
      <ul className={props.className}>
        {errors.map(item => {
          return (<li key={item.code} >{item.message}</li>)
        })}
      </ul>
    )
  }

  if (items === null) {
    return (
      <div className={props.className}>항목을 불러오고 있습니다.</div>
    )
  }

  return (
    <AutoResizeGallery
      className={props.className}
      itemWidth={128}
      items={items}
      keySelector={keySelector}
      renderer={newHostRenderer}
      row={2}
      paginationDisabled
    />
  )
})

function TalkingHostContainer({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={ClassNameHelper.concat(className, 'content-wrapper')}>
      <HorizontalLinearLayout className="fs-3 fw-semibold content-title-wrapper">
        <div className="content-title">나와 대화중인 <span className="text-primary">메세지</span></div>
        <div className="content-title-option">
          <Link className="text-primary" to={EndPoints.message}>더보기 +</Link>
        </div>
      </HorizontalLinearLayout>
      <div className="talking-host-content-wrapper">
        <Hosts className="talking-host-content" />
      </div>
    </div >
  )
}

export default React.memo(TalkingHostContainer);