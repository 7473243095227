import React from "react";
import { Link } from "react-router-dom";
import EndPoints from "../../EndPoints";
import { ReactComponent as Logo } from './logo.svg';
import HomeIcon from "../../icons/HomeIcon";
import FileUserDocumentIcon from "../../icons/FileUserDocumentIcon";
import AlarmClockIcon from "../../icons/AlarmClockIcon";
import AnchorIcon from "../../icons/AnchorIcon";
import CircleDollarIcon from "../../icons/CircleDollarIcon";
import ChartUpIcon from "../../icons/ChartUpIcon";
import CommentsIcon from "../../icons/CommentsIcon";
import { ReactComponent as EnvelopeIcon } from './envelopeIcon.svg';

function ManagersNav({
  className,
  ...rest
}: Omit<React.HTMLAttributes<HTMLElement>, 'children'>) {
  return (
    <nav className={className} {...rest}>
      <Link to={EndPoints.home} className="logol-wrapper">
        <Logo />
      </Link>
      <ul className="nav flex-column">
        <Link to={EndPoints.manager.index}>
          <li><HomeIcon />대시보드</li>
        </Link>
        <li>
          <FileUserDocumentIcon />회원관리
          <ul>
            <Link to={EndPoints.manager.users.total}>
              <li>전체 사용자</li>
            </Link>
            <Link to={EndPoints.manager.users.showhost}>
              <li>쇼호스트</li>
            </Link>
            <Link to={EndPoints.manager.users.advertiser}>
              <li>광고주</li>
            </Link>
            <Link to={EndPoints.manager.users.agency}>
              <li>에이전시</li>
            </Link>
          </ul>
        </li>
        <li>
          <AlarmClockIcon />매니지먼트
          <ul>
            <Link to={EndPoints.manager.managements.showhost}>
              <li>쇼호스트 관리</li>
            </Link>
            <Link to={EndPoints.manager.managements.advertiserAd}>
              <li>광고주 상품 관리</li>
            </Link>
            <Link to={EndPoints.manager.managements.agencyAd}>
              <li>에이전시 상품 관리</li>
            </Link>
          </ul>
        </li>
        <li>
          <AnchorIcon />매칭 관리
          <ul>
            <Link to={EndPoints.manager.schedule.index}>
              <li>매칭 목록</li>
            </Link>
            <Link to={EndPoints.manager.schedule.contract}>
              <li>매칭 완료</li>
            </Link>
            <Link to={EndPoints.manager.schedule.cancel}>
              <li>매칭 취소</li>
            </Link>
            <Link to={EndPoints.manager.schedule.done}>
              <li>방송 완료</li>
            </Link>
          </ul>
        </li>
        <li>
          <EnvelopeIcon />메세지 관리
          <ul>
            <Link to={EndPoints.manager.messages.chat}>
              <li>관리자 메시지</li>
            </Link>
          </ul>
        </li>
        <li>
          <CircleDollarIcon />포인트 관리
          <ul>
            <Link to={EndPoints.manager.points.index}>
              <li >포인트 현황</li>
            </Link>
            <Link to={EndPoints.manager.points.recharge}>
              <li>충전 관리</li>
            </Link>
            <Link to={EndPoints.manager.points.refund}>
              <li>출금 관리</li>
            </Link>
          </ul>
        </li>
        <li>
          <ChartUpIcon />통계
          <ul>
            <Link to={EndPoints.manager.statistics.index}>
              <li>전체 통계</li>
            </Link>
            <Link to={EndPoints.manager.statistics.schedule}>
              <li>매칭 통계</li>
            </Link>
            <Link to={EndPoints.manager.statistics.point}>
              <li>포인트 통계</li>
            </Link>
          </ul>
        </li>
        <li>
          <CommentsIcon />이벤트 관리
          <ul>
            <Link to={EndPoints.manager.events.email}>
              <li>메일 발송</li>
            </Link>
            <Link to={EndPoints.manager.events.popup.index} >
              <li>팝업</li>
            </Link>
            <Link to={EndPoints.manager.events.banner.index}>
              <li>배너 이미지</li>
            </Link>
            <Link to={EndPoints.manager.events.mainVisual.index}>
              <li >메인 비쥬얼</li>
            </Link>
          </ul>
        </li>
      </ul>
    </nav>
  )
}

export default React.memo(ManagersNav);