import React from "react";
import LazyShowHostIcon from "../../icons/LazyShowHostIcon";
import { ClassNameHelper } from "@loonfactory/common";

function ShowHostButton({ className, ...rest }: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button className={ClassNameHelper.concat("btn", "btn-outline-secondary", "fs-3", className)} {...rest}>
      <LazyShowHostIcon />
      쇼호스트
    </button>
  )
}

export default React.memo(ShowHostButton);