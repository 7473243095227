import React from "react";
import IdentityLayout from "../../layouts/IdentityLayout";
import IdentityContentWrapper from "../../components/identities/IdentityContentWrapper";
import ShowHostButton from "../../components/buttons/ShowHostButton";
import AdvertiserButton from "../../components/buttons/AdvertiserButton";
import AgencyButton from "../../components/buttons/AgencyButton";
import PrimaryOutlineButton from "../../components/buttons/PrimaryOutlineButton";
import { useLocation, useNavigate } from "react-router-dom";
import EndPoints from "../../EndPoints";
import './RegisterIntroPage.scss';

function RegisterIntroPage(props: React.HTMLAttributes<HTMLDivElement>) {
    const [selectedRole, setSelectedRole] = React.useState('');
    const navigate = useNavigate();
    const location = useLocation();

    const handleNextButtonClick = React.useCallback(() => {
        if (selectedRole !== String.empty) {
            let url = EndPoints.register;
            url += location.search.length !== 0 ? `${location.search}&` : "?";
            url += `role=${selectedRole}`;
            navigate(url);
        }
    }, [navigate, selectedRole, location.search]);

    return (
        <IdentityLayout className="register-intro-page">
            <IdentityContentWrapper>
                <div className="d-grid gap-5">
                    <div className="d-grid gap-4 register-intro-btn-group">
                        <ShowHostButton
                            className={selectedRole === 'showhost' ? 'active' : ''}
                            onClick={() => setSelectedRole('showhost')}
                        />
                        <AdvertiserButton
                            className={selectedRole === 'farmer' ? 'active' : ''}
                            onClick={() => setSelectedRole('farmer')}
                        />
                        <AgencyButton
                            className={selectedRole === 'agency' ? 'active' : ''}
                            onClick={() => setSelectedRole('agency')}
                        />
                    </div>
                    <PrimaryOutlineButton className="fs-3" onClick={handleNextButtonClick}>
                        다음
                    </PrimaryOutlineButton>
                </div>
            </IdentityContentWrapper>
        </IdentityLayout>
    )
}

export default React.memo(RegisterIntroPage)