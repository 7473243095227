import React from "react";
import RoleType from "../../farmerLounges/RoleType";
import PrimaryButton from "../buttons/PrimaryButton";
import PrimaryOutlineButton from "../buttons/PrimaryOutlineButton";
import AccountHolderInputGroup from "./inputs/AccountHolderInputGroup";
import AccountNumberInputGroup from "./inputs/AccountNumberInputGroup";
import AddressInputGroup from "./inputs/AddressInputGroup";
import BankNameInputGroup from "./inputs/BankNameInputGroup";
import BankbookFileInputGroup from "./inputs/BankbookFileInputGroup";
import BusinessRegistrationFileInputGroup from "./inputs/BusinessRegistrationFileInputGroup";
import BusinessRegistrationInputGroup from "./inputs/BusinessRegistrationInputGroup";
import ManagerNameInputGroup from "./inputs/ManagerNameInputGroup";
import PhoneNumberInputGroup from "./inputs/PhoneNumberInputGroup";

interface IBankAccountFormProps extends React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> {
  managerName?: string;
  phone?: string;
  address?: string;
  role?: RoleType;
  businessRegistrationNumber?: string;
  businessRegistrationFileName?: string;
  bankname?: string;
  accountHolder?: string;
  accountNumber?: string;
  backbookImageName?: string;
  onCancelClick?: React.MouseEventHandler<HTMLButtonElement>;
}

function BankAccountForm({
  managerName,
  phone,
  address,
  role,
  businessRegistrationNumber,
  businessRegistrationFileName,
  bankname,
  accountHolder,
  accountNumber,
  backbookImageName,
  onCancelClick,
  ...rest
}: Readonly<IBankAccountFormProps>) {

  const isShowhost = role === "showhost";

  return (
    <form {...rest}>
      <div className="row">
        <div className="col-4">
          <ManagerNameInputGroup readOnly disabled required defaultValue={managerName} />
        </div>
        <div className="col-8">
          <PhoneNumberInputGroup readOnly disabled required defaultValue={phone} />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <AddressInputGroup required defaultValue={address} />
        </div>
      </div>
      <div className="row">
        <div className="col-8">
          <BusinessRegistrationInputGroup
            required
            label={isShowhost ? "주민등록번호" : undefined}
            placeholder={isShowhost ? "주민등록번호를 입력해 주세요." : undefined}
            defaultValue={businessRegistrationNumber}
          />
        </div>
        <div className="col-4">
          <BusinessRegistrationFileInputGroup
            title={role === "showhost" ? "신분증 사본" : undefined}
            fileName={businessRegistrationFileName}
            required
          />
        </div>
      </div>
      <hr className="row mt-5 mb-5" />
      <div className="row">
        <div className="col-4">
          <BankNameInputGroup required defaultValue={bankname} />
        </div>
        <div className="col-4">
          <AccountHolderInputGroup required defaultValue={accountHolder} />
        </div>
        <div className="col-4">
          <BankbookFileInputGroup
            fileName={backbookImageName}
            name="bankbook"
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <AccountNumberInputGroup required defaultValue={accountNumber} />
        </div>
      </div>
      <div className="row justify-content-end mt-3">
        <div className="col-2">
          <PrimaryButton
            type="submit"
            className="w-100"
          >
            저장하기
          </PrimaryButton>
        </div>
        <div className="col-2">
          <PrimaryOutlineButton
            className="w-100"
            onClick={onCancelClick}
          >
            취소
          </PrimaryOutlineButton>
        </div>
      </div>
    </form>
  )
}

export default React.memo(BankAccountForm);