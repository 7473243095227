import React from "react";
import IDashBoardPageProps from "../DashBoardPage/IDashBoardPageProps";
import MainLayout from "../../layouts/MainLayout";
import PrimaryCalendar from "../../components/PrimaryCalendar";
import { ClassNameHelper } from "@loonfactory/common";
import { TileArgs } from "react-calendar/dist/cjs/shared/types";
import './MatchingPage.scss';
import LasyLeftIcon from "../../components/ImageSlier/LasyLeftIcon";
import LasyRightIcon from "../../components/ImageSlier/LasyRightIcon";

function MatchingPage(props: IDashBoardPageProps) {
  const [date, setDate] = React.useState(new Date());

  const handleLeftClick = React.useCallback(() => {
    setDate(new Date(date.setMonth(date.getMonth() - 1)));
  }, [date]);


  const handleRightClick = React.useCallback(() => {
    setDate(new Date(date.setMonth(date.getMonth() + 1)));
  }, [date]);

  const calendarTitleClassName = React.useCallback<(arg: TileArgs) => string | null>((arg: TileArgs) => {
    let today = new Date();
    if (today === arg.date) {
      return "today"
    }

    return null;
  }, []);

  return (
    <MainLayout {...props}>
      <section className={ClassNameHelper.concat('matching-section', "main-section")}>
        <div className="content-region">
          <div className="calendar-wrapper mt-5">
            <LasyLeftIcon
              className="btn pointer"
              width="5%"
              strokeWidth={3}
              height="none"
              stroke="#4E9A5E"
              onClick={handleLeftClick}
            />
            <PrimaryCalendar
              tileClassName={calendarTitleClassName}
              activeStartDate={date}
            />
            <LasyRightIcon
              className="btn pointer"
              width="5%"
              strokeWidth={3}
              height="none"
              stroke="#4E9A5E"
              onClick={handleRightClick}
            />
          </div>
          <div className="mt-5">
            <p></p>
          </div>
        </div>
      </section>
    </MainLayout>
  )
}

export default React.memo(MatchingPage);