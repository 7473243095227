import React from "react";
import ManagersLayout from "../../../layouts/ManagersLayout";
import { useMonthlyDeposit, useTotalDeposit, useTotalSend, useTotalWithdraw } from "../../../hooks/analytics";

interface IPointMainPage extends React.HTMLAttributes<HTMLDivElement> {

}


function PointMainPage(props: IPointMainPage) {

  const now = new Date();
  const nowMonth = (now.getMonth() + 1);
  const nowYear = now.getFullYear();

  const totalDeposit = useTotalDeposit();
  const totalSend = useTotalSend();
  const totalWithdraw = useTotalWithdraw();

  const monthlyDeposit = useMonthlyDeposit();
  const monthlySend = useMonthlyDeposit();
  const monthlyWithdraw = useMonthlyDeposit();

  const monthDeposit = monthlyDeposit?.find(item => item.year === nowYear && item.month === nowMonth)?.amount ?? 0;
  const monthSend = monthlySend?.find(item => item.year === nowYear && item.month === nowMonth)?.amount ?? 0;
  const monthWithdraw = monthlyWithdraw?.find(item => item.year === nowYear && item.month === nowMonth)?.amount ?? 0;

  return (
    <ManagersLayout className={props.className}>
      <main className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="my-3">총합</h2>
            </div>
            <div className="col-4 info-card">
              <div className="title">총 입금액</div>
              <div className="value">{totalDeposit} 원</div>
            </div>
            <div className="col-4 info-card dark-lime-green">
              <div className="title">총 출금액</div>
              <div className="value">{totalWithdraw} 원</div>
            </div>
            <div className="col-4 info-card strong-green">
              <div className="title">총 거래액</div>
              <div className="value">{totalSend} 원</div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h2 className="my-3">{nowYear}년 {nowMonth}월</h2>
            </div>
            <div className="col-4 info-card">
              <div className="title">이번달 입금액</div>
              <div className="value">{monthDeposit} 원</div>
            </div>
            <div className="col-4 info-card dark-lime-green">
              <div className="title">이번달 출금액</div>
              <div className="value">{monthWithdraw} 원</div>
            </div>
            <div className="col-4 info-card strong-green">
              <div className="title">이번달 거래액</div>
              <div className="value">{monthSend} 원</div>
            </div>
          </div>
        </div>
      </main>
    </ManagersLayout>
  )
}

export default React.memo(PointMainPage);