import { NotImplementedError } from "@loonfactory/common";
import React from "react";
import RechargPopup from "../components/popups/RechargPopup";
import RefundPopup from "../components/popups/RefundPopup";
import ProductPopup from "../components/popups/ProductPopup/ProductPopup";
import MessageRoomProudctPopup from "../components/popups/MessageRoomProudctPopup/MessageRoomProudctPopup";
import MessageRoomScheduleCompletedPopup from "../components/popups/MessageRoomScheduleCompletedPopup/MessageRoomScheduleCompletedPopup";
import NotificationPopup from "../components/popups/NotificationPopup";

export const PopupDict = {
  'recharg': RechargPopup,
  'refund': RefundPopup,
  'product': ProductPopup,
  'messageRoomProudct': MessageRoomProudctPopup,
  'messageRoomScheduleCompleted': MessageRoomScheduleCompletedPopup,
  'notification': NotificationPopup,
}

type PopupDictType = typeof PopupDict;
export type PopupName = keyof PopupDictType;

export type PopupType = { [name in PopupName]: Parameters<PopupDictType[name]>[0] };

export type PopupItemType<T extends PopupName> = { name: T, payload: PopupType[T] };


export interface IPopupContext {
  popups: (PopupItemType<PopupName>)[] | null;

  setPopup: <T extends PopupName>(name: T, payload: PopupType[T]) => void;
  setPopups: (popups: (PopupItemType<PopupName>)[]) => void;
  close: () => void;
}

const PopupContext = React.createContext<IPopupContext>({
  popups: null,
  setPopup: () => { throw new NotImplementedError(); },
  setPopups: () => { throw new NotImplementedError(); },
  close: () => { throw new NotImplementedError(); },
});

export function usePopupContextCreate() {
  const [popupItems, setPopupItems] = React.useState<PopupItemType<PopupName>[] | null>(null);

  const setPopups = React.useCallback((popups: PopupItemType<PopupName>[]) => {
    setPopupItems(popups);
  }, []);

  const setPopup = React.useCallback(<T extends PopupName>(name: T, payload: PopupType[T]) => {
    setPopups([{
      name: name,
      payload: payload,
    }])
  }, [setPopups]);

  const close = React.useCallback(() => {
    setPopupItems(null);
  }, []);

  return React.useMemo(() => ({
    popups: popupItems,
    setPopup: setPopup,
    setPopups: setPopups,
    close: close,
  }), [popupItems, setPopup, setPopups, close]);
}

export function usePopupContext() {
  return React.useContext(PopupContext);
}

export default PopupContext;

PopupContext.displayName = 'PopupContext'