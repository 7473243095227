import React, { Suspense } from 'react';

const AgencyIcon = React.lazy(() => import('./AgencyIcon'));

function LazyAgencyIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <Suspense fallback={<svg width="46" height="47" viewBox="0 0 46 47"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />}>
      <AgencyIcon {...props} />
    </Suspense>
  )
}

export default React.memo(LazyAgencyIcon)