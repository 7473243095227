import { ClassNameHelper } from "@loonfactory/common";
import HorizontalLinearLayout from "../layouts/HorizontalLinearLayout";
import React from "react";
import ProductGirdContainer from "./products/ProductGirdContainer";
import IItemResource from "../farmerLounges/farmerLoungeResources/IItemResource";
import { IItemListGetOptions } from "../farmerLounges/ItemsService";
import { useLocation } from "react-router-dom";
import AgencyProductItem from "../components/AgencyProductItem";

const agencyRender = (item: IItemResource) => (<AgencyProductItem {...item} />);

function AgencyContainer({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) {
  const location = useLocation();
  const searchParams = React.useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search])

  const options = React.useMemo(() => {
    let opt: IItemListGetOptions = {
      role: 'agency',
      picked: false,
    }
    const q = searchParams.get('q');

    if (q) {
      opt.search = q;
    }
    return opt;
  }, [searchParams]);


  return (
    <div className={ClassNameHelper.concat(className, 'content-wrapper')} {...rest}>
      <HorizontalLinearLayout className="fs-3 fw-semibold content-title-wrapper">
        <div className="content-title"><span className="text-primary">에이전시</span></div>
      </HorizontalLinearLayout>
      <div className="new-host-content-wrapper mt-2">
        <div className="new-host-content primary-gallery-wrapper">
          <ProductGirdContainer
            className="primary-gallery"
            itemWidth={180}
            row={3}
            renderer={agencyRender}
            options={options}
          />
        </div>
      </div>
    </div>
  );
}


export default React.memo(AgencyContainer);