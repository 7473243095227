import { ClassNameHelper } from "@loonfactory/common"
import React from "react"
import LazyCircleExclamationIcon from "../../icons/LazyCircleExclamationIcon"

function EmptyContent({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={ClassNameHelper.concat("empty-content", className)} {...rest}>
      <div>
        <LazyCircleExclamationIcon />
        <div>항목이 비어 있습니다</div>
      </div>
    </div>
  )
}

export default React.memo(EmptyContent)