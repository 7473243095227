import React from "react";
import { ClassNameHelper } from "@loonfactory/common";
import "./HorizontalLinearLayout.scss";

function HorizontalLinearLayout({ className, ...rest }: React.HTMLAttributes<HTMLDivElement>, ref: React.ForwardedRef<HTMLDivElement>) {
  return (
    <div ref={ref} className={ClassNameHelper.concat("horizontal-linear-layout", "layout", className)} {...rest} />
  )
}

export default React.memo(React.forwardRef(HorizontalLinearLayout));