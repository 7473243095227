import RoleType from "../farmerLounges/RoleType";

const roleNameDict: { [role in RoleType]: string } = {
  'farmer': '광고주',
  'agency': '에이전시',
  'showhost': '쇼호스트',
  'admin': '최고 관리자',
  'manager': '관리자'
}

const adminRoleSet = new Set<RoleType | null | undefined>(['admin', 'manager']);

export default class RoleManager {
  static getName(role: RoleType) {
    return roleNameDict[role];
  }

  static isAdmin(role: RoleType | null | undefined) {
    return adminRoleSet.has(role);
  }
}