import { useNavigate, useSearchParams } from "react-router-dom";
import SerachParamsHelper from "../../../../lib/SerachParamsHelper";
import ManagersLayout from "../../../../layouts/ManagersLayout";
import EmptyContent from "../../../../components/contents/EmptyContent";
import ShowhostProfileContainer from "../../../../containers/ShowhostProfileContainer/ShowhostProfileContainer";
import React from "react";
import IUserDetailResource from "../../../../farmerLounges/farmerLoungeResources/IUserDetailResource";
import { CancellationTokenSource } from "@code-rabbits/core";
import farmerLoungeService from "../../../../farmerLounges/FarmerLoungeService";
import EndPoints from "../../../../EndPoints";
import AcceptRejectButtonGroup from "../../../../components/managemensts/AcceptRejectButtonGroup";

interface IShowHostDetailPageProps extends React.HTMLAttributes<HTMLDivElement> {
}

function ShowhostManagementDetailPage({
  className,
  ...rest
}: IShowHostDetailPageProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [targetUser, setTargetUser] = React.useState<IUserDetailResource | null>(null);

  let hostId: number | undefined = undefined;

  React.useEffect(() => {
    if (hostId === undefined) {
      return;
    }

    let cts = new CancellationTokenSource();
    const updateDataAsync = (async () => {
      const response = await farmerLoungeService.users.getDetailAsync(hostId!);

      if (cts.isCancellationRequested) {
        return;
      }

      if (!response.succeeded) {
        return;
      }

      setTargetUser(response.data!);
    });

    updateDataAsync();

    return () => {
      cts.cancel();
    }
  }, [hostId]);

  const handleAcceptClick = React.useCallback(async (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation();
    evt.preventDefault();

    if (hostId === undefined) {
      return;
    }

    const result = await farmerLoungeService.users.updatePortfolioStateAsync(hostId, 'approved');
    if (!result.succeeded) {
      alert(result.errors.first().message);
      return;
    }

    navigate(EndPoints.manager.managements.showhost);
  }, [hostId, navigate])

  const handleRejectClick = React.useCallback(async (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation();
    evt.preventDefault();

    if (hostId === undefined) {
      return;
    }

    const result = await farmerLoungeService.users.updatePortfolioStateAsync(hostId, 'denied');
    if (!result.succeeded) {
      alert(result.errors.first().message);
      return;
    }

    navigate(EndPoints.manager.managements.showhost);
  }, [hostId, navigate]);


  try {
    hostId = SerachParamsHelper.getInt(searchParams, 'id')
  } catch (e) {
    if (e instanceof Error) {
      alert(e.message);
    }

    return (
      <ManagersLayout className={className} {...rest}>
        <EmptyContent />
      </ManagersLayout>
    )
  }

  return (
    <ManagersLayout className={className} {...rest}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <ShowhostProfileContainer
              hostId={hostId}
            />
            {targetUser?.portfolio?.first().state === 'pending' && (
              <AcceptRejectButtonGroup
                onAcceptClick={handleAcceptClick}
                onRejectClick={handleRejectClick}
              />
            )}
          </div>
        </div>
      </div>

    </ManagersLayout>
  )
}

export default React.memo(ShowhostManagementDetailPage);