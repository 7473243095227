import React from "react";
import HorizontalLinearLayout from "../../../layouts/HorizontalLinearLayout";
import { IFormInputProps } from "./FormInputGroup";
import { ClassNameHelper } from "@loonfactory/common";
import IWorkInfoResource from "../../../farmerLounges/farmerLoungeResources/IWorkInfoResource";

interface ISNSAccountInputProps extends IFormInputProps {
  liveTimeDefaultValue?: IWorkInfoResource | null;
}

function AvailableLiveTimeWrapper({
  onChange,
  required,
  liveTimeDefaultValue,
  ...rest
}: ISNSAccountInputProps) {
  const [typeSelect, setTypeSelect] = React.useState<string | undefined>(liveTimeDefaultValue?.days);
  const prevTime = liveTimeDefaultValue?.time.split('~')[0] ?? '';
  const nextTime = liveTimeDefaultValue?.time.split('~')[1] ?? '';

  React.useEffect(() => {
    setTypeSelect(liveTimeDefaultValue?.days);
  }, [liveTimeDefaultValue]);

  const handleTypeSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTypeSelect(e.target.value);
  };

  return (
    <div className="available-live-time" {...rest}>
      <HorizontalLinearLayout className={ClassNameHelper.concat(required && "required", "fw-semibold fs-4")}>
        라이브 가능 시간
      </HorizontalLinearLayout>
      <div className="row">
        <HorizontalLinearLayout className="justify-content-between pe-0">
          <select name="time-select" className="col-4 fs-6 px-2"
            value={typeSelect ?? 'type-select'} style={{ borderColor: '#E7E7E7' }}
            onChange={handleTypeSelectChange}>
            <option disabled hidden value='type-select'>유형 선택</option>
            <option value='weekday'>평일</option>
            <option value='weekend'>주말</option>
            <option value='all'>평일/주말</option>
          </select>
          <HorizontalLinearLayout className="justify-content-between align-items-center input-time-wrapper" >
            <input
              name="prev"
              type="text"
              onChange={onChange}
              required={required}
              className="form-control"
              defaultValue={prevTime}
            />
            <div className="px-4 fs-4" style={{ color: '#E7E7E7' }}>~</div>
            <input
              name="next"
              type="text"
              onChange={onChange}
              required={required}
              className="form-control"
              defaultValue={nextTime}
            />
          </HorizontalLinearLayout>
        </HorizontalLinearLayout>
      </div>
    </div>
  )
}

export default React.memo(AvailableLiveTimeWrapper);