import React from "react";
import INavItemProps from "./INavItemProps";
import NavItem from "./NavItem";
import LazyGridIcon from "../../../icons/LazyGridIcon";

function DashBoardNavItem({
  ...rest
}: Omit<INavItemProps, 'children'>) {
  return (
    <NavItem {...rest}>
      <LazyGridIcon /> 대쉬보드
    </NavItem>
  )
}

export default React.memo(DashBoardNavItem);