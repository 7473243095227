import React from "react";
import ManagersLayout from "../../../../layouts/ManagersLayout";
import farmerLoungeService from "../../../../farmerLounges/FarmerLoungeService";
import FormDataHelper from "../../../../lib/FormDataHelper";
import { useLocation, useNavigate } from "react-router-dom";
import EndPoints from "../../../../EndPoints";
import IPostResource from "../../../../farmerLounges/posts/IPostResource";
import { CancellationTokenSource } from "@code-rabbits/core";
import { IPostListGetOptions } from "../../../../farmerLounges/posts/PostsService";
import EmptyContent from "../../../../components/contents/EmptyContent";
import SerachParamsHelper from "../../../../lib/SerachParamsHelper";
import PopupForm from "../../../../components/managemensts/events/popups/PopupForm";

const options: IPostListGetOptions = {
  type: 'popup'
}

function PopupEditPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [popup, setBanner] = React.useState<IPostResource | undefined | null>(
    location.state as IPostResource | undefined | null
  );

  const id = React.useMemo(() => {
    try {

      return SerachParamsHelper.getInt(location.search, 'id');
    } catch (e) {
      if (popup !== undefined && popup !== null) {
        return popup._id;
      }

      throw e;
    }
  }, [popup, location.search]);

  const handleSubmit = React.useCallback<React.FormEventHandler<HTMLFormElement>>(async evt => {
    evt.stopPropagation();
    evt.preventDefault();

    if (id === null) {
      return;
    }

    let images: FileList | null = (evt.currentTarget.querySelector('input[type=file][name=image]') as HTMLInputElement | null)?.files ?? null;
    if (images === null || images.length === 0) {
      alert("사진을 반드시 넣어주세요.")
      return;
    }

    const formData = new FormData(evt.currentTarget);
    const title = FormDataHelper.getString(formData, 'title');

    const result = await farmerLoungeService.posts.editPostAsync(id, {
      type: 'popup',
      images: images,
      title: title
    });

    if (!result.succeeded) {
      alert(result.errors.first()?.codeDescription);
      return;
    }

    navigate(EndPoints.manager.events.popup.index);
  }, [id, navigate]);

  React.useEffect(() => {
    if (id === null) {
      return;
    }

    const cts = new CancellationTokenSource();

    (async () => {
      const result = await farmerLoungeService.posts.getListAsync(options);
      if (cts.isCancellationRequested) {
        return;
      }

      if (!result.succeeded) {
        alert("팝업 불러오는데에 실패했습니다.");
        return;
      }

      const post = result.data!.posts.find(item => item._id === id);
      if (post === undefined) {
        return;
      }

      setBanner(post);
    })();
  }, [id]);

  if (id === null && popup === null) {
    return (
      <ManagersLayout>
        <EmptyContent />
      </ManagersLayout>
    )
  }

  return (
    <ManagersLayout className="banner-create-page">
      <section className="d-flex justify-content-center align-items-center h-100">
        <PopupForm
          popupTitle={popup?.title}
          popupUrl={popup?.files?.first().location}
          className="container"
          onSubmit={handleSubmit}
        />
      </section>
    </ManagersLayout>
  )
}

export default React.memo(PopupEditPage);