import React from "react";
import Thumbnail from "../../Thumbnail";
import DangerButton from "../../buttons/DangerButton";
import PrimaryOutlineButton from "../../buttons/PrimaryOutlineButton";
import IItemResource from "../../../farmerLounges/farmerLoungeResources/IItemResource";
import { useUserDetail } from "../../../hooks/users";

interface IProductProps {
  item: IItemResource;
  onCheckedChange?: (evt: React.ChangeEvent<HTMLInputElement>, item: IItemResource) => void;
  onAcceptClick?: (evt: React.MouseEvent<HTMLButtonElement>, item: IItemResource) => void;
  onDetailClick?: (evt: React.MouseEvent<HTMLButtonElement>, item: IItemResource) => void;
  onRejectClick?: (evt: React.MouseEvent<HTMLButtonElement>, item: IItemResource) => void;
}

const Prodcut = React.memo(({
  item,
  onAcceptClick,
  onCheckedChange,
  onRejectClick,
  onDetailClick,
}: IProductProps) => {

  const user = useUserDetail(item.owner_id);

  const handleAcceptClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(async evt => {
    onAcceptClick && onAcceptClick(evt, item);
  }, [item, onAcceptClick]);

  const handleCheckedChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(evt => {
    onCheckedChange && onCheckedChange(evt, item);
  }, [item, onCheckedChange]);

  const handleRejectClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(async evt => {
    onRejectClick && onRejectClick(evt, item);
  }, [item, onRejectClick]);

  const handleDetailClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(async evt => {
    onDetailClick && onDetailClick(evt, item);
  }, [item, onDetailClick]);

  return (
    <tr>
      <td><input type="checkbox" onChange={handleCheckedChange} /></td>
      <td>{item._id}</td>
      <td>
        <Thumbnail className="d-flex justify-content-center" src={item.images?.first()?.location} width={100} height={100} />
      </td>
      <td>{user?.email}</td>
      <td>{item.name}</td>
      <td>{item.price}</td>
      <td>{item.state}</td>
      <td>
        <PrimaryOutlineButton onClick={handleDetailClick}>자세히 보기</PrimaryOutlineButton>
      </td>
      <td>
        {item.state === "pending" && (
          <div className="d-flex w-100 justify-content-around">
            <PrimaryOutlineButton onClick={handleAcceptClick}>승인</PrimaryOutlineButton>
            <DangerButton onClick={handleRejectClick}>거절</DangerButton>
          </div>
        )}
      </td>
    </tr>
  )
});

export default React.memo(Prodcut);