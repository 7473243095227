import React from "react";
import INavItemProps from "./INavItemProps";
import NavItem from "./NavItem";
import LazyBoxIcon from "../../../icons/LazyBoxIcon";

function AdProductNavItem(props: Omit<INavItemProps, 'children'>) {
  return (
    <NavItem {...props}>
      <LazyBoxIcon /> 광고 상품
    </NavItem>
  )
}

export default React.memo(AdProductNavItem);