import React from "react";
import { ClassNameHelper } from "@loonfactory/common";

function ContentRegion({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={ClassNameHelper.concat(className, 'content-region')} {...rest} />
  )
}

export default React.memo(ContentRegion);