import React from "react";
import Thumbnail from "./Thumbnail";
import { ClassNameHelper } from "@loonfactory/common";

export interface IPrimaryGalleryItemProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  thumbnailUrl?: string | null;
  title?: string | null;
  ageRange?: number | null;
}

function PrimaryGalleryItem({
  thumbnailUrl,
  title,
  className,
  ageRange,
  ...rest
}: IPrimaryGalleryItemProps) {

  return (
    <div className={ClassNameHelper.concat('primary-gallery-item', className)} {...rest}>
      <Thumbnail src={thumbnailUrl} />
      <div className="primary-gallery-info mt-2">
        <div className="fw-semibold fs-4">{title}</div>
        {ageRange !== undefined && <div className="fs-7 text-gray">{ageRange} 대</div>}
      </div>
    </div>
  )
}

export default React.memo(PrimaryGalleryItem);