import { ClassNameHelper } from "@loonfactory/common";
import React from "react";

export interface IInputTitleProps extends React.HTMLAttributes<HTMLDivElement> {
  required?: boolean;
}

function InputTitle({
  required,
  children,
  ...rest
}: IInputTitleProps) {
  return (
    <div className="input-title" {...rest}>
      <span className={ClassNameHelper.concat(required && "required")}>{children}</span>
    </div>
  )
}

export default React.memo(InputTitle);