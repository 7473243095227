import React from "react";
import MainLayout from "../layouts/MainLayout";
import { ClassNameHelper } from "@loonfactory/common";
import ShowhostProfileContainer from "../containers/ShowhostProfileContainer/ShowhostProfileContainer";
import { useSearchParams } from "react-router-dom";
import EmptyContent from "../components/contents/EmptyContent";
import SerachParamsHelper from "../lib/SerachParamsHelper";

interface IShowHostDetailPageProps extends React.HTMLAttributes<HTMLDivElement> {
}

function ShowHostDetailPage({ className, ...rest }: IShowHostDetailPageProps) {
  const [searchParams] = useSearchParams();
  let hostId: number;

  try {
    hostId = SerachParamsHelper.getInt(searchParams, 'hostId')
  } catch (e) {
    if (e instanceof Error) {
      alert(e.message);
    }

    return (
      <MainLayout>
        <section className={ClassNameHelper.concat("find-show-host-detail-section main-section", className)}>
          <EmptyContent />
        </section>
      </MainLayout>
    )
  }

  return (
    <MainLayout>
      <ShowhostProfileContainer
        hostId={hostId}
        className="main-section"
        {...rest}
      />
    </MainLayout>
  )
}

export default React.memo(ShowHostDetailPage);