import { ClassNameHelper } from "@loonfactory/common";
import React from "react";

const ColGroup = React.memo(() => {
  return (
    <colgroup>
      <col style={{ width: "2rem" }} />
      <col />
      <col />
      <col />
      <col />
      <col style={{ width: "10rem" }} />
    </colgroup>
  )
});

function UserTable({ children, className, ...rest }: React.TableHTMLAttributes<HTMLTableElement>) {
  return (
    <table className={ClassNameHelper.concat(className, "table")} {...rest}>
      <ColGroup />
      <thead>
        <tr>
          <th></th>
          <th>아이디</th>
          <th>이름</th>
          <th>생년월일</th>
          <th>전화번호</th>
          <th></th>
        </tr>
      </thead>
      {children}
    </table >

  )
}

export default React.memo(UserTable);