import React from "react";
import VerticalLinearLayout from "../layouts/VerticalLinearLayout";
import Thmbnail from "./Thumbnail";

export interface ILiveCommerceItemProps {
  thumbnailUrl?: string;
  title?: string;
}

function LiveCommerceItem({
  thumbnailUrl,
  title,
  ...rest
}: ILiveCommerceItemProps) {
  return (
    <VerticalLinearLayout className="live-commerce-item" {...rest}>
      <Thmbnail src={thumbnailUrl} />
      <div className="title-wrapper mt-1">
        <div className="fs-4 title">{title}</div>
      </div>
    </VerticalLinearLayout>
  )
}

export default React.memo(LiveCommerceItem);