import ApiEndPoints from "../ApiEndPoints";
import FarmerLoungesBaseApiService from "../FarmerLoungesBaseApiService";
import IServiceResponse from "../IServiceResponse";
import ServiceResult from "../ServiceResult";
import IWalletInfoResource from "./IWalletInfoResource";
import IWalletResource from "./IWalletInfoResource";

class WalletsService extends FarmerLoungesBaseApiService {
  getDetailAsync = async (walletId: string | number): Promise<ServiceResult<IWalletInfoResource>> => {
    const url = this.buildUrl(ApiEndPoints.wallets.detail.replaceAll("{id}", walletId.toString()));
    const response = await this.getAsync(url);

    const result = (await response.json()) as IServiceResponse<IWalletResource>;
    if (result.code !== 2000) {
      switch (result.code) {
        default:
          return this.globalErrorHandler(result);
      }
    }

    return ServiceResult.succeeded(result.result);
  }
}

export default WalletsService;