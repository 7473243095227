import { ClassNameHelper } from "@loonfactory/common";
import HorizontalLinearLayout from "../layouts/HorizontalLinearLayout";
import React from "react";
import PrimaryGalleryItem from "../components/PrimaryGalleryItem";
import IUserDetailResource from "../farmerLounges/farmerLoungeResources/IUserDetailResource";
import { Link, useLocation } from "react-router-dom";
import EndPoints from "../EndPoints";
import { IChallengeUsersOptions } from "../challenges";
import UsersGirdContainer from "./UsersGirdContainer";

const hostItemRender = (item: IUserDetailResource) => {
  return (
    <Link to={`${EndPoints.hostDetail}?hostId=${item._id}`}>
      <PrimaryGalleryItem
        className="primary-gallery-item"
        thumbnailUrl={item.portfolio?.first()?.images?.first().location}
        title={item.name}
      />
    </Link>

  )
}

const defaultOptions: IChallengeUsersOptions = {
  role: "showhost"
}

function ShowHostContainer({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) {
  const location = useLocation();
  const searchParams = React.useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search])

  const options = React.useMemo(() => {
    let opt = {
      ...defaultOptions,
    }
    const q = searchParams.get('q');

    if (q) {
      opt.name = q;
    }
    return opt;
  }, [searchParams]);

  return (
    <div className={ClassNameHelper.concat(className, 'content-wrapper')} {...rest}>
      <HorizontalLinearLayout className="fs-3 fw-semibold content-title-wrapper">
        <div className="content-title">쇼호스트</div>
      </HorizontalLinearLayout>
      <div className="new-host-content-wrapper mt-2">
        <UsersGirdContainer
          className="new-host-content primary-gallery-wrapper"
          itemWidth={180}
          row={2}
          renderer={hostItemRender}
          options={options}
        />
      </div>
    </div>
  );
}


export default React.memo(ShowHostContainer);