import { ClassNameHelper } from "@loonfactory/common";
import React from "react";

interface IPaginationsProps extends React.HTMLAttributes<HTMLDivElement> {
  index?: number;
  totalCount?: number;
  pagePerCount?: number;
  take?: number;
  onIndexClick?: React.MouseEventHandler<HTMLButtonElement>;
}

function Paginations({
  index = 1,
  totalCount = 1,
  pagePerCount = 16,
  take = 5,
  className,
  onIndexClick,
  ...rest
}: IPaginationsProps) {

  const startIndex = React.useMemo(() => Math.max(1, index - Math.floor(take / 2)), [index, take]);

  const maxIndex = React.useMemo(
    () => Math.ceil((totalCount) / (pagePerCount)),
    [pagePerCount, totalCount]
  );

  const indexs = React.useMemo(() => {
    let ary = [];
    for (let index = startIndex; index <= Math.min(startIndex + take, maxIndex); index++) {
      ary.push(index);
    }
    return ary;
  }, [startIndex, maxIndex, take]);

  return (
    <div className={ClassNameHelper.concat('paginations', className)} {...rest}>
      {indexs.map(offset => {
        const currentIndex = offset === index;
        return (
          <button key={`page-${offset}`}
            className={ClassNameHelper.concat("page-index btn", currentIndex && "active")}
            disabled={currentIndex}
            data-index={offset}
            onClick={onIndexClick}
          >
            {offset}
          </button>
        )
      })}
    </div>
  );
}

export default React.memo(Paginations);