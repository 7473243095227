import React from "react";
import { ClassNameHelper } from "@loonfactory/common";
import LazyAgencyIcon from "../../icons/LazyAgencyIcon";

function AgencyButton({ className, ...rest }: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button className={ClassNameHelper.concat("btn", "btn-outline-secondary", "fs-3", className)} {...rest}>
      <LazyAgencyIcon />
      에이전시
    </button>
  )
}

export default React.memo(AgencyButton);