import { ClassNameHelper } from "@loonfactory/common";
import React from "react";

export interface IMessageProps extends React.HTMLAttributes<HTMLDivElement> {
  publistAt?: string;
}

const timeFormat: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', hour12: true };

function Message({ children, publistAt, className, ...rest }: IMessageProps) {
  const time = React.useMemo(() => {
    if (!publistAt) {
      return;
    }
    return new Date(publistAt).toLocaleString('en-US', timeFormat)
  }, [publistAt])
  return (
    <div className={ClassNameHelper.concat("message", className)} {...rest}>
      <div className="speech-bubble">{children}</div>
      <div className="time">{time}</div>
    </div>
  )
}

export default React.memo(Message);