import React from "react";
import Thumbnail from "./Thumbnail";
import { ClassNameHelper } from "@loonfactory/common";

interface IProfileIconProps extends React.HTMLAttributes<HTMLDivElement> {
  url?: string;
}

function ProfileIcon({
  className,
  url,
  ...rest
}: IProfileIconProps) {

  return (
    <div className={ClassNameHelper.concat('profile-icon', className)} {...rest}>
      <Thumbnail src={url ?? '/imgs/defaultProfileIcon.png'} />
    </div>
  )
}


export default React.memo(ProfileIcon);