import React from "react";
import IdentityLayout from "../../layouts/IdentityLayout";
import IdentityContentWrapper from "../../components/identities/IdentityContentWrapper";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import './RegisterPage.scss';
import { useLocation, useNavigate } from "react-router-dom";
import EndPoints from "../../EndPoints";
import farmerLoungeService from "../../farmerLounges/FarmerLoungeService";
import IServiceError from "../../farmerLounges/IServiceError";
import ServiceErrorGenerator from "../../ServiceErrorGenerator";
import { ICraeteUserPropreties } from "../../farmerLounges/UsersService";
import ErrorViewer from "../../components/ErrorViewer";

interface IChallengeResult {
    succeeded: boolean;
    errors?: IServiceError[];
}

const phonNumberRegExp = /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/;

const challengeCraetUserAsync = (formData: FormData): Promise<IChallengeResult> => {
    const email = formData.get('email') as string | null;
    if (String.isUnvalidOrWhiteSpace(email)) {
        return Promise.resolve({
            succeeded: false,
            errors: [ServiceErrorGenerator.emailIsRequired]
        });
    }

    const password = formData.get('password') as string | null;
    if (password === String.empty || password === null) {
        return Promise.resolve({
            succeeded: false,
            errors: [ServiceErrorGenerator.passwordIsRequired]
        });
    }


    const confirmPassword = formData.get('confirm-password') as string | null;
    if (confirmPassword === String.empty || confirmPassword === null) {
        return Promise.resolve({
            succeeded: false,
            errors: [ServiceErrorGenerator.passwordNotMatch]
        });
    }

    const name = (formData.get('name') as string | null)?.trim();
    if (String.isUnvalidOrWhiteSpace(name)) {
        return Promise.resolve({
            succeeded: false,
            errors: [ServiceErrorGenerator.nameIsRequired]
        });
    }

    const phonNumber = formData.get('phonNumber') as string | null;
    if (phonNumber === null) {
        return Promise.resolve({
            succeeded: false,
            errors: [ServiceErrorGenerator.phoneNumberIsRequired]
        });
    }

    if (!phonNumberRegExp.test(phonNumber)) {
        return Promise.resolve({
            succeeded: false,
            errors: [ServiceErrorGenerator.invalidPhoneNumberFormat]
        });
    }

    const verifyPhoneNumberToken = formData.get('verify-phone-number-token') as string | null;
    if (String.isUnvalidOrWhiteSpace(verifyPhoneNumberToken)) {
        return Promise.resolve({
            succeeded: false,
            errors: [ServiceErrorGenerator.verifyPhoneNumberTokenIsRequired]
        });
    }

    const birthday = formData.get('birth') as string | null;
    if (String.isUnvalidOrWhiteSpace(birthday)) {
        return Promise.resolve({
            succeeded: false,
            errors: [ServiceErrorGenerator.birthdayIsRequired]
        });
    }

    const role = formData.get('role') as string | null;
    if (String.isUnvalidOrWhiteSpace(role)) {
        return Promise.resolve({
            succeeded: false,
            errors: [ServiceErrorGenerator.roleIsRequired]
        });
    }

    return challenge({
        name: name,
        phone: phonNumber,
        verify_code: verifyPhoneNumberToken,
        birth: birthday,
        email: email,
        password: password,
        role: role,
    });

    async function challenge(user: ICraeteUserPropreties): Promise<IChallengeResult> {
        let phoneNumberConfirmResult = await farmerLoungeService.auth.confirmPhoneNumberAsync(user.phone, user.verify_code);
        if (!phoneNumberConfirmResult.succeeded || !phoneNumberConfirmResult.data) {
            return {
                succeeded: false,
                errors: [ServiceErrorGenerator.invalidPhoneNumber]
            }
        }

        const result = await farmerLoungeService.users.createAsync(user);
        if (!result.succeeded) {
            return {
                succeeded: false,
                errors: result.errors
            }
        }

        return {
            succeeded: true
        }
    }
};

function RegisterPage(props: React.HTMLAttributes<HTMLDivElement>) {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const role = searchParams.get('role')?.trim();
    const email = searchParams.get('email');

    const [errors, setErrors] = React.useState<IServiceError[] | null>(null);
    const [phonNumber, setPhonNumber] = React.useState('');
    const [verifyPhoneNumberLock, setVerifyPhoneNumberLock] = React.useState(false);

    const handlePhonNumberChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(evt => {
        setPhonNumber(evt.currentTarget.value);
        setVerifyPhoneNumberLock(false);
    }, []);

    const handleVerifyPhoneNumberClick = React.useCallback(async () => {
        if (String.isUnvalidOrWhiteSpace(phonNumber)) {
            return;
        }

        if (verifyPhoneNumberLock) {
            return;
        }

        setVerifyPhoneNumberLock(true);
        const requestResult = await farmerLoungeService.auth.generatePhoneNumberConfirmationTokenAsync(phonNumber);
        if (!requestResult.succeeded) {
            alert(requestResult.errors?.first()?.message);
            setVerifyPhoneNumberLock(false);
            return;
        }

        alert("인증번호가 성공적으로 전송되었습니다.")
    }, [phonNumber, verifyPhoneNumberLock]);

    const [submitLock, setSubmitLock] = React.useState(false);

    const handleFormSubmit = React.useCallback<React.FormEventHandler<HTMLFormElement>>(async evt => {
        evt.stopPropagation();
        evt.preventDefault();

        if (submitLock) {
            return;
        }

        setSubmitLock(true);
        const formData = new FormData(evt.currentTarget);
        try {
            const result = await challengeCraetUserAsync(formData);
            if (result.succeeded) {
                navigate(EndPoints.login);
            } else {
                setErrors(result.errors!);
            }
        } finally {
            setSubmitLock(false);
        }

        return;
    }, [submitLock, navigate]);

    if (role === null || role === undefined || role === String.empty) {
        window.location.pathname = EndPoints.registerIntro;
        return <></>;
    }

    return (
        <IdentityLayout className="register-page">
            <IdentityContentWrapper>
                <form className="d-grid gap-5" action="" method="post" onSubmit={handleFormSubmit}>
                    <input readOnly name="role" type="hidden" value={role ?? ''} />
                    <div className="d-grid gap-4">
                        <div className="input-group-wrapper">
                            <label htmlFor="email" className="form-label fs-4">이메일</label>
                            <div className="input-group">
                                <input type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    value={email ?? undefined}
                                    readOnly={email !== null}
                                />
                            </div>
                        </div>
                        <div className="input-group-wrapper">
                            <label htmlFor="password" className="form-label fs-4">비밀번호</label>
                            <div className="input-group">
                                <input type="password"
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    autoComplete="new-password"
                                />
                            </div>
                        </div>
                        <div className="input-group-wrapper">
                            <label htmlFor="confirm-password" className="form-label fs-4">비밀번호 확인</label>
                            <div className="input-group">
                                <input type="password"
                                    className="form-control"
                                    id="confirm-password"
                                    name="confirm-password"
                                    autoComplete="new-password"
                                />
                            </div>
                        </div>
                        <div className="input-group-wrapper">
                            <label htmlFor="name" className="form-label fs-4">이름</label>
                            <div className="input-group">
                                <input type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                />
                            </div>
                        </div>
                        <div className="input-group-wrapper">
                            <label htmlFor="id" className="form-label fs-4">전화번호</label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="phonNumber"
                                    value={phonNumber}
                                    onChange={handlePhonNumberChange}
                                />
                                <button
                                    type="button"
                                    className="btn btn-secondary fs-6"
                                    disabled={verifyPhoneNumberLock}
                                    onClick={handleVerifyPhoneNumberClick}
                                >
                                    인증
                                </button>
                            </div>
                        </div>
                        <div className="input-group-wrapper">
                            <label htmlFor="id" className="form-label fs-4">인증번호</label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="verify-phone-number-token"
                                />
                            </div>
                        </div>
                        <div className="input-group-wrapper">
                            <label htmlFor="birth" className="form-label fs-4">생년월일</label>
                            <div className="input-group">
                                <input type="text" className="form-control" id="birth" name="birth" maxLength={6} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <label>
                            <input type="checkbox" name="terms" required /> 파머라운지 서비스 이용 약관에 동의합니다. <span className="text-red">(필수)</span> <a className="text-gray" target="_blank" rel="noreferrer" href={process.env.REACT_APP_FAQ_URL}>자세히 보기</a>
                        </label>
                    </div>
                    {errors?.any() && <ErrorViewer errors={errors} />}
                    <PrimaryButton className="fs-3" type="submit">회원가입</PrimaryButton>
                </form>
            </IdentityContentWrapper>
        </IdentityLayout>
    )
}

export default React.memo(RegisterPage);