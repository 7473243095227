import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import EndPoints from './EndPoints';
import HomePage from './pages/HomePage/HomePage';
import LoginPage from './pages/LoginPage';
import RegisterIntroPage from './pages/RegisterIntroPage';
import RegisterPage from './pages/RegisterPage';
import { useIdentityContext } from './contexts/IdentityContext';
import UnauthRoutes from './routes/UnauthRoutes';
import ForgotPasswordPage from './pages/identities/ForgotPasswordPage';
import ForgotPasswordResultPage from './pages/identities/ForgotPasswordResultPage';
import ForgotIdPage from './pages/identities/ForgotIdPage';
import PopupContaier from './containers/popups/PopupContainer';
import IdentityProvider from './providers/IdentityProvider';
import PopupProvider from './providers/PopupProvider';
import { Chart as ChartJS, registerables } from "chart.js";
import ManagerRoutes from './pages/routes/ManagerRoutes';
import UserRoutes from './pages/routes/UserRoutes';
import LoginCallbackPage from './pages/LoginCallbackPage';

ChartJS.register(...registerables);

function Routers() {
  const { tokenInfo } = useIdentityContext();
  const role = tokenInfo?.role;
  const handleMath = React.useCallback(() => {
    if (role !== "manager" && role !== "admin") {
      return false;
    }
  }, [role]);

  return (
    <>
      <Routes>
        <Route path={EndPoints.home} Component={HomePage}></Route>
      </Routes>
      <UnauthRoutes>
        <Route path={EndPoints.login} Component={LoginPage}></Route>
        <Route path={EndPoints.loginCallback} Component={LoginCallbackPage}></Route>
        <Route path={EndPoints.register} Component={RegisterPage}></Route>
        <Route path={EndPoints.registerIntro} Component={RegisterIntroPage}></Route>
        <Route path={EndPoints.identities.forgotId} Component={ForgotIdPage}></Route>
        <Route path={EndPoints.identities.forgotPassword} Component={ForgotPasswordPage}></Route>
        <Route path={EndPoints.identities.forgotPasswordResult} Component={ForgotPasswordResultPage}></Route>
      </UnauthRoutes>
      <UserRoutes />
      <ManagerRoutes onMatche={handleMath} />
    </>
  )
}

function App() {
  return (
    <BrowserRouter>
      <IdentityProvider>
        <PopupProvider>
          <Routers />
          <PopupContaier />
        </PopupProvider>
      </IdentityProvider>
    </BrowserRouter>
  );
}

export default App;
