import React from "react";
import Message, { IMessageProps } from "./Message";
import PrimaryButton from "../buttons/PrimaryButton";
import { useIdentityContext } from "../../contexts/IdentityContext";
import IScheduleDetailResource from "../../farmerLounges/farmerLoungeResources/IScheduleDetailResource";

interface IScheduleConfirmMessageProps extends Omit<IMessageProps, 'children'> {
  senderId?: number;
  schdule: IScheduleDetailResource;
  onScheduleConfirmClick?: (evt: React.MouseEvent<HTMLButtonElement>, schdule: IScheduleDetailResource) => void;
}

function ScheduleConfirmMessage({ schdule, senderId, onScheduleConfirmClick, ...rest }: IScheduleConfirmMessageProps) {
  const { userInfo } = useIdentityContext();
  const handleClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(evt => {
    
    onScheduleConfirmClick && onScheduleConfirmClick(evt, schdule)
  }, [schdule, onScheduleConfirmClick]);

  const scheduleAt = schdule?.scheduled_at !== undefined ? new Date(schdule.scheduled_at) : undefined;
  const date = `${scheduleAt?.getFullYear().toString().padStart(4, '0')}-${scheduleAt?.getMonth().toString().padStart(2, '0')}-${scheduleAt?.getDay().toString().padStart(2, '0')}`;

  return (
    <Message {...rest}>
      <div className="schedule-form contaier">
        <div className="row">
          <label className="col-5" >진행상품</label>
          <input className="col-7" type="text" readOnly defaultValue={schdule?.items?.first()?.name} />
        </div>
        <div className="row">
          <label className="col-5" >날짜</label>
          <input className="col-7" name="date" type="date" readOnly
            defaultValue={date} />
        </div>
        <div className="row">
          <label className="col-5" >시간</label>
          <input className="col-7" name="time" type="time" readOnly
            defaultValue={`${scheduleAt?.getHours().toString().padStart(2, '0')}:${scheduleAt?.getMinutes().toString().padStart(2, '0')}`} />
        </div>
        <div className="row">
          <label className="col-5" >진행예정채널</label>
          <input className="col-7" name="channel" type="text" readOnly
            defaultValue={schdule?.expected_infos?.first()?.platform}
          />
        </div>
        <div className="row">
          <label className="col-5" >금액</label>
          <input className="col-7" name="pay" type="text" readOnly
            defaultValue={schdule?.fee} />
        </div>
        {userInfo?._id === senderId ? (
          <div className="row justify-content-center mt-4">
            {userInfo?.name}께서 예약 요청을 하셨습니다.
          </div>
        ) : (
          <div className="row justify-content-end mt-4">
            <PrimaryButton className="col-4" onClick={handleClick}>예약 수락</PrimaryButton>
          </div>
        )}
      </div>
    </Message>
  )
}

export default React.memo(ScheduleConfirmMessage);