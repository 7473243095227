import React from "react";
import { IFormInputProps } from "./forms/inputs/FormInputGroup";
import HorizontalLinearLayout from "../layouts/HorizontalLinearLayout";

function TotalPeriodWrapper({
  defaultValue,
  onChange,
  required,
  ...rest
}: IFormInputProps) {
  return (
    <div className="total-career-wrapper" {...rest}>
      <HorizontalLinearLayout className="fw-semibold fs-4 required">
        총 경력
      </HorizontalLinearLayout>
      <HorizontalLinearLayout key={defaultValue?.toString() ?? ""} >
        <HorizontalLinearLayout className="justify-content-center align-items-center">
          <input type="radio" name="period" id="year-1-below"
            value="1년 미만" defaultChecked={defaultValue === "1년 미만"} required={required} />
          <label htmlFor="year-1-below" className="ps-3 fs-4 fw-semibold">1년 미만</label>
        </HorizontalLinearLayout>
        <HorizontalLinearLayout className="justify-content-center align-items-center ms-4">
          <input type="radio" name="period" id="year-1"
            value="1년" defaultChecked={defaultValue === "1년"} required={required} />
          <label htmlFor="year-1" className="ps-3 fs-4 fw-semibold">1년</label>
        </HorizontalLinearLayout>
        <HorizontalLinearLayout className="justify-content-center align-items-center ms-4">
          <input type="radio" name="period" id="year-2"
            value="2년" defaultChecked={defaultValue === "2년"} required={required} />
          <label htmlFor="year-2" className="ps-3 fs-4 fw-semibold">2년</label>
        </HorizontalLinearLayout>
        <HorizontalLinearLayout className="justify-content-center align-items-center ms-4">
          <input type="radio" name="period" id="year-3"
            value="3년" defaultChecked={defaultValue === "3년"} required={required} />
          <label htmlFor="year-3" className="ps-3 fs-4 fw-semibold">3년</label>
        </HorizontalLinearLayout>
        <HorizontalLinearLayout className="justify-content-center align-items-center ms-4">
          <input type="radio" name="period" id="year-4"
            value="4년" defaultChecked={defaultValue === "4년"} required={required} />
          <label htmlFor="year-4" className="ps-3 fs-4 fw-semibold">4년</label>
        </HorizontalLinearLayout>
        <HorizontalLinearLayout className="justify-content-center align-items-center ms-4">
          <input type="radio" name="period" id="year-5-over"
            value="5년 이상" defaultChecked={defaultValue === "5년 이상"} required={required} />
          <label htmlFor="year-5-over" className="ps-3 fs-4 fw-semibold">5년 이상</label>
        </HorizontalLinearLayout>
      </HorizontalLinearLayout>
    </div>
  )
}

export default React.memo(TotalPeriodWrapper);