import React from "react";
import PostTableItem from "./PostTableItem";
import ContentLoadFail from "../../../contents/ContentLoadFail";
import IServiceError from "../../../../farmerLounges/IServiceError";
import LoadingContent from "../../../contents/LoadingContent";
import EmptyContent from "../../../contents/EmptyContent";
import IPostResource from "../../../../farmerLounges/posts/IPostResource";

interface IPostTableItemsProps {
  items?: IPostResource[] | null;
  errors?: IServiceError[]
  onEditClick?: (evt: React.MouseEvent<HTMLButtonElement>, item: IPostResource) => void;
  onCheckedChange?: (evt: React.ChangeEvent<HTMLInputElement>, item: IPostResource) => void;
}

const PostTableItems = React.memo((props: IPostTableItemsProps) => {
  if (props.errors?.any()) {
    return (
      <tbody>
        <tr>
          <td colSpan={8}>
            <ContentLoadFail errors={props.errors} />
          </td>
        </tr>
      </tbody>
    )
  }

  if (props.items === null) {
    return (
      <tbody>
        <tr>
          <td colSpan={8}>
            <LoadingContent />
          </td>
        </tr>
      </tbody>
    )
  }

  if (!props.items?.any()) {
    return (
      <tbody>
        <tr>
          <td colSpan={8}>
            <EmptyContent />
          </td>
        </tr>
      </tbody>
    )
  }

  return (
    <tbody>
      {props.items?.map(item => {
        return (
          <PostTableItem
            key={item._id}
            item={item}
            onEditClick={props.onEditClick}
            onCheckedChange={props.onCheckedChange}
          />
        )
      })}
    </tbody>
  )
});

export default PostTableItems;