import ApiEndPoints from "../ApiEndPoints";
import FarmerLoungesBaseApiService from "../FarmerLoungesBaseApiService";
import IServiceResponse from "../IServiceResponse";
import RoleType from "../RoleType";
import ServiceResult from "../ServiceResult";

interface ISendProperties {
  to?: string;
  role?: RoleType | 'all';
  subject: string;
  content: string;
}

class EmailService extends FarmerLoungesBaseApiService {
  sendAsync = async (properties?: ISendProperties): Promise<ServiceResult> => {
    const url = this.buildUrl(ApiEndPoints.emails.send);
    const response = await this.postJsonAsync(url, properties);

    const result = (await response.json()) as IServiceResponse;
    if (result.code === 2000) return ServiceResult.succeeded(result.result);

    return this.globalErrorHandler(result);
  }
}

export default EmailService;