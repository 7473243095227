import { ClassNameHelper } from "@loonfactory/common";
import React from "react";
import { ReactComponent as FileInputIcon } from './FileInputIcon.svg';

export interface IFileInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  fileName?: string;
}

function FileInput({
  fileName,
  className,
  onChange,
  ...rest
}: IFileInputProps) {
  const [name, setName] = React.useState<string>();
  const handleChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(evt => {
    const files = evt.currentTarget.files;
    if (files !== null) {
      setName(files[0]?.name)
    }

    onChange && onChange(evt);
  }, [onChange]);

  return (
    <label className={ClassNameHelper.concat("file-input", className)}>
      <span>{name ?? fileName}</span>
      <span className="file-open-btn">
        <FileInputIcon />      
        파일 찾기
        <input type="file" onChange={handleChange} {...rest} />
      </span>
    </label>
  )
}

export default React.memo(FileInput);