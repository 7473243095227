export default class FormatError extends Error {
  constructor()
  constructor(message: string)
  constructor(name: string, value: string)
  constructor(arg1: string | undefined = "Invalid format.", arg2?: string | undefined) {
    let message = arg1;
    if (arg2 !== undefined) {
      message = `The '${arg1}' is an invalid format '${arg2}'`;
    }
    super(message);
  }
}