import React from "react";
import MainLayout from "../../layouts/MainLayout";
import { ClassNameHelper } from "@loonfactory/common";
import ContentTitleWrapper from "../../components/contents/ContentTitleWrapper";
import ProductForm from "../../components/forms/ProductForm";
import farmerLoungeService from "../../farmerLounges/FarmerLoungeService";
import { IUpdateItemProperties } from "../../farmerLounges/ItemsService";
import { useLocation, useNavigate } from "react-router-dom";
import { useIdentityContext } from "../../contexts/IdentityContext";
import './ProductPage.scss';
import IItemResource from "../../farmerLounges/farmerLoungeResources/IItemResource";
import { CancellationToken, CancellationTokenSource } from "@code-rabbits/core";

interface IProductUpdatePageProps extends React.HTMLAttributes<HTMLDivElement> {

}

function ProductUpdatePage(props: IProductUpdatePageProps) {
  const { userInfo } = useIdentityContext();
  const location = useLocation();
  const id = React.useMemo(() => {
    return Number.parseInt((new URLSearchParams(location.search)).get('id') as string ?? '');
  }, [location.search])

  const navigate = useNavigate();
  const [productItem, setProductItem] = React.useState<IItemResource>();

  const handleFormSubmit = React.useCallback<React.FormEventHandler<HTMLFormElement>>(async evt => {
    evt.preventDefault();
    evt.stopPropagation();

    const current = evt.currentTarget;
    const formdata = new FormData(current);

    const discussionContents = formdata.get('discussionContents') as string;
    const request = formdata.get('request') as string;
    const saleType = formdata.get('saleType') as string;

    let payload: IUpdateItemProperties = {
      id: Number.parseInt(formdata.get('id') as string),
      company: userInfo?.name ?? "",
      discussion_content: discussionContents,
      request: request,
      sale_type: saleType,
      name: formdata.get('productName') as string,
      price: Number.parseInt(formdata.get('productPrice') as string ?? ''),
    };

    const thumbnail = current.querySelector('input[name=thumbnail]') as HTMLInputElement;
    const detail = current.querySelector('input[name=detail]') as HTMLInputElement;

    if (thumbnail.files !== null && thumbnail.files.length > 0) {
      payload.images = thumbnail.files;
    }

    if (detail.files !== null && detail.files.length > 0) {
      payload.details = detail.files;
    }

    const result = await farmerLoungeService.items.updateAsync(payload);
    if (!result.succeeded) {
      alert(result.errors.first().message)
      return;
    }

    navigate(-1);
  }, [userInfo?.name, navigate]);


  React.useEffect(() => {
    if (id === null) {
      return;
    }

    let cts = new CancellationTokenSource();

    (async (token: CancellationToken) => {
      const result = await farmerLoungeService.items.detailAsync(id);
      if (token.isCancellationRequested) {
        return;
      }

      if (!result.succeeded) {
        alert(result.errors?.first()?.message)
        return;
      }

      setProductItem(result.data!);
    })(cts.token);

    return () => {
      cts.cancel();
    }
  }, [id]);
  
  return (
    <MainLayout className={props.className}>
      <section className={ClassNameHelper.concat('product-create-section', "main-section")}>
        <div className="content-region mt-4">
          <ContentTitleWrapper>상품 <span className="text-primary">업데이트</span></ContentTitleWrapper>
          <ProductForm
            productId={id}
            productName={productItem?.name}
            price={productItem?.price !== undefined ? Number.parseInt(productItem?.price) : undefined}
            request={productItem?.request}
            saleType={productItem?.sale_type}
            discussionContent={productItem?.discussion_content}
            thumbnailUrl={productItem?.images?.first()?.location}
            detailUrl={productItem?.details?.first()?.location}
            onSubmit={handleFormSubmit}
          />
        </div>
      </section>
    </MainLayout>
  )
}

export default React.memo(ProductUpdatePage);