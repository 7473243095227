import React from "react";
import { useIdentityContext } from "../../contexts/IdentityContext";
import AdvertiserWalletPage from "../advertisers/AdvertiserWalletPage";
import HostWalletPage from "../hosts/HostWalletPage";
import AgencyWalletPage from "../agencies/AgencyWalletPage";
import './WalletPage.scss';

export interface IWalletPageProps extends React.HTMLAttributes<HTMLDivElement> {

}

const WalletDict: { [key: string]: React.FunctionComponent<IWalletPageProps> } = {
  'farmer': AdvertiserWalletPage,
  'agency': AgencyWalletPage,
  'showhost': HostWalletPage 
}

function WalletPage(props: IWalletPageProps) {
  const {userInfo} = useIdentityContext();
  
const role = userInfo?.role;
const Wallet = (role !== undefined ? WalletDict[role] : undefined) ?? AdvertiserWalletPage;

  return (
    <Wallet className="wallet-page" />
  )
}

export default React.memo(WalletPage);