import React from "react";
import IDashBoardPageProps from "../../DashBoardPage/IDashBoardPageProps";
import MainLayout from "../../../layouts/MainLayout";
import MyLiveCommerceContaier from "../../../containers/MyLiveCommerceContainer";
import HorizontalLinearLayout from "../../../layouts/HorizontalLinearLayout";
import PrimaryCalendar from "../../../components/PrimaryCalendar";
import BookingInqueryContainer from "../../../containers/BookingInqueryContainer";
import TalkingAdvertiserContainer from "../../../containers/rooms/TalkingAdvertiserContainer";
import { ClassNameHelper } from "@loonfactory/common";
import './ShowhostDashBoardPage.scss';
import { Link } from "react-router-dom";
import EndPoints from "../../../EndPoints";

function HostDashBoardPage(props: IDashBoardPageProps) {
  return (
    <MainLayout className={props.className}>
      <section className={ClassNameHelper.concat('host-dashboard-section', 'main-section')}>
        <div className="content-region mt-4">
          <MyLiveCommerceContaier className="mt-4" />
          <div className="calendar-wrapper mt-5">
            <HorizontalLinearLayout className="justify-content-between align-items-center fw-semibold fs-3 mb-3">
              <div className="title">라이브 커머스 <span className="text-primary">스케쥴</span></div>
              <Link className="text-primary fs-5 pointer" to={EndPoints.matchingManagement}>더보기 +</Link>
            </HorizontalLinearLayout>
            <PrimaryCalendar />
          </div>
          <HorizontalLinearLayout className="host-section mt-5">
            <BookingInqueryContainer />
            <TalkingAdvertiserContainer />
          </HorizontalLinearLayout>
        </div>
      </section>
    </MainLayout>
  )
}

export default React.memo(HostDashBoardPage);