import React, { FormEventHandler } from "react";
import MessageRoom from "../../components/MessageRoom/MessageRoom";
import IMessageResource from "../../farmerLounges/farmerLoungeResources/IMessageResource";
import { useIdentityContext } from "../../contexts/IdentityContext";
import IUserDetailResource from "../../farmerLounges/farmerLoungeResources/IUserDetailResource";
import farmerLoungeService from "../../farmerLounges/FarmerLoungeService";
import { useLocation } from "react-router-dom";
import { challengeRoomAsync } from "../../challenges";
import IServiceError from "../../farmerLounges/IServiceError";
import { CancellationToken, CancellationTokenSource } from "@code-rabbits/core";
import ContentLoadFail from "../../components/contents/ContentLoadFail";
import { usePopupContext } from "../../contexts/PopupContext";
import IItemResource from "../../farmerLounges/farmerLoungeResources/IItemResource";
import ICreateScheduleSubmitProperties from "../../components/MessageRoom/ICreateScheduleSubmitProperties";
import IScheduleDetailResource from "../../farmerLounges/farmerLoungeResources/IScheduleDetailResource";
import IScheduleCompletedSubmitProperties from "../../components/MessageRoom/IScheduleCompletedSubmitProperties";
import IBroadcastReviewSubmitProperties from "../../components/MessageRoom/IBroadcastReviewSubmitProperties";

const interval = 500;

export interface ILocalMessageResource extends Partial<IMessageResource> {
  type?: string;
}

function MessageRoomContainer() {
  const { userInfo } = useIdentityContext();
  const { setPopup } = usePopupContext();

  const location = useLocation();

  const [messages, setMessages] = React.useState<ILocalMessageResource[]>();
  const [localMessage, setLocalMessage] = React.useState<ILocalMessageResource[]>();
  const [otherUser, setOtherUser] = React.useState<IUserDetailResource>()
  const [errors, setErrors] = React.useState<IServiceError[]>()
  const [localMessageId, setLocalMessageId] = React.useState(-1);

  const urlSearchParams = new URLSearchParams(location.search);
  const searchId = urlSearchParams.get('id');
  const roomId = searchId !== null ? Number.parseInt(searchId) : searchId;

  const handleMessageSubmit = React.useCallback<FormEventHandler<HTMLFormElement>>(async evt => {
    evt.stopPropagation();
    evt.preventDefault();

    const current = evt.currentTarget;
    const formData = new FormData(current);
    const message = formData.get('message') as string | null;

    if (roomId === null || message === null) {
      return;
    }

    const result = await farmerLoungeService.rooms.createMessageAsync(roomId, {
      content: message
    });

    if (!result.succeeded) {
      alert(result.errors.first().message);
      return;
    }

    (current.querySelector('input[name=message]') as HTMLInputElement).value = '';
  }, [roomId]);

  const updateMessageAsync = React.useCallback(async (roomId: number, cancellationToken?: CancellationToken) => {
    const result = await challengeRoomAsync(roomId);
    if (cancellationToken?.isCancellationRequested) {
      return;
    }

    if (!result.succeeded) {
      setErrors(result.errors);
      return;
    }

    setMessages(result.data!.messages!);
  }, []);

  const userId = userInfo?._id;

  const initMessageAsync = React.useCallback(async (id: number, cancellationToken: CancellationToken) => {
    const result = await challengeRoomAsync(id);
    if (cancellationToken.isCancellationRequested) {
      return null;
    }

    if (!result.succeeded) {
      setErrors(result.errors);
      return null;
    }
    const idSet = new Set([result.data!.service_provider_id, result.data!.client_id]);
    if (userId !== undefined) {
      idSet.delete(userId);
    }

    const otherId = Array.from(idSet.values())[0];
    const otherUserTask = farmerLoungeService.users.getDetailAsync(otherId);

    setMessages(result.data!.messages!);

    const intervalId = setInterval(() => {
      updateMessageAsync(id, cancellationToken);
    }, interval);

    const otherUserResult = await otherUserTask;
    if (!otherUserResult.succeeded) {
      console.error(otherUserResult.errors);
      return null;
    }

    setOtherUser(otherUserResult.data!);

    return intervalId;
  }, [userId, updateMessageAsync]);

  const handleProductSubmitCallback = React.useCallback(async (evt: React.MouseEvent<HTMLButtonElement>, product: IItemResource) => {
    if (roomId === null) {
      return;
    }

    const request = await farmerLoungeService.rooms.createMessageAsync(roomId, {
      content: '',
      items: [product]
    });

    if (!request.succeeded) {
      alert("상품 전송에 실패했습니다.");
      return;
    }
  }, [roomId]);

  const hadleProductClick = React.useCallback(() => {
    setPopup('messageRoomProudct', {
      onAcceptClick: handleProductSubmitCallback
    });
  }, [setPopup, handleProductSubmitCallback]);

  const handleScheduleCreateClick = React.useCallback(() => {
    const id = localMessageId;
    setLocalMessageId(state => state - 1);
    setLocalMessage([{
      _id: id,
      type: 'scheduleCreate',
      sender_id: userId,
    }]);
  }, [userId, localMessageId]);

  const handleScheduleCreateSubmit = React.useCallback(async (evt: React.FormEvent<HTMLFormElement>, properties: ICreateScheduleSubmitProperties) => {
    if (roomId === null) {
      return;
    }

    const requeset = await farmerLoungeService.rooms.createSchedulesAsync(roomId, {
      expected_infos: [{
        platform: properties.channel,
      }],
      fee: properties.pay,
      items: [properties.product],
      scheduled_at: (new Date(`${properties.date} ${properties.time}`)).toJSON(),
    })

    if (!requeset.succeeded) {
      alert("스케줄 요청에 실패했습니다")
      console.error(requeset);
      return;
    }

    setLocalMessage(undefined);
    updateMessageAsync(roomId)
  }, [roomId, updateMessageAsync]);

  const handleScheduleConfirmClick = React.useCallback(async (
    evt: React.MouseEvent<HTMLButtonElement>,
    schdule: IScheduleDetailResource
  ) => {
    if (roomId === null) {
      return;
    }

    const requeset = await farmerLoungeService.rooms.acceptScheduleAsync(roomId, schdule._id);
    if (!requeset.succeeded) {
      alert(requeset.errors?.first().message)
      return;
    }
  }, [roomId]);

  const handleScheduleCompletedPopupAcceptClick = React.useCallback((
    evt: React.MouseEvent<HTMLButtonElement>,
    schedule: IScheduleDetailResource
  ) => {
    const id = localMessageId;
    setLocalMessageId(state => state - 1);
    setLocalMessage([{
      _id: id,
      type: 'scheduleCompleted',
      schedule: schedule,
      sender_id: userId,
    }]);
  }, [localMessageId, userId])

  const handleScheduleCompletedClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(evt => {
    if (roomId === null) {
      return;
    }

    setPopup('messageRoomScheduleCompleted', {
      roomId: roomId,
      onAcceptClick: handleScheduleCompletedPopupAcceptClick
    });
  }, [roomId, setPopup, handleScheduleCompletedPopupAcceptClick]);

  const handleScheduleCompletedSubmit = React.useCallback(async (
    evt: React.FormEvent<HTMLFormElement>,
    properties: IScheduleCompletedSubmitProperties
  ) => {
    if (roomId === null) {
      alert("roomId는 null일수 없습니다.");
      return;
    }

    const scheduleId = properties?.schedule?._id;
    if (scheduleId === null || scheduleId === undefined) {
      alert(`scheduleId는 ${scheduleId}일수 없습니다.`);
      return;
    }

    const requeset = await farmerLoungeService.rooms.registerBoradcastInfoAsync(roomId, scheduleId, {
      broadcasted_infos: [{
        link: properties.broadcastLink,
        platform: properties.channel,
        sold_amount: properties.revenue,
        sold_quantity: properties.quantity,
        viewers: properties.viewers,
      }]
    });

    if (!requeset.succeeded) {
      alert("스케줄 요청에 실패했습니다")
      console.error(requeset);
      return;
    }

    setLocalMessage(undefined);
    updateMessageAsync(roomId)

  }, [roomId, updateMessageAsync]);

  const handleBroadcastReviewSubmit = React.useCallback(async (
    evt: React.FormEvent<HTMLFormElement>,
    properties: IBroadcastReviewSubmitProperties
  ) => {
    if (roomId === null) {
      alert("roomId는 null일수 없습니다.");
      return;
    }

    const scheduleId = properties?.schedule?._id;
    if (scheduleId === null || scheduleId === undefined) {
      alert(`scheduleId는 ${scheduleId}일수 없습니다.`);
      return;
    }

    const requeset = await farmerLoungeService.rooms.registerBoradcastReviewAsync(roomId, scheduleId, {
      content: properties.review,
    });

    if (!requeset.succeeded) {
      alert("스케줄 요청에 실패했습니다")
      console.error(requeset);
      return;
    }

    updateMessageAsync(roomId);
    alert("후기 등록이 완료되었습니다. 관리자의 확인후 정산이 완료됩니다.");
  }, [roomId, updateMessageAsync]);

  React.useEffect(() => {
    if (roomId === null) {
      return;
    }

    const cts = new CancellationTokenSource();
    let intervalId: NodeJS.Timer | null = null;
    (async () => {
      intervalId = await initMessageAsync(roomId, cts.token);
      if (cts.isCancellationRequested) {
        if (intervalId !== null) clearInterval(intervalId);
        intervalId = null;
      }
    })();

    return () => {
      cts.cancel();
      if (intervalId !== null) clearInterval(intervalId);
    }
  }, [roomId, initMessageAsync]);

  if (roomId === null) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <div className="fs-4 text-primary">표시할 메세지가 없습니다.</div>
      </div>
    )
  }

  if (errors?.any()) {
    return <ContentLoadFail errors={errors} />
  }

  return (
    <MessageRoom
      myId={userInfo?._id}
      profileUrl={otherUser?.profile?.location}
      name={otherUser?.name}
      role={otherUser?.role}
      items={[...(messages ?? []), ...(localMessage ?? [])]}
      onProductClick={hadleProductClick}
      onScheduleCreateClick={handleScheduleCreateClick}
      onMessageSubmit={handleMessageSubmit}
      onScheduleCreateSubmit={handleScheduleCreateSubmit}
      onScheduleConfirmClick={handleScheduleConfirmClick}
      onScheduleCompletedClick={handleScheduleCompletedClick}
      onScheduleCompletedSubmit={handleScheduleCompletedSubmit}
      onBroadcastReviewSubmit={handleBroadcastReviewSubmit}
    />
  )
}

export default React.memo(MessageRoomContainer);