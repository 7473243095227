import React from "react";
import FormInput from "./FormInputGroup";

function ProductNameInputGroup({
  ...rest
}: React.InputHTMLAttributes<HTMLInputElement>) {
  return (
    <FormInput
      label="상품 이름"
      name="productName"
      type="text"
      placeholder="상품 이름을 입력해 주세요."      
      {...rest}
    />
  )
}

export default React.memo(ProductNameInputGroup);