import React from "react";
import MainLayout from "../../layouts/MainLayout";
import ProfileSettingsSection from "../../sections/hosts/ProfileSettingsSection";

interface IProfileSettingsPageProps extends React.HTMLAttributes<HTMLDivElement> {
}

function ProfileSettingsPage(props: IProfileSettingsPageProps) {
  return (
    <MainLayout>
      <ProfileSettingsSection className="main-section" {...props} />
    </MainLayout>
  )
}

export default React.memo(ProfileSettingsPage);