import React from "react";
import ITransactionResource from "../../../farmerLounges/transactions/ITransactionResource";
import Transaction from "./Transaction";
import IServiceError from "../../../farmerLounges/IServiceError";
import EmptyContent from "../../contents/EmptyContent";
import LoadingContent from "../../contents/LoadingContent";
import ContentLoadFail from "../../contents/ContentLoadFail";

interface ITransactionsProps extends React.HTMLAttributes<HTMLTableSectionElement> {
  items?: ITransactionResource[] | null;
  errors?: IServiceError[]
  onAcceptClick?: React.MouseEventHandler<HTMLButtonElement>;
  onRejectClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const Transactions = React.memo(({
  items,
  errors,
  onAcceptClick,
  onRejectClick,
  ...rest
}: ITransactionsProps) => {

  if (errors?.any()) {
    return (
      <tbody>
        <tr>
          <td colSpan={7}>
            <ContentLoadFail errors={errors} />
          </td>
        </tr>
      </tbody>
    )
  }

  if (items === null) {
    return (
      <tbody>
        <tr>
          <td colSpan={7}>
            <LoadingContent />
          </td>
        </tr>
      </tbody>
    )
  }

  if (!items?.any()) {
    return (
      <tbody>
        <tr>
          <td colSpan={7}>
            <EmptyContent />
          </td>
        </tr>
      </tbody>
    )
  }


  return (
    <tbody {...rest}>
      {items?.map(item => {
        return (
          <Transaction
            key={item._id}
            item={item}
            data-id={item._id}
            onAcceptClick={onAcceptClick}
            onRejectClick={onRejectClick}
          />
        )
      })}
    </tbody>
  )
});


export default Transactions;