import React from "react";
import FormInput from "./FormInputGroup";

function PhoneNumberInput({
  onChange,
  ...rest
}: React.InputHTMLAttributes<HTMLInputElement>) {
  return (
    <FormInput
      label="전화번호"
      name="phoneNumber"
      type="text"
      placeholder="전화번호를 입력해주세요"
      onChange={onChange}
      {...rest}
    />
  )
}

export default React.memo(PhoneNumberInput);