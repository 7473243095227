import React from "react";
import LasyLeftIcon from "../ImageSlier/LasyLeftIcon";
import LasyRightIcon from "../ImageSlier/LasyRightIcon";
import HorizontalLinearLayout from "../../layouts/HorizontalLinearLayout";
import { ClassNameHelper } from "@loonfactory/common";

export interface IContentSliderProps<T> extends React.HTMLAttributes<HTMLDivElement> {
  items: T[];
  renderer: (item: T, index: number, array: T[]) => React.ReactElement | null | undefined;
  keySelector: (item: T, index: number, array: T[]) => React.Key;
  invisibleButton?: boolean;
}

function ContentSlider<T>({
  items,
  renderer,
  keySelector,
  className,
  invisibleButton,
  ...rest
}: IContentSliderProps<T>) {

  const divRef = React.useRef<HTMLDivElement>(null);

  const rightScoll = React.useCallback(() => {
    const current = divRef.current;
    if (current === null) {
      return;
    }

    let p = current.scrollLeft + current.clientWidth;
    if (p >= current.scrollWidth) {
      p = 0;
    }

    current.scrollTo({
      left: p,
      behavior: 'smooth'
    })
  }, []);

  const leftScoll = React.useCallback(() => {
    const current = divRef.current;
    if (current === null) {
      return;
    }

    let p = current.scrollLeft - current.clientWidth;
    if (p <= -current.clientWidth) {
      p = current.scrollWidth - current.clientWidth;
    }

    current.scrollTo({
      left: p,
      behavior: 'smooth'
    })
  }, []);

  const buttons = invisibleButton ? null : (<>
    <div className="button left-button" onClick={leftScoll}>
      <LasyLeftIcon stroke="#C8C8C8" />
    </div>
    <div className="button right-button" onClick={rightScoll}>
      <LasyRightIcon stroke="#C8C8C8" />
    </div>
  </>)

  React.useEffect(() => {
    const id = setInterval(rightScoll, 10 * 1000);
    return () => {
      clearInterval(id);
    }
  }, [rightScoll]);

  return (
    <HorizontalLinearLayout ref={divRef} className={ClassNameHelper.concat("content-slider", className)} {...rest}>
      {items.map((item, index, array) => {
        const el = renderer(item, index, array);
        if (el === null || el === undefined) {
          return el;
        }

        return React.cloneElement(
          el, { key: keySelector(item, index, array) }
        );
      })}
      {buttons}
    </HorizontalLinearLayout>
  )
}

export default React.memo(ContentSlider) as typeof ContentSlider;