import React, { useRef } from "react";
import { PopupDict, PopupName, PopupType, usePopupContext } from "../../contexts/PopupContext";
import PopupArea from "../../components/popups/PopupArea";

function PopupContaier() {
  const { popups } = usePopupContext();

  const popupAreaRef = useRef<HTMLDivElement>(null);

  const prevdentWhileEvent = React.useCallback((evt: WheelEvent) => {
    if (evt.target !== popupAreaRef.current) {
      return;
    }

    evt.stopPropagation();
    evt.preventDefault();
  }, []);

  React.useEffect(() => {
    if (popups === null) {
      return;
    }

    window.addEventListener('wheel', prevdentWhileEvent, { passive: false });
    return () => {
      window.removeEventListener('wheel', prevdentWhileEvent);
    }
  }, [popups, prevdentWhileEvent]);

  if (popups === null) {
    return null;
  }

  return (
    <PopupArea ref={popupAreaRef}>
      {popups?.map(item => {
        const popupName = item.name as PopupName;
        const Popup = PopupDict[popupName] as (typeof PopupDict)[typeof popupName];
        const payload = item.payload as PopupType[typeof popupName] as any;
        return <Popup key={item.name}  {...payload} />
      })}
    </PopupArea>
  )
}

export default React.memo(PopupContaier);