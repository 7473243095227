import { ClassNameHelper } from "@loonfactory/common";
import React from "react";

function ContentTitleOption({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={ClassNameHelper.concat("content-title-option", className)} {...rest} />
  )
}

export default React.memo(ContentTitleOption);