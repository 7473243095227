import { ClassNameHelper } from "@loonfactory/common";
import React from "react";
import ContentTitleWrapper from "../../components/contents/ContentTitleWrapper";
import ProductGirdContainer from "../products/ProductGirdContainer";
import IItemResource from "../../farmerLounges/farmerLoungeResources/IItemResource";
import { IItemListGetOptions } from "../../farmerLounges/ItemsService";
import AgencyProductItem from "../../components/AgencyProductItem";

const agencyRender = (props: IItemResource) => {
  return (
    <AgencyProductItem {...props} />
  )
}

function RecommendedAgencyContainer({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) {

  const options = React.useMemo(() => {
    let opt: IItemListGetOptions = {
      role: 'agency',
      picked: true,
    }

    return opt;
  }, []);

  return (
    <div className={ClassNameHelper.concat(className, 'content-wrapper')}>
      <ContentTitleWrapper>
        <div className="content-title">추천 <span className="text-primary">에이전시</span></div>
      </ContentTitleWrapper>
      <div className="new-host-content-wrapper mt-2">
        <div className="new-host-content primary-gallery-wrapper">
          <ProductGirdContainer
            className="primary-gallery"
            itemWidth={180}
            row={3}
            renderer={agencyRender}
            options={options}
          />
        </div>
      </div>
    </div>
  );
}


export default React.memo(RecommendedAgencyContainer);