import { ClassNameHelper } from "@loonfactory/common";
import React from "react";
import VerticalLinearLayout from "../../layouts/VerticalLinearLayout";

function GalleryItem({ className, ...rest }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <VerticalLinearLayout className={ClassNameHelper.concat("gallery-item", className)}  {...rest} />    
  )
}

export default React.memo(GalleryItem);