import React from "react";
import "./AutoResizeGallery.scss";
import { ClassNameHelper } from "@loonfactory/common";
import Paginations from "../../Paginations";

function resizeGrid(element: HTMLElement, width: number, gap = 0.15) {
  const offsetWidth = element.offsetWidth;
  let divsion = Math.floor(offsetWidth / width);
  let mod = offsetWidth % width;
  if (mod < width * gap && divsion > 1) {
    mod += width;
    divsion -= 1;
  }

  element.style.columnGap = `${mod / divsion}px`;
  element.style.gridTemplateColumns = `repeat(${divsion}, ${width}px)`;
}

export interface IAutoResizeGalleryProps<T> extends React.HTMLAttributes<HTMLDivElement> {
  items?: T[];
  itemWidth: number;
  row?: number;
  gap?: number;
  paginationDisabled?: boolean;
  totalCount?: number;
  pageIndex?: number;
  renderer: ((item: T, index: number, array: T[]) => React.ReactElement | null | undefined);
  keySelector: (item: T, index: number, array: T[]) => React.Key;
  onIndexClick?: React.MouseEventHandler<HTMLButtonElement>;
}

function AutoResizeGallery<T>({
  className,
  items,
  itemWidth,
  row,
  gap = 0.15,
  paginationDisabled,
  totalCount,
  pageIndex,
  renderer,
  keySelector,
  onIndexClick,
  ...rest
}: IAutoResizeGalleryProps<T>) {
  const [pagePerCount, setPagePerCount] = React.useState<number | null>(null);
  const ref = React.useRef<HTMLDivElement>(null);

  const handleResize = React.useCallback(() => {
    const div = ref.current;

    if (!items?.any() || div === null) {
      return;
    }
    if (row !== undefined) {
      const offsetWidth = div.offsetWidth;
      let divsion = Math.floor(offsetWidth / itemWidth);
      let mod = offsetWidth % itemWidth;
      if (mod < itemWidth * gap && divsion > 1) {
        mod += itemWidth;
        divsion -= 1;
      }

      let maxCount = divsion * row;
      let calcItemCount = Math.min(items.length, maxCount);
      setPagePerCount(calcItemCount);
    } else {
      setPagePerCount(null)
    }

    resizeGrid(div, itemWidth, gap);
  }, [itemWidth, items, row, gap]);

  React.useEffect(() => {
    const current = ref.current;
    if (current === null) {
      return;
    }

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [handleResize, ref]);


  const renerItems = React.useMemo(() => {
    if (pagePerCount === null) {
      return [];
    }

    return items?.skip(((pageIndex ?? 1) - 1) * pagePerCount).take(pagePerCount);
  }, [pagePerCount, pageIndex, items]);

  return (
    <div className="lf-auto-resize-gallery-wrapper">
      <div ref={ref}
        className={ClassNameHelper.concat("lf-auto-resize-gallery", className)}
        onResize={handleResize}
        {...rest}
      >
        {renerItems?.any() ? renerItems?.map((item, index, array) => {
          const el = renderer(item, index, array);
          if (el === null || el === undefined) {
            return el;
          }

          return React.cloneElement(
            el, { key: keySelector(item, index, array) }
          );
        }) : <div>항목이 비어있습니다</div>}
      </div>
      {!paginationDisabled &&
        <Paginations
          className="lf-auto-resize-gallery-pagination mt-4"
          index={pageIndex}
          pagePerCount={pagePerCount ?? 20}
          totalCount={totalCount ?? items?.length ?? 0}
          onIndexClick={onIndexClick}
          data-page-per-count={pagePerCount ?? 20}
        />
      }
    </div>

  )
}

export default React.memo(AutoResizeGallery) as typeof AutoResizeGallery;;