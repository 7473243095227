import { ClassNameHelper } from "@loonfactory/common";
import React from "react";
import HorizontalLinearLayout from "../HorizontalLinearLayout";
import VerticalLinearLayout from "../VerticalLinearLayout";
import ManagersNav from "../../components/navs/ManagersNav";
import LogoutButton from "../../components/buttons/LogoutButton";
import "./ManagerLayout.scss";

interface IMainLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
}

function ManagerLayout({
  className,
  children,
  ...rest
}: IMainLayoutProps) {
  return (
    <div>
      <HorizontalLinearLayout className={ClassNameHelper.concat("manager-layout", className)} {...rest}>
        <ManagersNav className="manager-nav" />
        <VerticalLinearLayout className="content-body pb-5">
          <div className="d-flex justify-content-end p-3 pe-4 border-bottom border-2">
            <LogoutButton />
          </div>
          {children}
        </VerticalLinearLayout>
      </HorizontalLinearLayout>
    </div>

  )
}

export default React.memo(ManagerLayout);