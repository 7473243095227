import React from "react";
import ManagersLayout from "../../layouts/ManagersLayout";
import { Line } from 'react-chartjs-2';
import { useMonthlyDeposit, useMonthlyMatchedSchedule, useTotalUserCount, useUserCountbyRole } from "../../hooks/analytics";
import Heler from "../../Helper";

function ManagerDashboardPage(props: React.HTMLAttributes<HTMLDivElement>) {
  const totalUserCount = useTotalUserCount();
  const userCountByRole = useUserCountbyRole();

  const months = Heler.getYearMonths();
  const lables = months.map(Heler.toDateChartLabelFormat);

  const matchedSchedules = useMonthlyMatchedSchedule();
  const matcheds = matchedSchedules?.reduce((acc, current) => {
    return {
      ...acc,
      [Heler.toDateChartLabelFormat(new Date(current.year, current.month, 1))]: current.count
    }
  }, {} as { [key: string]: number }) ?? {};

  const monthlyDeposit = useMonthlyDeposit();

  const deposities = monthlyDeposit?.reduce((acc, current) => {
    return {
      ...acc,
      [Heler.toDateChartLabelFormat(new Date(current.year, current.month, 1))]: current.amount
    }
  }, {} as { [key: string]: number }) ?? {};


  return (
    <ManagersLayout  {...props}>
      <main className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-3 info-card">
              <div className="title">총 이용자수</div>
              <div className="value">{totalUserCount ?? "-"} 명</div>
            </div>
            <div className="col-3 info-card dark-lime-green">
              <div className="title">총 광고주 회원수</div>
              <div className="value">{userCountByRole?.farmer ?? "-"} 명</div>
            </div>
            <div className="col-3 info-card strong-green">
              <div className="title">총 쇼호스트 회원수</div>
              <div className="value">{userCountByRole?.showhost ?? "-"} 명</div>
            </div>
            <div className="col-3 info-card bright-blue">
              <div className="title">총 에이전시 회원수</div>
              <div className="value">{userCountByRole?.agency ?? "-"} 명</div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 title">매칭 통계</div>
            <Line className="col-12" height={75} data={{
              labels: lables,
              datasets: [{
                label: "매칭수",
                data: lables.map(item => matcheds[item] ?? 0),
              }],
            }} options={{
              scales: {
                y: {
                  beginAtZero: true,
                }
              }
            }} />
          </div>
          <div className="포인트 월간 충전액">
            <div className="col-12 title">포인트 월간 충전액</div>
            <Line className="col-12" height={75} data={{
              labels: lables,
              datasets: [{
                label: "충전액",
                data: lables.map(item => deposities[item] ?? 0),
              }],
            }} options={{
              scales: {
                y: {
                  beginAtZero: true,
                }
              }
            }} />
          </div>
        </div>
      </main>
    </ManagersLayout>
  )
}

export default React.memo(ManagerDashboardPage);