import { useIdentityContext } from "../../contexts/IdentityContext";
import React from "react";
import HorizontalLinearLayout from "../../layouts/HorizontalLinearLayout";
import RoleType from "../../farmerLounges/RoleType";
import LogoutButton from "../buttons/LogoutButton";
import ProfileIcon from "../ProfileIcon";

export interface IMainHeaderProps extends React.HTMLAttributes<HTMLElement> {

}


const roleNameDict: { [name in RoleType]?: string } = {
  "agency": "에이전시",
  "farmer": "광고주",
  "showhost": "쇼호스트",
}

function MainHeader(props: IMainHeaderProps) {
  const { userInfo } = useIdentityContext();
  return (
    <header className="header fs-2">
      <div className="fw-semibold">{userInfo?.name} {userInfo?.role && roleNameDict[userInfo?.role as RoleType]}님, 반갑습니다. 👋</div>
      <HorizontalLinearLayout className="fw-bolder header-nav">
        <div className="profile-icon">
          <ProfileIcon url={userInfo?.profile?.location} className="profile-img" />
        </div>
        <div>{userInfo?.name}</div>
        <LogoutButton />
      </HorizontalLinearLayout>
    </header>
  )
}

export default MainHeader;