import React from "react";
import { ClassNameHelper } from "@loonfactory/common";
import PrimaryGalleryItem from "./PrimaryGalleryItem";

interface IAgencyItemProps extends React.HTMLAttributes<HTMLDivElement> {
  thumbnailUrl?: string;
  title?: string;
}

function AgencyItem({
  thumbnailUrl,
  title,
  className,
  ...rest
}: IAgencyItemProps) {
  return (
    <PrimaryGalleryItem
      className={ClassNameHelper.concat('agency-item', className)}
      title={title}
      thumbnailUrl={thumbnailUrl}
      {...rest}
    />
  )
}

export default React.memo(AgencyItem);