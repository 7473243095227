import React from "react";
import ManagersLayout from "../../../../layouts/ManagersLayout";
import PostsManagerContainer from "../../../../containers/managers/PostsManagerContainer";
import { IPostListGetOptions } from "../../../../farmerLounges/posts/PostsService";
import { useNavigate } from "react-router-dom";
import EndPoints from "../../../../EndPoints";
import IPostResource from "../../../../farmerLounges/posts/IPostResource";

const options: IPostListGetOptions = {
  type: 'popup'
}

function PopupManagementPage() {
  const navigate = useNavigate();

  const handleCreateClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(evt => {
    navigate(EndPoints.manager.events.popup.create);
  }, [navigate]);

  const handleEditClick = React.useCallback(async (
    evt: React.MouseEvent<HTMLButtonElement>,
    item: IPostResource
  ) => {
    navigate(`${EndPoints.manager.events.popup.edit}?id=${item._id}`, {
      state: item
    })
  }, [navigate]);

  return (
    <ManagersLayout className="banner-management-page">
      <PostsManagerContainer
        className="container"
        options={options}
        onCreateClick={handleCreateClick}
        onEditClick={handleEditClick}
      />
    </ManagersLayout>
  )
}

export default React.memo(PopupManagementPage);