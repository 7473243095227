import React from "react";
import INavItemProps from "./INavItemProps";
import NavItem from "./NavItem";
import UserLockIcon from "../../../icons/UesrLockIcon/UserLockIcon";

function AccountNavItem(props: Omit<INavItemProps, 'children'>) {
  return (
    <NavItem {...props}>
      <UserLockIcon /> 내 정보 관리
    </NavItem>
  )
}

export default React.memo(AccountNavItem);