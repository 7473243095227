import React, { Suspense } from 'react';

const SearchIcon = React.lazy(() => import('./SearchIcon'));

function LazySearchIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <Suspense fallback={<svg width="39" height="39" viewBox="0 0 39 39"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />}>
      <SearchIcon {...props} />
    </Suspense>
  )
}

export default React.memo(LazySearchIcon)