import React from "react";
import VerticalLinearLayout from "../layouts/VerticalLinearLayout";
import LazyKakaoLiteralIcon from "../icons/LazyKakaoLiteralIcon";
import LazyFAQIcon from "../icons/LazyFAQIcon";

interface IShortCutsProps {
  onKakaoClick?: React.MouseEventHandler<HTMLDivElement>;
  onFaqClick?: React.MouseEventHandler<HTMLDivElement>;
}

function ShortCuts(props: IShortCutsProps) {
  return (
    <VerticalLinearLayout className="short-cuts">
      <div className="sticky-btn kakao" onClick={props.onKakaoClick} tabIndex={100}>
        <LazyKakaoLiteralIcon className="kakao-icon" />
      </div>
      <div className="sticky-btn faq" onClick={props.onFaqClick} tabIndex={100}>
        <LazyFAQIcon />
      </div>
    </VerticalLinearLayout>
  )
}

export default React.memo(ShortCuts)