import React from "react";
import Wallet, { IWalletProps } from "../../components/Wallet";
import { useIdentityContext } from "../../contexts/IdentityContext";

function WalletContaier(props: Omit<IWalletProps, 'value'>) {
  const { userInfo } = useIdentityContext();
  return (
    <Wallet value={userInfo?.wallet.point} {...props} />
  )
}

export default React.memo(WalletContaier);