import React, { Suspense } from 'react';

const InstagramIcon = React.lazy(() => import('./InstagramIcon'));

function LazyInstagramIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <Suspense fallback={<svg
      width="28.87"
      height="28.87"
      viewBox="0 0 28.87 28.87"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />}>
      <InstagramIcon {...props} />
    </Suspense>
  )
}

export default React.memo(LazyInstagramIcon)