import { CancellationTokenSource } from "@code-rabbits/core";
import React from "react";
import farmerLoungeService from "../farmerLounges/FarmerLoungeService";
import { IMonthlyAmount, IMonthlyCount, IUserCountByRole } from "../farmerLounges/analytics/AnalyticsService";

const useTotalDeposit = () => {
  const [totalDeposit, setTotalDeposit] = React.useState<number>();

  React.useEffect(() => {
    const cts = new CancellationTokenSource();

    farmerLoungeService.analytics.getTotalDepositAsync().then(res => {
      if (cts.isCancellationRequested) {
        return;
      }

      if (res.errors.any()) {
        setTotalDeposit(-1);
        console.error(res.errors);
        return;
      }

      setTotalDeposit(res.data!.amount ?? 0);
    })
  }, []);

  return totalDeposit;
}

const useTotalSend = () => {
  const [totalSend, setTotalSend] = React.useState<number>();

  React.useEffect(() => {
    const cts = new CancellationTokenSource();

    farmerLoungeService.analytics.getTotalSendtAsync().then(res => {
      if (cts.isCancellationRequested) {
        return;
      }

      if (res.errors.any()) {
        setTotalSend(-1);
        console.error(res.errors);
        return;
      }

      setTotalSend(res.data!.amount ?? 0);
    })
  }, []);

  return totalSend;
}

const useTotalWithdraw = () => {
  const [totalWithdraw, setTotalWithdraw] = React.useState<number>();

  React.useEffect(() => {
    const cts = new CancellationTokenSource();

    farmerLoungeService.analytics.getTotalWithdrawAsync().then(res => {
      if (cts.isCancellationRequested) {
        return;
      }

      if (res.errors.any()) {
        setTotalWithdraw(-1);
        console.error(res.errors);
        return;
      }

      setTotalWithdraw(res.data!.amount ?? 0);
    })
  }, []);

  return totalWithdraw;
}

const useMonthlyDeposit = () => {
  const [monthlyDeposit, setMonthlyDeposit] = React.useState<IMonthlyAmount[]>();

  React.useEffect(() => {
    const cts = new CancellationTokenSource();

    farmerLoungeService.analytics.getMonthlyDepositAsync().then(res => {
      if (cts.isCancellationRequested) {
        return;
      }

      if (res.errors.any()) {
        setMonthlyDeposit([]);
        console.error(res.errors);
        return;
      }

      setMonthlyDeposit(res.data!);
    })
  }, []);

  return monthlyDeposit;
}

const useMonthlySend = () => {
  const [monthlySend, setMonthlySend] = React.useState<IMonthlyAmount[]>();

  React.useEffect(() => {
    const cts = new CancellationTokenSource();

    farmerLoungeService.analytics.getMonthlySendAsync().then(res => {
      if (cts.isCancellationRequested) {
        return;
      }

      if (res.errors.any()) {
        setMonthlySend([]);
        console.error(res.errors);
        return;
      }

      setMonthlySend(res.data!);
    })
  }, []);

  return monthlySend;
}

const useMonthlyWithdraw = () => {
  const [monthlyWithdraw, setMonthlyWithdraw] = React.useState<IMonthlyAmount[]>();

  React.useEffect(() => {
    const cts = new CancellationTokenSource();

    farmerLoungeService.analytics.getMonthlyWithdrawAsync().then(res => {
      if (cts.isCancellationRequested) {
        return;
      }

      if (res.errors.any()) {
        setMonthlyWithdraw([]);
        console.error(res.errors);
        return;
      }

      setMonthlyWithdraw(res.data!);
    })
  }, []);

  return monthlyWithdraw;
}

const useMonthlyAcceptedSchedule = () => {
  const [monthlyWithdraw, setMonthlyWithdraw] = React.useState<IMonthlyCount[]>();

  React.useEffect(() => {
    const cts = new CancellationTokenSource();

    farmerLoungeService.analytics.getMonthlyAcceptedScheduleAsync().then(res => {
      if (cts.isCancellationRequested) {
        return;
      }

      if (res.errors.any()) {
        setMonthlyWithdraw([]);
        console.error(res.errors);
        return;
      }

      setMonthlyWithdraw(res.data!);
    })
  }, []);

  return monthlyWithdraw;
}

const useMonthlyMatchedSchedule = () => {
  const [monthlyWithdraw, setMonthlyWithdraw] = React.useState<IMonthlyCount[]>();

  React.useEffect(() => {
    const cts = new CancellationTokenSource();

    farmerLoungeService.analytics.getMonthlyMatchedScheduleAsync().then(res => {
      if (cts.isCancellationRequested) {
        return;
      }

      if (res.errors.any()) {
        setMonthlyWithdraw([]);
        console.error(res.errors);
        return;
      }

      setMonthlyWithdraw(res.data!);
    })
  }, []);

  return monthlyWithdraw;
}

const useTotalUserCount = () => {
  const [count, setCount] = React.useState<number>();

  React.useEffect(() => {
    const cts = new CancellationTokenSource();

    farmerLoungeService.analytics.getTotalUserCountAsync().then(res => {
      if (cts.isCancellationRequested) {
        return;
      }

      if (res.errors.any()) {
        setCount(-1);
        console.error(res.errors);
        return;
      }

      setCount(res.data!.count);
    })
  }, []);

  return count;
}


const useUserCountbyRole = () => {
  const [userCount, setUserCount] = React.useState<IUserCountByRole>();

  React.useEffect(() => {
    const cts = new CancellationTokenSource();

    farmerLoungeService.analytics.getUserCountbyRoleAsync()
      .then(res => {
        if (cts.isCancellationRequested) {
          return;
        }

        if (res.errors.any()) {
          console.error(res.errors);
          return;
        }

        setUserCount(res.data!);
      })
  }, []);

  return userCount;
}

export {
  useTotalDeposit,
  useTotalSend,
  useTotalWithdraw,
  useMonthlyDeposit,
  useMonthlySend,
  useMonthlyWithdraw,
  useMonthlyAcceptedSchedule,
  useMonthlyMatchedSchedule,
  useTotalUserCount,
  useUserCountbyRole,
};