import React from "react";
import ProductItemWithProfile from "./ProductItemWithProfile";
import farmerLoungeService from "../farmerLounges/FarmerLoungeService";
import { CancellationTokenSource } from "@code-rabbits/core";
import { usePopupContext } from "../contexts/PopupContext";
import IItemResource from "../farmerLounges/farmerLoungeResources/IItemResource";
import IUserDetailResource from "../farmerLounges/farmerLoungeResources/IUserDetailResource";
import { ClassNameHelper } from "@loonfactory/common";

interface IProductRenderItemProps {
  className?: string;
  product?: IItemResource;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const ProductRenderItem = React.memo((props: IProductRenderItemProps) => {
  const { setPopup } = usePopupContext();
  const [onwerInfo, setOnwerInfo] = React.useState<IUserDetailResource>();
  const ownerId = props.product?.owner_id;
  React.useEffect(() => {
    if (ownerId === undefined) {
      return;
    }

    const id = ownerId;
    let cts = new CancellationTokenSource();
    (async () => {
      const response = await farmerLoungeService.users.getDetailAsync(id);
      if (cts.isCancellationRequested) {
        return;
      }

      if (!response.succeeded) {
        return;
      }

      setOnwerInfo(response.data!);
    })();

    return () => {
      cts.cancel();
    }
  }, [ownerId]);

  const onClick = props.onClick

  const handleClick = React.useCallback<React.MouseEventHandler<HTMLDivElement>>(evt => {
    onClick && onClick(evt);
    if (evt.isDefaultPrevented()) {
      return;
    }

    if (onwerInfo === undefined || props.product === undefined) {
      return;
    }

    setPopup('product', {
      product: props.product,
      prodcutOnwer: onwerInfo,

    });
  }, [props.product, onwerInfo, setPopup, onClick]);

  return (
    <ProductItemWithProfile
      className={ClassNameHelper.concat("primary-gallery-item pointer", props.className)}
      thumbnailUrl={props.product?.images?.first()?.location}
      proudctName={props.product?.name}
      onwer={onwerInfo}
      onClick={handleClick}
    />
  )
})

export default ProductRenderItem;