import ServiceErrorGenerator from "../../ServiceErrorGenerator";
import ApiEndPoints from "../ApiEndPoints";
import FarmerLoungesBaseApiService from "../FarmerLoungesBaseApiService";
import IServiceResponse from "../IServiceResponse";
import ServiceResult from "../ServiceResult";
import IPostCrateInputPayload from "./IPostCrateInputPayload";
import IPostListResource from "./IPostListResource";
import IPostResource from "./IPostResource";
import PostType from "./PostType";

export interface IPostListGetOptions {
  type?: PostType | 'report';
}

interface IPostResult {
  post: {
    _id: IPostResource['_id'];
  }
}

class PostService extends FarmerLoungesBaseApiService {
  getListAsync = async (options?: IPostListGetOptions): Promise<ServiceResult<IPostListResource>> => {
    const url = this.buildUrl(ApiEndPoints.posts.create, { ...options });

    try {
      const response = await this.getAsync(url);
      const result = (await response.json()) as IServiceResponse<IPostListResource>;
      if (result.code !== 2000) {
        switch (result.code) {
          default:
            return this.globalErrorHandler(result);
        }
      }
      return ServiceResult.succeeded(result.result);
    } catch (e) {
      return ServiceResult.failed(ServiceErrorGenerator.unknownError)
    }
  }

  createPostAsync = async (payload: IPostCrateInputPayload): Promise<ServiceResult<IPostResource>> => {
    const url = this.buildUrl(ApiEndPoints.posts.create);
    if (payload.images !== undefined) {
      if (!(payload.images instanceof Array)) {
        payload.images = Array.from(payload.images);
      }
    }

    const formData = new FormData();
    (Object.keys(payload) as (keyof IPostCrateInputPayload)[]).forEach(key => {
      [payload[key]].flat().forEach(v => {
        formData.append(key, v as string | File);
      })
    })

    try {
      const response = await this.postAsync(url, formData);
      const result = (await response.json()) as IServiceResponse<IPostResource>;
      if (result.code !== 2000) {
        switch (result.code) {
          default:
            return this.globalErrorHandler(result);
        }
      }
      return ServiceResult.succeeded(result.result);
    } catch (e) {
      return ServiceResult.failed(ServiceErrorGenerator.unknownError)
    }
  }

  deletePostAsync = async (postId: IPostResource['_id']): Promise<ServiceResult<IPostResult>> => {
    const url = this.buildUrl(ApiEndPoints.posts.delete.replaceAll('{id}', postId.toString()));

    try {
      const response = await this.deleteAsync(url);
      const result = (await response.json()) as IServiceResponse<IPostResult>;
      if (result.code !== 2000) {
        switch (result.code) {
          default:
            return this.globalErrorHandler(result);
        }
      }

      return ServiceResult.succeeded(result.result);
    } catch (e) {
      return ServiceResult.failed(ServiceErrorGenerator.unknownError)
    }
  }

  editPostAsync = async (
    postId: IPostResource['_id'],
    payload: IPostCrateInputPayload
  ): Promise<ServiceResult<IPostResource>> => {
    const url = this.buildUrl(ApiEndPoints.posts.edit.replaceAll('{id}', postId.toString()));
    if (payload.images !== undefined) {
      if (!(payload.images instanceof Array)) {
        payload.images = Array.from(payload.images);
      }
    }

    const formData = new FormData();
    (Object.keys(payload) as (keyof IPostCrateInputPayload)[]).forEach(key => {
      [payload[key]].flat().forEach(v => {
        formData.append(key, v as string | File);
      })
    })

    try {
      const response = await this.putAsync(url, formData);
      const result = (await response.json()) as IServiceResponse<IPostResource>;
      if (result.code !== 2000) {
        switch (result.code) {
          default:
            return this.globalErrorHandler(result);
        }
      }

      return ServiceResult.succeeded(result.result);
    } catch (e) {
      return ServiceResult.failed(ServiceErrorGenerator.unknownError)
    }
  }

}

export default PostService;