import React from "react";
import ContentTitleWrapper from "../../components/contents/ContentTitleWrapper";
import './RequestQuoteSection.scss';
import { ClassNameHelper } from "@loonfactory/common";
import RequestQuoteForm from "../../components/RequestQuoteForm";
import farmerLoungeService from "../../farmerLounges/FarmerLoungeService";
import { useNavigate } from "react-router-dom";

interface IRequestQuoteSectionProps extends React.HTMLAttributes<HTMLDivElement> {

}

function RequestQuoteSection({
  className,
  children,
  ...rest
}: IRequestQuoteSectionProps) {
  const navigate = useNavigate()

  const handleSubmit = React.useCallback<React.FormEventHandler<HTMLFormElement>>(async evt => {
    evt.stopPropagation();
    evt.preventDefault();

    const formData = new FormData(evt.currentTarget);
    const productName = formData.get('productName') as string;
    const adQutoe = Number.parseInt(formData.get('adQutoe') as string);
    const adName = formData.get('adName') as string;
    const adType = formData.get('adType') as string;
    const contact = formData.get('contact') as string;
    const email = formData.get('email') as string;
    const etc = formData.get('etc') as string | null;

    const body = `
상품명: ${productName} <br />
광고예산: ${adQutoe} <br />
광고주 성함: ${adName} <br />
판매 채널: ${adType} <br />
연락처: ${contact} <br />
이메일: ${email} <br />
기타 요청사항: ${etc ?? ''}
`
    const rseult = await farmerLoungeService.email.sendAsync({
      content: body,
      subject: `[견적 문의] ${productName}`,
      to: process.env.REACT_APP_REVICE_EMAIL,
    });

    if (!rseult.succeeded) {
      alert("메일 전송에 실패하였습니다. 관리자에게 문의해주세요.");
      return;
    }

    alert("메일 전송 완료");
    navigate(-1);
  }, [navigate]);

  return (
    <section className={ClassNameHelper.concat("request-quote-section", className)} {...rest}>
      <ContentTitleWrapper className="d-grid">광고정보 등록</ContentTitleWrapper>
      <RequestQuoteForm onSubmit={handleSubmit} />
    </section>
  )
}

export default React.memo(RequestQuoteSection);