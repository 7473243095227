import React from "react";
import IItemResource from "../../farmerLounges/farmerLoungeResources/IItemResource";
import IServiceError from "../../farmerLounges/IServiceError";
import { CancellationToken, CancellationTokenSource } from "@code-rabbits/core";
import farmerLoungeService from "../../farmerLounges/FarmerLoungeService";
import Paginations from "../../components/Paginations";
import DatasetHelper from "../../lib/DatasetHelper";
import DangerButton from "../../components/buttons/DangerButton";
import IUserDetailResource from "../../farmerLounges/farmerLoungeResources/IUserDetailResource";
import { IGetUserListOptions } from "../../farmerLounges/UsersService";
import UserTable from "../../components/managemensts/users/UserTable";
import Users from "../../components/managemensts/users/Users";
import { useNavigate, useSearchParams } from "react-router-dom";
import SerachParamsHelper from "../../lib/SerachParamsHelper";
import EndPoints from "../../EndPoints";

interface IUserManagerContaierProps extends React.HTMLAttributes<HTMLDivElement> {
  options?: IGetUserListOptions;
}

const UserManagerContaier = React.memo(({
  options,
  className,
  ...rest
}: IUserManagerContaierProps) => {
  const navigate  = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const getLocationOffset = React.useCallback(() => {
    try {
      return SerachParamsHelper.getInt(searchParams, 'offset');
    } catch {
      return undefined;
    }
  }, [searchParams]);

  const [users, setUsers] = React.useState<IUserDetailResource[] | null>(null);
  const [errors, setErrors] = React.useState<IServiceError[]>();
  const [selectedUsersIds, setSelectedUsersIds] = React.useState<IItemResource['_id'][]>([]);
  const [offset, setOffset] = React.useState<number>(getLocationOffset() ?? 1);
  const [pagePerCount] = React.useState<number>(20);
  const [totalCount] = React.useState<number>();

  const updateUsersAsync = React.useCallback(async (cancellationToken?: CancellationToken) => {
    const result = await farmerLoungeService.users.getListAsync({
      ...options,
    });

    if (cancellationToken?.isCancellationRequested) {
      return;
    }

    if (!result.succeeded) {
      setErrors(result.errors);
      return;
    }

    setUsers(result.data!.users);
  }, [options])

  React.useEffect(() => {
    const cts = new CancellationTokenSource();
    updateUsersAsync(cts.token);

    return () => {
      cts.cancel();
    }
  }, [updateUsersAsync]);

  const handleCheckedChangeAsync = React.useCallback((
    evt: React.ChangeEvent<HTMLInputElement>,
    item: IUserDetailResource) => {
    const set = new Set(selectedUsersIds);
    if (evt.currentTarget.checked) {
      set.add(item._id)
    } else {
      set.delete(item._id);
    }

    setSelectedUsersIds(Array.from(set));
  }, [selectedUsersIds]);

  React.useEffect(() => {
    setOffset(getLocationOffset() ?? 1);
  }, [getLocationOffset]);

  const handlePaginationIndexClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(evt => {
    const index = DatasetHelper.getInt(evt.currentTarget.dataset, "index");
    searchParams.set('offset', index.toString());
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const handleRemoveClick = React.useCallback(async () => {
    if (!window.confirm("아 작업은 되될리수 없습니다. 정말 수행하시겠습니까?")) {
      return;
    }

    const result = await Promise.all(selectedUsersIds.map(id => {
      return farmerLoungeService.users.deleteUserAsync(id);
    }));

    const faileds = result.filter(item => !item.succeeded);
    if (faileds.any()) {
      alert("지정된 유저들중 일부 삭제에 실패했습니다.");
    }
    else {
      alert("지정된 모든 유저를 제거하였습니다.");
    }

    updateUsersAsync();
  }, [selectedUsersIds, updateUsersAsync])

  const handleEditClick = React.useCallback((
    evt: React.MouseEvent<HTMLButtonElement>,
    item: IUserDetailResource
  ) => {
    navigate(`${EndPoints.manager.users.edit}?id=${item._id}`)
  }, [navigate]);

  return (
    <div className={className} {...rest}>
      <div className="row justify-content-end mt-3 mb-3">
        <div className="col-2 d-flex justify-content-end">
          <DangerButton onClick={handleRemoveClick}>제거하기</DangerButton>
        </div>
      </div>
      <div className="row">
        <UserTable className="col-12">
          <Users
            items={users}
            errors={errors}
            onCheckedChange={handleCheckedChangeAsync}
            onEditClick={handleEditClick}
          />
        </UserTable>
        <div className="row">
          <div className="col-12 justify-content-center">
            <Paginations
              className="text-center"
              index={offset}
              totalCount={totalCount}
              pagePerCount={pagePerCount}
              onIndexClick={handlePaginationIndexClick}
            />
          </div>
        </div>
      </div>
    </div>
  )
});

export default React.memo(UserManagerContaier);