import React from "react";
import "./AccountPage.scss";
import { useIdentityContext } from "../../contexts/IdentityContext";
import MainLayout from "../../layouts/MainLayout";
import ImageInput from "../../components/forms/inputs/ImageInput";
import FormDataHelper from "../../lib/FormDataHelper";
import farmerLoungeService from "../../farmerLounges/FarmerLoungeService";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { ClassNameHelper } from "@loonfactory/common";

function HomePage({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) {
  const { userInfo, setUserInfo } = useIdentityContext();
  const userId = userInfo?._id;

  const handleSubmit = React.useCallback<React.FormEventHandler<HTMLFormElement>>(async evt => {
    evt.stopPropagation();
    evt.preventDefault();
    if (userId === undefined) {
      return;
    }

    const formData = new FormData(evt.currentTarget);
    const password = FormDataHelper.getString(formData, 'password', undefined, true);
    const newPassword = FormDataHelper.getString(formData, 'new-password', undefined, true);
    const confirmPassword = FormDataHelper.getString(formData, 'confirm-password', undefined, true);

    if (password !== String.empty || newPassword !== String.empty || confirmPassword !== String.empty) {
      if (newPassword === confirmPassword) {
        const requeset = await farmerLoungeService.users.updatePasswordAsync(userId, password, newPassword);
        if (!requeset.succeeded) {
          alert(requeset?.errors?.first().message);
        } else {
          alert("비밀번호 변경에 성공했습니다.")
        }

      }
      else {
        alert("비밀번호가 일치하지 않습니다.");
      }
    }

    const target = (evt.currentTarget ?? evt.target);
    const profileImage = (target.querySelector('input[name=profile]') as HTMLInputElement).files?.item(0);
    if (profileImage) {
      const requeset = await farmerLoungeService.users.updateProfileImageAsync(userId, profileImage);
      if (!requeset.succeeded) {
        alert(requeset?.errors?.first().message);
      }
      else {
        const userInfoRequestTask = farmerLoungeService.users.getDetailAsync(userId);
        alert("프로필 이미지 변경에 성공했습니다.")
        const userInfoRequest = await userInfoRequestTask;
        if (userInfoRequest.succeeded) {
          setUserInfo(userInfoRequest.data!);
        }
      }
    }
  }, [userId, setUserInfo]);

  return (
    <MainLayout className={ClassNameHelper.concat('account-page', className)} {...rest}>
      <section className="main-section">
        <form className="account-form container mb-5" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-6">
              <div className="form-label fs-4">
                <div className="title">프로필 사진 등록</div>
                <ImageInput
                  name="profile"
                  location={userInfo?.profile?.location}
                  required
                />
              </div>
            </div>
          </div>
          <hr />
          <div className="row mt-5">
            <div className="col-5">
              <div className="form-label fs-4">
                <div className="title">기존 비밀번호</div>
                <input className="w-100" type="password" name="password" autoComplete="current-password" placeholder="비밀번호를 확인해주세요." />
              </div>
            </div>
          </div>
          <div className="row justify-content-between mt-3">
            <div className="col-5">
              <div className="form-label fs-4">
                <div className="title">새 비밀번호</div>
                <input className="w-100" type="password" name="new-password" autoComplete="new-password" placeholder="비밀번호를 입력해주세요." />
              </div>
            </div>
            <div className="col-5">
              <div className="form-label fs-4">
                <div className="title">새 비밀번호 확인</div>
                <input className="w-100" type="password" name="confirm-password" autoComplete="new-password" placeholder="비밀번호를 입력해주세요." />
              </div>
            </div>
          </div>
          <hr />
          <div className="row justify-content-end mt-4">
            <div className="col-2">
              <PrimaryButton className="w-100" type="submit">저장하기</PrimaryButton>
            </div>
          </div>
        </form>
      </section>
    </MainLayout >
  )
}

export default React.memo(HomePage);