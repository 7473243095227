import React, { Suspense } from 'react';

const LinkIcon = React.lazy(() => import('./LinkIcon'));

function LazyLinkIcon(props: React.HTMLAttributes<SVGSVGElement>) {
  return (
    <Suspense fallback={<svg
      width="49"
      height="33"
      viewBox="0 0 49 33"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />}>
      <LinkIcon {...props} />
    </Suspense>
  )
}

export default React.memo(LazyLinkIcon)