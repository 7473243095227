import React, { Suspense } from 'react';

const FAQIcon = React.lazy(() => import('./FAQIcon'));

function LazyFAQIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <Suspense fallback={<svg width="98" height="98" viewBox="0 0 98 98"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />}>
      <FAQIcon {...props} />
    </Suspense>
  )
}

export default React.memo(LazyFAQIcon)