import AutoResizeGallery from "../../components/galleries/AutoResizeGallery";
import React from "react";
import farmerLoungeService from "../../farmerLounges/FarmerLoungeService";
import IItemResource from "../../farmerLounges/farmerLoungeResources/IItemResource";
import IServiceError from "../../farmerLounges/IServiceError";
import { CancellationTokenSource } from "@code-rabbits/core";
import { IItemListGetOptions } from "../../farmerLounges/ItemsService";
import EmptyContent from "../../components/contents/EmptyContent";
import LoadingContent from "../../components/contents/LoadingContent";
import { IAutoResizeGalleryProps } from "../../components/galleries/AutoResizeGallery/AutoResizeGallery";
import { ClassNameHelper } from "@loonfactory/common";

const keySelector = (item: IItemResource) => {
  return item._id;
}

interface IProductGirdContainerProps extends Omit<IAutoResizeGalleryProps<IItemResource>, 'items' | 'keySelector'> {
  className?: string;
  renderer: (props: IItemResource, index: number, array: IItemResource[]) => React.ReactElement | JSX.Element | null | undefined;
  options?: IItemListGetOptions | undefined
}

function ProductGirdContainer(props: IProductGirdContainerProps) {
  const [items, setItems] = React.useState<IItemResource[] | null>(null);
  const [errors, setErrors] = React.useState<IServiceError[]>();
  const [totalCount, setTotalCount] = React.useState<number>();
  const [pageIndex, setPageIndex] = React.useState<number>(1);

  const handleIndexClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(evt => {
    const target = evt.currentTarget;
    const index = Number(target.dataset.index);
    setPageIndex(index);
  }, []);

  React.useEffect(() => {
    let cts = new CancellationTokenSource();
    (async () => {
      const result = await farmerLoungeService.items.getListAsync(props.options);

      if (cts.isCancellationRequested) {
        return;
      }

      if (!result.succeeded) {
        setErrors(result.errors!);
        return;
      }

      setTotalCount(result.data!.count!);
      setItems(result.data?.items ?? null);
    })();

    return () => {
      cts.cancel();
    }
  }, [props.options]);

  if (errors?.any()) {
    return (
      <div className="text-danger">
        항목을 불러오는데 실패햐엿습니다.
        <ul>
          {errors.map(item => <li>{item.message}</li>)}
        </ul>
      </div>
    )
  }

  if (items === null) {
    return (
      <LoadingContent />
    )
  }

  if (!items.any()) {
    return <EmptyContent />
  }

  return (
    <AutoResizeGallery
      className={ClassNameHelper.concat("ad-product-gallery", props.className)}
      items={items}
      row={props.row}
      itemWidth={props.itemWidth}
      gap={props.gap}
      totalCount={totalCount}
      pageIndex={pageIndex}
      renderer={props.renderer}
      keySelector={keySelector}
      onIndexClick={handleIndexClick}
      paginationDisabled={props.paginationDisabled}
    />
  )
}

export default React.memo(ProductGirdContainer);