import React from "react";
import { IFormInputProps } from "./forms/inputs/FormInputGroup";
import HorizontalLinearLayout from "../layouts/HorizontalLinearLayout";
import LazyInstagramOutlineIcon from "../icons/LazyInstagramOutlineIcon";
import LazyYoutubeOutlineIcon from "../icons/LazyYoutubeOutlineIcon";
import { ClassNameHelper } from "@loonfactory/common";
import ISNSResource from "../farmerLounges/farmerLoungeResources/ISNSResource";

interface ISNSAccountInputProps extends IFormInputProps {
  snsDefaultValue?: ISNSResource[] | null;
}

function SNSAccountInputGroup({
  onChange,
  required,
  snsDefaultValue,
  ...rest
}: ISNSAccountInputProps) {
  const instagramLink = snsDefaultValue?.find(item => item.service === "instagram")?.link ?? '';
  const youtubeLink = snsDefaultValue?.find(item => item.service === "youtube")?.link ?? '';

  return (
    <div className="sns-account-wrapper" {...rest}>
      <span className={ClassNameHelper.concat(required && "required", "fw-semibold fs-4")}>SNS 계정</span>
      <HorizontalLinearLayout className="mt-2 align-items-center">
        <LazyInstagramOutlineIcon />
        <input
          name="instagram"
          type="text"
          placeholder="인스타그램 (Instagram) 아이디를 입력해주세요."
          className="ms-3 form-control"
          required={required}
          onChange={onChange}
          defaultValue={instagramLink}
        />
      </HorizontalLinearLayout>
      <HorizontalLinearLayout className="align-items-center mt-2">
        <LazyYoutubeOutlineIcon style={{ width: 40, height: 39 }} />
        <input
          name="youtube"
          type="text"
          placeholder="유튜브 채널 URL을 입력해주세요."
          className="ms-3 form-control"
          required={required}
          onChange={onChange}
          defaultValue={youtubeLink}
        />
      </HorizontalLinearLayout>
    </div>
  )
}

export default React.memo(SNSAccountInputGroup);