import { ClassNameHelper } from "@loonfactory/common";
import React from "react";
import PrimaryButton from "../../buttons/PrimaryButton";
import { usePopupContext } from "../../../contexts/PopupContext";
import './DefaultPopup.scss';
import ExitIcon from "../../../icons/ExitIcon";

interface IDefaultPopupProps extends React.HTMLAttributes<HTMLDivElement> {
  onAcceptClick?: React.MouseEventHandler<HTMLButtonElement>;
}

function DefaultPopup({
  className,
  children,
  onAcceptClick,
  ...rest
}: IDefaultPopupProps) {
  const { close } = usePopupContext();

  const handleExitClick = React.useCallback(() => {
    close();
  }, [close]);

  const handleAcceptClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(evt => {
    onAcceptClick && onAcceptClick(evt);
    if (evt.isPropagationStopped()) {
      return;
    }

    close();
  }, [close, onAcceptClick]);

  return (
    <div className={ClassNameHelper.concat("popup default-popup", className)} {...rest}>
      <div className="popup-content">
        {children}
        <ExitIcon className="btn close-btn" onClick={handleExitClick} />
      </div>
      <div>
        <PrimaryButton className="accept-btn" onClick={handleAcceptClick}>확 인</PrimaryButton>
      </div>
    </div>
  )
}

export default React.memo(DefaultPopup);