import React from "react";
import "@loonfactory/common/JsExtensions";
import UserMessageLabel from "../../components/UserMessageLabel";
import { challengeTalkingUsersAsync } from "../../challenges";
import { useIdentityContext } from "../../contexts/IdentityContext";
import { CancellationToken, CancellationTokenSource } from "@code-rabbits/core";
import IServiceError from "../../farmerLounges/IServiceError";
import ContentLoadFail from "../../components/contents/ContentLoadFail";
import LoadingContent from "../../components/contents/LoadingContent";
import EmptyContent from "../../components/contents/EmptyContent";
import ServiceErrorGenerator from "../../ServiceErrorGenerator";
import ITalkingUserResource from "../../lib/ITalkingUserResource";

interface IRoomListContaierProps {
  targetId?: number;
  onUserClick?: (evt: React.MouseEvent<HTMLDivElement>, user: ITalkingUserResource) => void;
}


const TalkingUser = React.memo(({
  item,
  className,
  onClick,
}: {
  item: ITalkingUserResource,
  className?: string,
  onClick?: (evt: React.MouseEvent<HTMLDivElement>, user: ITalkingUserResource) => void,
}) => {
  const handleClick = React.useCallback<React.MouseEventHandler<HTMLDivElement>>(evt => {
    onClick && onClick(evt, item);
  }, [item, onClick]);

  return (
    <UserMessageLabel
      key={item._id?.toString()}
      className={className}
      name={item.name ?? "탈퇴한 유저"}
      role={item.role}
      lastMeassage={item.lastMessage ?? "대화를 아직 시작하지 않았습니다."}
      profileUrl={item.profile?.location}
      onClick={handleClick}
    />
  )
})

function RoomListContaier({ targetId, onUserClick }: Readonly<IRoomListContaierProps>) {
  const { userInfo } = useIdentityContext();

  const [talkingUsers, setTalkingUsers] = React.useState<ITalkingUserResource[] | null>(null);
  const [errors, setErrors] = React.useState<IServiceError[] | null>(null);

  React.useEffect(() => {
    const id = userInfo?._id;
    if (id === undefined || id === null) {
      setErrors([ServiceErrorGenerator.loginRequired]);
      return;
    }
    setErrors(null);
    const cts = new CancellationTokenSource();

    (async (cancellationToken: CancellationToken) => {
      try {
        const result = await challengeTalkingUsersAsync({
          myId: id,
          take: 50,
        }, cancellationToken);

        if (!result.succeeded) {
          setErrors(result.errors);
          return;
        }

        setTalkingUsers(result.data)
      } catch (e) {
        console.warn(e);
      }

    })(cts.token);

    return () => {
      cts.cancel();
    }
  }, [userInfo?._id]);

  if (errors?.any()) {
    return (
      <ContentLoadFail errors={errors} />
    )
  }

  if (talkingUsers === null) {
    return (
      <LoadingContent />
    )
  }

  if (!talkingUsers.any()) {
    return (
      <EmptyContent />
    )
  }

  return (
    <div className="users-mesaage-list">
      {talkingUsers.map(item => {
        return (
          <TalkingUser
            key={item._id?.toString()}
            className={item.roomId === targetId ? "active" : undefined}
            item={item}
            onClick={onUserClick}
          />
        )
      })}
    </div>
  )
}

export default React.memo(RoomListContaier);