import React from "react";
import { ClassNameHelper } from "@loonfactory/common";
import './WalletSection.scss';

interface IWalletSection extends React.HTMLAttributes<HTMLDivElement> {
}

function WalletSection({
  className,
  children,
  ...rest
}: IWalletSection) {
  return (
    <section className={ClassNameHelper.concat('wallet-section', className)} {...rest}>
      <div className="content-region">
        {children}
      </div>
    </section>
  )
}

export default React.memo(WalletSection);