import React from "react";
import ManagersLayout from "../../../layouts/ManagersLayout";
import TransactionsContainer from "../../../containers/managers/TransactionsContainer";
import ITransactionListOptions from "../../../farmerLounges/transactions/ITransactionListOptions";
import TransactionStateType from "../../../farmerLounges/TransactionStateType";
import farmerLoungeService from "../../../farmerLounges/FarmerLoungeService";

interface IRefundManagementPageProps extends React.HTMLAttributes<HTMLDivElement> {

}

const refundOptions: ITransactionListOptions = {
  type: 'withdraw',
}

function findParentElementByTagName(el: HTMLElement, tagName: string) {
  tagName = tagName.toUpperCase();

  let read: HTMLElement | null = el;
  do {
    read = read.parentElement;
  } while (read !== null && read.tagName !== tagName)

  return read;
}

function getIdFromTableElement(el: HTMLElement) {
  const tr = findParentElementByTagName(el, 'tr');
  if (tr === null) {
    throw new Error("에러 발생 관리자에게 문의 부탁드립니다. [CODE: -1]");
  }

  let id: string | number | undefined = tr.dataset.id;
  if (id === undefined) {
    throw new Error("에러 발생 관리자에게 문의 부탁드립니다. [CODE: -2]");
  }

  id = Number.parseInt(id);
  if (Number.isNaN(id)) {
    throw new Error("에러 발생 관리자에게 문의 부탁드립니다. [CODE: -3]");
  }

  return id;
}

function RefundManagementPage(props: IRefundManagementPageProps) {
  const handleClick = React.useCallback(async (
    evt: React.MouseEvent<HTMLButtonElement>,
    state: TransactionStateType,
  ) => {
    evt.preventDefault();
    try {
      const id = getIdFromTableElement(evt.currentTarget);
      if (id === undefined) {
        return;
      }

      const result = await farmerLoungeService.transactions.updateStateAsync(id, state);
      if (!result.succeeded) {
        const error = result.errors?.first();
        alert(error.message);
        return;
      }

      alert("변경 완료");
    } catch (e) {
      if (e instanceof Error) {
        alert(e.message);
      }

      console.error(e);
    }
  }, []);

  const handleAcceptClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(evt => {
    handleClick(evt, 'success');
  }, [handleClick]);

  const handleRejectClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(evt => {
    handleClick(evt, 'cancel');
  }, [handleClick]);


  return (
    <ManagersLayout className={props.className}>
      <section className="mt-5">
        <TransactionsContainer className="container" 
          options={refundOptions}
          onAcceptClick={handleAcceptClick}
          onRejectClick={handleRejectClick}
        />
      </section>
    </ManagersLayout>
  )
}

export default React.memo(RefundManagementPage);