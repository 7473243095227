import React from "react";
import HorizontalLinearLayout from "../layouts/HorizontalLinearLayout";
import VerticalLinearLayout from "../layouts/VerticalLinearLayout";
import { ClassNameHelper } from "@loonfactory/common";
import ProfileIcon from "./ProfileIcon";
import RoleManager from "../lib/RoleManager";
import RoleType from "../farmerLounges/RoleType";

interface IUserMessageLabelProps extends React.HTMLAttributes<HTMLDivElement> {
  profileUrl?: string;
  role?: RoleType;
  name?: string;
  lastMeassage?: string;
}

function UserMessageLabel({
  profileUrl,
  role,
  name,
  lastMeassage,
  className,
  ...rest
}: IUserMessageLabelProps) {

  return (
    <HorizontalLinearLayout className={ClassNameHelper.concat("user-message-label-wrapper", className)} {...rest}>
      <div className="profile-img-wrapper">
        <ProfileIcon url={profileUrl} className="profile-img" />
      </div>
      <VerticalLinearLayout>
        <div className="fw-semibold fs-7">{name} <span className="fs-8 fw-light">{role && RoleManager.getName(role)}</span></div>
        <div className="fw-light info">{lastMeassage}</div>
      </VerticalLinearLayout>
    </HorizontalLinearLayout >
  )
}


export default React.memo(UserMessageLabel);