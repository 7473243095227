import React from "react";
import FormInput from "./FormInputGroup";

function SalesTypeInputGroup({
  ...rest
}: React.InputHTMLAttributes<HTMLInputElement>) {
  return (
    <FormInput
      label="판매 채널"
      name="saleType"
      type="text"
      placeholder="판매 채널을 작성해 주세요."
      {...rest}
    />
  )
}

export default React.memo(SalesTypeInputGroup);