import { ClassNameHelper } from "@loonfactory/common";
import React from "react";

function PrimarySpeechBubble({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) {
  return <div className={ClassNameHelper.concat('primary-speech-bubble', className)} {...rest} />
}

export default React.memo(PrimarySpeechBubble);