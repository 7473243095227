import React from "react";
import MainLayout from "../../layouts/MainLayout";
import WithdrawalWalletSection from "../../sections/WithdrawalWalletSection";

interface IAgencyWalletPageProps extends React.HTMLAttributes<HTMLDivElement> {

}

function AgencyWalletPage(props: IAgencyWalletPageProps) {
  return (
    <MainLayout className={props.className}>
      <WithdrawalWalletSection className="main-section" />
    </MainLayout>
  )
}

export default React.memo(AgencyWalletPage);