import { ClassNameHelper } from "@loonfactory/common";
import HorizontalLinearLayout from "../layouts/HorizontalLinearLayout";
import React from "react";
import Wallet from "../components/Wallet";
import { Link } from "react-router-dom";
import EndPoints from "../EndPoints";

interface IDashBoardWalletProps extends React.HTMLAttributes<HTMLDivElement> {
  value?: number;
}
function DashBoardWallet({
  className,
  value,
  ...rest
}: IDashBoardWalletProps) {
  return (
    <div className={ClassNameHelper.concat(className, 'content-wrapper')} {...rest}>
      <HorizontalLinearLayout className="fs-3 fw-semibold content-title-wrapper">
        <div className="content-title text-primary">포인트 현황</div>
        <div className="content-title-option">
          <Link to={EndPoints.wallet} className="text-primary">더보기 +</Link>
        </div>
      </HorizontalLinearLayout>
      <div className="new-host-content-wrapper">
        <div className="new-host-content">
          <Wallet className="col-8 primary-speech-bubble align-items-center flex-column" value={value} />
        </div>
      </div>
    </div>
  )
}

export default React.memo(DashBoardWallet);