import React, { useCallback } from "react";
import { IFormInputProps } from "./forms/inputs/FormInputGroup";
import HorizontalLinearLayout from "../layouts/HorizontalLinearLayout";
import { ClassNameHelper } from "@loonfactory/common";

interface ISpecializationWrapperProps extends IFormInputProps {
  defaultValue?: string[];
}

function SpecializationWrapper({
  onChange,
  defaultValue = [],
  required,
  ...rest
}: ISpecializationWrapperProps) {
  const [inputFields, setInputFields] = React.useState<string[]>(["", "", ""]);

  React.useEffect(() => {
    if (defaultValue.length > 0) {
      setInputFields(defaultValue);
    }
  }, [defaultValue]);

  const handleAddInput = useCallback(() => {
    setInputFields(prevFields => [...prevFields, ""]);
  }, []);

  return (
    <div className="specialization-wrapper" {...rest}>
      <HorizontalLinearLayout className="justify-content-between fw-semibold fs-4">
        <div className={ClassNameHelper.concat(required && "required")}>전문 분야</div>
        <div className="text-primary fs-5" onClick={handleAddInput} style={{ cursor: 'pointer' }}>+ 추가하기</div>
      </HorizontalLinearLayout>
      <HorizontalLinearLayout className="row">
        {inputFields.map((value, index) => (
          <div className="col-3 mt-2" key={`specialization-${index}`}>
            <input
              name="specialization"
              type="text"
              placeholder="전문 분야를 입력해 주세요."
              className="form-control"
              required={required}
              onChange={onChange}
              defaultValue={value}
            />
          </div>
        ))}
      </HorizontalLinearLayout>
    </div>
  )
}

export default React.memo(SpecializationWrapper);