import React from "react";
import INavItemProps from "./INavItemProps";
import NavItem from "./NavItem";
import LazyBuildingsIcon from "../../../icons/LazyBuildingsIcon";

function SupportCompanyNavItem(props: Omit<INavItemProps, 'children'>) {
  return (
    <NavItem {...props}>
      <LazyBuildingsIcon /> 지원 업체
    </NavItem>
  )
}

export default React.memo(SupportCompanyNavItem);