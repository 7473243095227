import React from "react";
import { ClassNameHelper } from "@loonfactory/common";
import './BankAccountSection.scss';

interface IBankAccountSectionProps extends React.HTMLAttributes<HTMLDivElement> {
}

function BankAccountSection({
  className,
  ...rest
}: IBankAccountSectionProps) {
  return (
    <section
      className={ClassNameHelper.concat('back-account-section', className)}
      {...rest}
    />
  )
}

export default React.memo(BankAccountSection);