import React from "react";
import FormInput from "./FormInputGroup";

function ProductPriceInputGroup({
  ...rest
}: React.InputHTMLAttributes<HTMLInputElement>) {
  return (
    <FormInput
      label="상품 금액"
      name="productPrice"
      type="number"
      {...rest}
    />
  )
}

export default React.memo(ProductPriceInputGroup);