import React from "react";
import MainLayout from "../../../layouts/MainLayout";
import RecommendSection from "../../../sections/RecommandSection/RecommendSection";
import RecommendAgencyContainer from "../../../containers/recommands/RecommendAgencyContainer";
import AgencyContainer from "../../../containers/AgencyContainer";
import { ClassNameHelper } from "@loonfactory/common";
import './SupportCompanySection.scss';
import { useNavigate } from "react-router-dom";
import EndPoints from "../../../EndPoints";

interface IMatchingPageProps extends React.HTMLAttributes<HTMLDivElement> {

}

function MatchingPage(props: IMatchingPageProps) {
  const navigate = useNavigate();
  const handleSubmit = React.useCallback<React.FormEventHandler<HTMLFormElement>>(evt => {
    evt.stopPropagation();
    evt.preventDefault();

    const formData = new FormData(evt.currentTarget);
    const q = formData.get('q');
    navigate(`${EndPoints.supportCompany}?q=${q}`);
  }, [navigate]);


  return (
    <MainLayout className={props.className}>
      <RecommendSection
        className={ClassNameHelper.concat('main-section', 'support-company-section')}
        onSearchSubmit={handleSubmit}
      >
        <RecommendAgencyContainer className="mt-5" />
        <AgencyContainer className="mt-5" />
      </RecommendSection>
    </MainLayout>
  )
}

export default React.memo(MatchingPage);