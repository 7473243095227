import React from "react";
import MainLayout from "../../layouts/MainLayout";
import MessageSection from "../../sections/MessageSection";
import MessageRoomContainer from "../../containers/rooms/MessageRoomContainer";
import SpeechBalloonIcon from "../../icons/SpeechBalloonIcon";
import ContentRegion from "../../components/contents/ContentRegion";
import RoomListContaier from "../../containers/rooms/RoomListContaier";
import { useSearchParams } from "react-router-dom";
import ITalkingUserResource from "../../lib/ITalkingUserResource";

interface IMessagePageProps extends React.HTMLAttributes<HTMLDivElement> {

}

function MessagePage(props: IMessagePageProps) {
  const [searchParmas, setSearchParmas] = useSearchParams()
  const searchId = searchParmas.get('id');
  const targetId = searchId != null ? Number.parseInt(searchId) : undefined;

  const handleUserClick = React.useCallback((
    evt: React.MouseEvent<HTMLDivElement>,
    user: ITalkingUserResource
  ) => {
    setSearchParmas({
      ...searchParmas,
      'id': user.roomId!.toString()
    });
  }, [searchParmas, setSearchParmas]);

  return (
    <MainLayout className={props.className}>
      <MessageSection>
        <ContentRegion>
          <div className="users-wrapper">
            <div className="speech-balloon-wrapper">
              <SpeechBalloonIcon />
              <div>메세지 리스트</div>
            </div>
            <div className="users-mesaage-list">
              <RoomListContaier
                targetId={targetId}
                onUserClick={handleUserClick}
              />
            </div>
          </div>
          <div className="message-list-wrapper">
            <MessageRoomContainer />
          </div>
        </ContentRegion>
      </MessageSection>
    </MainLayout>
  )
}

export default React.memo(MessagePage);