import React from "react";
import VerticalLinearLayout from "../../layouts/VerticalLinearLayout";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import LazyCaretRightIcon from "../../icons/LazyCaretRightIcon";
import LazyInstagramIcon from "../../icons/LazyInstagramIcon";
import HorizontalLinearLayout from "../../layouts/HorizontalLinearLayout";
import LazyYoutubeIcon from "../../icons/LazyYoutubeIcon";
import IUserDetailResource from "../../farmerLounges/farmerLoungeResources/IUserDetailResource";
import IServiceError from "../../farmerLounges/IServiceError";
import farmerLoungeService from "../../farmerLounges/FarmerLoungeService";
import { Link, useNavigate } from "react-router-dom";
import { CancellationTokenSource } from "@code-rabbits/core";
import LazyLinkIcon from "../../icons/LazyLinkIcon";
import Thumbnail from "../../components/Thumbnail";
import { useIdentityContext } from "../../contexts/IdentityContext";
import EndPoints from "../../EndPoints";
import PortfolioThumbnailItem from "../../components/PortfolioThumbnailItem";
import IScheduleDetailListResource from "../../farmerLounges/farmerLoungeResources/IScheduleDetailListResource";
import StarMask from "../../components/StarMask";
import AgeHelper from "../../AgeHelper";
import LazyOutlineCircleIcon from "../../icons/LazyOutlineCircleIcon";
import AutoResizeGallery from "../../components/galleries/AutoResizeGallery";
import { ClassNameHelper } from "@loonfactory/common";
import './ShowhostProfileContainer.scss'
import RoleManager from "../../lib/RoleManager";

const renderBadges = (items: string[] | undefined) => {
  if (!items) return null;

  return items.map((item, index) => (
    <span key={index} className={`badge bg-gray fs-9 ${index === 0 ? '' : 'ms-1'}`}>{item}</span>
  ));
}

interface ISnsProps {
  sns: 'youtube' | 'instagram';
  link: string;
}

const Sns = React.memo((props: ISnsProps) => {
  const name = props.sns === 'youtube' ? "유튜브" : "인스타그램";
  const SnsIcon = props.sns === 'youtube' ? LazyYoutubeIcon : LazyInstagramIcon;

  return (
    <HorizontalLinearLayout className="align-items-center">
      <LazyCaretRightIcon />
      <SnsIcon className="ms-1 icon" />
      <Link to={props.link} className="ms-1">
        쇼호스트 {name}
      </Link>
    </HorizontalLinearLayout>
  )
})

const portfoliosRenderer = (url: string) => {
  return (
    <a href={url} target="_blank" rel="noreferrer" >
      <PortfolioThumbnailItem videoUrl={url} />
    </a>
  );
}

const keySelector = (item: string, index: number) => index;

const starMaskRenderer = (scheduleList: IScheduleDetailListResource) => {
  return <StarMask schedules={scheduleList.schedules} count={scheduleList.count} />;
}

interface IShowhostProfileContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  hostId: number;
}

function ShowhostProfileContainer({
  hostId,
  className,
  ...rest
}: Readonly<IShowhostProfileContainerProps>) {
  const navigate = useNavigate()
  const { userInfo } = useIdentityContext();

  const [targetUser, setTargetUser] = React.useState<IUserDetailResource | null>(null);
  const [errors, setErrors] = React.useState<IServiceError[]>();
  const [scheduleItem] = React.useState<IScheduleDetailListResource | null>(null);

  const handleConnectClick = React.useCallback(async () => {
    if (hostId === undefined) {
      return;
    }

    const result = await farmerLoungeService.rooms.createAsync(hostId);
    if (!result.succeeded) {
      alert(result.errors.first().message);
      return;
    }

    navigate(`${EndPoints.message}?id=${result.data?._id}`);
  }, [hostId, navigate]);

  React.useEffect(() => {
    if (hostId === undefined) {
      return;
    }

    let cts = new CancellationTokenSource();
    const updateDataAsync = (async () => {
      const response = await farmerLoungeService.users.getDetailAsync(hostId);

      if (cts.isCancellationRequested) {
        return;
      }

      if (!response.succeeded) {
        setErrors(response.errors!);
        return;
      }

      setTargetUser(response.data!);
    });

    updateDataAsync();

    return () => {
      cts.cancel();
    }
  }, [hostId]);

  let ageRange = undefined;
  if (targetUser?.birth !== undefined) {
    const age = AgeHelper.getAge(targetUser?.birth);

    if (age % 10 < 3) {
      ageRange = `${Math.floor(age / 10) * 10}대 초반`;
    } else if (age % 10 <= 6) {
      ageRange = `${Math.floor(age / 10) * 10}대 중반`;
    } else {
      ageRange = `${Math.floor(age / 10) * 10}대 후반`;
    }
  }

  if (hostId === null || hostId === undefined) {
    navigate(-1);
    return <></>;
  }

  const portfolio = targetUser?.portfolio?.first();
  const imgUrl = portfolio?.images?.first()?.location;;

  if (hostId === null || hostId === undefined) {
    navigate(-1);
    return <></>;
  }

  if (errors?.any()) {
    return (
      <div className="text-danger">
        항목을 불러오는데 실패하였습니다.
        <ul>
          {errors.map(item => <li key={item.codeDescription}>{item.message}</li>)}
        </ul>
      </div>
    )
  }

  if (targetUser === null) {
    return (
      <div>
        항목을 불러오고 있습니다.
      </div>
    )
  }

  return (
    <section className={ClassNameHelper.concat('showhost-profile-container', className)}>
      <HorizontalLinearLayout className="content-region mt-5" {...rest}>
        <div className="profile-content-wrapper">
          <VerticalLinearLayout className="position-sticky">
            <div className="profile-image-wrapper">
              {imgUrl && <LazyLinkIcon className="link-icon" />}
              <Thumbnail src={imgUrl} />
            </div>
            <div className="profile-content">
              <div>
                <div className="fs-6 fw-semibold">주 활동 플랫폼</div>
                <div className="mt-1 badge-group">
                  {renderBadges(portfolio?.career?.descriptions)}
                </div>
              </div>
              <div className="mt-4">
                <div className="fs-6 fw-semibold">전문 분야</div>
                <div className="mt-1">
                  {renderBadges(portfolio?.career?.content)}
                </div>
              </div>
              <div className="mt-4">
                <div className="fs-6 fw-semibold">라이브 가능 시간</div>
                <div className="text-end fs-6 broadcast-time">{targetUser.portfolio?.first()?.work_info?.time}</div>
              </div>
              {(userInfo?.role !== undefined && (userInfo.role !== "showhost" && !RoleManager.isAdmin(userInfo.role))) && (
                <PrimaryButton className="fs-6 mt-4 consulting-btn" onClick={handleConnectClick}>
                  상담 신청하기
                </PrimaryButton>
              )}
              <div className="mt-4">
                {targetUser.portfolio?.first()?.sns?.map(item => {
                  return (<Sns key={item.link} link={item.link} sns={item.service as "instagram" | "youtube"} />)
                })}
              </div>
            </div>
          </VerticalLinearLayout>
        </div>
        <div className="info-content-wrapper">
          <VerticalLinearLayout>
            <div className="fs-2 fw-semibold">{targetUser?.portfolio?.first()?.introduce}</div>
            <div className="info-content mt-4">
              <HorizontalLinearLayout className="justify-content-between">
                <HorizontalLinearLayout className="align-items-center">
                  <LazyOutlineCircleIcon />
                  <span className="fs-6 fw-semibold ms-2">이름</span>
                </HorizontalLinearLayout>
                <div className="fs-4">{targetUser?.name}</div>
              </HorizontalLinearLayout>
              <HorizontalLinearLayout className="justify-content-between mt-3">
                <HorizontalLinearLayout className="align-items-center">
                  <LazyOutlineCircleIcon />
                  <span className="fs-6 fw-semibold ms-2">나이</span>
                </HorizontalLinearLayout>
                <div className="fs-4">{ageRange}</div>
              </HorizontalLinearLayout>
              <HorizontalLinearLayout className="justify-content-between mt-3">
                <HorizontalLinearLayout className="align-items-center subtitle">
                  <LazyOutlineCircleIcon />
                  <span className="fs-6 fw-semibold ms-2">경력</span>
                </HorizontalLinearLayout>
                <div className="text-end fs-4">
                  {portfolio?.career?.descriptions?.map((item, index) => (
                    <div key={index}>{item}</div>
                  ))}
                </div>
              </HorizontalLinearLayout>
              <div className="mt-3">
                <HorizontalLinearLayout className="align-items-center">
                  <LazyOutlineCircleIcon />
                  <span className="fs-6 fw-semibold ms-2">포트폴리오</span>
                </HorizontalLinearLayout>
                <AutoResizeGallery
                  className="px-4 mt-3"
                  itemWidth={224}
                  items={portfolio?.videos?.link}
                  keySelector={keySelector}
                  renderer={portfoliosRenderer}
                  row={3}
                  style={{ rowGap: '10px' }}
                  paginationDisabled
                />
              </div>
              <HorizontalLinearLayout className="mt-3">
                <HorizontalLinearLayout className="align-items-center subtitle">
                  <LazyOutlineCircleIcon />
                  <span className="fs-6 fw-semibold ms-2">후기</span>
                </HorizontalLinearLayout>
                {scheduleItem && starMaskRenderer(scheduleItem)}
              </HorizontalLinearLayout>
            </div>
          </VerticalLinearLayout>
        </div>
      </HorizontalLinearLayout>
    </section>
  )
}

export default React.memo(ShowhostProfileContainer);