import React from "react";
import MatcheRoutes from "./MatcheRoutes";
import { RoutesProps } from "react-router-dom";
import EndPoints from "../EndPoints";
import farmerLoungeService from "../farmerLounges/FarmerLoungeService";

function UnauthRoutes({
  children,
  location,
}: RoutesProps) {
  const handleMath = React.useCallback(async () => {
    if (farmerLoungeService.accessToken !== null) {
      const result = await farmerLoungeService.auth.getTokenInfoAsync();
      const role = result.data?.role;
      if (role === "manager" || role === "admin") {
        window.location.pathname = EndPoints.manager.managements.showhost;
      }
      else {
        window.location.pathname = EndPoints.dashboard;
      }

      return false;
    }
  }, []);

  return (
    <MatcheRoutes onMatche={handleMath} location={location} children={children} />
  )
}

export default UnauthRoutes;