import { CancellationToken, CancellationTokenSource } from "@code-rabbits/core";
import React from "react";
import ContentSlider from "../components/ContentSlider";
import IServiceError from "../farmerLounges/IServiceError";
import LoadingContent from "../components/contents/LoadingContent";
import EmptyContent from "../components/contents/EmptyContent";
import ContentLoadFail from "../components/contents/ContentLoadFail";
import { IContentSliderProps } from "../components/ContentSlider/ContentSlider";
import IScheduleDetailResource from "../farmerLounges/farmerLoungeResources/IScheduleDetailResource";
import { IChallengeSchedulesOptions, challengeUserSchedulesAsync } from "../challenges";


interface ISchedulesContentSliderContainerProps extends Omit<IContentSliderProps<IScheduleDetailResource>, 'items' | 'keySelector'> {
  renderer: (props: IScheduleDetailResource, index: number, array: IScheduleDetailResource[]) => React.ReactElement | null | undefined;
  options: IChallengeSchedulesOptions,
}

const keySelector = (item: IScheduleDetailResource) => {
  return item._id;
}

function UserSchedulesContentSliderContainer({
  renderer,
  options,
  ...rest
}: ISchedulesContentSliderContainerProps) {
  const [items, setItems] = React.useState<IScheduleDetailResource[] | null>(null);
  const [errors, setErrors] = React.useState<IServiceError[]>();

  React.useEffect(() => {
    const cts = new CancellationTokenSource();
    (async (token: CancellationToken) => {
      try {
        setErrors(undefined);
        const result = await challengeUserSchedulesAsync(
          { ...options },
          token
        );

        if (token.isCancellationRequested) {
          return;
        }

        if (!result.succeeded) {
          setErrors(result.errors);
          return;
        }

        setItems(result.data!.schedules!);
      } catch (e) {
        console.warn(e);
        return;
      }
    })(cts.token);

    return () => {
      cts.cancel();
    }
  }, [options])

  if (errors?.any()) {
    return (
      <ContentLoadFail errors={errors} />
    )
  }

  if (items === null) {
    return (
      <LoadingContent />
    )
  }

  if (!items.any()) {
    return <EmptyContent />
  }

  return (
    <ContentSlider
      className="live-broadcast-slider"
      items={items}
      keySelector={keySelector}
      renderer={renderer}
      {...rest}
    />
  )
}

export default React.memo(UserSchedulesContentSliderContainer);