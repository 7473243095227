import React from "react";
import HorizontalLinearLayout from "../layouts/HorizontalLinearLayout";
import IScheduleDetailListResource from "../farmerLounges/farmerLoungeResources/IScheduleDetailListResource";

function StarMask({
  schedules,
  ...rest
}: IScheduleDetailListResource) {
  return (
    <HorizontalLinearLayout className="flex-column" {...rest}>
      {schedules.first().reviews.map(review => (
        <HorizontalLinearLayout className="ms-5 align-items-center" key={review._id}>
          <div className="star-mask">
            <div className="star-fill-mask"
              style={{
                background: `linear-gradient(to right, #4E9A5E ${review.score / 10 * 100}%, white ${review.score / 10 * 100}% 100%)`,
              }}
            />
          </div>
          <div className="ms-3">
            {review.content}
          </div>
        </HorizontalLinearLayout>
      ))}
    </HorizontalLinearLayout>
  )
}

export default React.memo(StarMask);