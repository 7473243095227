import React from "react";
import PrimaryButton from "./buttons/PrimaryButton";
import InputTitle from "./forms/inputs/InputTitle";

function RequestQuoteForm(props: React.FormHTMLAttributes<HTMLFormElement>) {
  return (
    <form {...props}>
      <div className="product-name">
        <InputTitle required>상품 이름</InputTitle>
        <div className="input-group">
          <input type="text" required
            className="form-control"
            name="productName"
          />
        </div>
      </div>
      <div className="ad-qutoe">
        <InputTitle required>광고 예산</InputTitle>
        <div className="input-group">
          <input type="number" required
            className="form-control"
            name="adQutoe"
          />
        </div>
      </div>
      <div className="ad-name">
        <InputTitle required>광고주 성함</InputTitle>
        <div className="input-group">
          <input type="text" required
            className="form-control"
            name="adName"
          />
        </div>
      </div>
      <div className="ad-type">
        <InputTitle required>판매 채널</InputTitle>
        <div className="input-group">
          <input type="text" required
            className="form-control"
            name="adType"
          />
        </div>
      </div>
      <div className="contact">
        <InputTitle required>연락처</InputTitle>
        <div className="input-group">
          <input type="text" required
            className="form-control"
            name="contact"
          />
        </div>
      </div>
      <div className="email">
        <InputTitle required>이메일</InputTitle>
        <div className="input-group">
          <input type="text" required
            className="form-control"
            name="email"
          />
        </div>
      </div>
      <div className="etc">
        <InputTitle>기타 요청사항</InputTitle>
        <div className="input-group">
          <input type="text"
            className="form-control"
            name="etc"
          />
        </div>
      </div>
      <div className="submit-wrapper">
        <PrimaryButton type="submit">발송하기</PrimaryButton>
      </div>
    </form>
  )
}

export default React.memo(RequestQuoteForm);