import React from "react";
import FormInput from "./FormInputGroup";

function IntroduceInputGroup({
  onChange,
  defaultValue,
  ...rest
}: React.InputHTMLAttributes<HTMLInputElement>) {
  return (
    <FormInput
      label="인사말"
      name="introduce"
      type="text"
      placeholder="자기소개를 입력해 주세요. (20자 내외)"
      onChange={onChange}
      maxLength={20}
      defaultValue={defaultValue}
      {...rest}
    />
  )
}

export default React.memo(IntroduceInputGroup);