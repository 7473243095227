import React from "react";
import ImageSlider from "../components/ImageSlier/ImageSlider";
import ImageSliderItem from "../components/ImageSlier/ImageSliderItem";
import Thumbnail from "../components/Thumbnail";
import { ClassNameHelper } from "@loonfactory/common";
import IPostResource from "../farmerLounges/posts/IPostResource";
import IServiceError from "../farmerLounges/IServiceError";
import { CancellationToken, CancellationTokenSource } from "@code-rabbits/core";
import farmerLoungeService from "../farmerLounges/FarmerLoungeService";

// @TODO: 백엔드에서 삭제된 항목 제외 지원후 재수정 필요
function HomeAdBannerContaier({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) {
  const [posts, setPosts] = React.useState<IPostResource[] | null>(null);

  // eslint-disable-next-line
  const [errors, setErrors] = React.useState<IServiceError[]>();

  // eslint-disable-next-line
  const [totalCount, setTotalCount] = React.useState<number>();

  const updatePostsAsync = React.useCallback(async (cancellationToken?: CancellationToken) => {
    const result = await farmerLoungeService.posts.getListAsync({
      type: 'banner',
    });

    if (cancellationToken?.isCancellationRequested) {
      return;
    }

    if (!result.succeeded) {
      setErrors(result.errors);
      return;
    }

    const data = result.data;
    if (!data) {
      alert("데이터가 비어있습니다.")
      return;
    }

    setTotalCount(data.count);
    setPosts(data.posts.filter(item => item.deleted_at === null));
  }, []);

  React.useEffect(() => {
    const cts = new CancellationTokenSource();
    updatePostsAsync(cts.token);

    return () => {
      cts.cancel();
    }
  }, [updatePostsAsync]);

  return (
    <div className={ClassNameHelper.concat("ad-banner", className)} {...rest} >
      <ImageSlider className="ad-slider">
        {posts?.map(item => {
          return (
            <ImageSliderItem key={item._id}>
              <Thumbnail
                src={item.files?.first().location}
                width="100%"
                height="100%"
              />
            </ImageSliderItem>
          )
        })}
      </ImageSlider>
    </div>
  )

}

export default React.memo(HomeAdBannerContaier);