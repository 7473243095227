import React from "react";
import { ClassNameHelper } from "@loonfactory/common";
import FileInput, { IFileInputProps } from "./FileInput";

function BankbookFileInputGroup({
  required,
  fileName,
  name,
  onChange,
  ...rest
}: IFileInputProps) {
  return (
    <div className="input-group" {...rest}>
      <label className="form-label fs-4">
        <span className={ClassNameHelper.concat(required && "required")}>통장 사본</span>
        <FileInput
          name={name ?? "bankbook"}
          fileName={fileName}
          onChange={onChange}
        />
      </label>
    </div>
  )
}

export default React.memo(BankbookFileInputGroup);