import ApiErrorCodeDescriptions from "./farmerLounges/ApiErrorCodeDescriptions";
import IServiceError from "./farmerLounges/IServiceError";

class ServiceErrorGenerator {
  public static get usernameIsRequired(): IServiceError {
    return {
      code: 1,
      codeDescription: ApiErrorCodeDescriptions.usernameIsRequired,
      message: '아이디를 입력해주세요.'
    }
  }

  public static get passwordIsRequired(): IServiceError {
    return {
      code: 2,
      codeDescription: ApiErrorCodeDescriptions.usernameIsRequired,
      message: '비밀번호를 입력해주세요.'
    }
  }

  public static get phoneNumberIsRequired(): IServiceError {
    return {
      code: 3,
      codeDescription: ApiErrorCodeDescriptions.phoneNumberIsRequired,
      message: '휴대폰 번호를 입력해주세요.'
    }
  }

  public static get birthdayIsRequired(): IServiceError {
    return {
      code: 4,
      codeDescription: ApiErrorCodeDescriptions.birthdayIsRequired,
      message: '생년월일를 입력해주세요.'
    }
  }

  public static get emailIsRequired(): IServiceError {
    return {
      code: 4,
      codeDescription: ApiErrorCodeDescriptions.emailIsRequired,
      message: '이메일을 입력해주세요.'
    }
  }

  public static get roleIsRequired(): IServiceError {
    return {
      code: 5,
      codeDescription: ApiErrorCodeDescriptions.roleIsRequired,
      message: 'role을 입력해주세요.'
    }
  }

  public static get verifyPhoneNumberTokenIsRequired(): IServiceError {
    return {
      code: 6,
      codeDescription: ApiErrorCodeDescriptions.verifyPhoneNumberTokenIsRequired,
      message: '인증 번호를 입력해주세요.'
    }
  }

  public static get invalidPhoneNumber(): IServiceError {
    return {
      code: 7,
      codeDescription: ApiErrorCodeDescriptions.invalidPhoneNumber,
      message: '유효하지 않은 전화번호입니다.'
    }
  }

  public static get invalidPhoneNumberFormat(): IServiceError {
    return {
      code: 8,
      codeDescription: ApiErrorCodeDescriptions.invalidPhoneNumberFormat,
      message: '유효하지 않은 전화번호 형식입니다.'
    }
  }

  public static get nameIsRequired(): IServiceError {
    return {
      code: 9,
      codeDescription: ApiErrorCodeDescriptions.nameIsRequired,
      message: '이름을 입력해주세요.'
    }
  }

  public static get unknownError(): IServiceError {
    return {
      code: -1,
      codeDescription: ApiErrorCodeDescriptions.unknownError,
      message: '알수 없는 에러가 발생했습니다.'
    }
  }

  public static get loginRequired(): IServiceError {
    return {
      code: 10,
      codeDescription: ApiErrorCodeDescriptions.loginRequired,
      message: '로그인를 우선 진행해주세요.'
    }
  }

  public static get passwordNotMatch(): IServiceError {
    return {
      code: 12,
      codeDescription: ApiErrorCodeDescriptions.passwordNotMatch,
      message: '패스워드가 일치하지 않습니다.'
    }
  }

  public static get withdrawalAccountIsRequired(): IServiceError {
    return {
      code: 13,
      codeDescription: ApiErrorCodeDescriptions.withdrawalAccountIsRequired,
      message: '출금 계좌를 등록해주세요.'
    }
  }

  public static get notEnoughpoints(): IServiceError {
    return {
      code: 14,
      codeDescription: ApiErrorCodeDescriptions.notEnoughpoints,
      message: '포인트가 충분하지 않습니다.'
    }
  }

  public static get duplicateTransactionRequests(): IServiceError {
    return {
      code: 15,
      codeDescription: ApiErrorCodeDescriptions.duplicateTransactionRequests,
      message: '이미 처리된 거래입니다.'
    }
  }

  public static get forbidden(): IServiceError {
    return {
      code: 16,
      codeDescription: ApiErrorCodeDescriptions.duplicateTransactionRequests,
      message: '권한이 부족합니다.'
    }
  }

  public static get duplicateScheduleRequests(): IServiceError {
    return {
      code: 17,
      codeDescription: ApiErrorCodeDescriptions.duplicateTransactionRequests,
      message: '이미 처리된 스케줄입니다.'
    }
  }

  public static get invalidPassword(): IServiceError {
    return {
      code: 18,
      codeDescription: ApiErrorCodeDescriptions.invalidPassword,
      message: '패스워드가 틀렸습니다.'
    }
  }

  public static get alreadyHasBeenUpdated(): IServiceError {
    return {
      code: 19,
      codeDescription: ApiErrorCodeDescriptions.alreadyHasBeenUpdated,
      message: '이미 업데이트되었습니다.'
    }
  }

  public static get userInfoHasBeenRemoved(): IServiceError {
    return {
      code: 20,
      codeDescription: ApiErrorCodeDescriptions.userInfoHasBeenRemoved,
      message: '유저 정보가 제거되었습니다.'
    }
  }
}

export default ServiceErrorGenerator;