import React from "react"
import IServiceError from "../../farmerLounges/IServiceError"

interface IContentLoadFailProps extends React.HTMLAttributes<HTMLDivElement> {
  errors?: IServiceError[];
}

function ContentLoadFail({
  errors,
  ...rest
}: IContentLoadFailProps) {
  return (
    <div className="text-danger" {...rest}>
      항목을 불러오는데 실패햐엿습니다.
      <ul>
        {errors?.map(item => <li key={item.codeDescription}>{item.message}</li>)}
      </ul>
    </div>
  )
}

export default React.memo(ContentLoadFail)