import { Route } from "react-router-dom";
import EndPoints from "../../EndPoints";
import AuthRoutes from "../../routes/AuthRoutes";
import DashBoardPage from "../DashBoardPage";
import FindShowHostPage from "../hosts/FindShowHostPage";
import SupportCompanyPage from "../hosts/SupportCompanyPage";
import AdProductPage from "../AdProductPage";
import WalletPage from "../WalletPage";
import ProductListPage from "../product/ProductListPage";
import MatchingPage from "../MatchingPage";
import ProductCreatePage from "../product/ProductCreatePage";
import ProductUpdatePage from "../product/ProductUpdatePage";
import MessagePage from "../MessagePage";
import ShowHostDetailPage from "../ShowHostDetailPage";
import RequestQuotePage from "../RequestQuotePage";
import BankAccountPage from "../BankAccountPage";
import ProfileSettingsPage from "../hosts/ProfileSettingsPage";
import AccountPage from "../AccountPage";
import React from "react";

function UserRoutes(props: Readonly<Parameters<typeof AuthRoutes>[0]>) {
  return (
    <AuthRoutes {...props}>
      <Route path={EndPoints.dashboard} Component={DashBoardPage}></Route>
      <Route path={EndPoints.hostList} Component={FindShowHostPage}></Route>
      <Route path={EndPoints.supportCompany} Component={SupportCompanyPage}></Route>
      <Route path={EndPoints.adProduct} Component={AdProductPage}></Route>
      <Route path={EndPoints.wallet} Component={WalletPage}></Route>
      <Route path={EndPoints.matchingManagement} Component={MatchingPage}></Route>
      <Route path={EndPoints.products.list} Component={ProductListPage}></Route>
      <Route path={EndPoints.products.create} Component={ProductCreatePage}></Route>
      <Route path={EndPoints.products.update} Component={ProductUpdatePage}></Route>
      <Route path={EndPoints.message} Component={MessagePage}></Route>
      <Route path={EndPoints.hostDetail} Component={ShowHostDetailPage}></Route>
      <Route path={EndPoints.requestQuote} Component={RequestQuotePage}></Route>
      <Route path={EndPoints.profileSettings} Component={ProfileSettingsPage}></Route>
      <Route path={EndPoints.bankAccount} Component={BankAccountPage}></Route>
      <Route path={EndPoints.account} Component={AccountPage}></Route>
    </AuthRoutes>
  )
}

export default React.memo(UserRoutes);