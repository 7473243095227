import React from "react";
import PrimaryButton from "./buttons/PrimaryButton";
import PrimaryOutlineButton from "./buttons/PrimaryOutlineButton";

export interface IProductManagementItemProps {
  id?: number;
  productName?: string;
  price?: number | string;
  salesTypes?: string;
  discussionContent?: string;
  onEditClick?: React.MouseEventHandler<HTMLButtonElement>;
  onRemoveClick?: React.MouseEventHandler<HTMLButtonElement>;

}

function ProductManagementItem({
  id,
  productName,
  salesTypes,
  discussionContent,
  price,
  onEditClick,
  onRemoveClick,
  ...rest
}: IProductManagementItemProps) {
  return (
    <tr className="product-management-item" {...rest}>
      <td>{id}</td>
      <td>{productName}</td>
      <td>{salesTypes}</td>
      <td>{price}</td>
      <td>{discussionContent}</td>
      <td>
        <PrimaryButton onClick={onEditClick}>수정</PrimaryButton>
        <PrimaryOutlineButton onClick={onRemoveClick}>삭제</PrimaryOutlineButton>
      </td>
    </tr>
  )
}

export default React.memo(ProductManagementItem);