import React from "react";
import './DashBoardPage.scss';
import ShowhostDashBoardPage from "../hosts/ShowhostDashBoardPage/ShowhostDashBoardPage";
import AdvertiserDashBoardPage from "../advertisers/AdvertiserDashBoardSection";
import { useIdentityContext } from "../../contexts/IdentityContext";
import AgencyDashBoardPage from "../agencies/AgencyDashBoardPage";

export interface IDashBoardPageProps extends React.HTMLAttributes<HTMLDivElement> {

}

const DashBoardDict: { [key: string]: React.FunctionComponent<IDashBoardPageProps> } = {
  'farmer': AdvertiserDashBoardPage,
  'showhost': ShowhostDashBoardPage,
  'agency': AgencyDashBoardPage,
}

function DashBoardPage(props: IDashBoardPageProps) {
  const { userInfo } = useIdentityContext();

  const role = userInfo?.role;
  const DashBoard = (role !== undefined ? DashBoardDict[role] : undefined) ?? AdvertiserDashBoardPage;

  return (
    <DashBoard className="dash-board-page" />
  )
}

export default React.memo(DashBoardPage);