import React from "react";
import MainLayout from "../../../layouts/MainLayout";
import RecommandSection from "../../../sections/RecommandSection";
import { ClassNameHelper } from "@loonfactory/common";
import RecommendedShowHostContainer from "../../../containers/recommands/RecommendShowHostContainer";
import ShowHostContainer from "../../../containers/ShowHostContainer";
import EndPoints from "../../../EndPoints";
import { useNavigate } from "react-router-dom";

interface IFindShowHostPageProps extends React.HTMLAttributes<HTMLDivElement> {

}

function FindShowHostPage(props: IFindShowHostPageProps) {
  const navigate = useNavigate();
  const handleSubmit = React.useCallback<React.FormEventHandler<HTMLFormElement>>(evt => {
    evt.stopPropagation();
    evt.preventDefault();

    const formData = new FormData(evt.currentTarget);
    const q = formData.get('q');
    navigate(`${EndPoints.hostList}?q=${q}`);
  }, [navigate]);


  return (
    <MainLayout className={props.className}>
      <RecommandSection
        className={ClassNameHelper.concat('find-showhost-section', 'main-section')}
        onSearchSubmit={handleSubmit}
      >
        <RecommendedShowHostContainer className="mt-4" />
        <ShowHostContainer className="mt-4" />
      </RecommandSection>
    </MainLayout>
  )
}

export default React.memo(FindShowHostPage);