const ApiEndPoints = Object.freeze({
  login: "/v1/auth/login",
  externalLoginGoogle: "/v1/auth/oauth/google",
  getItemList: "/v1/items",
  generatePhoneNumberConfirmationToken: '/v1/auth/verify/sms',
  confirmPhoneNumber: '/v1/auth/verify/sms',
  createUser: '/v1/users',
  getTokenInfo: '/v1/auth/verify',
  items: {
    create: '/v1/items',
    detail: '/v1/items/{id}',
    update: '/v1/items/{id}',
    delete: '/v1/items/{id}',
    updateState: '/v1/items/{id}/state',
    recommand: '/v1/items/{id}/pick'
  },
  rooms: {
    getList: '/v1/rooms',
    get: '/v1/rooms/{id}',
    create: '/v1/rooms',
    createMessage: '/v1/rooms/{id}/messages',
    scheduleCreate: '/v1/rooms/{id}/schedules',
    acceptSchedule: '/v1/rooms/{id}/schedules/{scheduleId}/approve',
    getSchedules: '/v1/rooms/{id}/schedules',
    registerBoradcastInfo: '/v1/rooms/{id}/schedules/{scheduleId}/infos',
    registerBoradcastReview: '/v1/rooms/{id}/schedules/{scheduleId}/reviews',
  },
  emails: {
    send: '/v1/emails'
  },
  users: {
    creat: '/v1/users',
    getList: '/v1/users',
    getUserDetail: '/v1/users/{id}',
    delete: '/v1/users/{id}',
    updateProtfolioState: '/v1/users/{id}/portfolios',
    schedules: '/v1/users/{id}/schedules',
    updateAccount: '/v1/users/{id}/accounts',
    portfolios: '/v1/users/portfolios',
    updateProfileImage: '/v1/users/{id}/profiles',
    updatePassword: '/v1/users/{id}/password',
    recommand: '/v1/users/{id}/pick',
    transactions: '/v1/users/{id}/transactions',
  },
  points: {
    recharge: '/v1/users/{id}/request/deposit',
    refund: '/v1/users/{id}/request/withdraw',
  },
  auth: {
    google: '/v1/auth/oauth/google',
    kakao: '/v1/auth/oauth/kakao',
    findUsername: '/v1/auth/emails',
    resetPassword: '/v1/auth/temporary-password',
  },
  transactions: {
    list: '/v1/transactions',
    updateState: '/v1/transactions/{id}/state',
  },
  wallets: {
    detail: '/v1/wallets/{id}',
  },
  schedules: {
    index: '/v1/schedules',
    detail: '/v1/schedules/{id}',
  },
  posts: {
    getList: '/v1/posts',
    create: '/v1/posts',
    delete: '/v1/posts/{id}',
    edit: '/v1/posts/{id}',
  },
  analytics: {
    totalDeposit: '/v1/transactions/deposit/total',
    monthlyDeposit: '/v1/transactions/deposit/month',
    totalWithdrawt: '/v1/transactions/withdraw/total',
    monthlyWithdraw: '/v1/transactions/withdraw/month',
    totalSend: '/v1/transactions/send/total',
    monthlySend: '/v1/transactions/send/month',
    monthlyMatchedSchedule: '/v1/schedules/match',
    monthlyAcceptedSchedule: '/v1/schedules/accept',
    totalUserCount: '/v1/users/total',
    userCountbyRole: '/v1/users/role',
  }
});

export default ApiEndPoints;