import React from "react";
import PopupContext, { usePopupContextCreate } from "../contexts/PopupContext";
import farmerLoungeService from "../farmerLounges/FarmerLoungeService";
import { CancellationToken } from "@code-rabbits/core";

function PopupProvider(props: React.PropsWithChildren<{}>) {
  const popupContext = usePopupContextCreate();
  const { setPopup } = popupContext;
  React.useEffect(() => {
    (async (cancellationToken?: CancellationToken) => {
      const result = await farmerLoungeService.posts.getListAsync({
        type: 'popup',
      });

      if (cancellationToken?.isCancellationRequested) {
        return;
      }

      if (!result.succeeded) {
        return;
      }


      const post = result.data!.posts.filter(item => item.deleted_at === null)?.first();
      if (post !== undefined) {
        const popup = {
          alt: post.title,
          url: post.files.first().location,
        }

        setPopup('notification', popup);
      }
    })();
  }, [setPopup]);

  return (
    <PopupContext.Provider value={popupContext}>
      {props.children}
    </PopupContext.Provider>
  )
}

export default PopupProvider;