import React, { Suspense } from 'react';

const InstagramOutlineIcon = React.lazy(() => import('./InstagramOutlineIcon'));

function LazyInstagramOutlineIcon(props: React.HTMLAttributes<SVGSVGElement>) {
  return (
    <Suspense fallback={<svg
      width="40"
      height="39"
      viewBox="0 0 40 39"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />}>
      <InstagramOutlineIcon {...props} />
    </Suspense>
  )
}

export default React.memo(LazyInstagramOutlineIcon)