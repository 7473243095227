import React from "react";
import { ClassNameHelper } from "@loonfactory/common";
import "./VerticalLinearLayout.scss";

function VerticalLinearLayout({ className, ...rest }: React.HTMLAttributes<HTMLDivElement>) {
  className = ClassNameHelper.concat("vertical-linear-layout", "layout", className);

  return (
    <div className={className} {...rest} />
  )
}

export default React.memo(VerticalLinearLayout);