import React from "react";
import ManagersLayout from "../../layouts/ManagersLayout";

function PreparationPage() {
  return (
    <ManagersLayout>
      <div className="d-flex border justify-content-center align-items-center h-100">
        <div className="w-75 h-75 fs-1 rounded border-3 d-flex border justify-content-center align-items-center">
          준비중입니다.
        </div>
      </div>
    </ManagersLayout>
  )
}

export default React.memo(PreparationPage);