import React from "react";
import { IFormInputProps } from "./FormInputGroup";
import ImgIcon from "../../../icons/ImgIcon";
import Thumbnail from "../../Thumbnail";
import { ClassNameHelper } from "@loonfactory/common";

const EmptyImage = React.memo(() => {
  return (
    <div className="image-input-placeholder">
      <ImgIcon fill="none" />
      <div>
        <div>이곳을 클릭하여</div>
        <div>사진을 <span>업로드</span>하세요!</div>
      </div>
    </div>
  )
})

interface IImageInputProps extends IFormInputProps {
  location?: string;
}

function ImageInput({
  required,
  label,
  type,
  placeholder,
  location,
  className,
  readOnly,
  onChange,
  ...rest
}: Readonly<IImageInputProps>) {
  const [file, setFile] = React.useState<File | null>(null);

  const handleClickCapture = React.useCallback<React.MouseEventHandler<HTMLLabelElement>>(evt => {
    if (readOnly) {
      evt.preventDefault();
    }
  }, [readOnly]);

  const handleChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(evt => {
    setFile(evt.currentTarget.files?.item(0) ?? null);
    onChange && onChange(evt);
  }, [onChange]);

  const fileUrl = React.useMemo(() => {
    if (file === null) {
      return null;
    }

    return URL.createObjectURL(file);
  }, [file]);

  return (
    <label className={ClassNameHelper.concat(className, "image-input")} onClickCapture={handleClickCapture}>
      {file === null && location === undefined ? <EmptyImage /> : <Thumbnail src={fileUrl ?? location} />}
      <input type="file"
        accept="image/*"
        style={{ display: 'none' }}
        readOnly={readOnly}
        onChange={handleChange}
        {...rest}
      />
    </label>
  )
}

export default React.memo(ImageInput);