import React from "react";
import { IItemListGetOptions } from "../../farmerLounges/ItemsService";
import IItemResource from "../../farmerLounges/farmerLoungeResources/IItemResource";
import IServiceError from "../../farmerLounges/IServiceError";
import { CancellationToken, CancellationTokenSource } from "@code-rabbits/core";
import Products from "../../components/managemensts/products/Products";
import farmerLoungeService from "../../farmerLounges/FarmerLoungeService";
import ProductTable from "../../components/managemensts/products/ProductTable";
import Paginations from "../../components/Paginations";
import DatasetHelper from "../../lib/DatasetHelper";
import DangerButton from "../../components/buttons/DangerButton";
import { useSearchParams } from "react-router-dom";
import SerachParamsHelper from "../../lib/SerachParamsHelper";
import PrimaryOutlineButton from "../../components/buttons/PrimaryOutlineButton";
import ServiceResult from "../../farmerLounges/ServiceResult";

interface IResultWithId<T> {
  id: number;
  result: ServiceResult<T>;
}

interface IProductsManagerContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  options?: Omit<IItemListGetOptions, 'page' | 'size'>
  onDetailClick?: (evt: React.MouseEvent<HTMLButtonElement>, item: IItemResource) => void;
}

async function requestsHelper<T>(
  ids: number[],
  func: (id: number) => Promise<ServiceResult<T>>
) {
  const results = await Promise.all(ids.map(async id => {
    let result = await func(id);
    return {
      id: id,
      result: result
    };
  }));

  return results;
}

function errorHelper<T>(items: IResultWithId<T>[], message: string, errorMessage: string) {
  const faileds = items.filter(item => !item.result.succeeded);
  let result = !faileds.any();
  const errors = faileds.map(item => ` • ${item.id}: ${item.result.errors.first().message}`).join("\n");
  let resultMessage: string = result ? message : `${errorMessage}\n${errors}`;

  return [result, resultMessage];
}

const ProductsManagerContainer = React.memo(({
  options,
  className,
  onDetailClick,
  ...rest
}: IProductsManagerContainerProps) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const getLocationOffset = React.useCallback(() => {
    try {
      return SerachParamsHelper.getInt(searchParams, 'offset');
    } catch {
      return undefined;
    }
  }, [searchParams]);

  const [selectedProductIds, setSelectedProductIds] = React.useState<IItemResource['_id'][]>([]);
  const [products, setProdcuts] = React.useState<IItemResource[] | null>(null);
  const [errors, setErrors] = React.useState<IServiceError[]>();
  const [offset, setOffset] = React.useState<number>(getLocationOffset() ?? 1);
  const [pagePerCount] = React.useState<number>(20);
  const [totalCount, setTotalCount] = React.useState<number>();

  const updateProductsAsync = React.useCallback(async (cancellationToken?: CancellationToken) => {
    const result = await farmerLoungeService.items.getListAsync({
      ...options,
      page: offset,
      size: pagePerCount,
    });

    if (cancellationToken?.isCancellationRequested) {
      return;
    }

    if (!result.succeeded) {
      setErrors(result.errors);
      return;
    }

    const data = result.data;
    if (!data) {
      alert("데이터가 비어있습니다.")
      return;
    }
    setTotalCount(data.count);
    setProdcuts(data.items);
  }, [offset, options, pagePerCount]);


  React.useEffect(() => {
    const cts = new CancellationTokenSource();
    updateProductsAsync(cts.token);

    return () => {
      cts.cancel();
    }
  }, [updateProductsAsync]);

  React.useEffect(() => {
    setOffset(getLocationOffset() ?? 1);
  }, [getLocationOffset]);

  const handleAcceptClickAsync = React.useCallback(async (evt: React.MouseEvent<HTMLButtonElement>, item: IItemResource) => {
    evt.stopPropagation();
    evt.preventDefault();

    const result = await farmerLoungeService.items.updateStateAsync(item._id, 'approved');
    if (!result.succeeded) {
      alert(result.errors.first().message);
      return;
    }

    alert("변경 성공");

    updateProductsAsync();
  }, [updateProductsAsync])

  const handleRejectClickAsync = React.useCallback(async (evt: React.MouseEvent<HTMLButtonElement>, item: IItemResource) => {
    evt.stopPropagation();
    evt.preventDefault();

    const result = await farmerLoungeService.items.updateStateAsync(item._id, 'approved');
    if (!result.succeeded) {
      alert(result.errors.first().message);
      return;
    }

    alert("변경 성공");

    updateProductsAsync();
  }, [updateProductsAsync])

  const handleCheckedChangeAsync = React.useCallback((evt: React.ChangeEvent<HTMLInputElement>, item: IItemResource) => {
    const set = new Set(selectedProductIds);
    if (evt.currentTarget.checked) {
      set.add(item._id)
    } else {
      set.delete(item._id);
    }

    setSelectedProductIds(Array.from(set));
  }, [selectedProductIds]);

  const handlePaginationIndexClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(evt => {
    const index = DatasetHelper.getInt(evt.currentTarget.dataset, "index");
    searchParams.set('offset', index.toString());
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);


  const handleRecommendClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(async evt => {
    const requests = await requestsHelper(
      selectedProductIds,
      (id) => farmerLoungeService.items.recommandAsync(id, true)
    );

    const [, message] = errorHelper(
      requests,
      "지정된 모든 상품을 추천했습니다.",
      "지정된 상품들중 일부 항목를 추천하는 데에 실패했습니다."
    )

    alert(message);

    updateProductsAsync();
  }, [selectedProductIds, updateProductsAsync]);

  const handleUnrecommendClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(async evt => {
    const requests = await requestsHelper(
      selectedProductIds,
      (id) => farmerLoungeService.items.recommandAsync(id, false)
    );

    const [, message] = errorHelper(
      requests,
      "지정된 모든 상품을 추천 해제했습니다.",
      "지정된 상품들중 일부 항목를 추천 해제하는 데에 실패했습니다."
    )

    alert(message);

    updateProductsAsync();
  }, [selectedProductIds, updateProductsAsync]);

  const handleRemoveClick = React.useCallback(async () => {
    if (!window.confirm("아 작업은 되될리수 없습니다. 정말 수행하시겠습니까?")) {
      return;
    }

    const requests = await requestsHelper(
      selectedProductIds,
      (id) => farmerLoungeService.items.deleteItemAsync(id)
    );

    const [, message] = errorHelper(
      requests,
      "지정된 모든 상품를 제거하였습니다..",
      "지정된 상품들중 일부 삭제에 실패했습니다."
    )

    alert(message);

    updateProductsAsync();
  }, [selectedProductIds, updateProductsAsync]);

  return (
    <div className={className} {...rest}>
      <div className="row justify-content-end mt-3 mb-3">
        <div className="col-12 d-flex justify-content-end">
          <PrimaryOutlineButton className="mx-3" onClick={handleRecommendClick}>추천</PrimaryOutlineButton>
          <PrimaryOutlineButton className="mx-3" onClick={handleUnrecommendClick} >추천 해제</PrimaryOutlineButton>
          <DangerButton className="mx-3" onClick={handleRemoveClick}>제거하기</DangerButton>
        </div>
      </div>
      <div className="row">
        <ProductTable className="col-12">
          <Products
            items={products?.take(pagePerCount)}
            errors={errors}
            onAcceptClick={handleAcceptClickAsync}
            onRejectClick={handleRejectClickAsync}
            onCheckedChange={handleCheckedChangeAsync}
            onDetailClick={onDetailClick}
          />
        </ProductTable>
        <div className="row">
          <div className="col-12 justify-content-center">
            <Paginations
              className="text-center"
              totalCount={totalCount}
              index={offset}
              pagePerCount={pagePerCount}
              onIndexClick={handlePaginationIndexClick}
            />
          </div>
        </div>
      </div>
    </div>
  )
});

export default React.memo(ProductsManagerContainer);