import React from "react";
import IdentityLayout from "../../layouts/IdentityLayout";
import PrimaryOutlineButton from "../../components/buttons/PrimaryOutlineButton";
import IServiceError from "../../farmerLounges/IServiceError";
import ErrorViewer from "../../components/ErrorViewer";
import farmerLoungeService from "../../farmerLounges/FarmerLoungeService";
import ServiceErrorGenerator from "../../ServiceErrorGenerator";
import { Link } from "react-router-dom";
import EndPoints from "../../EndPoints";

function ForgotIdPage() {
  const [usernames, setUsernames] = React.useState<string[]>([]);
  const [errors, setErrors] = React.useState<IServiceError[]>([]);
  const [name, setName] = React.useState<string>();

  const handleSubmit = React.useCallback<React.FormEventHandler<HTMLFormElement>>(async evt => {
    evt.stopPropagation();
    evt.preventDefault();

    const formData = new FormData(evt.currentTarget);
    const name = formData.get('name')?.toString();
    const phoneNumber = formData.get('phone-number')?.toString();
    let errors: IServiceError[] = [];
    if (name === undefined || name.trim() === String.empty) {
      errors.push(ServiceErrorGenerator.nameIsRequired);
    }

    if (phoneNumber === undefined || phoneNumber.trim() === String.empty) {
      errors.push(ServiceErrorGenerator.phoneNumberIsRequired);
    }

    if (errors.any()) {
      setErrors(errors);
      return;
    }

    const result = await farmerLoungeService.auth.findUsernameAsync(name!, phoneNumber!);
    if (!result.succeeded) {
      setErrors(result.errors);
      return;
    }

    setErrors([]);
    setName(name!);
    setUsernames(result.data!);
  }, [])

  return (
    <IdentityLayout className="forgot-password-page">
      <form className="d-grid gap-5 mt-5" method="post" onSubmit={handleSubmit}>
        <div className="d-grid gap-2 mt-5">
          <div className="input-group-wrapper">
            <label htmlFor="name" className="form-label fs-4">이름</label>
            <div className="input-group">
              <input type="text"
                className="form-control"
                name="name"
                id="name"
                required
              />
            </div>
          </div>
          <div className="input-group-wrapper">
            <label htmlFor="phone-number" className="form-label fs-4">전화번호</label>
            <div className="input-group">
              <input type="text"
                className="form-control"
                id="phone-number"
                name="phone-number"
                required
              />
            </div>
          </div>
        </div>
        {errors.any() && <ErrorViewer errors={errors} />}
        <div className="mt-2">
          <PrimaryOutlineButton className="w-100" type="submit">아이디 찾기</PrimaryOutlineButton>
        </div>
      </form>
      {usernames?.any() && (
        <div className="w-100">
          <hr className="w-100 mt-5" />
          <div className="text-primary text-center mt-5 fs-4 h-50">
            {name} 회원님의 아이디는 <br />
            {usernames.join(", ")} 입니다.
          </div>
          <Link className="w-100" to={EndPoints.login}>
            <PrimaryOutlineButton className="w-100 mt-4">로그인하기</PrimaryOutlineButton>
          </Link>
        </div>
      )}
    </IdentityLayout>
  )
}

export default React.memo(ForgotIdPage);