import React from "react";
import ProductManagementItem from "../../components/ProductManagementItem";
import { ClassNameHelper } from "@loonfactory/common";
import HorizontalLinearLayout from "../../layouts/HorizontalLinearLayout";
import IServiceError from "../../farmerLounges/IServiceError";
import { CancellationToken, CancellationTokenSource } from "@code-rabbits/core";
import farmerLoungeService from "../../farmerLounges/FarmerLoungeService";
import { Link, useNavigate } from "react-router-dom";
import EndPoints from "../../EndPoints";
import IItemResource from "../../farmerLounges/farmerLoungeResources/IItemResource";
import { useIdentityContext } from "../../contexts/IdentityContext";
import EmptyContent from "../../components/contents/EmptyContent";

interface IProductItemProps extends IItemResource {
  onEditClick?: React.MouseEventHandler<HTMLButtonElement>;
  onRemoveClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const ProductItem = React.memo((props: IProductItemProps) => {
  const navigate = useNavigate();
  const productId = props._id;
  const onEditClick = props.onEditClick;
  const onRemoveClick = props.onRemoveClick;

  const handleEditClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(evt => {
    navigate(`${EndPoints.products.update}?id=${productId}`)
    onEditClick && onEditClick(evt);
  }, [productId, onEditClick, navigate]);

  const handleRemoveClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(async evt => {
    if (!window.confirm("정말 지우시겠습니까?")) {
      return;
    }

    const request = await farmerLoungeService.items.deleteItemAsync(productId);
    if (!request.succeeded) {
      alert("아이템 제거에 실패했습니다.");
      return;
    }

    alert("아이템 제거 완료했습니다.");
    onRemoveClick && onRemoveClick(evt);
    return;
  }, [productId, onRemoveClick]);


  return (
    <ProductManagementItem
      id={productId}
      price={props.price}
      productName={props.name}
      salesTypes={props.sale_type}
      discussionContent={props.discussion_content}
      onEditClick={handleEditClick}
      onRemoveClick={handleRemoveClick}
    />
  )
});

const ProductManagements = React.memo(() => {
  const { userInfo } = useIdentityContext();
  const [items, setItems] = React.useState<IItemResource[] | null>(null);
  const [errors, setErrors] = React.useState<IServiceError[]>();

  const userId = userInfo?._id;
  const getItemsAsync = React.useCallback(async (token?: CancellationToken) => {
    if (userId === null || userId === undefined) {
      return;
    }

    const response = await farmerLoungeService.items.getListAsync({
      owner_id: userId,
    });

    if (token?.isCancellationRequested) {
      return;
    }

    if (!response.succeeded) {
      setErrors(response.errors!);
      return;
    }

    setItems(response.data!.items);

  }, [userId]);

  const handleRemoveClick = React.useCallback(async () => {
    await getItemsAsync();
  }, [getItemsAsync]);

  React.useEffect(() => {
    let cts = new CancellationTokenSource();
    getItemsAsync(cts.token);

    return () => {
      cts.cancel();
    }
  }, [getItemsAsync]);

  if (errors?.any()) {
    return (
      <div className="text-danger">
        항목을 불러오는데 실패하였습니다.
        <ul>
          {errors.map(item => <li>{item.message}</li>)}
        </ul>
      </div>
    )
  }

  if (items === null) {
    return (
      <div>
        항목을 불러오고 있습니다.
      </div>
    )
  }

  if (!items.any()) {
    return (
      <EmptyContent />
    )
  }

  return (
    <div className="product-management-table-wrapper mt-4">
      <table className="product-management-table">
        <thead>
          <tr>
            <th>번호</th>
            <th>상품명</th>
            <th>판매 채널</th>
            <th>광고 금액</th>
            <th>기타 협의 사항</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {items.map(item => (
            <ProductItem
              key={item._id}
              onRemoveClick={handleRemoveClick}
              {...item}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
})

function ProductManagementContainer({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) {

  return (
    <div className={ClassNameHelper.concat("product-management-wrapper", className)} {...rest}>
      <HorizontalLinearLayout className="justify-content-between fs-3 fw-semibold">
        <div className="title">내 <span className="text-primary">상품목록</span></div>
        <Link to={EndPoints.products.create}>
          <div className="title-option text-primary">+ 추가하기</div>
        </Link>
      </HorizontalLinearLayout>
      <ProductManagements />
    </div>
  )
}

export default React.memo(ProductManagementContainer);