import FormatError from "./FormatError";

class DatasetHelper {
  private static validString(v: FormDataEntryValue | null | undefined, allowWhiteSpace = false): v is string {
    if (typeof v !== "string") {
      return false;
    }

    if (!allowWhiteSpace && String.isUnvalidOrWhiteSpace(v)) {
      return false;
    }

    return true;
  }

  static getString(dataset: DOMStringMap, name: string, errorMessage?: string, allowWhiteSpace = false) {
    let value = dataset[name];
    errorMessage ??= `[${name}]은 유효하지 않은 문자 '${value}'입니다.`;

    if (!DatasetHelper.validString(value, allowWhiteSpace)) {
      throw new FormatError(errorMessage);
    }

    return value;
  }

  static getInt(dataset: DOMStringMap, name: string, errorMessage?: string) {
    let value: string | number | null | undefined = dataset[name];
    errorMessage ??= `[${name}]은 유효하지 않은 숫자 '${value}'입니다.`;

    if (!DatasetHelper.validString(value)) {
      throw new FormatError(errorMessage);
    }

    value = Number.parseInt(value);
    if (Number.isNaN(value)) {
      throw new FormatError(errorMessage);
    }

    return value;
  }
}

export default DatasetHelper;