import React, { Suspense } from 'react';

const EyeIcon = React.lazy(() => import('./EyeIcon'));

function LazyEyeIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <Suspense fallback={<svg width="32" height="20" viewBox="0 0 32 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />}>
      <EyeIcon {...props} />
    </Suspense>
  )
}

export default React.memo(LazyEyeIcon)