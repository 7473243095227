import React from "react";
import PrimaryButton from "../buttons/PrimaryButton";
import DiscussionContentsInputGroup from "./inputs/DiscussionContentsInputGroup";
import ImageInput from "./inputs/ImageInput";
import ProductNameInputGroup from "./inputs/ProductNameInputGroup";
import ProductPriceInputGroup from "./inputs/ProductPriceInputGroup";
import SalesTypeInputGroup from "./inputs/SalesTypeInputGroup";
import SpecialRequestInputGroup from "./inputs/SpecialRequestInputGroup";

export interface IProductFormProps extends React.HTMLAttributes<HTMLFormElement> {
  productId?: number;
  productName?: string;
  price?: number;
  discussionContent?: string;
  saleType?: string;
  thumbnailUrl?: string;
  detailUrl?: string;
  request?: string;
  readOnly?: boolean;
}

function ProductForm({
  productId,
  productName,
  price,
  discussionContent,
  saleType,
  thumbnailUrl,
  detailUrl,
  request,
  readOnly,
  ...rest
}: Readonly<IProductFormProps>) {

  return (
    <form {...rest}>
      <input type="hidden" readOnly name="id" value={productId} />
      <ProductNameInputGroup name="productName" defaultValue={productName} readOnly={readOnly} />
      <ProductPriceInputGroup name="productPrice" defaultValue={price} readOnly={readOnly} />
      <DiscussionContentsInputGroup name="discussionContents" className="etc" defaultValue={discussionContent} readOnly={readOnly} />
      <SpecialRequestInputGroup className="request" defaultValue={request} readOnly={readOnly} />
      <SalesTypeInputGroup name="saleType" defaultValue={saleType} readOnly={readOnly} />
      <div className="thumbnail">
        <div className="title required">썸네일</div>
        <ImageInput location={thumbnailUrl} name="thumbnail" readOnly={readOnly} />
      </div>
      <div className="detail">
        <div className="title required">상세페이지</div>
        <ImageInput name="detail" location={detailUrl} readOnly={readOnly} />
      </div>
      {!readOnly && (
        <div className="buttons">
          <PrimaryButton type="submit">등록하기</PrimaryButton>
        </div>
      )}
    </form>
  )
}

export default React.memo(ProductForm);