import React from "react";
import MainLayout from "../../layouts/MainLayout";
import DepositWithdrawalWalletSectionSection from "../../sections/DepositWithdrawalWalletSectionSection";

interface IAdvertiserWalletPageProps extends React.HTMLAttributes<HTMLDivElement> {

}


function AdvertiserWalletPage(props: IAdvertiserWalletPageProps) {
  return (
    <MainLayout className={props.className}>
      <DepositWithdrawalWalletSectionSection className="main-section" />
    </MainLayout>
  )
}

export default React.memo(AdvertiserWalletPage);