import React from "react";
import PrimaryButton from "../buttons/PrimaryButton";
import HorizontalLinearLayout from "../../layouts/HorizontalLinearLayout";
import ProfileIcon from "../ProfileIcon";
import RoleType from "../../farmerLounges/RoleType";
import RoleManager from "../../lib/RoleManager";
import { useIdentityContext } from "../../contexts/IdentityContext";


interface IMessageRoomTitleProps extends React.HTMLAttributes<HTMLDivElement> {
  profileUrl?: string;
  name?: string;
  role?: RoleType;
  onProductClick?: React.MouseEventHandler<HTMLButtonElement>;
  onScheduleCreateClick?: React.MouseEventHandler<HTMLButtonElement>;
  onScheduleCompletedClick?: React.MouseEventHandler<HTMLButtonElement>;
  onReportClick?: React.MouseEventHandler<HTMLButtonElement>;
}

function MessageRoomTitle({
  profileUrl,
  name,
  role,
  onProductClick,
  onScheduleCreateClick,
  onScheduleCompletedClick,
  onReportClick,
  ...rest
}: IMessageRoomTitleProps) {
  const { userInfo } = useIdentityContext();
  const userRole = userInfo?.role;

  return (
    <HorizontalLinearLayout className="message-rome-title" {...rest}>
      <div className="profile">
        <div className="profile-icon-wrapper">
          <ProfileIcon url={profileUrl} className="profile-img" />
        </div>
        <div>
          <div>{name}</div>
          <div>{role && RoleManager.getName(role)}</div>
        </div>
      </div>
      <div className="options">
        {(userRole === "farmer" || userRole === "agency") && <PrimaryButton onClick={onProductClick}>제품 정보 전송</PrimaryButton>}
        {userRole === "farmer" && <PrimaryButton onClick={onScheduleCreateClick}>계약 생성</PrimaryButton>}
        {userRole === "showhost" && <PrimaryButton onClick={onScheduleCompletedClick}>방송 완료</PrimaryButton>}
        <PrimaryButton onClick={onReportClick}>신고하기</PrimaryButton>
      </div>
    </HorizontalLinearLayout>
  )
}

export default React.memo(MessageRoomTitle);