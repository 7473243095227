import { ClassNameHelper } from "@loonfactory/common";
import React from "react";

export interface IFormInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

function FormInput({
  required,
  name,
  label,
  type,
  placeholder,
  maxLength,
  onChange,
  defaultValue,
  readOnly,
  disabled,
  ...rest
}: IFormInputProps) {
  return (
    <div className="input-group" {...rest}>
      <label className="form-label fs-4">
        <span className={ClassNameHelper.concat(required && "required")}>{label}</span>
        <input type={type}
          name={name}
          className="form-control"
          onChange={onChange}
          required={required}
          placeholder={placeholder}
          defaultValue={defaultValue}
          maxLength={maxLength}
          readOnly={readOnly}
          disabled={disabled}
        />
      </label>
    </div>
  )
}

export default React.memo(FormInput);