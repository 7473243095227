import React from "react";
import INavItemProps from "./INavItemProps";
import NavItem from "./NavItem";
import LazyNotepadIcon from "../../../icons/LazyNotepadIcon";

function MatchingManagementNavItem(props: Omit<INavItemProps, 'children'>) {
  return (
    <NavItem {...props}>
      <LazyNotepadIcon /> 매칭 관리
    </NavItem>
  );
}

export default React.memo(MatchingManagementNavItem);