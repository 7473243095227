import React from "react";
import IUserDetailResource from "../farmerLounges/farmerLoungeResources/IUserDetailResource";
import { CancellationTokenSource } from "@code-rabbits/core";
import { challengeUserDetailAsync } from "../challenges";

const useUserDetail = (id: IUserDetailResource['_id']) => {
  const [user, setUser] = React.useState<IUserDetailResource>();

  React.useEffect(() => {
    const cts = new CancellationTokenSource();
    challengeUserDetailAsync(id, cts.token).then(res => {
      if (cts.isCancellationRequested) {
        return;
      }

      if (res.errors.any()) {
        console.error(res.errors);
        return;
      }

      setUser(res.data!);
    });

    return () => {
      cts.cancel();
    }
  }, [id]);

  return user;
}

export {
  useUserDetail,
}