import React, { Suspense } from 'react';

const CaretRightIcon = React.lazy(() => import('./CaretRightIcon'));

function LazyCaretRightIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <Suspense fallback={<svg
      width="5"
      height="6"
      viewBox="0 0 5 6"
      fill="#4E9A5E"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />}>
      <CaretRightIcon {...props} />
    </Suspense>
  )
}

export default React.memo(LazyCaretRightIcon)