import React, { useCallback } from "react";
import { IFormInputProps } from "./FormInputGroup";
import HorizontalLinearLayout from "../../../layouts/HorizontalLinearLayout";
import { ClassNameHelper } from "@loonfactory/common";

interface IPortfolioURLWrapperProps extends IFormInputProps {
  defaultValue?: string[];
}

function PortfolioURLWrapper({
  onChange,
  required,
  defaultValue = [],
  ...rest
}: IPortfolioURLWrapperProps) {
  const [inputFields, setInputFields] = React.useState<string[]>(["", ""]);

  React.useEffect(() => {
    if (defaultValue.length > 0) {
      setInputFields(defaultValue);
    }
  }, [defaultValue]);

  const handleAddInput = useCallback(() => {
    setInputFields(prevFields => [...prevFields, ""]);
  }, []);

  return (
    <div className="portfolio-url-wrapper" {...rest}>
      <HorizontalLinearLayout className="justify-content-between fw-semibold fs-4">
        <div className={ClassNameHelper.concat(required && "required")}>포트폴리오 영상 등록</div>
        <div className="text-primary fs-5" onClick={handleAddInput} style={{ cursor: 'pointer' }}>+ 추가하기</div>
      </HorizontalLinearLayout>
      {inputFields.map((link, index) => (
        <input
          key={`youtube-url-${index}`}
          name="youtube-url"
          type="text"
          placeholder="동영상 URL을 입력해주세요."
          className="mt-2 form-control"
          required={required}
          onChange={onChange}
          defaultValue={link}
        />
      ))}
    </div>
  )
}

export default React.memo(PortfolioURLWrapper);
