import { ClassNameHelper } from "@loonfactory/common";
import React from "react";

interface IImageSliderItemProps extends React.HTMLAttributes<HTMLDivElement> {
  imgUrl?: string;
}

function ImageSliderItem({ imgUrl, children, className, style, ...rest }: IImageSliderItemProps) {
  if (imgUrl) {
    style = {
      ...style,
      backgroundImage: imgUrl && `url(${imgUrl})`
    }
  }

  return (
    <div
      className={ClassNameHelper.concat("lf-image-slider-item", className)}
      style={style}
      {...rest}
    >
      <div className="lf-image-slider-item-content">
        {children}
      </div>
    </div>
  )
}
export default React.memo(ImageSliderItem);