import React, { Suspense } from 'react';

const RightArrowIcon = React.lazy(() => import('./RightArrowIcon'));

function LasyRightArrowIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <Suspense fallback={<svg width="11" height="19" viewBox="0 0 11 19"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />}>
      <RightArrowIcon {...props} />
    </Suspense>
  )
}

export default React.memo(LasyRightArrowIcon)