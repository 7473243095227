import React from "react";
import ProductItem from "../components/ProductItemWithProfile";
import ContentTitleWrapper from "../components/contents/ContentTitleWrapper";
import ContentTitleOption from "../components/contents/ContentTitleOption";
import ProductContentSliderContainer from "../containers/products/ProductContentSliderContainer";
import { useIdentityContext } from "../contexts/IdentityContext";
import { IItemListGetOptions } from "../farmerLounges/ItemsService";
import ContentWrapper from "./contents/ContentWrapper";
import { Link, useNavigate } from "react-router-dom";
import EndPoints from "../EndPoints";
import IItemResource from "../farmerLounges/farmerLoungeResources/IItemResource";

interface IMyProductItemProps extends IItemResource {
}

const MyProductItem = React.memo((props: IMyProductItemProps) => {
  const navigate = useNavigate();

  const handleClick = React.useCallback(() => {
    navigate(`${EndPoints.products.update}?id=${props._id}`);
  }, [props._id, navigate]);

  return (
    <ProductItem
      thumbnailUrl={props.images?.first()?.location}
      description={props.name}
      onClick={handleClick}
    />
  )
});

const myProductItemRenderer = (item: IItemResource) => {
  return (
    <MyProductItem {...item} />
  )
}

interface IMyProductListProps extends React.HTMLAttributes<HTMLDivElement> {
  listTitle?: React.ReactNode;
}

function MyProductList({
  listTitle,
  ...rest
}: IMyProductListProps) {
  const { userInfo } = useIdentityContext();

  const productOption: IItemListGetOptions = React.useMemo(() => {
    return {
      owner_id: userInfo?._id
    }
  }, [userInfo?._id])

  return (
    <ContentWrapper {...rest}>
      <ContentTitleWrapper>
        {listTitle ?? <div>내 상품 목록</div>}
        <ContentTitleOption>
          <Link className="text-primary" to={EndPoints.products.list}>더보기 +</Link>
        </ContentTitleOption>
      </ContentTitleWrapper>
      <ProductContentSliderContainer invisibleButton
        className="product-slider mt-2"
        renderer={myProductItemRenderer}
        options={productOption}
      />
    </ContentWrapper>
  )
}

export default React.memo(MyProductList);