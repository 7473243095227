import React from "react";
import { ClassNameHelper } from "@loonfactory/common";
import PrimarySpeechBubble from "./PrimarySpeechBubble";

function numberWithCommas(value: number) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export interface IMatcheCountProps extends React.HTMLAttributes<HTMLDivElement> {
  text?: string;
  value?: number;
}

function MatcheCount({
  value,
  text,
  className,
  ...rest
}: IMatcheCountProps) {
  return (
    <PrimarySpeechBubble className={ClassNameHelper.concat(className, 'wallet')} {...rest}>
      <p>
        <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.1461 0.5354C8.43911 0.5354 4.61243 4.36208 4.61243 9.06907C4.61243 13.6862 8.22352 17.4231 12.9305 17.5848C13.0742 17.5668 13.218 17.5668 13.3258 17.5848H13.4156H13.4515C18.0507 17.4231 21.6618 13.6862 21.6798 9.06907C21.6798 4.36208 17.8531 0.5354 13.1461 0.5354Z" fill="#4E9A5E" />
          <path d="M22.2743 22.3617C17.2619 19.0201 9.08757 19.0201 4.03923 22.3617C1.75759 23.8888 0.5 25.9548 0.5 28.1646C0.5 30.3743 1.75759 32.4224 4.02126 33.9315C6.53645 35.6203 9.84213 36.4647 13.1478 36.4647C16.4534 36.4647 19.7591 35.6203 22.2743 33.9315C24.5379 32.4045 25.7955 30.3564 25.7955 28.1286C25.7776 25.9189 24.5379 23.8708 22.2743 22.3617Z" fill="#4E9A5E" />
          <path d="M32.8892 10.1257C33.1767 13.6111 30.6974 16.6651 27.266 17.0784C27.248 17.0784 27.248 17.0784 27.2301 17.0784H27.1762C27.0684 17.0784 26.9606 17.0784 26.8707 17.1143C25.1281 17.2041 23.5291 16.6472 22.3254 15.6231C24.1759 13.9704 25.2359 11.4911 25.0203 8.79629C24.8945 7.34107 24.3915 6.01162 23.6369 4.87978C24.3196 4.53844 25.1101 4.32285 25.9186 4.25099C29.4398 3.94557 32.5838 6.56855 32.8892 10.1257Z" fill="#4E9A5E" />
          <path d="M36.4804 26.7479C36.3367 28.4906 35.2228 29.9997 33.3544 31.0238C31.5578 32.0119 29.2942 32.479 27.0485 32.4251C28.342 31.2573 29.0965 29.8021 29.2403 28.2571C29.4199 26.0293 28.3599 23.8914 26.24 22.1847C25.0363 21.2325 23.635 20.4779 22.1079 19.921C26.0783 18.7712 31.0728 19.5437 34.1449 22.023C35.7977 23.3524 36.6421 25.0232 36.4804 26.7479Z" fill="#4E9A5E" />
        </svg>
        {text ?? "매칭 건수"}
      </p>
      <p className="fw-semibold">{numberWithCommas(value ?? 0)} 건</p>
    </PrimarySpeechBubble>
  )
}

export default React.memo(MatcheCount);