import React from "react";
import LasyLeftIcon from "./LasyLeftIcon";
import LasyRightIcon from "./LasyRightIcon";
import { ClassNameHelper } from "@loonfactory/common";
import "./ImageSlier.scss";

export interface IImageSliderProps extends React.HTMLAttributes<HTMLDivElement> {
  height?: string | number;
}

function ImageSlider({
  className,
  style,
  height,
  children,
  ...rest
}: Readonly<IImageSliderProps>) {

  const divRef = React.useRef<HTMLDivElement>(null);

  if (typeof height === "number") {
    height = `${height}px`;
  }

  if (height) {
    style = {
      ...style,
      paddingTop: height,
    }
  }

  const rightScoll = React.useCallback(() => {
    const current = divRef.current;
    if (current === null) {
      return;
    }

    let p = Math.floor((current.scrollLeft / current.clientWidth)) * current.clientWidth + current.clientWidth;
    if (p >= current.scrollWidth) {
      p = 0;
    }

    current.scrollTo({
      left: p,
      behavior: 'smooth'
    })
  }, []);

  const leftScoll = React.useCallback(() => {
    const current = divRef.current;
    if (current === null) {
      return;
    }

    let p = Math.floor((current.scrollLeft / current.clientWidth)) * current.clientWidth - current.clientWidth;
    if (p < 0) {
      p = current.scrollWidth - current.clientWidth
    }

    current.scrollTo({
      left: p,
      behavior: 'smooth'
    })
  }, []);


  React.useEffect(() => {
    const id = setInterval(rightScoll, 10 * 1000);
    return () => {
      clearInterval(id);
    }
  }, [rightScoll]);


  return (
    <div className={ClassNameHelper.concat("lf-image-slider", className)} style={style} {...rest}>
      <div className="lf-image-slider-content">
        <div ref={divRef} className="lf-image-slider-bg">
          {children}
        </div>
      </div>
      <div className="button left-button" onClick={leftScoll}>
        <LasyLeftIcon />
      </div>
      <div className="button right-button" onClick={rightScoll}>
        <LasyRightIcon />
      </div>
    </div>
  )
}

export default React.memo(ImageSlider);