import React from "react";
import FormInput from "./FormInputGroup";

function DiscussionContentsInputGroup({
  ...rest
}: React.InputHTMLAttributes<HTMLInputElement>) {
  return (
    <FormInput
      label="기타 협의 내용"
      name="discussionContents"
      type="text"
      placeholder="기타 협의 내용을 입력해 주세요."
      {...rest}
    />
  )
}

export default React.memo(DiscussionContentsInputGroup);