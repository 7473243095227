import React from "react";
import IdentityLayout from "../../layouts/IdentityLayout";
import PrimaryOutlineButton from "../../components/buttons/PrimaryOutlineButton";
import IServiceError from "../../farmerLounges/IServiceError";
import ErrorViewer from "../../components/ErrorViewer";
import farmerLoungeService from "../../farmerLounges/FarmerLoungeService";
import ServiceErrorGenerator from "../../ServiceErrorGenerator";
import { useNavigate } from "react-router-dom";
import EndPoints from "../../EndPoints";

function ForgotPasswordPage() {
  const navigate = useNavigate();
  const [errors, setErrors] = React.useState<IServiceError[]>([]);

  const handleSubmit = React.useCallback<React.FormEventHandler<HTMLFormElement>>(async evt => {
    evt.stopPropagation();
    evt.preventDefault();

    const formData = new FormData(evt.currentTarget);
    const email = formData.get('email')?.toString();
    if (email === undefined || email.trim() === String.empty) {
      setErrors([ServiceErrorGenerator.emailIsRequired]);
      return;
    }

    const result = await farmerLoungeService.auth.resetPasswordAsync(email);
    if (!result.succeeded) {
      setErrors(result.errors);
      return;
    }

    navigate(EndPoints.identities.forgotPasswordResult);
  }, [navigate])

  return (
    <IdentityLayout className="forgot-password-page">
      <form className="d-grid gap-5 mt-5" method="post" onSubmit={handleSubmit}>
        <div className="d-grid gap-2 mt-5">
          <div className="input-group-wrapper">
            <label htmlFor="email" className="form-label fs-4">이메일</label>
            <div className="input-group">
              <input type="email"
                className="form-control"
                id="email"
                name="email"
                required
              />
            </div>
          </div>
          <div className="text-danger question-mark">
            아이디(이메일)을 입력하면, 해당 이메일로 임시비밀번호가 전달됩니다.
            로그인 후에 마이페이지에서 수정하시면 됩니다.
          </div>
        </div>
        {errors.any() && <ErrorViewer errors={errors} />}
        <div className="mt-5">
          <PrimaryOutlineButton className="w-100" type="submit">비밀번호 찾기</PrimaryOutlineButton>
        </div>
      </form>
    </IdentityLayout>
  )
}

export default React.memo(ForgotPasswordPage);