import React from "react";
import ContentLoadFail from "../../contents/ContentLoadFail";
import LoadingContent from "../../contents/LoadingContent";
import EmptyContent from "../../contents/EmptyContent";
import IServiceError from "../../../farmerLounges/IServiceError";
import Schedule from "./Schedule";
import IScheduleResource from "../../../farmerLounges/farmerLoungeResources/IScheduleResource";


interface IProductsProps {
  items?: IScheduleResource[] | null;
  errors?: IServiceError[]
  onAcceptClick?: (evt: React.MouseEvent<HTMLButtonElement>, item: IScheduleResource) => void;
  onCheckedChange?: (evt: React.ChangeEvent<HTMLInputElement>, item: IScheduleResource) => void;
  onDetailClick?: (evt: React.MouseEvent<HTMLButtonElement>, item: IScheduleResource) => void;
  onRejectClick?: (evt: React.MouseEvent<HTMLButtonElement>, item: IScheduleResource) => void;
}

const Schedules = React.memo((props: IProductsProps) => {
  if (props.errors?.any()) {
    return (
      <tbody>
        <tr>
          <td colSpan={8}>
            <ContentLoadFail errors={props.errors} />
          </td>
        </tr>
      </tbody>
    )
  }

  if (props.items === null) {
    return (
      <tbody>
        <tr>
          <td colSpan={8}>
            <LoadingContent />
          </td>
        </tr>
      </tbody>
    )
  }

  if (!props.items?.any()) {
    return (
      <tbody>
        <tr>
          <td colSpan={8}>
            <EmptyContent />
          </td>
        </tr>
      </tbody>
    )
  }

  return (
    <tbody>
      {props.items?.map(item => {
        return (
          <Schedule
            key={item._id}
            item={item}
          />
        )
      })}
    </tbody>
  )
});

export default Schedules;