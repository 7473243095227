import { ClassNameHelper } from "@loonfactory/common";
import React from "react";
import LazyGoogleIcon from "../../icons/LazyGoogleIcon";
import ApiEndPoints from "../../farmerLounges/ApiEndPoints";

function GoogleLoginButton({ className, ...rest }: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <form method="post" action={`${process.env.REACT_APP_API_ORIGIN}${ApiEndPoints.auth.google}`}>
      <button type="submit" className={ClassNameHelper.concat("btn", "btn-google", className)} {...rest}>
        <LazyGoogleIcon className="login-icon" />
        구글로 로그인하기
      </button>
    </form>
  )
}

export default React.memo(GoogleLoginButton);