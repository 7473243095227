import React from "react";
import FormInput from "./FormInputGroup";

function SpecialRequestInputGroup({
  ...rest
}: React.InputHTMLAttributes<HTMLInputElement>) {
  return (
    <FormInput
      label="특별 요청 사항"
      name="request"
      type="text"
      placeholder="특별 요청 사항을 입력해 주세요."
      {...rest}
    />
  )
}

export default React.memo(SpecialRequestInputGroup);