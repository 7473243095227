import React, { Suspense } from 'react';

const GoogleIcon = React.lazy(() => import('./GoogleIcon'));

function LazyGoogleIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <Suspense fallback={<svg width="48" height="48" viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />}>
      <GoogleIcon {...props} />
    </Suspense>
  )
}

export default React.memo(LazyGoogleIcon)