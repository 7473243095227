
import React from "react";
import ManagersLayout from "../../../layouts/ManagersLayout";
import SchedulesManagerContainer from "../../../containers/managers/SchedulesManagerContainer";
import { IGetScheduleListOptions } from "../../../farmerLounges/farmerLoungeResources/schedules/IGetScheduleListOptions";

interface IScheduleManagmentPageProps extends React.HTMLAttributes<HTMLDivElement> {

}

const options: IGetScheduleListOptions = {
  state: 'accepted'
}

function ScheduleContractPage(props: IScheduleManagmentPageProps) {
  return (
    <ManagersLayout className={props.className}>
      <SchedulesManagerContainer className="container" options={options} />
    </ManagersLayout>
  )
}

export default React.memo(ScheduleContractPage);