import React, { Suspense } from 'react';

const GearIcon = React.lazy(() => import('./GearIcon'));

function LazyGearIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <Suspense fallback={<svg width="32" height="32" viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />}>
      <GearIcon {...props} />
    </Suspense>
  )
}

export default React.memo(LazyGearIcon)