import { ClassNameHelper } from "@loonfactory/common";
import React from "react";
import { usePopupContext } from "../../contexts/PopupContext";
import ExitIcon from "../../icons/ExitIcon";

interface INotificationPopupProps extends React.HTMLAttributes<HTMLDivElement> {
  url: string;
  alt: string;
  onAcceptClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const style: React.CSSProperties = {
  maxHeight: '90vh',
}

function NotificationPopup({
  className,
  children,
  url,
  alt,
  onAcceptClick,
  ...rest
}: INotificationPopupProps) {
  const { close } = usePopupContext();

  const handleExitClick = React.useCallback(() => {
    close();
  }, [close]);


  return (
    <div className={ClassNameHelper.concat("popup", className)} {...rest}>
      <img src={url} alt={alt} style={style} />
      <div className="popup-content">
        {children}
        <ExitIcon className="btn close-btn" onClick={handleExitClick} />
      </div>
    </div>
  )
}

export default React.memo(NotificationPopup);