import React from "react";
import ManagersLayout from "../../../../layouts/ManagersLayout";
import BankAccountForm from "../../../../components/forms/BankAccountForm";
import farmerLoungeService from "../../../../farmerLounges/FarmerLoungeService";
import { useNavigate, useSearchParams } from "react-router-dom";
import IUserDetailResource from "../../../../farmerLounges/farmerLoungeResources/IUserDetailResource";
import SerachParamsHelper from "../../../../lib/SerachParamsHelper";
import { CancellationToken, CancellationTokenSource } from "@code-rabbits/core";
import IServiceError from "../../../../farmerLounges/IServiceError";
import './UserEditPage.scss';

function UserEditPage(props: React.HTMLAttributes<HTMLDivElement>) {
  const navigate = useNavigate();
  const [searchParma] = useSearchParams();
  const [userInfo, setUserInfo] = React.useState<IUserDetailResource>();
  const [errors, setErrors] = React.useState<IServiceError[]>();
  const userId = SerachParamsHelper.getInt(searchParma, 'id');
  const role = userInfo?.role;

  const handleCancelClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(async evt => {
    navigate(-1);
  }, [navigate]);

  const handleSubmit = React.useCallback<React.FormEventHandler<HTMLFormElement>>(async evt => {
    evt.stopPropagation();
    evt.preventDefault();

    if (userId === undefined) return;

    const formData = new FormData(evt.currentTarget);
    const address = formData.get('address') as string;
    const accountHolder = formData.get('accountHolder') as string;
    const accountNumber = formData.get('accountNumber') as string;
    const bank = formData.get('bankName') as string;
    const businessRegistration = formData.get('businessRegistration') as string;
    const bankbookImage = (evt.currentTarget.querySelector('input[name=bankbook]') as HTMLInputElement).files?.item(0);
    const business = (evt.currentTarget.querySelector('input[name=business]') as HTMLInputElement).files?.item(0);
    const result = await farmerLoungeService.users.updateAccountAsync({
      userId: userId,
      account_holder: accountHolder,
      account_number: accountNumber,
      address: address,
      bank: bank,
      bankbook_image: bankbookImage,
      business_registration_number: businessRegistration,
      business_registration_image: business,
    });

    if (!result.succeeded) {
      alert("변경에 실패했습니다.");
      console.warn(result.errors?.first().message);
      return;
    }

    alert("변경에 성공하였습니다.");
    navigate(-1);
  }, [userId, navigate]);

  React.useEffect(() => {
    const cts = new CancellationTokenSource();
    (async (cancellationToken: CancellationToken) => {
      const result = await farmerLoungeService.users.getDetailAsync(userId);

      if (cancellationToken?.isCancellationRequested) {
        return;
      }

      if (!result.succeeded) {
        setErrors(result.errors);
        return;
      }

      setUserInfo(result.data!);
    })(cts.token);

    return () => {
      cts.cancel();
    }
  }, [userId]);


  return (
    <ManagersLayout {...props}>
      <main className="user-edit-section">
        <BankAccountForm
          className="container border border-3 p-4"
          onSubmit={handleSubmit}
          managerName={userInfo?.name}
          phone={userInfo?.phone}
          address={userInfo?.address}
          role={role}
          businessRegistrationNumber={userInfo?.account.business_registration_number}
          businessRegistrationFileName={userInfo?.account.business_registration_image?.first()?.originalname}
          backbookImageName={userInfo?.account.bankbook_image?.first()?.originalname}
          bankname={userInfo?.account.bank}
          accountHolder={userInfo?.account.account_holder}
          accountNumber={userInfo?.account.account_number}
          onCancelClick={handleCancelClick}
        />
        {errors?.any() &&
          <ul>
            {errors?.map(item => <li>{item.message}</li>)}
          </ul>
        }
      </main>
    </ManagersLayout>
  )
}

export default React.memo(UserEditPage);