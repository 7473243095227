import React from "react";
import AdvertiserNav from "./AdvertiserNav";
import Logo from "../Logo";
import { useIdentityContext } from "../../contexts/IdentityContext";
import ShowhostNav from "./ShowhostNav";
import AgencyNav from "./AgencyNav";
import { Link } from "react-router-dom";
import EndPoints from "../../EndPoints";

interface IMainNavProps {

}

const NavDict: { [key: string]: React.FunctionComponent<IMainNavProps> } = {
  'farmer': AdvertiserNav,
  'agency': AgencyNav,
  'showhost': ShowhostNav,
}

function MainNav(props: IMainNavProps) {
  const { userInfo } = useIdentityContext();

  const role = userInfo?.role;
  const Nav = (role !== undefined ? NavDict[role] : undefined) ?? (() => null);

  return (
    <div className="nav-region">
      <Link to={EndPoints.home} className="logo-wrapper">
        <Logo />
      </Link>
      <Nav />
    </div>
  )
}

export default React.memo(MainNav);