import ApiEndPoints from "../ApiEndPoints";
import FarmerLoungesBaseApiService from "../FarmerLoungesBaseApiService";
import IServiceResponse from "../IServiceResponse";
import ServiceResult from "../ServiceResult";

export interface IAmount {
  amount: number;
}

export interface ICount {
  count: number;
}

export interface IMonthlyAmount {
  year: number;
  month: number;
  amount: number;
}

export interface IMonthlyCount {
  year: number;
  month: number;
  count: number;
}

export interface IUserCountByRole {
  admin: number;
  manager: number;
  showhost: number;
  farmer: number;
  agency: number;
}

class AnalyticsService extends FarmerLoungesBaseApiService {
  getTotalDepositAsync = async () => {
    const url = this.buildUrl(ApiEndPoints.analytics.totalDeposit);
    const response = await this.getAsync(url);

    const result = (await response.json()) as IServiceResponse<IAmount>;
    if (result.code === 2000) return ServiceResult.succeeded(result.result);

    return this.globalErrorHandler(result);
  }

  getMonthlyDepositAsync = async () => {
    const url = this.buildUrl(ApiEndPoints.analytics.monthlyDeposit);
    const response = await this.getAsync(url);

    const result = (await response.json()) as IServiceResponse<IMonthlyAmount[]>;
    if (result.code === 2000) return ServiceResult.succeeded(result.result);

    return this.globalErrorHandler(result);
  }

  getTotalSendtAsync = async () => {
    const url = this.buildUrl(ApiEndPoints.analytics.totalSend);
    const response = await this.getAsync(url);

    const result = (await response.json()) as IServiceResponse<IAmount>;
    if (result.code === 2000) return ServiceResult.succeeded(result.result);

    return this.globalErrorHandler(result);
  }

  getMonthlySendAsync = async () => {
    const url = this.buildUrl(ApiEndPoints.analytics.monthlySend);
    const response = await this.getAsync(url);

    const result = (await response.json()) as IServiceResponse<IMonthlyAmount[]>;
    if (result.code === 2000) return ServiceResult.succeeded(result.result);

    return this.globalErrorHandler(result);
  }

  getTotalWithdrawAsync = async () => {
    const url = this.buildUrl(ApiEndPoints.analytics.totalWithdrawt);
    const response = await this.getAsync(url);

    const result = (await response.json()) as IServiceResponse<IAmount>;
    if (result.code === 2000) return ServiceResult.succeeded(result.result);

    return this.globalErrorHandler(result);
  }

  getMonthlyWithdrawAsync = async () => {
    const url = this.buildUrl(ApiEndPoints.analytics.monthlyWithdraw);
    const response = await this.getAsync(url);

    const result = (await response.json()) as IServiceResponse<IMonthlyAmount[]>;
    if (result.code === 2000) return ServiceResult.succeeded(result.result);

    return this.globalErrorHandler(result);
  }

  getMonthlyMatchedScheduleAsync = async () => {
    const url = this.buildUrl(ApiEndPoints.analytics.monthlyMatchedSchedule);
    const response = await this.getAsync(url);

    const result = (await response.json()) as IServiceResponse<IMonthlyCount[]>;
    if (result.code === 2000) return ServiceResult.succeeded(result.result);

    return this.globalErrorHandler(result);
  }

  getMonthlyAcceptedScheduleAsync = async () => {
    const url = this.buildUrl(ApiEndPoints.analytics.monthlyAcceptedSchedule);
    const response = await this.getAsync(url);

    const result = (await response.json()) as IServiceResponse<IMonthlyCount[]>;
    if (result.code === 2000) return ServiceResult.succeeded(result.result);

    return this.globalErrorHandler(result);
  }

  getTotalUserCountAsync = async () => {
    const url = this.buildUrl(ApiEndPoints.analytics.totalUserCount);
    const response = await this.getAsync(url);

    const result = (await response.json()) as IServiceResponse<ICount>;
    if (result.code === 2000) return ServiceResult.succeeded(result.result);

    return this.globalErrorHandler(result);
  }

  getUserCountbyRoleAsync = async () => {
    const url = this.buildUrl(ApiEndPoints.analytics.userCountbyRole);
    const response = await this.getAsync(url);

    const result = (await response.json()) as IServiceResponse<IUserCountByRole>;
    if (result.code === 2000) return ServiceResult.succeeded(result.result);

    return this.globalErrorHandler(result);
  }
}

export default AnalyticsService;