import React from "react";
import { Line } from 'react-chartjs-2';
import ManagersLayout from "../../../layouts/ManagersLayout";
import { useMonthlyAcceptedSchedule, useMonthlyMatchedSchedule, } from "../../../hooks/analytics";
import Heler from "../../../Helper";

function StatistySchedulePage(props: React.HTMLAttributes<HTMLDivElement>) {
  const months = Heler.getYearMonths();
  const acceptedSchedules = useMonthlyAcceptedSchedule();
  const matchedSchedules = useMonthlyMatchedSchedule();

  const lables = months.map(Heler.toDateChartLabelFormat);

  const matcheds = matchedSchedules?.reduce((acc, current) => {
    return {
      ...acc,
      [Heler.toDateChartLabelFormat(new Date(current.year, current.month, 1))]: current.count
    }
  }, {} as { [key: string]: number }) ?? {};

  const accepteds = acceptedSchedules?.reduce((acc, current) => {
    return {
      ...acc,
      [Heler.toDateChartLabelFormat(new Date(current.year, current.month, 1))]: current.count
    }
  }, {} as { [key: string]: number }) ?? {};

  return (
    <ManagersLayout {...props}>
      <main className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-12 title">매칭 통계</div>
            <Line className="col-12" height={75} data={{
              labels: lables,
              datasets: [{
                label: "매칭수",
                data: lables.map(item => matcheds[item] ?? 0),
              }],
            }} options={{
              scales: {
                y: {
                  beginAtZero: true,
                }
              }
            }} />
          </div>
          <div className="row">
            <div className="col-12 title">방송 예약 통계</div>
            <Line className="col-12" height={75} data={{
              labels: lables,
              datasets: [{
                label: "방송 예약 수",
                data: lables.map(item => accepteds[item] ?? 0),
              }],
            }} options={{
              scales: {
                y: {
                  beginAtZero: true,
                }
              }
            }} />
          </div>
        </div>
      </main>
    </ManagersLayout>
  )
}

export default React.memo(StatistySchedulePage);