import React from "react";
import RoleType from "../../farmerLounges/RoleType";
import RoleManager from "../../lib/RoleManager";
import DefaultPopup from "./DefaultPopup";
import IAbstractPopup from "./IAbstractPopup";

export interface IRefundPopupProps {
  name: string;
  role: RoleType;
}

export interface IRefundPopup extends IAbstractPopup<IRefundPopupProps> {
  name: 'refund'
}

function RefundPopup({
  name,
  role
}: IRefundPopupProps) {
  return (
    <DefaultPopup>
      <div className="text-center d-flex flex-column justify-content-center align-item-cetner h-100">
        <p>
          안녕하세요. {name} {RoleManager.getName(role)}님!<br />
          출금 요청 주셔서 감사합니다.<br />
        </p>
        <br />
        <p>
          포인트 출금 확인에 다소 시간이 소요될 수 있습니다.<br />
          빠른 출금을 원하시면, 고객센터에 연락바랍니다.<br />
        </p>
      </div>
    </DefaultPopup >
  )
}

export default React.memo(RefundPopup);