import React from "react";
import ManagersLayout from "../../../layouts/ManagersLayout";
import UserManagerContaier from "../../../containers/managers/UserManagerContaier";

function TotalUserPage(props: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <ManagersLayout {...props}>
      <UserManagerContaier className="container" />
    </ManagersLayout>
  )
}

export default React.memo(TotalUserPage);