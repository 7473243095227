import React from "react";
import { Route } from "react-router-dom";
import MatcheRoutes from "../../routes/MatcheRoutes";
import EndPoints from "../../EndPoints";
import ManagerDashboardPage from "../managers/ManagerDashboardPage";
import TotalUserPage from "../managers/users/TotalUserPage";
import ShowhostUserPage from "../managers/users/ShowhostUserPage";
import AdvertiserUserPage from "../managers/users/AdvertiserUserPage";
import AgencyUserPage from "../managers/users/AgencyUserPage";
import ShowhostManagementPage from "../managers/managements/showhosts/ShowhostManagementPage";
import AdvertiserAdManagementPage from "../managers/managements/advertisers/AdvertiserAdManagementPage";
import AgencyAdManagementPage from "../managers/managements/agencies/AgencyAdManagementPage";
import PreparationPage from "../managers/PreparationPage";
import RechargeManagementPage from "../managers/points/RechargeManagementPage";
import RefundManagementPage from "../managers/points/RefundManagementPage";
import BannerManagementPage from "../managers/events/banners/BannerManagementPage";
import BannerCreatePage from "../managers/events/banners/BannerCreatePage";
import BannerEditPage from "../managers/events/banners/BannerEditPage";
import MainVisualManagementPage from "../managers/events/mainVisuals/MainVisualManagementPage";
import MainVisualCreatePage from "../managers/events/mainVisuals/MainVisualCreatePage";
import MainVisualEditPage from "../managers/events/mainVisuals/MainVisualEditPage";
import SendEmailPage from "../managers/events/SendEmailPage";
import ScheduleCancelPage from "../managers/schedules/ScheduleCancelPage";
import ScheduleContractPage from "../managers/schedules/ScheduleContractPage";
import ScheduleCompletedPage from "../managers/schedules/ScheduleCompletedPage";
import ScheduleManagmentPage from "../managers/schedules/ScheduleManagmentPage";
import PopupManagementPage from "../managers/events/popups/PopupManagementPage";
import PopupCreatePage from "../managers/events/popups/PopupCreatePage";
import PopupEditPage from "../managers/events/popups/PopupEditPage";
import UserEditPage from "../managers/users/UserEditPage/UserEditPage";
import ShowhostManagementDetailPage from "../managers/managements/showhosts/ShowhostManagementDetailPage";
import AbstractProductDetailManagementPage from "../managers/managements/AbstractProductDetailManagementPage";
import PointMainPage from "../managers/points/PointMainPage";
import StatistySchedulePage from "../managers/statistics/StatistySchedulePage";
import ManagerChatPage from "../managers/messages/ManagerChatPage";

function ManagerRoutes(props: Readonly<Parameters<typeof MatcheRoutes>[0]>) {
  return (
    <MatcheRoutes {...props}>
      <Route path={EndPoints.manager.index} Component={ManagerDashboardPage} ></Route>

      <Route path={EndPoints.manager.users.total} Component={TotalUserPage} ></Route>
      <Route path={EndPoints.manager.users.showhost} Component={ShowhostUserPage} ></Route>
      <Route path={EndPoints.manager.users.advertiser} Component={AdvertiserUserPage} ></Route>
      <Route path={EndPoints.manager.users.agency} Component={AgencyUserPage} ></Route>
      <Route path={EndPoints.manager.users.edit} Component={UserEditPage} ></Route>

      <Route path={EndPoints.manager.managements.showhost} Component={ShowhostManagementPage} ></Route>
      <Route path={EndPoints.manager.managements.showhostDetail} Component={ShowhostManagementDetailPage} ></Route>
      <Route path={EndPoints.manager.managements.advertiserAd} Component={AdvertiserAdManagementPage} ></Route>
      <Route path={EndPoints.manager.managements.advertiserAdDetail} Component={AbstractProductDetailManagementPage} ></Route>
      <Route path={EndPoints.manager.managements.agencyAd} Component={AgencyAdManagementPage} ></Route>

      <Route path={EndPoints.manager.events.banner.index} Component={BannerManagementPage} ></Route>
      <Route path={EndPoints.manager.events.banner.create} Component={BannerCreatePage} ></Route>
      <Route path={EndPoints.manager.events.banner.edit} Component={BannerEditPage} ></Route>
      <Route path={EndPoints.manager.events.mainVisual.index} Component={MainVisualManagementPage} ></Route>
      <Route path={EndPoints.manager.events.mainVisual.create} Component={MainVisualCreatePage} ></Route>
      <Route path={EndPoints.manager.events.mainVisual.edit} Component={MainVisualEditPage} ></Route>
      <Route path={EndPoints.manager.events.popup.index} Component={PopupManagementPage} ></Route>
      <Route path={EndPoints.manager.events.popup.create} Component={PopupCreatePage} ></Route>
      <Route path={EndPoints.manager.events.popup.edit} Component={PopupEditPage} ></Route>
      <Route path={EndPoints.manager.events.email} Component={SendEmailPage} ></Route>

      <Route path={EndPoints.manager.messages.chat} Component={ManagerChatPage} ></Route>

      <Route path={EndPoints.manager.schedule.cancel} Component={ScheduleCancelPage} ></Route>
      <Route path={EndPoints.manager.schedule.contract} Component={ScheduleContractPage} ></Route>
      <Route path={EndPoints.manager.schedule.done} Component={ScheduleCompletedPage} ></Route>
      <Route path={EndPoints.manager.schedule.index} Component={ScheduleManagmentPage} ></Route>

      <Route path={EndPoints.manager.points.index} Component={PointMainPage} ></Route>
      <Route path={EndPoints.manager.points.recharge} Component={RechargeManagementPage} ></Route>
      <Route path={EndPoints.manager.points.refund} Component={RefundManagementPage} ></Route>
      <Route path={EndPoints.manager.points.settlement} Component={PreparationPage} ></Route>
      <Route path={EndPoints.manager.points.cashReceipt} Component={PreparationPage} ></Route>

      <Route path={EndPoints.manager.statistics.index} Component={ManagerDashboardPage} ></Route>
      <Route path={EndPoints.manager.statistics.schedule} Component={StatistySchedulePage} ></Route>
      <Route path={EndPoints.manager.statistics.point} Component={PointMainPage} ></Route>

      <Route path={EndPoints.manager.users.advertiser} Component={PreparationPage} ></Route>
      <Route path={EndPoints.manager.users.agency} Component={PreparationPage} ></Route>
      <Route path={EndPoints.manager.users.showhost} Component={PreparationPage} ></Route>
      <Route path={EndPoints.manager.users.total} Component={PreparationPage} ></Route>
    </MatcheRoutes>
  )
}

export default React.memo(ManagerRoutes);