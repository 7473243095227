const ApiErrorCodeDescriptions = Object.freeze({
  birthdayIsRequired: 'birthdayIsRequired',
  roleIsRequired: 'roleIsRequired',
  emailIsRequired: 'emailIsRequired',
  nameIsRequired: 'nameIsRequired',
  usernameNotFound: 'usernameNotFound',
  usernameIsRequired: 'usernameIsRequired',
  unknownError: 'unknownError',
  passwordIsRequired: 'passwordIsRequired',
  passwordsDoNotMatch: 'passwordsDoNotMatch',
  phoneNumberIsRequired: 'phoneNumberIsRequired',
  invalidRoleType: 'invalidRoleType',
  invalidPhoneNumber: 'invalidPhoneNumber',
  invalidPhoneNumberFormat: 'invalidPhoneNumberFormat',
  verifyPhoneNumberTokenIsRequired: 'verifyPhoneNumberTokenIsRequired',
  tokenIsRequired: 'tokenIsRequired',
  invalidState: 'invalid state',
  loginRequired: 'loginRequired',
  roomNotFound: 'usernameNotFound',
  aligoError: 'aligoError', // 11
  passwordNotMatch: 'passwordNotMatch',
  withdrawalAccountIsRequired: 'withdrawalAccountIsRequired',
  notEnoughpoints: 'notEnoughpoints',
  duplicateTransactionRequests: 'duplicateTransactionRequests',
  forbidden: 'forbidden',
  duplicateScheduleRequests: 'duplicateScheduleRequests',
  invalidPassword: 'invalidPassword',
  sendingEmailAuthenticationFailed: 'sendingEmailAuthenticationFailed',
  alreadyHasBeenUpdated: 'alreadyHasBeenUpdated',
  userInfoHasBeenRemoved: 'userInfoHasBeenRemoved',
});

export default ApiErrorCodeDescriptions;