import React from "react";
import BackAccountSection from "../sections/BankAccountSection";
import farmerLoungeService from "../farmerLounges/FarmerLoungeService";
import { useIdentityContext } from "../contexts/IdentityContext";
import MainLayout from "../layouts/MainLayout";
import BankAccountForm from "../components/forms/BankAccountForm";
import { useNavigate } from "react-router-dom";

export interface IBankAccountPageProps extends React.HTMLAttributes<HTMLDivElement> {

}


function BankAccountPage({
  className,
  ...rest
}: IBankAccountPageProps) {
  const navigate = useNavigate();
  const { userInfo, setUserInfo } = useIdentityContext();
  const userId = userInfo?._id;
  const role = userInfo?.role;

  const handleCancelClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(async evt => {
    navigate(-1);
  }, [navigate]);

  const handleSubmit = React.useCallback<React.FormEventHandler<HTMLFormElement>>(async evt => {
    evt.stopPropagation();
    evt.preventDefault();

    const formData = new FormData(evt.currentTarget);
    const address = formData.get('address') as string;
    const accountHolder = formData.get('accountHolder') as string;
    const accountNumber = formData.get('accountNumber') as string;
    const bank = formData.get('bankName') as string;
    const businessRegistration = formData.get('businessRegistration') as string;
    const bankbookImage = (evt.currentTarget.querySelector('input[name=bankbook]') as HTMLInputElement).files?.item(0);
    const business = (evt.currentTarget.querySelector('input[name=business]') as HTMLInputElement).files?.item(0);
    const result = await farmerLoungeService.users.updateAccountAsync({
      userId: userId!,
      account_holder: accountHolder,
      account_number: accountNumber,
      address: address,
      bank: bank,
      bankbook_image: bankbookImage,
      business_registration_number: businessRegistration,
      business_registration_image: business,
    });

    if (!result.succeeded) {
      alert("변경에 실패했습니다.");
      console.warn(result.errors?.first().message);
      return;
    }

    alert("변경에 성공하였습니다.");
    const userInfoResult = await farmerLoungeService.users.getDetailAsync(userId!);
    if (userInfoResult.succeeded) {
      setUserInfo(userInfoResult.data);
    }
  }, [userId, setUserInfo]);

  return (
    <MainLayout className={className} {...rest}>
      <BackAccountSection className="main-section">
        <div className="content-region">
          <div className="fs-2">
          </div>
          <BankAccountForm
            className="container"
            onSubmit={handleSubmit}
            managerName={userInfo?.name}
            phone={userInfo?.phone}
            address={userInfo?.address}
            role={role}
            businessRegistrationNumber={userInfo?.account.business_registration_number}
            businessRegistrationFileName={userInfo?.account.business_registration_image?.first()?.originalname}
            backbookImageName={userInfo?.account.bankbook_image?.first()?.originalname}
            bankname={userInfo?.account.bank}
            accountHolder={userInfo?.account.account_holder}
            accountNumber={userInfo?.account.account_number}
            onCancelClick={handleCancelClick}
          />
        </div>
      </BackAccountSection>
    </MainLayout>
  )
}



export default React.memo(BankAccountPage);