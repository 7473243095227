import React from "react";
import Message, { IMessageProps } from "./Message";
import IScheduleDetailResource from "../../farmerLounges/farmerLoungeResources/IScheduleDetailResource";
import PrimaryButton from "../buttons/PrimaryButton";
import IScheduleCompletedSubmitProperties from "./IScheduleCompletedSubmitProperties";
import FormDataHelper from "../../lib/FormDataHelper";

interface IScheduleCreateMessageProps extends Omit<IMessageProps, 'children'> {
  schedule?: IScheduleDetailResource;
  onScheduleCompletedSubmit?: (evt: React.FormEvent<HTMLFormElement>, properties: IScheduleCompletedSubmitProperties) => void;
}

function ScheduleCompletedMessage({
  schedule,
  onScheduleCompletedSubmit,
  ...rest
}: IScheduleCreateMessageProps) {
  const handleSubmit = React.useCallback<React.FormEventHandler<HTMLFormElement>>(evt => {
    evt.preventDefault();
    evt.stopPropagation();

    try {
      const formdata = new FormData(evt.currentTarget);
      const viewer = FormDataHelper.getInt(formdata, 'viewer');
      const revenue = FormDataHelper.getInt(formdata, 'revenue');
      const quantity = FormDataHelper.getInt(formdata, 'quantity');
      const channel = FormDataHelper.getString(formdata, 'channel');
      const broadcastLink = FormDataHelper.getString(formdata, 'broadcast-link');

      onScheduleCompletedSubmit && onScheduleCompletedSubmit(evt, {
        broadcastLink: broadcastLink,
        channel: channel,
        revenue: revenue,
        quantity: quantity,
        viewers: viewer,
        schedule: schedule,
      })
    }
    catch (e) {
      if (e instanceof Error) {
        alert(e.message);
        return;
      }
    }
  }, [schedule, onScheduleCompletedSubmit]);
  
  return (
    <Message {...rest}>
      <form className="schedule-form contaier" onSubmit={handleSubmit}>
        <div className="row justify-content-center">
          {schedule?.items?.first().name} 라이브커머스 방송을 진행하셨습니다.
        </div>
        <div className="row">
          <label className="col-5" >시청자(명)</label>
          <input className="col-7" name="viewer" type="text" />
        </div>
        <div className="row">
          <label className="col-5" >판매금액</label>
          <input className="col-7" name="revenue" type="number" />
        </div>
        <div className="row">
          <label className="col-5" >수량</label>
          <input className="col-7" name="quantity" type="number" />
        </div>
        <div className="row">
          <label className="col-5" >진행채널</label>
          <input className="col-7" name="channel" type="text" />
        </div>
        <div className="row">
          <label className="col-5" >방송링크</label>
          <input className="col-7" name="broadcast-link" type="text" />
        </div>
        <div className="row justify-content-end mt-4">
          <PrimaryButton className="col-4" type="submit">정산 요청</PrimaryButton>
        </div>
      </form>
    </Message>
  )
}

export default React.memo(ScheduleCompletedMessage);