import React from "react";
import { ClassNameHelper } from "@loonfactory/common";
import LazyChartIcon from "../icons/LazyChartIcon";

function numberWithCommas(value: number) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

interface IWalletProps extends React.HTMLAttributes<HTMLDivElement> {
  value?: number;
}

function AccumulatedWallet({
  value,
  className,
  ...rest
}: IWalletProps) {
  return (
    <div className={ClassNameHelper.concat(className, 'wallet')} {...rest}>
      <p>
        <LazyChartIcon className="chart-icon"/>
        누적 수익 포인트
      </p>
      <p className="fw-semibold">{numberWithCommas(value ?? 0)}P</p>
    </div>
  )
}

export default React.memo(AccumulatedWallet);