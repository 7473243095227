import React, { Suspense } from 'react';

const GridIcon = React.lazy(() => import('./GridIcon'));

function LazyGridIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <Suspense fallback={<svg width="32" height="32" viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />}>
      <GridIcon {...props} />
    </Suspense>
  )
}

export default React.memo(LazyGridIcon)