import React from "react";
import PrimaryButton from "../../buttons/PrimaryButton";
import { useIdentityContext } from "../../../contexts/IdentityContext";
import ExitIcon from "../../../icons/ExitIcon";
import { usePopupContext } from "../../../contexts/PopupContext";
import IAbstractPopup from "../IAbstractPopup";
import IItemResource from "../../../farmerLounges/farmerLoungeResources/IItemResource";
import Heler from "../../../Helper";
import './ProductPopup.scss';
import IUserDetailResource from "../../../farmerLounges/farmerLoungeResources/IUserDetailResource";
import { useNavigate } from "react-router-dom";
import farmerLoungeService from "../../../farmerLounges/FarmerLoungeService";
import EndPoints from "../../../EndPoints";


export interface IProductPopupProps {
  product: IItemResource;
  prodcutOnwer?: Pick<IUserDetailResource, '_id' | 'role'>;
}

export interface IProductPopup extends IAbstractPopup<IProductPopupProps> {
  name: 'product',
}

function ProductPopup({
  product,
  prodcutOnwer,
}: IProductPopupProps) {
  const { userInfo } = useIdentityContext();
  const { close } = usePopupContext();
  const navigate = useNavigate()

  const handleExitClick = React.useCallback(() => {
    close();
  }, [close]);

  const handleAcceptClick = React.useCallback(async () => {
    if (prodcutOnwer?._id !== undefined) {
      const result = await farmerLoungeService.rooms.createAsync(prodcutOnwer?._id);
      if (!result.succeeded) {
        alert(result.errors.first().message);
        return;
      }

      navigate(`${EndPoints.message}?id=${result.data?._id}`);
    }
    close();
  }, [navigate, prodcutOnwer?._id, close]);

  return (
    <div className='popup product-popup'>
      <div className="content">
        <img src={product.details?.first()?.location} alt={product.name} />
        <ExitIcon className="btn close-btn" onClick={handleExitClick} />
      </div>
      <div className="info-wrapper">
        <div className="info">
          <div className="d-flex">
            <p className="property w-50"><span>광고 금액</span>{Heler.numberWithCommas(Number.parseInt(product.price ?? '0'))}원</p>
            <p className="property w-50"><span>판매 채널</span>{product.sale_type}</p>
          </div>
          <div>
            <p className="property"><span>기타 요청사항</span>{product.discussion_content}</p>
          </div>
          <div>
            <p className="property"><span>특별 요청사항</span>{product.request}</p>
          </div>
        </div>{
          (prodcutOnwer !== undefined && userInfo?.role !== prodcutOnwer?.role) && (
            <div>
              <PrimaryButton
                className="accept-btn"
                onClick={handleAcceptClick}
              >
                신청하기
              </PrimaryButton>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default React.memo(ProductPopup);