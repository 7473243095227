import { CancellationToken, CancellationTokenSource } from "@code-rabbits/core";
import IdentityContext, { useIdentityContextCreate } from "../contexts/IdentityContext";
import React from "react";
import farmerLoungeService from "../farmerLounges/FarmerLoungeService";
import { useLocation } from "react-router-dom";

function IdentityProvider(props: React.PropsWithChildren<{}>) {
  const location = useLocation();
  const identity = useIdentityContextCreate();
  const { accessToken, setTokenInfo, setUserInfo, setAccessToken } = identity;

  const updateIdentity = React.useCallback(async (cancellationToken: CancellationToken) => {
    const nextAccessToken = farmerLoungeService.accessToken;
    if (nextAccessToken === accessToken) {
      return;
    }

    if (nextAccessToken === null) {
      setAccessToken(null);
      setTokenInfo(null);
      setUserInfo(null);
      return;
    }

    const tokenInfoResult = await farmerLoungeService.auth.getTokenInfoAsync();
    if (cancellationToken.isCancellationRequested) {
      return;
    }

    if (!tokenInfoResult.succeeded) {
      return;
    }

    const userInfoResult = await farmerLoungeService.users.getDetailAsync(tokenInfoResult.data!.id);
    if (cancellationToken.isCancellationRequested || !userInfoResult.succeeded) {
      return;
    }

    setAccessToken(nextAccessToken);
    setTokenInfo(tokenInfoResult.data);
    setUserInfo(userInfoResult.data);
  }, [accessToken, setAccessToken, setTokenInfo, setUserInfo]);

  React.useEffect(() => {
    let cts = new CancellationTokenSource();
    updateIdentity(cts.token);

    return () => {
      cts.cancel();
    }
  }, [location, updateIdentity]);

  return (
    <IdentityContext.Provider value={identity}>
      {props.children}
    </IdentityContext.Provider>
  )
}

export default IdentityProvider;