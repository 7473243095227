import IPaginationOptions from "../../IPaginationOptions";
import ServiceErrorGenerator from "../../ServiceErrorGenerator";
import ApiEndPoints from "../ApiEndPoints";
import ApiErrorCodeDescriptions from "../ApiErrorCodeDescriptions";
import FarmerLoungesBaseApiService from "../FarmerLoungesBaseApiService";
import IServiceResponse from "../IServiceResponse";
import ServiceResult from "../ServiceResult";
import IItemResource from "../farmerLoungeResources/IItemResource";
import IMessageResource from "../farmerLoungeResources/IMessageResource";
import IScheduleDetailListResource from "../farmerLoungeResources/IScheduleDetailListResource";
import IScheduleResource from "../farmerLoungeResources/IScheduleResource";
import IRoomListResource from "../farmerLoungeResources/rooms/IRoomListResource";
import IRoomResource from "../farmerLoungeResources/rooms/IRoomResource";
import IBoardcaseReviewResult from "./IBoardcaseReviewResult";

interface IGetListOptions {
  query?: string;
  page?: number;
  size?: number;
}

interface ICraeteRoomResult {
  created_at: string;
  _id: null;
  service_provider_id: number;
  client_id: number;
}

interface ICreateMessageProperties {
  content: string;
  items?: IItemResource[];
  files?: FileList | File[];
}

interface IExpectedInfo {
  viewers?: number;
  expected_duration?: number;
  expected_sold_amount?: number;
  platform?: string;
}

interface IScheduleCreateProperties {
  fee?: number;
  items?: IItemResource[];
  scheduled_at?: string;
  expected_infos?: IExpectedInfo[];
}

interface IBoradcastInfomationProperties {
  broadcast_started_at?: string;
  broadcast_ended_at?: string;
  broadcasted_infos?: {
    link?: string;
    viewers?: number;
    platform?: string;
    sold_amount?: number;
    sold_quantity?: number;
  }[];
  thumbnail?: File;
}

interface IBoradcastReviewProperties {
  score?: number;
  content?: string;
}

class RoomService extends FarmerLoungesBaseApiService {
  getRoomAsync = async (roomId: number): Promise<ServiceResult<IRoomResource>> => {
    const url = this.buildUrl(ApiEndPoints.rooms.get.replaceAll("{id}", roomId.toString()));
    const response = await this.getAsync(url);

    const result = (await response.json()) as IServiceResponse<IRoomResource>;

    if (result.code !== 2000) {
      switch (result.code) {
        case 4040:
          return ServiceResult.failed({
            code: 4040,
            codeDescription: ApiErrorCodeDescriptions.roomNotFound,
            message: `Room[${roomId}]를 찾을수 없습니다.`
          });
      }
    }

    return ServiceResult.succeeded(result.result);
  }

  getListAsync = async (options?: IGetListOptions): Promise<ServiceResult<IRoomListResource>> => {
    const url = this.buildUrl(ApiEndPoints.rooms.getList, { ...options });
    const response = await this.getAsync(url);

    const result = (await response.json()) as IServiceResponse<IRoomListResource>;

    if (result.code !== 2000) {
      switch (result.code) {
        case 4040:
          return ServiceResult.failed({
            code: 4040,
            codeDescription: ApiErrorCodeDescriptions.usernameNotFound,
            message: `"${options?.query}"를 찾을수 없습니다.`
          });
      }
    }

    return ServiceResult.succeeded(result.result);
  }

  createAsync = async (otherId: number): Promise<ServiceResult<ICraeteRoomResult>> => {
    const url = this.buildUrl(ApiEndPoints.rooms.create);
    const response = await this.postJsonAsync(url, { other_id: otherId });

    const result = (await response.json()) as IServiceResponse<ICraeteRoomResult>;

    if (result.code !== 2000) {
      switch (result.code) {
        case 4040:
          return ServiceResult.failed({
            code: 4040,
            codeDescription: ApiErrorCodeDescriptions.usernameNotFound,
            message: `"${otherId}"를 찾을수 없습니다.`
          });
        default:
          return this.globalErrorHandler(result);
      }
    }

    return ServiceResult.succeeded(result.result);
  }

  createMessageAsync = async (roomId: number, properties: ICreateMessageProperties) => {
    const url = this.buildUrl(ApiEndPoints.rooms.createMessage.replaceAll('{id}', roomId.toString()));

    let response: Response;
    if (properties.files !== undefined) {
      const formData = new FormData();

      (Object.keys(properties) as (keyof ICreateMessageProperties)[]).forEach(key => {
        [properties[key]].flat().forEach(v => {
          if (typeof v === "object" && !(v instanceof File)) {
            v = JSON.stringify(v);
          }
          formData.append(key, v as string | File);
        });
      })
      response = await this.postAsync(url, formData);
    } else {
      response = await this.postJsonAsync(url, properties);
    }

    const result = (await response.json()) as IServiceResponse<IMessageResource>;

    return ServiceResult.succeeded(result.result)
  }

  createSchedulesAsync = async (roomId: number, properties: IScheduleCreateProperties) => {
    const url = this.buildUrl(ApiEndPoints.rooms.scheduleCreate.replaceAll('{id}', roomId.toString()));

    let response: Response = await this.postJsonAsync(url, properties);;
    const result = (await response.json()) as IServiceResponse<IMessageResource>;

    return ServiceResult.succeeded(result.result)
  }

  acceptScheduleAsync = async (roomId: number, scheduleId: number) => {
    const url = this.buildUrl(
      ApiEndPoints.rooms.acceptSchedule
        .replaceAll('{id}', roomId.toString())
        .replaceAll('{scheduleId}', scheduleId.toString())
    );

    let response: Response = await this.putAsync(url);;
    const result = (await response.json()) as IServiceResponse<IScheduleResource>;
    if (result.code !== 2000) {
      switch (result.code) {
        case 4000:
          if (result.detail?.message === "포인트가 부족합니다.") {
            return ServiceResult.failed([ServiceErrorGenerator.notEnoughpoints])
          } else if (result.detail?.message === "이미 처리된 스케줄입니다.") {
            return ServiceResult.failed([ServiceErrorGenerator.duplicateScheduleRequests])
          }
          return this.globalErrorHandler(result);
        case 4030:
          return ServiceResult.failed([ServiceErrorGenerator.forbidden])
        default:
          return this.globalErrorHandler(result);
      }
    }

    return ServiceResult.succeeded(result.result)
  }

  getSchedulesAsync = async (roomId: number, options?: IPaginationOptions) => {
    const url = this.buildUrl(
      ApiEndPoints.rooms.getSchedules.replaceAll('{id}', roomId.toString()), { ...options }
    );

    let response: Response = await this.getAsync(url);;
    const result = (await response.json()) as IServiceResponse<IScheduleDetailListResource>;
    if (result.code !== 2000) {
      switch (result.code) {
        default:
          return this.globalErrorHandler(result);
      }
    }

    return ServiceResult.succeeded(result.result)
  }

  registerBoradcastInfoAsync = async (roomId: number, scheduleId: number, properties: IBoradcastInfomationProperties) => {
    const url = this.buildUrl(
      ApiEndPoints.rooms.registerBoradcastInfo
        .replaceAll('{id}', roomId.toString())
        .replaceAll('{scheduleId}', scheduleId.toString())
    );

    let response: Response = await this.postJsonAsync(url, properties);;
    const result = (await response.json()) as IServiceResponse<IScheduleDetailListResource>;
    if (result.code !== 2000) {
      switch (result.code) {
        default:
          return this.globalErrorHandler(result);
      }
    }

    return ServiceResult.succeeded(result.result)
  }

  registerBoradcastReviewAsync = async (roomId: number, scheduleId: number, properties: IBoradcastReviewProperties) => {
    const url = this.buildUrl(
      ApiEndPoints.rooms.registerBoradcastReview
        .replaceAll('{id}', roomId.toString())
        .replaceAll('{scheduleId}', scheduleId.toString())
    );

    let response: Response = await this.postJsonAsync(url, properties);;
    const result = (await response.json()) as IServiceResponse<IBoardcaseReviewResult>;
    if (result.code !== 2000) {
      switch (result.code) {
        default:
          return this.globalErrorHandler(result);
      }
    }

    return ServiceResult.succeeded(result.result)
  }

}

export default RoomService;