import React from "react";
import { ImageSliderItem } from "../components/ImageSlier";
import ImageSlider, { IImageSliderProps } from "../components/ImageSlier/ImageSlider";
import { CancellationToken, CancellationTokenSource } from "@code-rabbits/core";
import IPostResource from "../farmerLounges/posts/IPostResource";
import farmerLoungeService from "../farmerLounges/FarmerLoungeService";
import IServiceError from "../farmerLounges/IServiceError";

// @TODO: 백엔드에서 삭제된 항목 제외 지원후 재수정 필요
function MainVisualContaier(props: Readonly<IImageSliderProps>) {
  const [posts, setPosts] = React.useState<IPostResource[] | null>(null);

  // eslint-disable-next-line
  const [errors, setErrors] = React.useState<IServiceError[]>();

  // eslint-disable-next-line
  const [totalCount, setTotalCount] = React.useState<number>();

  const updatePostsAsync = React.useCallback(async (cancellationToken?: CancellationToken) => {
    const result = await farmerLoungeService.posts.getListAsync({
      type: 'notice',
    });

    if (cancellationToken?.isCancellationRequested) {
      return;
    }

    if (!result.succeeded) {
      setErrors(result.errors);
      return;
    }

    const data = result.data;
    if (!data) {
      alert("데이터가 비어있습니다.")
      return;
    }

    setTotalCount(data.count);
    setPosts(data.posts.filter(item => item.deleted_at === null));
  }, []);

  React.useEffect(() => {
    const cts = new CancellationTokenSource();
    updatePostsAsync(cts.token);

    return () => {
      cts.cancel();
    }
  }, [updatePostsAsync]);

  return (
    <ImageSlider {...props}>
      {posts?.map(item => {
        return (
          <ImageSliderItem key={item._id} imgUrl={item.files?.first().location} />
        )
      })}
    </ImageSlider>
  )
}

export default React.memo(MainVisualContaier);