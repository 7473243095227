import React from "react";
import LazyCircleExclamationIcon from "../../icons/LazyCircleExclamationIcon";
import farmerLoungeService from "../../farmerLounges/FarmerLoungeService";
import { useIdentityContext } from "../../contexts/IdentityContext";
import ITransactionResource from "../../farmerLounges/transactions/ITransactionResource";
import IWalletInfoResource from "../../farmerLounges/wallets/IWalletInfoResource";
import IWalletResource from "../../farmerLounges/farmerLoungeResources/IWalletResource";
import { CancellationToken, CancellationTokenSource } from "@code-rabbits/core";

const EmptyBody = React.memo(() => {
  return (
    <tr className="empty-body">
      <td rowSpan={4} colSpan={4} className="pt-4">
        <LazyCircleExclamationIcon />
        <p className="mt-2">입출금 내역이 없습니다.</p>
      </td>
    </tr>
  )
})

function PointRechargeHistoryContaier({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) {

  const { userInfo } = useIdentityContext();
  const [tansactions, setTansactions] = React.useState<ITransactionResource[]>();
  // eslint-disable-next-line
  const [walletDict, setWalletDict] = React.useState<{ [id: number]: IWalletInfoResource }>({});
  const userId = userInfo?._id;

  React.useEffect(() => {
    if (userId === undefined) {
      return;
    }
    const cts = new CancellationTokenSource();

    (async (cancellationToken: CancellationToken) => {
      const request = await farmerLoungeService.users.getTransactionsAsync(userId, 'deposit');
      if (cancellationToken.isCancellationRequested) {
        return;
      }

      if (!request.succeeded) {
        alert("입금 내역을 불러오는 실패했습니다.")
        return;
      }

      const transactions = request.data!.transactions!;
      const walletIds = Array.from(transactions.reduce((acc, current) => {
        if (current.target?.wallet_id !== undefined) {
          acc.add(current.target.wallet_id)
        }

        if (current.source?.wallet_id !== undefined) {
          acc.add(current.source.wallet_id)
        }

        return acc;
      }, new Set<IWalletResource['_id']>()));


      const getWalletInfoTasks = walletIds.map(id => {
        return farmerLoungeService.wallets.getDetailAsync(id);
      });

      setTansactions(transactions);
      const walletInfos = await Promise.all(getWalletInfoTasks);
      if (cancellationToken.isCancellationRequested) {
        return;
      }

      const walletDict = walletInfos.reduce((acc, current) => {
        if (!current.succeeded) {
          console.log("지갑 정보를 불러오는대 실패했습니다.")
          return acc;
        }

        const data = current.data!;
        acc[data._id] = data;
        return acc;
      }, {} as { [id: number]: IWalletInfoResource })
      setWalletDict(walletDict);
    })(cts.token);

    return () => {
      cts.cancel();
    }
  }, [userId]);

  return (
    <table className="table table-bordered col-11 pay-table">
      <thead>
        <tr>
          <th>입금 일자</th>
          <th>금액</th>
        </tr>
      </thead>
      <tbody>
        {tansactions?.map(item => {
          return (
            <tr key={item._id}>
              <td className="text-dark">{new Date(item.created_at).toLocaleString()}</td>
              <td className="text-dark">{item.amount}</td>
            </tr>
          )
        }) ?? <EmptyBody />}
      </tbody>
    </table>
  )
}

export default React.memo(PointRechargeHistoryContaier);