import React from "react";
import Message, { IMessageProps } from "./Message";
import Thumbnail from "../Thumbnail";
import IItemResource from "../../farmerLounges/farmerLoungeResources/IItemResource";
import PrimaryButton from "../buttons/PrimaryButton";
import { usePopupContext } from "../../contexts/PopupContext";

interface IProductMessageProps extends Omit<IMessageProps, 'children'> {
  product: IItemResource;
}

function ProductMessage({ product, ...rest }: IProductMessageProps) {
  const { setPopup } = usePopupContext();
  const handleClick = React.useCallback(() => {
    setPopup('product', {
      product: product
    })
  }, [setPopup, product]);

  return (
    <Message {...rest}>
      <div className="proudct-message contaier">
        <div className="row h-100">
          <div className="col-6">
            <Thumbnail src={product.images?.first()?.location} />
          </div>
          <div className="col-6 d-flex flex-column justify-content-between">
            <div>상품 이름: {product.name}</div>
            <div >광고 금액: {product.price}</div>
            <div className="d-flex justify-content-end">
              <PrimaryButton className="col-10" onClick={handleClick}>제품 상세보기</PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </Message>
  )
}

export default React.memo(ProductMessage);