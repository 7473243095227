class AgeHelper {
  static getAge(birth: string) {
    let year = Number.parseInt(birth.substring(0, 2));
    let currentYear = new Date().getFullYear();
    let currentMonth = new Date().getMonth() + 1;
    let currentDay = new Date().getDate();

    if ((currentYear % 100) < year) {
      year = 1900 + year;
    } else {
      year = 2000 + year;
    }

    const birthMonth = Number.parseInt(birth.substring(2, 4));
    const birthDay = Number.parseInt(birth.substring(4, 6));

    let age = currentYear - year;

    if (birthMonth > currentMonth || (birthMonth === currentMonth && birthDay > currentDay)) {
      age -= 1;
    }

    return age;
  }
}

export default AgeHelper;