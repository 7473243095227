import { CancellationToken, CancellationTokenSource } from "@code-rabbits/core";
import React from "react";
import IItemResource from "../../farmerLounges/farmerLoungeResources/IItemResource";
import farmerLoungeService from "../../farmerLounges/FarmerLoungeService";
import ProductForm, { IProductFormProps } from "../../components/forms/ProductForm";

interface IProductFormContainerProps extends IProductFormProps {
  productId: number;
  product?: IItemResource;
}

function ProductFormContainer({
  productId,
  product: propProduct,
  ...rest
}: Readonly<IProductFormContainerProps>) {
  const [product, setProduct] = React.useState<IItemResource | undefined>(propProduct);

  React.useEffect(() => {
    if(product?._id === productId) {
      return;
    }

    let cts = new CancellationTokenSource();
    (async (token: CancellationToken) => {
      const result = await farmerLoungeService.items.detailAsync(productId);
      if (token.isCancellationRequested) {
        return;
      }

      if (!result.succeeded) {
        alert(result.errors?.first()?.message)
        return;
      }

      setProduct(result.data!);
    })(cts.token);

    return () => {
      cts.cancel();
    }
  }, [productId, product?._id]);

  return (
    <ProductForm
      productId={productId}
      productName={product?.name}
      price={product?.price !== undefined ? Number.parseInt(product?.price) : undefined}
      request={product?.request}
      saleType={product?.sale_type}
      discussionContent={product?.discussion_content}
      thumbnailUrl={product?.images?.first()?.location}
      detailUrl={product?.details?.first()?.location}
      {...rest}
    />

  )
}

export default React.memo(ProductFormContainer);