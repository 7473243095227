import React from "react";
import { RoutesProps, UNSAFE_RouteContext, createRoutesFromChildren, matchRoutes, parsePath, useLocation, useRoutes } from "react-router-dom";
import { UNSAFE_invariant as invariant } from "@remix-run/router";

interface IMatcheRoutesProps extends RoutesProps {
  onMatche?: (routes: Exclude<ReturnType<typeof matchRoutes>, null>) => void | boolean | Promise<void | boolean>;
}

function MatcheRoutes({
  children,
  location: locationArg,
  onMatche
}: IMatcheRoutesProps) {
  let { matches: parentMatches } = React.useContext(UNSAFE_RouteContext);
  let routeMatch = parentMatches[parentMatches.length - 1];
  let parentPathnameBase = routeMatch ? routeMatch.pathnameBase : "/";

  let locationFromContext = useLocation();

  let location;
  if (locationArg) {
    let parsedLocationArg =
      typeof locationArg === "string" ? parsePath(locationArg) : locationArg;

    invariant(
      parentPathnameBase === "/" ||
      parsedLocationArg.pathname?.startsWith(parentPathnameBase),
      `When overriding the location using \`<Routes location>\` or \`useRoutes(routes, location)\`, ` +
      `the location pathname must begin with the portion of the URL pathname that was ` +
      `matched by all parent routes. The current pathname base is "${parentPathnameBase}" ` +
      `but pathname "${parsedLocationArg.pathname}" was given in the \`location\` prop.`
    );

    location = parsedLocationArg;
  } else {
    location = locationFromContext;
  }

  let pathname = location.pathname || "/";
  let remainingPathname =
    parentPathnameBase === "/"
      ? pathname
      : pathname.slice(parentPathnameBase.length) || "/";

  const routesObjcets = createRoutesFromChildren(children);
  const routesElements = useRoutes(routesObjcets, location);

  let matches = matchRoutes(routesObjcets, { pathname: remainingPathname });
  if (matches?.any()) {
    if (onMatche && (onMatche(matches) === false)) {
      return null;
    }
  }

  return routesElements;
}

export default MatcheRoutes;