import { NotImplementedError } from "@loonfactory/common";
import React from "react";
import ITokenInfo from "../farmerLounges/farmerLoungeResources/ITokenInfo";
import IUserDetailResource from "../farmerLounges/farmerLoungeResources/IUserDetailResource";

export interface IIdentityContext {
  accessToken: string | null;
  tokenInfo: ITokenInfo | null;
  userInfo: IUserDetailResource | null;

  setAccessToken: (token: string | null) => void;
  setTokenInfo: (tokenInfo: ITokenInfo | null) => void;
  setUserInfo: (userInfo: IUserDetailResource | null) => void;
}

const IdentityContext = React.createContext<IIdentityContext>({
  accessToken: null,
  tokenInfo: null,
  userInfo: null,
  setAccessToken: () => { throw new NotImplementedError(); },
  setUserInfo: () => { throw new NotImplementedError(); },
  setTokenInfo: () => { throw new NotImplementedError(); },
});

export function useIdentityContextCreate() {
  const [accessToken, setAccessToken] = React.useState<string | null>(null);
  const [tokenInfo, setTokenInfo] = React.useState<ITokenInfo | null>(null);
  const [userInfo, setUserInfo] = React.useState<IUserDetailResource | null>(null);

  return React.useMemo(() => ({
    accessToken: accessToken,
    tokenInfo: tokenInfo,
    userInfo: userInfo,
    setAccessToken: setAccessToken,
    setTokenInfo: setTokenInfo,
    setUserInfo: setUserInfo,
  }), [accessToken, tokenInfo, userInfo, setAccessToken, setTokenInfo, setUserInfo]);
}

export function useIdentityContext() {
  return React.useContext(IdentityContext);
}

export default IdentityContext;

IdentityContext.displayName = 'IdentityContext'