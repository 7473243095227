import ServiceErrorGenerator from "../ServiceErrorGenerator";
import ApiEndPoints from "./ApiEndPoints";
import ApiErrorCodeDescriptions from "./ApiErrorCodeDescriptions";
import BaseApiService from "./FarmerLoungesBaseApiService";
import IServiceResponse from "./IServiceResponse";
import ServiceResult from "./ServiceResult";
import IItemListResource from "./farmerLoungeResources/IItemListResource";
import IItemResource from "./farmerLoungeResources/IItemResource";

export interface IItemListGetOptions {
  owner_id?: string | number;
  state?: 'pending' | 'denied' | 'approved' | 'deprecated';
  picked?: boolean;
  search?: string;
  role?: 'farmer' | 'agency';
  page?: number;
  size?: number;
}

export interface ICreateItemProperties {
  name: string;
  company: string;
  price: number;
  discussion_content: string;
  request: string;
  sale_type: string;
  images?: FileList | File[];
  details?: FileList | File[];
}

export interface IUpdateItemProperties {
  id: number;
  name: string;
  company: string;
  price: number;
  discussion_content: string;
  request: string;
  sale_type: string;
  images?: FileList | File[];
  details?: FileList | File[];
}


class ItemsService extends BaseApiService {
  getListAsync = async (options?: IItemListGetOptions): Promise<ServiceResult<IItemListResource>> => {
    const url = this.buildUrl(ApiEndPoints.getItemList, { ...options });
    const response = await this.getAsync(url);

    const result = (await response.json()) as IServiceResponse<IItemListResource>;

    if (result.code !== 2000) {
      switch (result.code) {
        case 4010:
          return ServiceResult.failed({
            code: 4010,
            codeDescription: ApiErrorCodeDescriptions.tokenIsRequired,
            message: "우선 로그인을 해주세요."
          });
        default:
          console.error(result);
          return ServiceResult.failed({
            code: -1,
            codeDescription: ApiErrorCodeDescriptions.unknownError,
            message: "정의되지 않은 에러입니다."
          });
      }
    }

    return ServiceResult.succeeded(result.result)
  }

  creatAsync = async (properties: ICreateItemProperties): Promise<ServiceResult<IItemResource>> => {
    const url = this.buildUrl(ApiEndPoints.items.create);
    if (properties.images !== undefined) {
      if (!(properties.images instanceof Array)) {
        properties.images = Array.from(properties.images);
      }
    }

    if (properties.details !== undefined) {
      if (!(properties.details instanceof Array)) {
        properties.details = Array.from(properties.details);
      }
    }

    const formData = new FormData();
    (Object.keys(properties) as (keyof ICreateItemProperties)[]).forEach(key => {
      [properties[key]].flat().forEach(v => {
        formData.append(key, v as string | File);
      })
    })    

    try {
      const response = await this.postAsync(url, formData);
      const result = (await response.json()) as IServiceResponse<IItemResource>;
      if (result.code !== 2000) {
        switch (result.code) {
          default:
            return this.globalErrorHandler(result);
        }
      }
      return ServiceResult.succeeded(result.result);
    } catch (e) {
      return ServiceResult.failed(ServiceErrorGenerator.unknownError)
    }
  }

  updateAsync = async (properties: IUpdateItemProperties): Promise<ServiceResult<IItemResource>> => {
    const url = this.buildUrl(ApiEndPoints.items.update.replaceAll('{id}', properties.id.toString()));
    if (properties.images !== undefined) {
      if (!(properties.images instanceof Array)) {
        properties.images = Array.from(properties.images);
      }
    }

    if (properties.details !== undefined) {
      if (!(properties.details instanceof Array)) {
        properties.details = Array.from(properties.details);
      }
    }

    const formData = new FormData();
    (Object.keys(properties) as (keyof ICreateItemProperties)[]).forEach(key => {
      [properties[key]].flat().forEach(v => {
        formData.append(key, v as string | File);
      })
    })

    try {
      const response = await this.putAsync(url, formData);
      const result = (await response.json()) as IServiceResponse<IItemResource>;
      if (result.code !== 2000) {
        switch (result.code) {
          default:
            return this.globalErrorHandler(result);
        }
      }
      return ServiceResult.succeeded(result.result);
    } catch (e) {
      return ServiceResult.failed(ServiceErrorGenerator.unknownError)
    }
  }

  updateStateAsync = async (id: number, state: 'approved' | 'denied') => {
    const url = this.buildUrl(ApiEndPoints.items.updateState.replaceAll('{id}', id.toString()));
    try {
      const response = await this.putJsonAsync(url, {
        state: state
      });

      const result = (await response.json()) as IServiceResponse<IItemResource>;
      if (result.code !== 2000) {
        //@TODO: ErrorCodeCheck
        switch (result.code) {
          default:
            return this.globalErrorHandler(result);
        }
      }
      return ServiceResult.succeeded(result.result);
    } catch (e) {
      return ServiceResult.failed(ServiceErrorGenerator.unknownError)
    }
  }

  detailAsync = async (id: number): Promise<ServiceResult<IItemResource>> => {
    const url = this.buildUrl(ApiEndPoints.items.detail.replaceAll('{id}', id.toString()));
    try {
      const response = await this.getAsync(url);

      const result = (await response.json()) as IServiceResponse<{ item: IItemResource }>;
      if (result.code !== 2000) {
        //@TODO: ErrorCodeCheck
        switch (result.code) {
          default:
            return this.globalErrorHandler(result);
        }
      }
      return ServiceResult.succeeded(result.result.item);
    } catch (e) {
      return ServiceResult.failed(ServiceErrorGenerator.unknownError)
    }
  }

  deleteItemAsync = async (id: IItemResource['_id']): Promise<ServiceResult<Pick<IItemResource, '_id'>>> => {
    const url = this.buildUrl(ApiEndPoints.items.delete.replaceAll('{id}', id.toString()));

    try {
      const response = await this.deleteAsync(url);
      const result = (await response.json()) as IServiceResponse<IItemResource>;
      if (result.code !== 2000) {
        switch (result.code) {
          default:
            return this.globalErrorHandler(result);
        }
      }
      return ServiceResult.succeeded(result.result);
    } catch (e) {
      return ServiceResult.failed(ServiceErrorGenerator.unknownError)
    }
  }

  recommandAsync = async (id: IItemResource['_id'], recommand: boolean): Promise<ServiceResult<Pick<IItemResource, '_id'>>> => {
    const url = this.buildUrl(ApiEndPoints.items.recommand.replaceAll('{id}', id.toString()));

    try {
      const response = await this.putJsonAsync(url, {
        picked: recommand
      });

      const result = (await response.json()) as IServiceResponse<IItemResource>;
      if (result.code !== 2000) {
        switch (result.code) {
          case 4000:
            return ServiceResult.failed(ServiceErrorGenerator.alreadyHasBeenUpdated);
          default:
            return this.globalErrorHandler(result);
        }
      }

      return ServiceResult.succeeded(result.result);
    } catch (e) {
      return ServiceResult.failed(ServiceErrorGenerator.unknownError)
    }
  }
}

export default ItemsService;