import { ClassNameHelper } from "@loonfactory/common";
import React from "react";

interface IPopupArea extends React.HTMLAttributes<HTMLDivElement> {

}

function PopupArea({
  className,
  ...rest
}: IPopupArea,
  ref: React.ForwardedRef<HTMLDivElement>) {
  return (
    <div className={ClassNameHelper.concat('popup-area', className)} ref={ref} {...rest} />
  )
}

export default React.memo(React.forwardRef<HTMLDivElement, IPopupArea>(PopupArea));