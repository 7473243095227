import ApiEndPoints from "../../ApiEndPoints";
import ApiErrorCodeDescriptions from "../../ApiErrorCodeDescriptions";
import FarmerLoungesBaseApiService from "../../FarmerLoungesBaseApiService";
import IServiceResponse from "../../IServiceResponse";
import ServiceResult from "../../ServiceResult";
import IScheduleDetailResource from "../IScheduleDetailResource";
import IScheduleListResource from "../IScheduleListResource";
import { IGetScheduleListOptions } from "./IGetScheduleListOptions";

class SchedulesService extends FarmerLoungesBaseApiService {
  getListAsync = async (options?: IGetScheduleListOptions): Promise<ServiceResult<IScheduleListResource>> => {
    const url = this.buildUrl(ApiEndPoints.schedules.index, { ...options });
    const response = await this.getAsync(url);

    const result = (await response.json()) as IServiceResponse<IScheduleListResource>;

    if (result.code !== 2000) {
      switch (result.code) {
        case 4000:
          return ServiceResult.failed({
            code: 4000,
            codeDescription: ApiErrorCodeDescriptions.invalidState,
            message: `state가 올바르지 않습니다.`
          });
      }
    }

    return ServiceResult.succeeded(result.result);
  }

  getScheduleDetailsAsync = async (
    scheduleId: number
  ): Promise<ServiceResult<IScheduleDetailResource>> => {
    const url = this.buildUrl(ApiEndPoints.schedules.detail.replaceAll('{id}', scheduleId.toString()));
    const response = await this.getAsync(url);

    const result = (await response.json()) as IServiceResponse<IScheduleDetailResource>;
    if (result.code !== 2000) {
      switch (result.code) {
        default:
          return this.globalErrorHandler(result);
      }
    }

    return ServiceResult.succeeded(result.result);
  }
}

export default SchedulesService;