import React from "react";
import IDashBoardPageProps from "../../DashBoardPage/IDashBoardPageProps";
import MainLayout from "../../../layouts/MainLayout";
import { ClassNameHelper } from "@loonfactory/common";
import MyProductList from "../../../components/MyProductList";
import HorizontalLinearLayout from "../../../layouts/HorizontalLinearLayout";
import TalkingHostContainer from "../../../containers/rooms/TalkingHostContainer";
import './AgencyDashBoardPage.scss';
import WalletContaier from "../../../containers/points/WalletContaier";
import MatcheCountContainer from "../../../containers/MatcheCountContainer";
import InquiryRecruitmentContaier from "../../../containers/InquiryRecruitmentContaier";

const MyProudctListTitle = React.memo(() => {
  return <div>내 <span className="text-primary">상품</span></div>
})

function AgencyDashBoardPage(props: IDashBoardPageProps) {
  return (
    <MainLayout className={props.className}>
      <section className={ClassNameHelper.concat('advertiser-dashboard-section', 'main-section')}>
        <div className="content-region mt-4">
          <div className="container justify-content-center  mt-4 mb-5">
            <div className="row">
              <MatcheCountContainer className="col-12" />
            </div>
            <div className="row mt-4">
              <WalletContaier className="col-12" text="출금 가능 포인트" />
            </div>
          </div>
          <MyProductList className="mt-5"
            listTitle={<MyProudctListTitle />}
          />
          <HorizontalLinearLayout className="advertiser-section mt-5">
            <InquiryRecruitmentContaier />
            <TalkingHostContainer />
          </HorizontalLinearLayout>
        </div>
      </section>
    </MainLayout>
  )
}

export default React.memo(AgencyDashBoardPage);