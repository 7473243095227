import React from "react";
import ImageInput from "../../../../forms/inputs/ImageInput";
import PrimaryButton from "../../../../buttons/PrimaryButton";
import './PopupForm.scss'
import { ClassNameHelper } from "@loonfactory/common";

interface IPopupFormProps extends React.HTMLAttributes<HTMLFormElement> {
  popupTitle?: string;
  popupUrl?: string;
}

function PopupForm({
  popupTitle,
  popupUrl,
  className,
  ...rest
}: Readonly<IPopupFormProps>) {
  const [url, setUrl] = React.useState(popupUrl);
  const handleImgChage = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(evt => {
    let nextUrl;
    const file = evt.currentTarget.files?.item(0) ?? null;
    if (file === null) {
      nextUrl = popupUrl;
    }
    else {
      nextUrl = URL.createObjectURL(file);
    }

    setUrl(nextUrl);
  }, [popupUrl])

  return (
    <form className={ClassNameHelper.concat('popup-form', className)} {...rest}>
      <div className="row mt-3">
        <div className="col-12"><strong>제목</strong></div>
        <div className="col-12">
          <input name="title" type="text" className="border w-100" defaultValue={popupTitle} />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12"><strong>팝업 이미지</strong></div>
        <div className="col-12">
          <label className="d-block">
            <div className="border w-100 popup-preview-wrapper">
              <div className="popup-preview">
                <img src={url} alt="" />
                <ImageInput
                  name="image"
                  className={ClassNameHelper.concat("w-100 h-100 text-center", url !== undefined && "d-none")}
                  onChange={handleImgChage}
                  required
                />
              </div>
            </div>
          </label>
        </div>
      </div>
      <div className="row mt-3 justify-content-end">
        <div className="col-3 col-md-2 col-xl-1">
          <PrimaryButton className="w-100" type="submit">저장</PrimaryButton>
        </div>
      </div>
    </form>
  )
}

export default React.memo(PopupForm);