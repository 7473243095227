import React from "react";
import INavItemProps from "./INavItemProps";
import NavItem from "./NavItem";
import LazyBagIcon from "../../../icons/LazyBagIcon";

function BankAccountNavItem(props: Omit<INavItemProps, 'children'>) {
  return (
    <NavItem {...props}>
      <LazyBagIcon /> 계좌 관리
    </NavItem>
  )
}

export default React.memo(BankAccountNavItem);