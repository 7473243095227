import React from "react";
import HorizontalLinearLayout from "../layouts/HorizontalLinearLayout";
import { ClassNameHelper } from "@loonfactory/common";
import { Link } from "react-router-dom";
import EndPoints from "../EndPoints";
import EmptyContent from "../components/contents/EmptyContent";


function BookingInqueryContainer({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={ClassNameHelper.concat(className, 'content-wrapper')}>
      <HorizontalLinearLayout className="fs-3 fw-semibold content-title-wrapper">
        <div className="title">나에게 들어온 <span className="text-primary">섭외 문의</span></div>
        <Link to={EndPoints.message} className="text-primary fs-5 pointer">더보기 +</Link>
      </HorizontalLinearLayout>
      <div className="booking-inquery-content-wrapper mt-3">
        {/* @TODO */}
        <EmptyContent />
      </div>
    </div>
  )
}

export default React.memo(BookingInqueryContainer);