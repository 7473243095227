import { ClassNameHelper } from "@loonfactory/common";
import HorizontalLinearLayout from "../layouts/HorizontalLinearLayout";
import React from "react";
import ProductGirdContainer from "./products/ProductGirdContainer";
import { IItemListGetOptions } from "../farmerLounges/ItemsService";
import IItemResource from "../farmerLounges/farmerLoungeResources/IItemResource";
import ProductRenderItem from "../components/ProductRenderItem";

const options: IItemListGetOptions = {
  picked: false,
  role: 'farmer',
}

const advertiserRenderer = (product: IItemResource) => {
  return (
    <ProductRenderItem product={product} />
  )
}

function AdvertiserContaier({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={ClassNameHelper.concat(className, 'content-wrapper')} {...rest}>
      <HorizontalLinearLayout className="fs-3 fw-semibold content-title-wrapper">
        <div className="content-title">전체 <span className="text-primary">광고주</span></div>
      </HorizontalLinearLayout>
      <div className="new-host-content-wrapper mt-2">
        <div className="primary-gallery-wrapper">
          <ProductGirdContainer
            className="primary-gallery"
            row={2}
            itemWidth={200}
            gap={0.6}
            options={options}
            renderer={advertiserRenderer}
          />
        </div>
      </div>
    </div>
  );
}


export default React.memo(AdvertiserContaier);