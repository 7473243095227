import React from "react";
import "./HomePage.scss";
import SignInButton from "../../components/buttons/SignInButton";
import RegisterButton from "../../components/buttons/RegisterButton";
import AdProductGalleryContaier from "../../containers/products/AdProductGalleryContaier";
import LiveBroadcastContaier from "../../containers/LiveBroadcastContaier";
import { useNavigate } from "react-router-dom";
import EndPoints from "../../EndPoints";
import BestHostGalleryContaier from "../../containers/BestHostGalleryContaier";
import HomeAdBannerContaier from "../../containers/HomeAdBannerContaier";
import ShortCutsContaier from "../../containers/ShortCutsContaier";
import farmerLoungeService from "../../farmerLounges/FarmerLoungeService";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { useIdentityContext } from "../../contexts/IdentityContext";
import MainVisualContaier from "../../containers/MainVisualContaier";

const Footer = React.memo(() => {
  return (
    <footer>
      <div className="footer-content">
        <div className="d-grid gap-5">
          <img width={218} height={89} src="/logo_with_literal.png" alt="logo_with_literal" />
          <div className="d-flex gap-5">
            <div>
              <div className="d-flex vertical-split-line info">
                <p>사업자등록번호</p>
                <p>267-04-02536</p>
              </div>
              <div className="d-flex vertical-split-line info">
                <p>업체명</p>
                <p>파머라운지</p>
              </div>
              <div className="d-flex vertical-split-line info">
                <p>주소</p>
                <p>전라북도 익산시 중앙로 22-202,3층 307호(중앙동 2가, 익산청년시청)</p>
              </div>
            </div>
            <div>
              <div className="d-flex vertical-split-line info">
                <p>개인정보책임자</p>
                <p>도수빈</p>
              </div>
              <div className="d-flex vertical-split-line info">
                <p>대표자</p>
                <p>도수빈</p>
              </div>
              <div className="d-flex vertical-split-line info">
                <p>통신판매업번호</p>
                <p>2022-전북익산-0413</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
});

function HomePage(props: React.HTMLAttributes<HTMLDivElement>) {
  const navigate = useNavigate();
  const { userInfo } = useIdentityContext();

  const handleLoginButtonClick = React.useCallback(() => {
    navigate(EndPoints.login);
  }, [navigate]);

  const handleSignInButtonClick = React.useCallback(() => {
    navigate(EndPoints.registerIntro);
  }, [navigate]);

  const handleMyPageClick = React.useCallback(() => {
    const role = userInfo?.role;
    if (role === "admin" || role === "manager") {
      navigate(EndPoints.manager.index);
    } else {
      navigate(EndPoints.dashboard);
    }
  }, [userInfo?.role, navigate]);

  return (
    <div className="home-page" {...props}>
      <header>
        <div className="header-content">
          <img src="/logo.png" alt="logo" />
          {!farmerLoungeService.auth.isSignedIn() ? (
            <div className="option-region d-none d-sm-flex">
              <SignInButton onClick={handleLoginButtonClick} />
              <RegisterButton onClick={handleSignInButtonClick} />
            </div>
          ) : (
            <div className="option-region d-none d-sm-flex">
              <PrimaryButton onClick={handleMyPageClick}>마이페이지</PrimaryButton>
            </div>
          )}
        </div>
      </header>
      <MainVisualContaier className="main-banner-sidler mb-5" />
      <AdProductGalleryContaier />
      <LiveBroadcastContaier />
      <BestHostGalleryContaier />
      <HomeAdBannerContaier className="mt-5 mb-5" />
      <Footer />
      <ShortCutsContaier />
    </div>
  )
}

export default React.memo(HomePage);