import React from "react";
import ImageInput from "../../../../forms/inputs/ImageInput";
import PrimaryButton from "../../../../buttons/PrimaryButton";
import './BannerForm.scss'
import { ClassNameHelper } from "@loonfactory/common";

interface IBannerFormProps extends React.HTMLAttributes<HTMLFormElement> {
  bannerTitle?: string;
  bannerUrl?: string;
}

function BannerForm({
  bannerTitle,
  bannerUrl,
  className,
  ...rest
}: IBannerFormProps) {

  return (
    <form className={ClassNameHelper.concat('banner-form', className)} {...rest}>
      <div className="row mt-3">
        <div className="col-12"><strong>제목</strong></div>
        <div className="col-12">
          <input name="title" type="text" className="border w-100" defaultValue={bannerTitle} />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12"><strong>배너 이미지</strong></div>
        <div className="col-12">
          <div className="border w-100 banner-preview-wrapper">
            <div className="banner-preview">
              <ImageInput
                name="image"
                className="w-100 h-100 text-center"
                location={bannerUrl}
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3 justify-content-end">
        <div className="col-3 col-md-2 col-xl-1">
          <PrimaryButton className="w-100" type="submit">저장</PrimaryButton>
        </div>
      </div>
    </form>
  )
}

export default React.memo(BannerForm);