import React, { Suspense } from 'react';

const OutlineCircleIcon = React.lazy(() => import('./OutlineCircleIcon'));

function LazyOutlineCircleIcon(props: React.HTMLAttributes<SVGSVGElement>) {
  return (
    <Suspense fallback={<svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="#4E9A5E"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />}>
      <OutlineCircleIcon {...props} />
    </Suspense>
  )
}

export default React.memo(LazyOutlineCircleIcon)