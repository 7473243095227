import React from "react";
import ShortCuts from "../components/ShortCuts";

const faqUrl = process.env.REACT_APP_FAQ_URL;
const chatbotUrl = process.env.REACT_APP_CHAT_BOT_URL;

function ShortCutsContaier() {
  const handleKakaoClick = React.useCallback(() => {
    if (!chatbotUrl) {
      alert("카카오톡 쳇봇 경로가 설정되지 않았습니다.");
      return
    }

    window.location.href = chatbotUrl;
  }, []);


  const handleFAQClick = React.useCallback(() => {
    if (!faqUrl) {
      alert("FAQ 경로가 설정되지 않았습니다.");
      return
    }

    window.open(faqUrl);
  }, []);

  return (
    <ShortCuts
      onKakaoClick={handleKakaoClick}
      onFaqClick={handleFAQClick}
    />
  )
}

export default React.memo(ShortCutsContaier);