import { CancellationToken } from "@code-rabbits/core";
import IPaginationOptions from "./IPaginationOptions";
import ScheduleStatusType from "./farmerLounges/farmerLoungeResources/ScheduleStatusType";
import IScheduleDetailListResource from "./farmerLounges/farmerLoungeResources/IScheduleDetailListResource";
import ServiceResult from "./farmerLounges/ServiceResult";
import ServiceErrorGenerator from "./ServiceErrorGenerator";
import farmerLoungeService from "./farmerLounges/FarmerLoungeService";
import IServiceError from "./farmerLounges/IServiceError";
import IScheduleListResource from "./farmerLounges/farmerLoungeResources/IScheduleListResource";
import RoleType from "./farmerLounges/RoleType";
import IUserDetailListResource from "./farmerLounges/farmerLoungeResources/IUserDetailListResource";
import IUserDetailResource from "./farmerLounges/farmerLoungeResources/IUserDetailResource";
import IRoomResource from "./farmerLounges/farmerLoungeResources/rooms/IRoomResource";
import ITalkingUserResource from "./lib/ITalkingUserResource";
import IScheduleDetailResource from "./farmerLounges/farmerLoungeResources/IScheduleDetailResource";
import ApiErrorCodeDescriptions from "./farmerLounges/ApiErrorCodeDescriptions";

export interface IChallengeSchedulesOptions extends IPaginationOptions {
  userId?: number;
  state?: ScheduleStatusType;
  invisibleButton?: boolean;
}

export const challengeUserSchedulesAsync = (
  options: IChallengeSchedulesOptions,
  cancellationToken?: CancellationToken,
): Promise<ServiceResult<IScheduleDetailListResource>> => {
  if (!options.userId) {
    return Promise.resolve(ServiceResult.failed(ServiceErrorGenerator.loginRequired));
  }

  return challenge(options.userId, options);

  async function challenge(userId: number, options?: IChallengeSchedulesOptions): Promise<ServiceResult<IScheduleDetailListResource>> {
    const result = await farmerLoungeService.users.getSchedulesAsync(userId, options);
    if (cancellationToken?.isCancellationRequested ?? false) {
      throw new Error("Canceled");
    }

    if (!result.succeeded) {
      return ServiceResult.failed(...result.errors);
    }

    return ServiceResult.succeeded(result.data!);
  }
};

export interface IChallengeTalkingUsersOptions {
  myId: number;
  take?: number;
}

export const challengeTalkingUsersAsync = (
  options: IChallengeTalkingUsersOptions,
  cancellationToken: CancellationToken,
): Promise<ServiceResult<ITalkingUserResource[]>> => {
  return challengeTalking(options);

  async function challengeTalking(options: IChallengeTalkingUsersOptions): Promise<ServiceResult<ITalkingUserResource[]>> {
    const result = await farmerLoungeService.rooms.getListAsync({
      size: options.take,
    });
    
    if (cancellationToken.isCancellationRequested) {
      throw new Error("Canceled");
    }

    if (!result.succeeded) {
      return ServiceResult.failed(...result.errors)
    }

    const rooms = result.data!.rooms;

    const roomDcit = rooms.reduce<{ [key: number]: IRoomResource }>((acc, current) => {
      let key = current.service_provider_id !== options.myId ? current.service_provider_id : current.client_id;
      acc[key] = current;
      return acc;
    }, {});

    const userResults = await Promise.all(Object.keys(roomDcit).map(async id => {
      const result = await farmerLoungeService.users.getDetailAsync(Number.parseInt(id));
      return {
        id: id,
        payload: result,
      }
    }));

    if (cancellationToken.isCancellationRequested) {
      throw new Error("Canceled");
    }

    let errors: IServiceError[] = [];
    const results: (Partial<IUserDetailResource> | null)[] = userResults.map<ITalkingUserResource | null>(item => {
      if (!item.payload.succeeded) {
        if (item.payload.errors.first().codeDescription !== ApiErrorCodeDescriptions.userInfoHasBeenRemoved) {
          errors = errors.concat(item.payload.errors);
        }

        return {
          roomId: Number.parseInt(item.id),
        };
      }

      const userId = item.payload.data!._id;

      let lastMessage;
      const messages = roomDcit[userId].messages?.last();
      if (messages !== undefined) {
        if (messages.items?.any()
          && messages.items?.first() !== null) {
          lastMessage = "상품"
        } else if (messages.schedule) {
          lastMessage = "스케쥴"
        } else {
          lastMessage = messages.content;
        }
      }

      return {
        ...item.payload.data!,
        lastMessage: lastMessage,
        roomId: roomDcit[userId]?._id,
      };
    })

    if (errors.any()) {
      return ServiceResult.failed(errors);
    }

    return ServiceResult.succeeded(results as ITalkingUserResource[]);
  }
};

export const challengeSignInAsync = async (formData: FormData): Promise<ServiceResult> => {
  const username = (formData.get('username') as string | null)?.trim();
  if (String.isUnvalidOrWhiteSpace(username)) {
    alert("아이디를 입력해주세요.")
    return ServiceResult.failed([ServiceErrorGenerator.usernameIsRequired]);
  }

  const password = formData.get('password') as string | null;
  if (password === String.empty || password === null) {
    alert("비밀번호를 입력해주세요.")
    return ServiceResult.failed([ServiceErrorGenerator.passwordIsRequired]);
  }

  return challengeSignIn(username.toString(), password.toString());

  async function challengeSignIn(username: string, password: string): Promise<ServiceResult> {
    const result = await farmerLoungeService.auth.loginAsync({
      email: username,
      password: password,
    });

    if (!result.succeeded) {
      return ServiceResult.failed(result.errors);
    }

    return ServiceResult.succeeded(undefined);
  }
};

export interface IChallengeSchedulesOptions extends IPaginationOptions {
  state?: ScheduleStatusType;
  invisibleButton?: boolean,
}

export const challengeSchedulesAsync = (
  options: IChallengeSchedulesOptions,
  cancellationToken: CancellationToken,
): Promise<ServiceResult<IScheduleListResource>> => {
  return challengeSchedules(options);

  async function challengeSchedules(options: IChallengeSchedulesOptions): Promise<ServiceResult<IScheduleListResource>> {
    const result = await farmerLoungeService.schedules.getListAsync(options);
    if (cancellationToken.isCancellationRequested) {
      throw new Error("Canceled");
    }

    if (!result.succeeded) {
      return ServiceResult.failed(...result.errors)
    }

    return ServiceResult.succeeded(result.data!);
  }
};

export interface IChallengeUsersOptions extends IPaginationOptions {
  role?: RoleType;
  name?: string;
  picked?: boolean;
}

export const challengeUsersAsync = (
  options: IChallengeUsersOptions,
  cancellationToken?: CancellationToken,
): Promise<ServiceResult<IUserDetailListResource>> => {

  return challengeUsers(options);

  async function challengeUsers(options?: IChallengeUsersOptions): Promise<ServiceResult<IUserDetailListResource>> {
    const result = await farmerLoungeService.users.getListAsync(options);
    if (cancellationToken?.isCancellationRequested ?? false) {
      throw new Error("Canceled");
    }

    if (!result.succeeded) {
      return ServiceResult.failed(...result.errors);
    }

    return ServiceResult.succeeded(result.data!);
  }
};

export const challengeRoomAsync = (roomId: number) => {
  return farmerLoungeService.rooms.getRoomAsync(roomId);
}


export const challengeUserDetailAsync = (
  userId: IUserDetailResource['_id'],
  cancellationToken?: CancellationToken,
): Promise<ServiceResult<IUserDetailResource>> => {

  return _challengeUserDetailAsycn(userId, cancellationToken);

  async function _challengeUserDetailAsycn(
    userId: IUserDetailResource['_id'],
    cancellationToken?: CancellationToken,
  ): Promise<ServiceResult<IUserDetailResource>> {
    const result = await farmerLoungeService.users.getDetailAsync(userId);
    if (cancellationToken?.isCancellationRequested ?? false) {
      throw new Error("Canceled");
    }

    if (!result.succeeded) {
      return ServiceResult.failed(...result.errors);
    }

    return ServiceResult.succeeded(result.data!);
  }
};

export const challengeScheduleDetailAsync = (
  userId: IScheduleDetailResource['_id'],
  cancellationToken?: CancellationToken,
): Promise<ServiceResult<IScheduleDetailResource>> => {

  return _challengeScheduleDetailAsync(userId, cancellationToken);

  async function _challengeScheduleDetailAsync(
    userId: IScheduleDetailResource['_id'],
    cancellationToken?: CancellationToken,
  ): Promise<ServiceResult<IScheduleDetailResource>> {
    const result = await farmerLoungeService.schedules.getScheduleDetailsAsync(userId);
    if (cancellationToken?.isCancellationRequested ?? false) {
      throw new Error("Canceled");
    }

    if (!result.succeeded) {
      return ServiceResult.failed(...result.errors);
    }

    return ServiceResult.succeeded(result.data!);
  }
};