import AutoResizeGallery from "../../components/galleries/AutoResizeGallery";
import React from "react";
import farmerLoungeService from "../../farmerLounges/FarmerLoungeService";
import IServiceError from "../../farmerLounges/IServiceError";
import { CancellationTokenSource } from "@code-rabbits/core";
import EmptyContent from "../../components/contents/EmptyContent";
import LoadingContent from "../../components/contents/LoadingContent";
import { IAutoResizeGalleryProps } from "../../components/galleries/AutoResizeGallery/AutoResizeGallery";
import { ClassNameHelper } from "@loonfactory/common";
import IPaginationOptions from "../../IPaginationOptions";
import IScheduleDetailResource from "../../farmerLounges/farmerLoungeResources/IScheduleDetailResource";

const keySelector = (item: IScheduleDetailResource) => {
  return item._id;
}

interface IRoomScheduleGirdContainerProps extends Omit<
  IAutoResizeGalleryProps<IScheduleDetailResource>, 'items' | 'keySelector'
> {
  roomId: number;
  className?: string;
  options?: IPaginationOptions;
  renderer: (props: IScheduleDetailResource, index: number, array: IScheduleDetailResource[]) => React.ReactElement | null | undefined;
}

function RoomScheduleGirdContainer(props: IRoomScheduleGirdContainerProps) {
  const [items, setItems] = React.useState<IScheduleDetailResource[] | null>(null);
  const [errors, setErrors] = React.useState<IServiceError[]>();
  const [totalCount, setTotalCount] = React.useState<number>();
  const [pageIndex, setPageIndex] = React.useState<number>(1);

  const handleIndexClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(evt => {
    const target = evt.currentTarget;
    const index = Number(target.dataset.index);
    setPageIndex(index);
  }, []);

  React.useEffect(() => {
    let cts = new CancellationTokenSource();
    (async () => {
      const result = await farmerLoungeService.rooms.getSchedulesAsync(props.roomId, props.options);

      if (cts.isCancellationRequested) {
        return;
      }

      if (!result.succeeded) {
        setErrors(result.errors!);
        return;
      }

      setTotalCount(result.data!.count!);
      setItems(result.data!.schedules);
    })();

    return () => {
      cts.cancel();
    }
  }, [props.roomId, props.options]);

  if (errors?.any()) {
    return (
      <div className="text-danger">
        항목을 불러오는데 실패햐엿습니다.
        <ul>
          {errors.map(item => <li>{item.message}</li>)}
        </ul>
      </div>
    )
  }

  if (items === null) {
    return (
      <LoadingContent />
    )
  }

  if (!items.any()) {
    return <EmptyContent />
  }

  return (
    <AutoResizeGallery
      className={ClassNameHelper.concat("ad-product-gallery", props.className)}
      items={items}
      row={props.row}
      itemWidth={props.itemWidth}
      gap={props.gap}
      totalCount={totalCount}
      pageIndex={pageIndex}
      renderer={props.renderer}
      keySelector={keySelector}
      onIndexClick={handleIndexClick}
      paginationDisabled={props.paginationDisabled}
    />
  )
}



export default React.memo(RoomScheduleGirdContainer);