import { ClassNameHelper } from "@loonfactory/common";
import HorizontalLinearLayout from "../../layouts/HorizontalLinearLayout";
import React from "react";
import PrimaryGalleryItem from "../../components/PrimaryGalleryItem";
import UsersGirdContainer from "../UsersGirdContainer";
import IUserDetailResource from "../../farmerLounges/farmerLoungeResources/IUserDetailResource";
import { IChallengeUsersOptions } from "../../challenges";

const options: IChallengeUsersOptions = {
  role: "showhost",
  picked: true,
}

const hostItemRender = (props: IUserDetailResource) => {
  return (
    <PrimaryGalleryItem
      className="primary-gallery-item"
      thumbnailUrl={props.portfolio?.first()?.images?.first().location}
      title={props.name}
    />
  )
}

function RecommendShowHostContainer({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) {

  return (
    <div className={ClassNameHelper.concat(className, 'content-wrapper')} {...rest}>
      <HorizontalLinearLayout className="fs-3 fw-semibold content-title-wrapper">
        <div className="content-title">추천 쇼호스트</div>
      </HorizontalLinearLayout>
      <div className="new-host-content-wrapper mt-2">
        <div className="new-host-content primary-gallery-wrapper">
          <UsersGirdContainer
            className="primary-gallery"
            itemWidth={180}
            row={2}
            renderer={hostItemRender}
            options={options}
          />
        </div>
      </div>
    </div>
  );
}


export default React.memo(RecommendShowHostContainer);