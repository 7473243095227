import React from "react";
import ITransactionResource from "../../farmerLounges/transactions/ITransactionResource";
import IServiceError from "../../farmerLounges/IServiceError";
import Transactions from "../../components/managemensts/transactions/Transactions";
import ITransactionListOptions from "../../farmerLounges/transactions/ITransactionListOptions";
import { CancellationToken, CancellationTokenSource } from "@code-rabbits/core";
import farmerLoungeService from "../../farmerLounges/FarmerLoungeService";
import Paginations from "../../components/Paginations";
import DatasetHelper from "../../lib/DatasetHelper";
import TransactionTable from "../../components/managemensts/transactions/TransactionTable";

interface ITransactionsContainerProps extends React.HTMLAttributes<HTMLDivElement>{
  options?: ITransactionListOptions;
  onAcceptClick?: React.MouseEventHandler<HTMLButtonElement>;
  onRejectClick?: React.MouseEventHandler<HTMLButtonElement>;
}

function TransactionsContainer({
  options,
  onAcceptClick,
  onRejectClick,
  ...rest
}: ITransactionsContainerProps) {
  const [transactions, setTransactions] = React.useState<ITransactionResource[] | null>(null);
  const [errors, setErrors] = React.useState<IServiceError[]>();
  const [offset, setOffset] = React.useState<number>(0);
  const [pagePerCount] = React.useState<number>(20);
  const [totalCount] = React.useState<number>();

  const updateTransactionsAsync = React.useCallback(async (
    options?: ITransactionListOptions,
    cancellationToken?: CancellationToken
  ) => {
    const result = await farmerLoungeService.transactions.getListAsync(options);
    if (cancellationToken?.isCancellationRequested) {
      return;
    }

    if (!result.succeeded) {
      setErrors(result.errors);
      return;
    }

    setTransactions([...result.data!.transactions]);
  }, [])

  const handleClick = React.useCallback<React.MouseEventHandler>(async evt => {
    if (!evt.isPropagationStopped()) {
      await updateTransactionsAsync(options);
      console.log(1);
    }
  }, [updateTransactionsAsync, options]);

  const handleAcceptClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(async evt => {
    onAcceptClick && onAcceptClick(evt);
    handleClick(evt);
  }, [onAcceptClick, handleClick]);

  const handleRejectClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(async evt => {
    onRejectClick && onRejectClick(evt);
    handleClick(evt);
  }, [onRejectClick, handleClick]);

  const handlePaginationIndexClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(evt => {
    const index = DatasetHelper.getInt(evt.currentTarget.dataset, "index");
    setOffset(index);
  }, []);


  React.useEffect(() => {
    const cts = new CancellationTokenSource();
    updateTransactionsAsync(options, cts.token);

    return () => {
      cts.cancel();
    }
  }, [options, updateTransactionsAsync]);

  return (
    <div {...rest}>
      <TransactionTable>
        <Transactions
          items={transactions}
          errors={errors}
          onAcceptClick={handleAcceptClick}
          onRejectClick={handleRejectClick}
        />
      </TransactionTable>
      <div className="row">
        <div className="col-12 justify-content-center">
          <Paginations
            className="text-center"
            index={offset}
            totalCount={totalCount}
            pagePerCount={pagePerCount}
            onIndexClick={handlePaginationIndexClick}
          />
        </div>
      </div>
    </div>
  )
}

export default React.memo(TransactionsContainer);