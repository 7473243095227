import React, { Suspense } from 'react';

const RightIcon = React.lazy(() => import('./RightIcon'));

function LasyRightIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <Suspense fallback={<svg width="29"
      height="52"
      viewBox="0 0 29 52"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />}>
      <RightIcon {...props} />
    </Suspense>
  )
}

export default React.memo(LasyRightIcon)