import React from "react";
import MainLayout from "../../layouts/MainLayout";
import RecommendSection from "../../sections/RecommandSection/RecommendSection";
import RecommendAdvertiserContaier from "../../containers/recommands/RecommendAdvertiserContaier";
import AdvertiserContaier from "../../containers/AdvertiserContaier";
import { ClassNameHelper } from "@loonfactory/common";
import './AdProductPage.scss'

interface IAdProductPageProps extends React.HTMLAttributes<HTMLDivElement> {

}

function AdProductPage({
  className,
  ...rest
}: IAdProductPageProps) {
  return (
    <MainLayout className={className}>
      <RecommendSection className={ClassNameHelper.concat('ad-section', 'main-section')} {...rest}>
        <RecommendAdvertiserContaier className="mt-5" />
        <AdvertiserContaier className="mt-5" />
      </RecommendSection>
    </MainLayout>
  )
}

export default React.memo(AdProductPage);