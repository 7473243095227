import React from "react";
import VerticalLinearLayout from "../layouts/VerticalLinearLayout";
import Thmbnail from "./Thumbnail";
import Helper from "../lib/Helper";
import OperationCanceledError from "../lib/OperationCanceledError";
import { CancellationToken, CancellationTokenSource } from "@code-rabbits/core";
import { Link } from "react-router-dom";

export interface ILiveBroadcastItemProps {
  videoUrl: string;
  title: string;
  channelName: string;
}

function LiveBroadcastItem({
  channelName,
  videoUrl,
  title,
  ...rest
}: ILiveBroadcastItemProps) {
  const [thumbnailUrl, setThumbnailUrl] = React.useState<string | null>(Helper.loadingImage);

  const updateThumbnailUrl = React.useCallback(async (url: string, token: CancellationToken) => {
    try {
      const result = await Helper.getOgImageAsync(url, token);
      if (token.isCancellationRequested) {
        return;
      }
      setThumbnailUrl(result);

    } catch (e) {
      if (e instanceof OperationCanceledError) {
        return;
      }

      console.warn(e);
      if (token.isCancellationRequested) {
        return;
      }

      setThumbnailUrl(null);
    }
  }, []);

  React.useEffect(() => {
    if (videoUrl === undefined) {
      return;
    }

    const cts = new CancellationTokenSource();
    updateThumbnailUrl(videoUrl, cts.token);

    return () => {
      cts.cancel();
    }

  }, [updateThumbnailUrl, videoUrl]);

  return (
    <VerticalLinearLayout className="live-broadcast-item" {...rest}>
      <Link to={videoUrl}>
        <Thmbnail src={thumbnailUrl} />
        <div className="title-wrapper">
          <div className="fs-4 title">{title}</div>
          <div className="fs-6 fw-light">{channelName}</div>
        </div>
      </Link>
    </VerticalLinearLayout>
  )
}

export default React.memo(LiveBroadcastItem);