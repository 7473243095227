import React from "react";
import { ClassNameHelper } from "@loonfactory/common";
import LazyAdvertiserIcon from "../../icons/LazyAdvertiserIcon";

function AdvertiserButton({ className, ...rest }: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button className={ClassNameHelper.concat("btn", "btn-outline-secondary", "fs-3", className)} {...rest}>
      <LazyAdvertiserIcon />
      광고주
    </button>
  )
}

export default React.memo(AdvertiserButton);