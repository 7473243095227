import React from "react";
import ManagersLayout from "../../../../layouts/ManagersLayout";
import farmerLoungeService from "../../../../farmerLounges/FarmerLoungeService";
import FormDataHelper from "../../../../lib/FormDataHelper";
import { useNavigate } from "react-router-dom";
import EndPoints from "../../../../EndPoints";
import MainVisualForm from "../../../../components/managemensts/events/mainVisuals/MainVisualForm";

function MainVisualCreatePage() {
  const navigate = useNavigate();

  const handleSubmit = React.useCallback<React.FormEventHandler<HTMLFormElement>>(async evt => {
    evt.stopPropagation();
    evt.preventDefault();

    let images: FileList | null = (evt.currentTarget.querySelector('input[type=file][name=image]') as HTMLInputElement | null)?.files ?? null;
    if (images === null || images.length === 0) {
      alert("프로필 사진을 반드시 넣어주세요.")
      return;
    }

    const formData = new FormData(evt.currentTarget);
    const title = FormDataHelper.getString(formData, 'title');

    const result = await farmerLoungeService.posts.createPostAsync({
      type: 'notice',
      images: images,
      title: title
    });

    if (!result.succeeded) {
      alert(result.errors.first()?.codeDescription);
      return;
    }

    navigate(EndPoints.manager.events.mainVisual.index);
  }, [navigate]);

  return (
    <ManagersLayout className="banner-create-page">
      <section className="d-flex justify-content-center align-items-center h-100">
        <MainVisualForm
          className="container"
          onSubmit={handleSubmit}
        />
      </section>
    </ManagersLayout>
  )
}

export default React.memo(MainVisualCreatePage);