import React from "react";
import MainLayout from "../../layouts/MainLayout";
import WithdrawalWalletSection from "../../sections/WithdrawalWalletSection";

interface IHostWalletPageProps extends React.HTMLAttributes<HTMLDivElement> {

}

function HostWalletPage(props: IHostWalletPageProps) {
  return (
    <MainLayout className={props.className}>
      <WithdrawalWalletSection className="main-section" />
    </MainLayout>
  )
}

export default React.memo(HostWalletPage);