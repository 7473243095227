import React from "react";
import Message, { IMessageProps } from "./Message";
import IScheduleDetailResource from "../../farmerLounges/farmerLoungeResources/IScheduleDetailResource";

interface IScheduleConfirmMessageProps extends Omit<IMessageProps, 'children'> {
  schedule: IScheduleDetailResource;
}

function ScheduleAcceptMessage({ schedule, ...rest }: IScheduleConfirmMessageProps) {
  const scheduleAt = schedule?.scheduled_at !== undefined ? new Date(schedule.scheduled_at) : undefined;
  const date = `${scheduleAt?.getFullYear().toString().padStart(4, '0')}-${scheduleAt?.getMonth().toString().padStart(2, '0')}-${scheduleAt?.getDay().toString().padStart(2, '0')}`;

  return (
    <>
      <Message {...rest}>
        <div className="schedule-form contaier">
          <div className="row">
            <label className="col-5" >진행상품</label>
            <input className="col-7" type="text" readOnly defaultValue={schedule?.items?.first()?.name} />
          </div>
          <div className="row">
            <label className="col-5" >날짜</label>
            <input className="col-7" name="date" type="date" readOnly
              defaultValue={date} />
          </div>
          <div className="row">
            <label className="col-5" >시간</label>
            <input className="col-7" name="time" type="time" readOnly
              defaultValue={`${scheduleAt?.getHours().toString().padStart(2, '0')}:${scheduleAt?.getMinutes().toString().padStart(2, '0')}`} />
          </div>
          <div className="row">
            <label className="col-5" >진행예정채널</label>
            <input className="col-7" name="channel" type="text" readOnly
              defaultValue={schedule?.expected_infos?.first()?.platform}
            />
          </div>
          <div className="row">
            <label className="col-5" >금액</label>
            <input className="col-7" name="pay" type="text" readOnly
              defaultValue={schedule?.fee} />
          </div>
          <div className="row justify-content-center mt-4">
            예약이 수락 되었습니다.
          </div>
        </div>
      </Message>
    </>
  )
}

export default React.memo(ScheduleAcceptMessage);