import React from "react";
import HorizontalLinearLayout from "../layouts/HorizontalLinearLayout";
import LiveCommerceItem from "../components/LiveCommerceItem";
import { ClassNameHelper } from "@loonfactory/common";
import UserSchedulesContentSliderContainer from "./UserSchedulesContentSliderContainer";
import { useIdentityContext } from "../contexts/IdentityContext";
import IScheduleDetailResource from "../farmerLounges/farmerLoungeResources/IScheduleDetailResource";
import { Link } from "react-router-dom";
import EndPoints from "../EndPoints";
import { IChallengeSchedulesOptions } from "../challenges";

const myLiveCommerceItemRenderer = (item: IScheduleDetailResource) => {
  return (
    <LiveCommerceItem
    />
  )
}

function MyLiveCommerceContaier({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) {
  const { userInfo } = useIdentityContext();

  const options = React.useMemo<IChallengeSchedulesOptions>(() => {
    return ({
      userId: userInfo?._id,
      state: 'closed',
    })
  }, [userInfo?._id])

  return (
    <div className={ClassNameHelper.concat("live-commerce-wrapper", className)}>
      <HorizontalLinearLayout className="justify-content-between fs-3 fw-semibold">
        <div className="title">내 <span className="text-primary">라이브커머스</span></div>
        <div className="title-option">
          <Link className="text-primary" to={EndPoints.matchingManagement}>더보기 +</Link>
        </div>
      </HorizontalLinearLayout>
      <UserSchedulesContentSliderContainer
        invisibleButton
        className="live-commerce-slider mt-2"
        options={options}
        renderer={myLiveCommerceItemRenderer}
      />
    </div>
  )
}

export default React.memo(MyLiveCommerceContaier);