import React from "react";
import RoleType from "../../farmerLounges/RoleType";
import RoleManager from "../../lib/RoleManager";
import DefaultPopup from "./DefaultPopup";
import IAbstractPopup from "./IAbstractPopup";

export interface IRechargPopupProps {
  name: string;
  role: RoleType;
}

export interface IRechargPopup extends IAbstractPopup<IRechargPopupProps> {
  name: 'recharg'
}

const profitAccountBannkName = process.env.REACT_APP_PROFIT_ACCOUNT_BANK_NAME;
const profitAccountNumber = process.env.REACT_APP_PROFIT_ACCOUNT_NUMBER;

function RechargPopup({
  name,
  role
}: IRechargPopupProps) {
  return (
    <DefaultPopup>
      <div className="text-center d-flex flex-column justify-content-center align-item-cetner h-100">
        <p>
          안녕하세요. {name} {RoleManager.getName(role)}님! <br />
          충전 요청 주셔서 감사합니다. <br />
          입금 계좌는 {profitAccountBannkName} {profitAccountNumber} 입니다. <br />
        </p>
        <br />
        <p>
          입금 확인 후 충전드리겠습니다.<br />
          충전이 완료되면, 충전 알림 문자가 발송됩니다.<br />
        </p>
      </div>
    </DefaultPopup >
  )
}

export default React.memo(RechargPopup);