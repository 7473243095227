import { ClassNameHelper } from "@loonfactory/common"
import React from "react";
import HorizontalLinearLayout from "../../layouts/HorizontalLinearLayout";

function HomeContentTitle({ className, ...rest }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <HorizontalLinearLayout
      className={ClassNameHelper.concat(
        "home-content-title",
        "align-items-baseline",
        className
      )}
      {...rest}
    />
  )
}

export default React.memo(HomeContentTitle);