import React from "react";
import FormInput, { IFormInputProps } from "./FormInputGroup";

function AccountHolderInputGroup({
  onChange,
  ...rest
}: IFormInputProps) {
  return (
    <FormInput
      label="예금주"
      name="accountHolder"
      type="text"
      placeholder="예금주를 입력해 주세요."
      onChange={onChange}
      {...rest}
    />
  )
}

export default React.memo(AccountHolderInputGroup);