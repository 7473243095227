import React, { useCallback } from "react";
import { IFormInputProps } from "./FormInputGroup";
import HorizontalLinearLayout from "../../../layouts/HorizontalLinearLayout";
import VerticalLinearLayout from "../../../layouts/VerticalLinearLayout";
import { ClassNameHelper } from "@loonfactory/common";

interface ICareerWrapperProps extends IFormInputProps {
  defaultValue?: string[];
}

function CareerWrapper({
  onChange,
  defaultValue = [],
  required,
  ...rest
}: ICareerWrapperProps) {
  const [inputFields, setInputFields] = React.useState<string[]>(["", ""]);

  React.useEffect(() => {
    if (defaultValue.length > 0) {
      setInputFields(defaultValue);
    }
  }, [defaultValue]);

  const handleAddInput = useCallback(() => {
    setInputFields(prevFields => [...prevFields, ""]);
  }, []);

  return (
    <div className="career-wrapper" {...rest}>
      <HorizontalLinearLayout className="justify-content-between fw-semibold fs-4">
        <div className={ClassNameHelper.concat(required && "required")}>경력사항</div>
        <div className="text-primary fs-5" onClick={handleAddInput} style={{ cursor: 'pointer' }}>+ 추가하기</div>
      </HorizontalLinearLayout>
      <VerticalLinearLayout className="row mt-2">
        {inputFields.map((value, index) => (
          <div className="col-6 mt-2" key={`career-${index}`}>
            <input
              name="career"
              type="text"
              placeholder="경력사항을 입력해 주세요. (39자 내외)"
              className="form-control"
              required={required}
              onChange={onChange}
              maxLength={39}
              defaultValue={value}
            />
          </div>
        ))}
      </VerticalLinearLayout>
    </div>
  )
}

export default React.memo(CareerWrapper);