import React from "react";
import IItemResource from "../../farmerLounges/farmerLoungeResources/IItemResource";
import IServiceError from "../../farmerLounges/IServiceError";
import { CancellationToken, CancellationTokenSource } from "@code-rabbits/core";
import farmerLoungeService from "../../farmerLounges/FarmerLoungeService";
import Paginations from "../../components/Paginations";
import DatasetHelper from "../../lib/DatasetHelper";
import IUserDetailResource from "../../farmerLounges/farmerLoungeResources/IUserDetailResource";
import ShowhostTable from "../../components/managemensts/showhosts/ShowhostTable";
import Showhosts from "../../components/managemensts/showhosts/Showhosts";
import PrimaryOutlineButton from "../../components/buttons/PrimaryOutlineButton";
import IUserResource from "../../farmerLounges/farmerLoungeResources/IUserResource";
import ServiceResult from "../../farmerLounges/ServiceResult";

interface IResultWithId<T> {
  id: number;
  result: ServiceResult<T>;
}

interface IShowhostManagerContaierProps extends React.HTMLAttributes<HTMLDivElement> {
  options?: Parameters<typeof farmerLoungeService.users.getPortfolios>[0];
  onDetailClick?: (evt: React.MouseEvent<HTMLButtonElement>, item: IUserDetailResource) => void;
}

async function requestsHelper<T>(
  ids: number[],
  func: (id: number) => Promise<ServiceResult<T>>
) {
  const results = await Promise.all(ids.map(async id => {
    let result = await func(id);
    return {
      id: id,
      result: result
    };
  }));

  return results;
}

function errorHelper<T>(items: IResultWithId<T>[], message: string, errorMessage: string) {
  const faileds = items.filter(item => !item.result.succeeded);
  let result = !faileds.any();
  const errors = faileds.map(item => ` • ${item.id}: ${item.result.errors.first().message}`).join("\n");
  let resultMessage: string = result ? message : `${errorMessage}\n${errors}`;

  return [result, resultMessage];
}

const ShowhostManagerContaier = React.memo(({
  options,
  className,
  onDetailClick,
  ...rest
}: IShowhostManagerContaierProps) => {
  const [selectedItemIds, setSelectedUsersIds] = React.useState<IItemResource['_id'][]>([]);
  const [errors, setErrors] = React.useState<IServiceError[]>();
  const [offset, setOffset] = React.useState<number>(0);
  const [pagePerCount] = React.useState<number>(20);
  const [totalCount] = React.useState<number>();
  const [users, setUsers] = React.useState<IUserResource[] | null>(null);

  const updatePortfoliosAsync = React.useCallback(async (cancellationToken?: CancellationToken) => {
    const result = await farmerLoungeService.users.getPortfolios(options);
    if (cancellationToken?.isCancellationRequested) {
      return;
    }

    if (!result.succeeded) {
      setErrors(result.errors);
      return;
    }

    setUsers(result.data!.users);
  }, [options])

  React.useEffect(() => {
    const cts = new CancellationTokenSource();
    updatePortfoliosAsync(cts.token);

    return () => {
      cts.cancel();
    }
  }, [updatePortfoliosAsync]);

  const handleAcceptClickAsync = React.useCallback(async (
    evt: React.MouseEvent<HTMLButtonElement>,
    item: IUserDetailResource
  ) => {
    evt.stopPropagation();
    evt.preventDefault();

    const result = await farmerLoungeService.users.updatePortfolioStateAsync(item._id, 'approved');
    if (!result.succeeded) {
      alert(result.errors.first().message);
      return;
    }

    alert("변경 성공");
    updatePortfoliosAsync();
  }, [updatePortfoliosAsync])

  const handleRejectClick = React.useCallback(async (
    evt: React.MouseEvent<HTMLButtonElement>,
    item: IUserDetailResource
  ) => {
    evt.stopPropagation();
    evt.preventDefault();

    const result = await farmerLoungeService.users.updatePortfolioStateAsync(item._id, 'denied');
    if (!result.succeeded) {
      alert(result.errors.first().message);
      return;
    }

    alert("변경 성공");
    updatePortfoliosAsync();
  }, [updatePortfoliosAsync]);

  const handleCheckedChangeAsync = React.useCallback((
    evt: React.ChangeEvent<HTMLInputElement>,
    item: IUserDetailResource
  ) => {
    const set = new Set(selectedItemIds);
    if (evt.currentTarget.checked) {
      set.add(item._id)
    } else {
      set.delete(item._id);
    }

    setSelectedUsersIds(Array.from(set));
  }, [selectedItemIds]);

  const handlePaginationIndexClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(evt => {
    const index = DatasetHelper.getInt(evt.currentTarget.dataset, "index");
    setOffset(index);
  }, []);

  const handleRecommendClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(async evt => {
    const requests = await requestsHelper(
      selectedItemIds,
      (id) => farmerLoungeService.users.recommandAsync(id, true)
    );

    const [, message] = errorHelper(
      requests,
      "지정된 모든 유저를 추천했습니다.",
      "지정된 유저들중 일부 유저를 추천하는 데에 실패했습니다."
    )

    alert(message);

    updatePortfoliosAsync();
  }, [selectedItemIds, updatePortfoliosAsync]);

  const handleUnrecommendClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(async evt => {
    const requests = await requestsHelper(
      selectedItemIds,
      (id) => farmerLoungeService.users.recommandAsync(id, false)
    );

    const [, message] = errorHelper(
      requests,
      "지정된 모든 유저를 추천 해제합니다.",
      "지정된 유저들중 일부 항목를 추천 해제 하는 데에 실패했습니다."
    )

    alert(message);

    updatePortfoliosAsync();
  }, [selectedItemIds, updatePortfoliosAsync]);

  return (
    <div className={className} {...rest}>
      <div className="row justify-content-end mt-3 mb-3">
        <div className="col-12 d-flex justify-content-end">
          <PrimaryOutlineButton className="mx-3" onClick={handleRecommendClick}>추천</PrimaryOutlineButton>
          <PrimaryOutlineButton className="mx-3" onClick={handleUnrecommendClick}>추천 해제</PrimaryOutlineButton>
        </div>
      </div>
      <div className="row">
        <ShowhostTable className="col-12">
          <Showhosts
            items={users}
            errors={errors}
            onAcceptClick={handleAcceptClickAsync}
            onRejectClick={handleRejectClick}
            onCheckedChange={handleCheckedChangeAsync}
            onDetailClick={onDetailClick}
          />
        </ShowhostTable>
        <div className="row">
          <div className="col-12 justify-content-center">
            <Paginations
              className="text-center"
              index={offset}
              totalCount={totalCount}
              pagePerCount={pagePerCount}
              onIndexClick={handlePaginationIndexClick}
            />
          </div>
        </div>
      </div>
    </div>
  )
});

export default React.memo(ShowhostManagerContaier);