import React from "react";
import IScheduleResource from "../../../farmerLounges/farmerLoungeResources/IScheduleResource";
import IScheduleDetailResource from "../../../farmerLounges/farmerLoungeResources/IScheduleDetailResource";
import { challengeScheduleDetailAsync, challengeUserDetailAsync } from "../../../challenges";
import { CancellationTokenSource } from "@code-rabbits/core";
import Heler from "../../../Helper";

interface IProductProps {
  item: IScheduleResource;
  onCheckedChange?: (evt: React.ChangeEvent<HTMLInputElement>, item: IScheduleResource) => void;
}

const Prodcut = React.memo(({
  item,
  onCheckedChange,
}: IProductProps) => {

  const [serviceProviderUserName, setServiceProviderUserName] = React.useState<string>();
  const [clientUserName, setClientUserName] = React.useState<string>();
  const [schedule, setSchedule] = React.useState<IScheduleDetailResource>();

  const handleCheckedChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(evt => {
    onCheckedChange && onCheckedChange(evt, item);
  }, [item, onCheckedChange]);

  React.useEffect(() => {
    const cts = new CancellationTokenSource();

    challengeUserDetailAsync(item.service_provider_id, cts.token).then(result => {
      setServiceProviderUserName(result.succeeded ? (result.data!.name ?? "-") : result.errors.first().message);
    }).catch(console.warn);

    challengeUserDetailAsync(item.client_id, cts.token).then(result => {
      setClientUserName(result.succeeded ? (result.data!.name ?? "-") : result.errors.first().message);
    }).catch(console.warn);

    challengeScheduleDetailAsync(item._id, cts.token).then(result => {
      if (result.succeeded) {
        setSchedule(result.data!)
      }
      else {
        console.warn(result);
      }
    }).catch(console.warn);
  }, [item._id, item.client_id, item.service_provider_id]);

  const date = schedule && new Date(schedule?.scheduled_at);
  return (
    <tr>
      <td><input type="checkbox" onChange={handleCheckedChange} /></td>
      <td>{item._id}</td>
      <td>{serviceProviderUserName}</td>
      <td>{clientUserName}</td>
      <td>{schedule?.items?.first().name}</td>
      <td>{schedule?.fee}</td>
      <td>{date && Heler.toDateTimeString(date)}</td>
      <td>{(schedule ?? item).state}</td>
      <td></td>
    </tr>
  )
});

export default React.memo(Prodcut);