import React from "react";
import ManagersLayout from "../../../../layouts/ManagersLayout";
import farmerLoungeService from "../../../../farmerLounges/FarmerLoungeService";
import ShowhostManagerContaier from "../../../../containers/managers/ShowhostManagerContaier";
import IUserDetailResource from "../../../../farmerLounges/farmerLoungeResources/IUserDetailResource";
import { useNavigate } from "react-router-dom";
import EndPoints from "../../../../EndPoints";

const options: Parameters<typeof farmerLoungeService.users.getPortfolios>[0] = undefined;

interface IShowhostManagementPageProps extends React.HTMLAttributes<HTMLDivElement> {

}

function ShowhostManagementPage(props: IShowhostManagementPageProps) {
  const navigate = useNavigate();

  const handleDetailClick = React.useCallback((evt: React.MouseEvent<HTMLButtonElement>, item: IUserDetailResource) => {
    navigate(`${EndPoints.manager.managements.showhostDetail}?id=${item._id}`);
  }, [navigate]);

  return (
    <ManagersLayout className={props.className}>
      <ShowhostManagerContaier
        className="container"
        options={options}
        onDetailClick={handleDetailClick}
      />
    </ManagersLayout>
  )
}

export default React.memo(ShowhostManagementPage);