import React from "react";
import INavItemProps from "./INavItemProps";
import NavItem from "./NavItem";
import LazyWalletIcon from "../../../icons/LazyWalletIcon";

function WalletNavItem(props: Omit<INavItemProps, 'children'>) {
  return (
    <NavItem {...props}>
      <LazyWalletIcon /> 지갑
    </NavItem>
  )
}

export default React.memo(WalletNavItem);