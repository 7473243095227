import ApiEndPoints from "./ApiEndPoints";
import ApiErrorCodeDescriptions from "./ApiErrorCodeDescriptions";
import FarmerLoungesBaseApiService from "./FarmerLoungesBaseApiService";
import IServiceResponse from "./IServiceResponse";
import ServiceResult from "./ServiceResult";
import ITokenInfo from "./farmerLoungeResources/ITokenInfo";

interface ILoginProperties {
  email: string;
  password: string;
}

interface ILoginResonseResult {
  token_type: string;
  access_token: string;
  max_age: number;
}

interface IAligoResult {
  result_code: number;
  message: string;
  msg_id?: number;
  success_cnt?: number;
  error_cnt?: number;
  msg_type?: string;
}

class AuthService extends FarmerLoungesBaseApiService {
  loginAsync = async (propertie: ILoginProperties): Promise<ServiceResult<void>> => {
    const url = this.buildUrl(ApiEndPoints.login);
    const response = await this.postJsonAsync(url, propertie);
    const result = (await response.json()) as IServiceResponse<ILoginResonseResult>;

    if (result.code !== 2000) {
      switch (result.code) {
        case 4040:
          return ServiceResult.failed({
            code: result.code,
            codeDescription: ApiErrorCodeDescriptions.usernameNotFound,
            message: '일치하는 아이디가 없습니다.',
          });
        case 4014:
          return ServiceResult.failed({
            code: result.code,
            codeDescription: ApiErrorCodeDescriptions.passwordsDoNotMatch,
            message: '비밀번호가 일치하지 않습니다.',
          });
        default:
          return this.globalErrorHandler(result);
      }
    }

    const { access_token, max_age } = result.result;
    const d = new Date();
    d.setTime(d.getTime() + (max_age * 1000));
    this.cookieProvider.setBanner(access_token, d);

    return ServiceResult.succeeded(undefined);
  }

  generatePhoneNumberConfirmationTokenAsync = async (phoneNumber: string) => {
    const url = this.buildUrl(ApiEndPoints.generatePhoneNumberConfirmationToken);

    const response = await this.postJsonAsync(url, {
      phone: phoneNumber,
    });

    const result = (await response.json()) as IServiceResponse<IAligoResult>;
    if (result.code !== 2000) {
      switch (result.code) {
        default:
          return this.globalErrorHandler(result);
      }
    }

    if (result.result.result_code < 0) {
      return ServiceResult.failed([{
        code: 11,
        codeDescription: ApiErrorCodeDescriptions.aligoError,
        message: result.result.message,
      }]);
    }

    return ServiceResult.succeeded(result.result);
  }

  confirmPhoneNumberAsync = async (phoneNumber: string, token: string) => {
    const url = this.buildUrl(ApiEndPoints.confirmPhoneNumber, {
      phone: phoneNumber,
      code: token,
    });

    const response = await this.getAsync(url);
    const result = (await response.json()) as IServiceResponse<boolean>;
    if (result.code !== 2000) {
      switch (result.code) {
        default:
          return this.globalErrorHandler(result);
      }
    }

    return ServiceResult.succeeded(result.result);
  }

  getTokenInfoAsync = async (): Promise<ServiceResult<ITokenInfo>> => {
    const url = this.buildUrl(ApiEndPoints.getTokenInfo);

    const response = await this.getAsync(url);
    const result = (await response.json()) as IServiceResponse<ITokenInfo>;
    if (result.code !== 2000) {
      switch (result.code) {
        case 4010:
          return ServiceResult.failed({
            code: 4010,
            codeDescription: ApiErrorCodeDescriptions.tokenIsRequired,
            message: '인증 토큰을 찾을수 없습니다.'
          })
        default:
          return this.globalErrorHandler(result);
      }
    }

    return ServiceResult.succeeded(result.result);
  }

  signOutAsync = (): Promise<ServiceResult<void>> => {
    this.cookieProvider.removeBanner();
    return Promise.resolve(ServiceResult.succeeded(undefined));
  }

  resetPasswordAsync = async (email: string): Promise<ServiceResult<void>> => {
    const url = this.buildUrl(ApiEndPoints.auth.resetPassword);

    const response = await this.postJsonAsync(url, {
      email: email
    });
    const result = (await response.json()) as IServiceResponse<void>;
    if (result.code !== 2000) {
      switch (result.code) {
        default:
          return this.globalErrorHandler(result);
      }
    }

    return ServiceResult.succeeded(result.result);
  }

  findUsernameAsync = async (name: string, phoneNumber: string): Promise<ServiceResult<string[]>> => {
    const url = this.buildUrl(ApiEndPoints.auth.findUsername, {
      name: name,
      phone: phoneNumber,
    });

    const response = await this.getAsync(url);
    const result = (await response.json()) as IServiceResponse<string[]>;
    if (result.code !== 2000) {
      switch (result.code) {
        case 4040:
          return ServiceResult.failed({
            code: 4040,
            codeDescription: ApiErrorCodeDescriptions.usernameNotFound,
            message: "전화번호와 이름을 확인해주세요."
          })
        default:
          return this.globalErrorHandler(result);
      }
    }

    return ServiceResult.succeeded(result.result);
  }

  isSignedIn = (): boolean => {
    return this.cookieProvider.getBanner() !== null;
  }
}

export default AuthService;