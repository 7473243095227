import { ClassNameHelper } from "@loonfactory/common";
import './RecommendSection.scss';
import React from "react";
import SearchBar from "../../components/SearchBar";

interface IRecommendSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  onSearchSubmit?: React.FormEventHandler<HTMLFormElement>;
}

function RecommendSection({
  className,
  children,
  onSearchSubmit,
  ...rest
}: IRecommendSectionProps) {

  return (
    <section className={ClassNameHelper.concat('find-showhost-section recommend-section', className)} {...rest}>
      <div className="recommend-content-wrapper ">
        <div className="search-bar-wrapper mt-5">
          <SearchBar onSubmit={onSearchSubmit} />
        </div>
        {children}
      </div>
    </section>
  )
}

export default React.memo(RecommendSection);