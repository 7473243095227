import React from "react";
import "./Thumbnail.scss";
import { ClassNameHelper } from "@loonfactory/common";

export interface IThmbnailProps {
  src?: string | null;
  width?: number | string;
  height?: number | string;
  className?: string;
}

function Thumbnail({
  src,
  width,
  height,
  className,
}: IThmbnailProps) {
  let img = src ? `url('${src}')` : `url(https://t4.ftcdn.net/jpg/04/73/25/49/360_F_473254957_bxG9yf4ly7OBO5I0O5KABlN930GwaMQz.jpg)`
  const thumbnailStyle = { backgroundImage: img, width: width, height: height }

  return (
    <div className={ClassNameHelper.concat("thumbnail-wrapper", className) }>
      <div className="thumbnail" style={thumbnailStyle} />
    </div>
  )
}

export default React.memo(Thumbnail);