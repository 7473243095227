import React from "react";
import "./SearchBar.scss";
import LazySearchIcon from "../../icons/LazySearchIcon";

interface ISearchBarProps extends React.HTMLAttributes<HTMLFormElement> {
  q?: string;
  className?: string;
}

function SearchBar({
  q,
  className,
  ...rest
}: ISearchBarProps) {

  return (
    <form className="search-bar fs-6" {...rest}>
      <input name="q" value={q} placeholder="검색어를 입력하세요." />
      <button className="btn" type="submit">
        <LazySearchIcon />
      </button>
    </form>
  )
}

export default React.memo(SearchBar);