import React from "react";
import ManagersLayout from "../../../../layouts/ManagersLayout";
import { IItemListGetOptions } from "../../../../farmerLounges/ItemsService";
import ProductsManagerContainer from "../../../../containers/managers/ProductsManagerContainer";
import IItemResource from "../../../../farmerLounges/farmerLoungeResources/IItemResource";
import EndPoints from "../../../../EndPoints";
import { useNavigate } from "react-router-dom";

interface IManagementPageProps extends React.HTMLAttributes<HTMLDivElement> {

}

const options: IItemListGetOptions = {
  role: 'agency'
}

function AgencyAdManagementPage(props: IManagementPageProps) {
  const navigate = useNavigate();


  const handleDetailClick = React.useCallback((
    evt: React.MouseEvent<HTMLButtonElement>,
    item: IItemResource
  ) => {
    navigate(`${EndPoints.manager.managements.advertiserAdDetail}?id=${item._id}`);
  }, [navigate]);


  return (
    <ManagersLayout className={props.className}>
      <ProductsManagerContainer
        className="container"
        options={options}
        onDetailClick={handleDetailClick}
      />
    </ManagersLayout>
  )
}

export default React.memo(AgencyAdManagementPage);