import React from "react";
import Message, { IMessageProps } from "./Message";
import PrimaryButton from "../buttons/PrimaryButton";
import { usePopupContext } from "../../contexts/PopupContext";
import IItemResource from "../../farmerLounges/farmerLoungeResources/IItemResource";
import ICreateScheduleSubmitProperties from "./ICreateScheduleSubmitProperties";

interface IScheduleCreateMessageProps extends Omit<IMessageProps, 'children'> {
  onCreateScheduleSubmit?: (evt: React.FormEvent<HTMLFormElement>, properties: ICreateScheduleSubmitProperties) => void;
}

function ScheduleCreateMessage({ onCreateScheduleSubmit, ...rest }: IScheduleCreateMessageProps) {
  const { setPopup } = usePopupContext();
  const [product, setProduct] = React.useState<IItemResource>();

  const handleProductPopupClick = React.useCallback((
    evt: React.MouseEvent<HTMLButtonElement>,
    product: IItemResource
  ) => {
    setProduct(product);
  }, [setProduct]);

  const handleSubmit = React.useCallback<React.FormEventHandler<HTMLFormElement>>(evt => {
    evt.preventDefault();
    evt.stopPropagation();

    if (product === undefined) {
      alert("상품을 선택해주세요");
      return;
    }

    const formdata = new FormData(evt.currentTarget);
    const date = formdata.get('date') as string;
    if (date === null || date.trim() === String.empty) {
      alert("시간을 선택해주세요");
      return;
    }

    const time = formdata.get('time') as string;
    if (time === null || time.trim() === String.empty) {
      alert("시간을 선택해주세요");
      return;
    }

    const channel = formdata.get('channel') as string;
    if (channel === null || channel.trim() === String.empty) {
      alert("채널을 입력해주세요");
      return;
    }

    let pay: string | number | null = formdata.get('pay') as string | null;
    if (pay === null) {
      alert("금액을 입력해주세요");
      return;
    }
    pay = Number.parseInt(pay);
    if (Number.isNaN(pay)) {
      alert("금액을 입력해주세요");
      return;
    }

    onCreateScheduleSubmit && onCreateScheduleSubmit(evt, {
      channel: channel,
      date: date,
      pay: pay,
      product: product,
      time: time,
    })
  }, [product, onCreateScheduleSubmit]);

  const handleProductClick = React.useCallback<React.MouseEventHandler<HTMLElement>>(evt => {
    setPopup("messageRoomProudct", {
      onAcceptClick: handleProductPopupClick,
    });
  }, [handleProductPopupClick, setPopup]);

  return (
    <Message {...rest}>
      <form className="schedule-form contaier" onSubmit={handleSubmit}>
        <div className="row" onClick={handleProductClick}>
          <label className="col-5" >진행상품</label>
          <input className="col-7" type="text" readOnly defaultValue={product?.name} />
        </div>
        <div className="row">
          <label className="col-5" >날짜</label>
          <input className="col-7" name="date" type="date" />
        </div>
        <div className="row">
          <label className="col-5" >시간</label>
          <input className="col-7" name="time" type="time" />
        </div>
        <div className="row">
          <label className="col-5" >진행예정채널</label>
          <input className="col-7" name="channel" type="text" />
        </div>
        <div className="row">
          <label className="col-5" >금액</label>
          <input className="col-7" name="pay" type="text" />
        </div>
        <div className="row justify-content-end mt-4">
          <PrimaryButton className="col-4" type="submit">예약 요청</PrimaryButton>
        </div>
      </form>
    </Message>
  )
}

export default React.memo(ScheduleCreateMessage);