import React from "react";
import PrimaryOutlineButton from "../../buttons/PrimaryOutlineButton";
import IUserDetailResource from "../../../farmerLounges/farmerLoungeResources/IUserDetailResource";
import { CancellationTokenSource } from "@code-rabbits/core";
import farmerLoungeService from "../../../farmerLounges/FarmerLoungeService";
import RoleManager from "../../../lib/RoleManager";

interface IUserProps {
  item: IUserDetailResource;
  onCheckedChange?: (evt: React.ChangeEvent<HTMLInputElement>, item: IUserDetailResource) => void;
  onDetailClick?: (evt: React.MouseEvent<HTMLButtonElement>, item: IUserDetailResource) => void;
}



const User = React.memo(({
  item,
  onDetailClick,
  onCheckedChange,
}: IUserProps) => {

  const [user, setUser] = React.useState<IUserDetailResource>();

  const handleCheckedChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(evt => {
    onCheckedChange && onCheckedChange(evt, item);
  }, [item, onCheckedChange]);

  const handleDetailClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(async evt => {
    onDetailClick && onDetailClick(evt, item);
  }, [item, onDetailClick]);

  React.useEffect(() => {
    const cts = new CancellationTokenSource();
    (async () => {
      const response = await farmerLoungeService.users.getDetailAsync(item._id);
      if (response.succeeded && !cts.isCancellationRequested) {
        setUser(response.data!);
      }
    })();

    return () => {
      cts.cancel();
    }
  }, [item._id])

  return (
    <tr>
      <td><input type="checkbox" onChange={handleCheckedChange} /></td>
      <td>{(user ?? item).email}</td>
      <td>{(user ?? item).name}</td>
      <td>{(user ?? item).birth}</td>
      <td>{(user ?? item).phone}</td>
      <td>
        {RoleManager.isAdmin(user?.role)
          ? null
          : <PrimaryOutlineButton onClick={handleDetailClick}>수정</PrimaryOutlineButton>
        }
      </td>
    </tr>
  )
});

export default React.memo(User);