
import React from "react";
import ManagersLayout from "../../../layouts/ManagersLayout";
import SchedulesManagerContainer from "../../../containers/managers/SchedulesManagerContainer";

interface IScheduleManagmentPageProps extends React.HTMLAttributes<HTMLDivElement> {

}

function ScheduleManagmentPage(props: IScheduleManagmentPageProps) {
  return (
    <ManagersLayout className={props.className}>
      <SchedulesManagerContainer className="container" />
    </ManagersLayout>
  )
}

export default React.memo(ScheduleManagmentPage);