import React from "react";
import INavItemProps from "./INavItemProps";
import NavItem from "./NavItem";
import LazyUserIcon from "../../../icons/LazyUserIcon";

function FindShowHostNavItem(props: Omit<INavItemProps, 'children'>) {
  return (
    <NavItem {...props}>
      <LazyUserIcon /> 쇼 호스트 찾기
    </NavItem>
  )
}

export default React.memo(FindShowHostNavItem);