import React from "react";
import LazyPouchWalletIcon from "../icons/LazyPouchWalletIcon";
import { ClassNameHelper } from "@loonfactory/common";
import PrimarySpeechBubble from "./PrimarySpeechBubble";

function numberWithCommas(value: number) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export interface IWalletProps extends React.HTMLAttributes<HTMLDivElement> {
  text?: string;
  value?: number;
}

function Wallet({
  value,
  text,
  className,
  ...rest
}: IWalletProps) {
  return (
    <PrimarySpeechBubble className={ClassNameHelper.concat(className, 'wallet')} {...rest}>
      <p>
        <LazyPouchWalletIcon />
        {text ?? "현재 보유중인 포인트"}
      </p>
      <p className="fw-semibold">{numberWithCommas(value ?? 0)}P</p>
    </PrimarySpeechBubble>
  )
}

export default React.memo(Wallet);