import ServiceErrorGenerator from "../ServiceErrorGenerator";
import ApiEndPoints from "./ApiEndPoints";
import ApiErrorCodeDescriptions from "./ApiErrorCodeDescriptions";
import FarmerLoungesBaseApiService from "./FarmerLoungesBaseApiService";
import IServiceResponse from "./IServiceResponse";
import RoleType from "./RoleType";
import ServiceResult from "./ServiceResult";
import IPortfolioListResource from "./farmerLoungeResources/IPortfolioListResource";
import ISNSResource from "./farmerLoungeResources/ISNSResource";
import IScheduleDetailListResource from "./farmerLoungeResources/IScheduleDetailListResource";
import IUserDetailListResource from "./farmerLoungeResources/IUserDetailListResource";
import IUserDetailResource from "./farmerLoungeResources/IUserDetailResource";
import IUserResource from "./farmerLoungeResources/IUserResource";
import IVideoResource from "./farmerLoungeResources/IVideoResource";
import IWorkInfoResource from "./farmerLoungeResources/IWorkInfoResource";
import ITransactionsListResource from "./transactions/ITransactionsListResource";

interface IPaginationOptions {
  page?: number;
  size?: number;
}

export interface IGetUserListOptions extends IPaginationOptions {
  owner_id?: string;
  state?: string;
  picked?: boolean;
  role?: RoleType;
}

export interface ICraeteUserPropreties {
  name: string;
  phone: string;
  verify_code: string;
  birth: string;
  email: string;
  password: string;
  role: string;
}

export interface IUpdatePortfolioProperties {
  userId: number;
  introduce?: string;
  work_info?: IWorkInfoResource[];
  sns?: ISNSResource[];
  videos?: IVideoResource[];
  career?: IUpdateCareerResource;
  images?: File[] | FileList;
}

export interface IUpdateAccountProperties {
  userId: number;
  bank?: string;
  account_holder?: string;
  account_number?: string;
  bankbook_image?: File | null;
  business_registration_number?: string;
  business_registration_image?: File | null;
  address?: string;
}

interface IUpdateCareerResource {
  period: string;
  descriptions: string[];
  content: string[];
  channel: string[];
}

interface IUserResult {
  user: {
    _id: number
  }
}


class UsersService extends FarmerLoungesBaseApiService {
  getListAsync = async (options?: IGetUserListOptions): Promise<ServiceResult<IUserDetailListResource>> => {
    const url = this.buildUrl(ApiEndPoints.users.getList, { ...options });
    const response = await this.getAsync(url);

    const result = (await response.json()) as IServiceResponse<IUserDetailListResource>;

    if (result.code !== 2000) {
      switch (result.code) {
        case 4000:
          return ServiceResult.failed({
            code: 4000,
            codeDescription: ApiErrorCodeDescriptions.invalidRoleType,
            message: "유효하지 않은 role을 입력하였습니다."
          });
        case 4010:
          return ServiceResult.failed({
            code: 4010,
            codeDescription: ApiErrorCodeDescriptions.tokenIsRequired,
            message: "우선 로그인을 해주세요."
          });
        default:
          return this.globalErrorHandler(result);
      }
    }

    return ServiceResult.succeeded(result.result)
  }

  getDetailAsync = async (userId: number): Promise<ServiceResult<IUserDetailResource>> => {
    const url = this.buildUrl(ApiEndPoints.users.getUserDetail.replaceAll("{id}", userId.toString()));
    const response = await this.getAsync(url);

    const result = (await response.json()) as IServiceResponse<IUserDetailResource>;

    if (result.code !== 2000) {
      switch (result.code) {
        default:
          return this.globalErrorHandler(result);
      }
    }

    let user = result.result;
    if (user === null) {
      return ServiceResult.failed(ServiceErrorGenerator.userInfoHasBeenRemoved);
    }

    user = {
      ...user,
      portfolio: user?.portfolio?.map(item => {
        return {
          ...item,
          sns: item.sns?.map(sns => JSON.parse(sns as unknown as string)) ?? null,
          introduce: JSON.parse(item.introduce),
          work_info: JSON.parse(item.work_info as unknown as string),
          videos: JSON.parse(item.videos as unknown as string),
          career: JSON.parse(item.career as unknown as string),
        }
      })
    }

    return ServiceResult.succeeded(user)
  }

  createAsync = async (user: ICraeteUserPropreties): Promise<ServiceResult<IUserResource>> => {
    const url = this.buildUrl(ApiEndPoints.users.creat);
    const response = await this.postJsonAsync(url, user);

    const result = (await response.json()) as IServiceResponse<IUserResource>;

    if (result.code !== 2000) {
      switch (result.code) {
        case 4000:
          return ServiceResult.failed({
            code: 4000,
            codeDescription: ApiErrorCodeDescriptions.verifyPhoneNumberTokenIsRequired,
            message: '인증번호가 유효하지 않습니다',
          })
        default:
          return this.globalErrorHandler(result);
      }
    }

    return ServiceResult.succeeded(result.result)
  }

  updatePortfolioAsync = async (item: IUpdatePortfolioProperties): Promise<ServiceResult<IUserResult>> => {
    const url = this.buildUrl(ApiEndPoints.users.updateProtfolioState.replaceAll('{id}', item.userId.toString()));
    const formData = new FormData();
    if (item.images !== undefined) {
      if (!(item.images instanceof Array)) {
        item.images = Array.from(item.images);
      }
    }

    (Object.keys(item) as (keyof IUpdatePortfolioProperties)[]).forEach(key => {
      [item[key]].flat().forEach(v => {
        if (key !== "images") {
          v = JSON.stringify(v);
        }

        formData.append(key, v as string | File);
      })
    })

    const response = await this.postAsync(url, formData);
    const result = (await response.json()) as IServiceResponse<IUserResult>;
    if (result.code !== 2000) {
      switch (result.code) {
        default:
          return this.globalErrorHandler(result);
      }
    }

    return ServiceResult.succeeded(result.result)
  }

  updatePortfolioStateAsync = async (userId: number, state: 'denied' | 'approved'): Promise<ServiceResult<IUserResult>> => {
    const url = this.buildUrl(ApiEndPoints.users.updateProtfolioState.replaceAll('{id}', userId.toString()));
    const response = await this.putJsonAsync(url, {
      state: state
    });
    const result = (await response.json()) as IServiceResponse<IUserResult>;
    if (result.code !== 2000) {
      switch (result.code) {
        default:
          return this.globalErrorHandler(result);
      }
    }

    return ServiceResult.succeeded(result.result)
  }

  updateAccountAsync = async (item: IUpdateAccountProperties): Promise<ServiceResult<IUserResult>> => {
    const url = this.buildUrl(ApiEndPoints.users.updateAccount.replaceAll('{id}', item.userId.toString()));

    const formData = new FormData();
    (Object.keys(item) as (keyof IUpdateAccountProperties)[]).forEach(key => {
      [item[key]].flat().forEach(v => {
        formData.append(key, v as string | File);
      })
    })

    const response = await this.postAsync(url, formData);
    const result = (await response.json()) as IServiceResponse<IUserResult>;
    if (result.code !== 2000) {
      switch (result.code) {
        default:
          return this.globalErrorHandler(result);
      }
    }

    return ServiceResult.succeeded(result.result)
  }

  getSchedulesAsync = async (userId: number, options?: IPaginationOptions): Promise<ServiceResult<IScheduleDetailListResource>> => {
    const url = this.buildUrl(ApiEndPoints.users.schedules.replaceAll('{id}', userId.toString()), { ...options });
    const response = await this.getAsync(url);

    const result = (await response.json()) as IServiceResponse<IScheduleDetailListResource>;

    if (result.code !== 2000) {
      switch (result.code) {
        default:
          return this.globalErrorHandler(result);
      }
    }

    return ServiceResult.succeeded(result.result);
  }

  getPortfolios = async (state?: 'pending' | 'denied' | 'approved' | 'deprecated') => {
    const url = this.buildUrl(ApiEndPoints.users.portfolios, { state: state });
    const response = await this.getAsync(url);

    const result = (await response.json()) as IServiceResponse<IPortfolioListResource>;

    if (result.code !== 2000) {
      switch (result.code) {
        default:
          return this.globalErrorHandler(result);
      }
    }

    let data = result.result;
    data.users = data.users.map(user => {
      return {
        ...user,
        portfolio: user.portfolio?.map(portfolio => {
          return {
            ...portfolio,
            sns: portfolio.sns?.map(sns => JSON.parse(sns as unknown as string)) ?? null,
            introduce: JSON.parse(portfolio.introduce),
            work_info: JSON.parse(portfolio.work_info as unknown as string),
            videos: JSON.parse(portfolio.videos as unknown as string),
            career: JSON.parse(portfolio.career as unknown as string),
          }
        })
      }
    })
    return ServiceResult.succeeded(result.result);
  }

  updateProfileImageAsync = async (userId: number, image: File) => {
    const url = this.buildUrl(ApiEndPoints.users.updateProfileImage.replaceAll("{id}", userId.toString()));
    const formData = new FormData();
    formData.append('image', image);

    const response = await this.postAsync(url, formData);

    const result = (await response.json()) as IServiceResponse<IUserResult>;

    if (result.code !== 2000) {
      switch (result.code) {
        default:
          return this.globalErrorHandler(result);
      }
    }

    return ServiceResult.succeeded(result.result);
  }

  updatePasswordAsync = async (userId: number, currentPassword: string, newPassword: string) => {
    const url = this.buildUrl(ApiEndPoints.users.updatePassword.replaceAll("{id}", userId.toString()));

    const response = await this.putJsonAsync(url, {
      old: currentPassword,
      password: newPassword
    });

    const result = (await response.json()) as IServiceResponse<IUserResult>;

    if (result.code !== 2000) {
      switch (result.code) {
        case 4000:
          if (result?.detail?.message === "비밀번호를 입력해주세요.") {
            return ServiceResult.failed([ServiceErrorGenerator.passwordIsRequired]);
          }
          return this.globalErrorHandler(result);
        case 4014:
          return ServiceResult.failed([ServiceErrorGenerator.invalidPassword]);
        default:
          return this.globalErrorHandler(result);
      }
    }

    return ServiceResult.succeeded(result.result);
  }

  deleteUserAsync = async (userId: number) => {
    const url = this.buildUrl(ApiEndPoints.users.delete.replaceAll("{id}", userId.toString()));
    const response = await this.deleteAsync(url);

    const result = (await response.json()) as IServiceResponse<IUserResult>;

    if (result.code !== 2000) {
      switch (result.code) {
        default:
          return this.globalErrorHandler(result);
      }
    }

    return ServiceResult.succeeded(result.result)
  }

  recommandAsync = async (id: IUserDetailResource['_id'], recommand: boolean): Promise<ServiceResult<IUserResult>> => {
    const url = this.buildUrl(ApiEndPoints.users.recommand.replaceAll('{id}', id.toString()));

    try {
      const response = await this.putJsonAsync(url, {
        picked: recommand
      });

      const result = (await response.json()) as IServiceResponse<IUserResult>;
      if (result.code !== 2000) {
        switch (result.code) {
          case 4000:
            return ServiceResult.failed(ServiceErrorGenerator.alreadyHasBeenUpdated);
          default:
            return this.globalErrorHandler(result);
        }
      }

      return ServiceResult.succeeded(result.result);
    } catch (e) {
      return ServiceResult.failed(ServiceErrorGenerator.unknownError)
    }
  }

  getTransactionsAsync = async (id: IUserDetailResource['_id'], type: 'deposit' | 'withdraw'): Promise<ServiceResult<ITransactionsListResource>> => {
    const url = this.buildUrl(ApiEndPoints.users.transactions.replaceAll('{id}', id.toString()), { type: type });

    try {
      const response = await this.getAsync(url);

      const result = (await response.json()) as IServiceResponse<ITransactionsListResource>;
      if (result.code !== 2000) {
        switch (result.code) {
          default:
            return this.globalErrorHandler(result);
        }
      }

      return ServiceResult.succeeded(result.result);
    } catch (e) {
      return ServiceResult.failed(ServiceErrorGenerator.unknownError)
    }
  }
}

export default UsersService;