import React, { Suspense } from 'react';

const KakaoLiteralIcon = React.lazy(() => import('./KakaoLiteralIcon'));

function LazyKakaoLiteralIcon(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <Suspense fallback={<svg width="2500" height="2500" viewBox="0 0 256 256"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />}>
      <KakaoLiteralIcon {...props} />
    </Suspense>
  )
}

export default React.memo(LazyKakaoLiteralIcon)