import React from "react";
import IDashBoardPageProps from "../../DashBoardPage/IDashBoardPageProps";
import MainLayout from "../../../layouts/MainLayout";
import { ClassNameHelper } from "@loonfactory/common";
import MyLiveCommerceContaier from "../../../containers/MyLiveCommerceContainer";
import MyProductList from "../../../components/MyProductList";
import HorizontalLinearLayout from "../../../layouts/HorizontalLinearLayout";
import DashBoardWallet from "../../../components/DashBoardWallet";
import TalkingHostContainer from "../../../containers/rooms/TalkingHostContainer";
import { useIdentityContext } from "../../../contexts/IdentityContext";
import './AdvertiserDashBoardSection.scss';

function AdvertiserDashBoardPage(props: IDashBoardPageProps) {

  const { userInfo } = useIdentityContext();

  return (
    <MainLayout className={props.className}>
      <section className={ClassNameHelper.concat('advertiser-dashboard-section', 'main-section')}>
        <div className="content-region mt-4">
          <MyLiveCommerceContaier className="mt-4" />
          <MyProductList className="mt-5" />
          <HorizontalLinearLayout className="advertiser-section mt-5">
            <DashBoardWallet value={userInfo?.wallet?.point} />
            <TalkingHostContainer />
          </HorizontalLinearLayout>
        </div>
      </section>
    </MainLayout>
  )
}

export default React.memo(AdvertiserDashBoardPage);