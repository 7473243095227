import React from "react";
import HomeContentTitle from "../components/homeContents/HomeContentTitle";
import HomeContentWrapper from "../components/homeContents/HomeContentWrapper";
import "@loonfactory/common/JsExtensions";
import LiveBroadcastItem, { ILiveBroadcastItemProps } from "../components/LiveBroadcastItem";
import ContentSlider from "../components/ContentSlider";
import VerticalLinearLayout from "../layouts/VerticalLinearLayout";

const liveBroadcastItemRenderer = (item: ILiveBroadcastItemProps) => {
  return (
    <LiveBroadcastItem
      channelName={item.channelName}
      videoUrl={item.videoUrl}
      title={item.title}
    />

  )
}

const selectLiveBroadcastItemKey = (item: ILiveBroadcastItemProps, index: number, ary: ILiveBroadcastItemProps[]) => {
  return index;
}

function LiveBroadcastContaier() {
  const [items, setItems] = React.useState<ILiveBroadcastItemProps[]>([]);

  React.useEffect(() => {
    let f = false;
    (async () => {
      const result = await fetch(`/json/liveBroadcastItems.json?t=${new Date().toJSON()}`);
      const data = await result.json();
      if (f) return;
      setItems(data);
    })();

    return () => {
      f = true;
    }
  }, [])

  return (
    <HomeContentWrapper className="d-grid mt-4 live-broadcast-wrapper">
      <HomeContentTitle className="justify-content-center mt-4">
        <VerticalLinearLayout className="live-title justify-content-center align-items-center color-white">
          <div className="fs-2 fw-bold title">지난 <span className="text-red">방송</span></div>
          <div className="fs-3">과거에 진행된 방송입니다.</div>
        </VerticalLinearLayout>
      </HomeContentTitle>
      <ContentSlider
        className="live-broadcast-slider mt-4"
        items={items}
        keySelector={selectLiveBroadcastItemKey}
        renderer={liveBroadcastItemRenderer}
      />
    </HomeContentWrapper>
  )
}

export default React.memo(LiveBroadcastContaier);