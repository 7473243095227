import React from "react";
import IPostResource from "../../../../farmerLounges/posts/IPostResource";
import Thumbnail from "../../../Thumbnail";
import IUserDetailResource from "../../../../farmerLounges/farmerLoungeResources/IUserDetailResource";
import farmerLoungeService from "../../../../farmerLounges/FarmerLoungeService";
import { CancellationTokenSource } from "@code-rabbits/core";
import PrimaryButton from "../../../buttons/PrimaryButton";

interface IPostTableItemProps {
  item: IPostResource;
  onCheckedChange?: (evt: React.ChangeEvent<HTMLInputElement>, item: IPostResource) => void;
  onEditClick?: (evt: React.MouseEvent<HTMLButtonElement>, item: IPostResource) => void;
}

const PostTableItem = React.memo(({
  item,
  onEditClick,
  onCheckedChange,
}: IPostTableItemProps) => {

  const [user, setUser] = React.useState<IUserDetailResource>();

  React.useEffect(() => {
    let cts = new CancellationTokenSource();
    (async () => {
      const result = await farmerLoungeService.users.getDetailAsync(item.author_id)
      if (!result.succeeded) {
        return;
      }

      if (cts.isCancellationRequested) {
        return;
      }

      setUser(result.data!);

      return () => {
        cts.cancel();
      }
    })();
  }, [item.author_id]);

  const handleCheckedChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(evt => {
    onCheckedChange && onCheckedChange(evt, item);
  }, [item, onCheckedChange]);

  const handleEditClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(async evt => {
    onEditClick && onEditClick(evt, item);
  }, [item, onEditClick]);

  const createDate = new Date(item.created_at);
  const deleteDate = item.deleted_at && new Date(item.deleted_at);

  return (
    <tr>
      <td><input type="checkbox" onChange={handleCheckedChange} /></td>
      <td>{item._id}</td>
      <td>{item.title}</td>
      <td>{user?.email}</td>
      <td>{createDate.toLocaleString()}</td>
      <td>{deleteDate?.toLocaleString() ?? "-"}</td>
      <td>
        <Thumbnail className="w-100" height={100} src={item.files.first().location} />
      </td>
      <td>
        <PrimaryButton onClick={handleEditClick}>수정하기</PrimaryButton>
      </td>
    </tr>
  )
});

export default React.memo(PostTableItem);