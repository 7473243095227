import AuthService from "./AuthService";
import BaseApiService from "./FarmerLoungesBaseApiService";
import ItemsService from "./ItemsService";
import UsersService from "./UsersService";
import AnalyticsService from "./analytics/AnalyticsService";
import EmailService from "./emails/EmailService";
import SchedulesService from "./farmerLoungeResources/schedules/SchedulesService";
import PointsService from "./points/PointsService";
import PostService from "./posts/PostsService";
import RoomService from "./rooms/RoomService";
import TransactionsService from "./transactions/TransactionsService";
import WalletsService from "./wallets/WalletsService";

class FarmerLoungeService extends BaseApiService {
  private _authService: AuthService | null = null;
  private _itemsSevice: ItemsService | null = null;
  private _userService: UsersService | null = null;
  private _roomService: RoomService | null = null;
  private _schedulesService: SchedulesService | null = null;
  private _email: EmailService | null = null;
  private _points: PointsService | null = null;
  private _transactions: TransactionsService | null = null;
  private _wallets: WalletsService | null = null;
  private _posts: PostService | null = null;
  private _analytics: AnalyticsService | null = null;

  public get accessToken(): string | null {
    return this.cookieProvider.getBanner();
  }

  public get auth(): AuthService {
    this._authService ??= new AuthService(this.origin);
    return this._authService;
  }

  public get items(): ItemsService {
    this._itemsSevice ??= new ItemsService(this.origin);
    return this._itemsSevice;
  }

  public get users(): UsersService {
    this._userService ??= new UsersService(this.origin);
    return this._userService;
  }

  public get rooms(): RoomService {
    this._roomService ??= new RoomService(this.origin);
    return this._roomService;
  }

  public get schedules(): SchedulesService {
    this._schedulesService ??= new SchedulesService(this.origin);
    return this._schedulesService;
  }

  public get email(): EmailService {
    this._email ??= new EmailService(this.origin);
    return this._email;
  }

  public get points(): PointsService {
    this._points ??= new PointsService(this.origin);
    return this._points;
  }

  public get transactions(): TransactionsService {
    this._transactions ??= new TransactionsService(this.origin);
    return this._transactions;
  }

  public get wallets(): WalletsService {
    this._wallets ??= new WalletsService(this.origin);
    return this._wallets;
  }

  public get posts(): PostService {
    this._posts ??= new PostService(this.origin);
    return this._posts;
  }

  public get analytics(): AnalyticsService {
    this._analytics ??= new AnalyticsService(this.origin);
    return this._analytics;
  }
}

const farmerLoungeService = new FarmerLoungeService('https://api.farmerlounge.com');
export default farmerLoungeService;