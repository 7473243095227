import React from "react";
import FormInput, { IFormInputProps } from "./FormInputGroup";

function BusinessRegistrationInputGroup({
  label,
  placeholder,
  onChange,
  ...rest
}: IFormInputProps) {
  return (
    <FormInput
      label={label ?? "사업자등록번호"}
      name="businessRegistration"
      type="text"
      placeholder={placeholder ?? "사업자등록번호를 입력해 주세요."}
      onChange={onChange}
      {...rest}
    />
  )
}

export default React.memo(BusinessRegistrationInputGroup);