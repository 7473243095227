import React from "react";
import { useNavigate } from "react-router-dom";
import EndPoints from "../../EndPoints";
import farmerLoungeService from "../../farmerLounges/FarmerLoungeService";

function LogoutButton(props: React.HTMLAttributes<HTMLButtonElement>) {
  const navigate = useNavigate();

  const handleLogoutClick = React.useCallback(async () => {
    await farmerLoungeService.auth.signOutAsync();
    navigate(EndPoints.home);
  }, [navigate]);

  return (
    <button onClick={handleLogoutClick} className="btn btn-danger" {...props}>
      로그아웃
    </button>
  )
}

export default React.memo(LogoutButton);